import React from 'react';

const panelTechnicalSheet = (styles, isSmallScreen) => (
  <>
    <p>
      <strong>SPARE</strong> | Sistema de Planeamento e Avaliação de Refeições
      Escolares - <br />
      Elaboração, verificação e monitorização na ótica do utilizador
    </p>
    <p>
      <strong>Edição</strong>
      <br />
      Faculdade de Ciências da Nutrição e Alimentação da Universidade do Porto -
      <br />
      FCNAUP
    </p>
    <p>
      <strong style={{ color: 'rgb(73, 119, 100)' }}>1ª Edição</strong>
      <br />
      dezembro 2009
    </p>
    <div style={{ paddingLeft: '1rem' }}>
      <p>
        <strong>Coordenação</strong>
        <br />
        Ada Rocha
      </p>
      <p>
        <strong>Desenvolvimento de software</strong> <br />
        Rui Chilro
      </p>
      <p>
        <strong>Design</strong> <br />
        Bela Franchini
      </p>
      <p>
        <strong>Equipa de trabalho</strong> <br />
        M.ª Cristina T. Santos, Cláudia Afonso, Bela Franchini, Cecília Morais
      </p>
      <p>
        <strong>ISBN</strong>
        <br />
        972-96840
      </p>
    </div>
    <p>
      <strong style={{ color: 'rgb(73, 119, 100)' }}>2ª Edição</strong>
      <br />
      julho 2014
    </p>
    <div style={{ paddingLeft: '1rem' }}>
      <p>
        <strong>Coordenação</strong>
        <br />
        Ada Rocha
      </p>
      <p>
        <strong>Desenvolvimento de software</strong> <br />
        Rui Chilro
      </p>
      <p>
        <strong>Design</strong> <br />
        Cristina Amil, Mariana Rei, Bela Franchini
      </p>
      <p>
        <strong>Equipa de trabalho</strong> <br />
        M.ª Cristina T. Santos, Cláudia Afonso, Bela Franchini, Cecília Morais,
        Margarida Liz
      </p>
      <p>
        <strong>ISBN</strong>
        <br />
        972-96840
      </p>
    </div>
    <div className={styles.div}>
      <div>
        <p>
          <strong style={{ color: 'rgb(73, 119, 100)' }}>3ª Edição</strong>
          <br />
          dezembro 2022
        </p>
      </div>
      <div>
        <img
          src={`${process.env.PUBLIC_URL}/logos/lgp.png`}
          alt="Linking Great Partners - FEUP"
          className={isSmallScreen ? styles.smallImg : styles.img}
        />
      </div>
    </div>
    <div style={{ paddingLeft: '1rem' }}>
      <p>
        <strong>Coordenação</strong>
        <br />
        Ada Rocha
      </p>
      <p>
        <strong>Desenvolvimento de software</strong> <br />
        Abel Tiago, Duarte Carvalho, Fábio Gaspar, Fábio Lino, Gonçalo Santos,
        João Bernardo, Sandro Campos, Miguel Moura, Bruno Fernandes, Fábio
        Oliveira, João Gonçalves, João Lima, Mariana Costa, Pedro Fernandes,
        Tiago Cardoso <br />
        Com o contributo da JuniFEUP
      </p>
      <p>
        <strong>Design</strong> <br />
        Letícia Paulucci
      </p>
      <p>
        <strong>Serviços e Gestão</strong>
        <br />
        Maria Laura Cardoso Pinto Ferreira da Silva
        <br />
        Mariana Grijó Poças Ribeiro da Silva
      </p>
      <p>
        <strong>Equipa de trabalho</strong>
        <br />
        M.ª Cristina T. Santos, Cláudia Afonso, Bela Franchini, Cecília Morais
      </p>
    </div>
  </>
);

export default panelTechnicalSheet;
