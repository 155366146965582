import React from 'react';
import { Autocomplete, Alert } from '@material-ui/lab';
import {
  FormGroup,
  TextField,
  MenuItem,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';

import Title from './Title';
import { Icon } from '../../common';

import styles from './TechnicalSheetForm.module.css';
import { units, useStyles } from './Ingredients';

const TechnicalSheets = ({
  technicalSheets,
  selectedTechnicalSheets,
  technicalSheetError,
  removeSelectedTechnicalSheet,
  selectTechnicalSheet,
  updateSelectedTechnicalSheetQty,
  updateSelectedTechnicalSheetUnit,
  retryTechnicalSheetFetch,
  technicalSheetId,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  function addTechnicalSheet(_, value) {
    selectTechnicalSheet(value);
  }

  const sortedTechnicalSheets = technicalSheets
    ?.sort(({ type: t1 }, { type: t2 }) => {
      if (t1 > t2) {
        return 1;
      }
      if (t1 < t2) {
        return -1;
      }
      return 0;
    })
    .filter(
      ({ id }) =>
        !Object.keys(selectedTechnicalSheets).includes(`technicalSheet${id}`) &&
        id !== Number(technicalSheetId),
    );

  return (
    <>
      <FormGroup>
        <Title text="Fichas Técnicas" />
        {// eslint-disable-next-line
        technicalSheets ? (
          <>
            {Object.keys(selectedTechnicalSheets).length > 0 ? (
              <List classes={{ root: classes.list }}>
                {Object.keys(selectedTechnicalSheets)
                  .filter(
                    unfiltered =>
                      selectedTechnicalSheets[unfiltered] !== undefined,
                  )
                  .map(selected => {
                    const {
                      componentType,
                      name,
                      amount,
                      unit,
                      id,
                    } = selectedTechnicalSheets[selected];

                    return (
                      <ListItem
                        key={`selected_ingredient${selected}`}
                        classes={{ root: classes.listItem }}
                      >
                        <ListItemAvatar>
                          <Icon name={componentType} />
                        </ListItemAvatar>
                        <div
                          className={
                            isMobile
                              ? classes.mobileListItemContent
                              : classes.listItemContent
                          }
                        >
                          <ListItemText className={classes.listItemText}>
                            {name}
                          </ListItemText>
                          <div
                            className={
                              isMobile
                                ? classes.mobileSecondaryAction
                                : classes.secondaryAction
                            }
                            
                          >
                            <TextField
                              label="Quantidade"
                              id={`quantity_${selected}`}
                              type="number"
                              inputProps={{ step: '1', min: '0' }}
                              value={amount}
                              name={selected}
                              color="secondary"
                              onChange={updateSelectedTechnicalSheetQty}
                            />
                            <TextField
                              label="Unidade"
                              select
                              id={`unit_${selected}`}
                              value={unit ?? 'un.'}
                              color="secondary"
                              name={selected}
                              onChange={updateSelectedTechnicalSheetUnit}
                            >
                              {units.map(un => (
                                <MenuItem key={un} value={un}>
                                  {un}
                                </MenuItem>
                              ))}
                            </TextField>
                            <IconButton
                              onClick={() => removeSelectedTechnicalSheet(id)}
                            >
                              <Close />
                            </IconButton>
                          </div>
                        </div>
                      </ListItem>
                    );
                  })}
              </List>
            ) : (
              <Typography color="textSecondary">
                Selecione uma ficha técnica para determinar a sua quantidade
              </Typography>
            )}
            <Autocomplete
              autoComplete
              className={styles.formSearch}
              label="Pesquisa de Fichas Técnicas"
              options={sortedTechnicalSheets}
              groupBy={({ type }) => type}
              getOptionLabel={({ name }) => name}
              key={Object.keys(selectedTechnicalSheets).length}
              onChange={addTechnicalSheet}
              renderInput={params => (
                <TextField
                  {...params}
                  id="technicalSheetSearchInput"
                  label="Pesquisa de Fichas Técnicas"
                  color="secondary"
                />
              )}
            />
          </>
        ) : !technicalSheetError ? (
          <Alert>A obter as fichas técnicas...</Alert>
        ) : (
          <Alert
            className={styles.retryAlert}
            color="warning"
            onClick={retryTechnicalSheetFetch}
          >
            Ocorreu um erro a tentar obter as fichas técnicas, carregue aqui
            para tentar novamente
          </Alert>
        )}
      </FormGroup>
    </>
  );
};

TechnicalSheets.defaultProps = {
  technicalSheets: null,
};

TechnicalSheets.propTypes = {
  selectedTechnicalSheets: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any))
    .isRequired,
  technicalSheets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  technicalSheetError: PropTypes.bool.isRequired,
  removeSelectedTechnicalSheet: PropTypes.func.isRequired,
  updateSelectedTechnicalSheetQty: PropTypes.func.isRequired,
  updateSelectedTechnicalSheetUnit: PropTypes.func.isRequired,
  selectTechnicalSheet: PropTypes.func.isRequired,
  retryTechnicalSheetFetch: PropTypes.func.isRequired,
  technicalSheetId: PropTypes.string.isRequired,
};

export default TechnicalSheets;
