import React from 'react';
import Layout from '../components_lgp/layout/Layout';
import Page from '../components/common/Page';
import AdminOptions from '../components_lgp/ChooseModule/AdminOptions';

const Admin = () => (
  <Page title="Administração">
    <Layout>
      <AdminOptions />
    </Layout>
  </Page>
);

export default Admin;
