import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  TextField,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Title from '../../TechnicalSheets/Form/Title';

export const useStyles = makeStyles(() => ({
  unit: {
    width: '3em',
    marginTop: 'auto',
  },
  qty: {
    width: '4em',
  },
  picker: {
    marginBottom: '1em',
  },
  alert: {
    marginTop: '1em',
  },
  retry: {
    extend: 'alert',
    cursor: 'pointer',
  },
}));

const PickNutritionalCriteria = ({
  nutritionalCriteria,
  selectedNutritionalCriteria,
  removeSelectedNutritionalCriteria,
  updateSelectedNutritionalCriteriaValue,
  selectNutritionalCriteria,
  fetchError,
  retryNutritionalCriteriaFetch,
}) => {
  const classes = useStyles();

  const sortedNutritionalCriteria = nutritionalCriteria
    ?.sort(({ is_essential: iE1 }, { is_essential: iE2 }) => {
      if (iE1 < iE2) {
        return 1;
      }
      if (iE1 > iE2) {
        return -1;
      }
      return 0;
    })
    .filter(
      ({ id }) =>
        !Object.keys(selectedNutritionalCriteria).includes(
          `nutritionalCriteria${id}`,
        ),
    );

  function addNutritionalCriteria(_, value) {
    if (value) selectNutritionalCriteria(value);
  }

  function editNutritionalCriteria(id, { target: { value } }) {
    if (id) updateSelectedNutritionalCriteriaValue({ id, value });
  }

  return (
    <FormGroup classes={{ root: classes.picker }}>
      <Title text="Informação Nutricional" />
      {// eslint-disable-next-line
      nutritionalCriteria ? (
        <>
          {Object.keys(selectedNutritionalCriteria).length > 0 ? (
            <div>
              <List>
                {Object.keys(selectedNutritionalCriteria)
                  .filter(
                    unfiltered =>
                      selectedNutritionalCriteria[unfiltered] !== undefined,
                  )
                  .map(selected => {
                    const { name, value, id } = selectedNutritionalCriteria[
                      selected
                    ];
                    return (
                      <ListItem key={`${selected}`}>
                        <ListItemText>{name}</ListItemText>
                        <Tooltip
                          title={`Por cada 100 ${
                            nutritionalCriteria.find(
                              nutritionalCriterium =>
                                nutritionalCriterium.name === name,
                            ).edible
                          } de parte edível`}
                        >
                          <TextField
                            label="Quantidade"
                            type="number"
                            name={selected}
                            id={`${selected}`}
                            classes={{ root: classes.qty }}
                            required
                            color="secondary"
                            value={value}
                            inputProps={{ max: 100, min: 0 }}
                            onChange={event =>
                              editNutritionalCriteria(id, event)
                            }
                            pattern="[0-9]*[.,]?[0-9]+"
                          />
                        </Tooltip>
                        <Typography classes={{ root: classes.unit }}>
                          {
                            nutritionalCriteria.find(
                              nutritionalCriterium =>
                                nutritionalCriterium.name ===
                                selectedNutritionalCriteria[selected].name,
                            ).unit
                          }
                        </Typography>
                        <IconButton
                          onClick={() => removeSelectedNutritionalCriteria(id)}
                        >
                          <Close />
                        </IconButton>
                      </ListItem>
                    );
                  })}
              </List>
            </div>
          ) : (
            <Typography color="textSecondary">
              Selecione um critério nutricional para determinar o seu valor
            </Typography>
          )}
          <Autocomplete
            autoComplete
            style={{ marginLeft: '24px', marginRight: '16px' }}
            label="Pesquisar Critérios Nutricionais"
            options={sortedNutritionalCriteria}
            groupBy={({ is_essential: isEssential }) =>
              isEssential ? 'Essenciais' : 'Suplementares'
            }
            key={Object.keys(selectedNutritionalCriteria).length}
            getOptionLabel={({ name }) => name}
            onChange={addNutritionalCriteria}
            renderInput={params => (
              <TextField
                {...params}
                id="technicalSheetSearchInput"
                label="Pesquisar Critérios Nutricionais"
                color="secondary"
              />
            )}
          />
        </>
      ) : !fetchError ? (
        <Alert classes={{ root: classes.alert }}>
          A obter os critérios nutricionais...
        </Alert>
      ) : (
        <Alert
          color="warning"
          onClick={retryNutritionalCriteriaFetch}
          classes={{ root: classes.retry }}
        >
          Ocorreu um erro a tentar obter os critérios nutricionais, carregue
          aqui para tentar novamente
        </Alert>
      )}
    </FormGroup>
  );
};

PickNutritionalCriteria.defaultProps = {
  nutritionalCriteria: null,
};

PickNutritionalCriteria.propTypes = {
  nutrientsLoading: PropTypes.bool.isRequired,
  fetchError: PropTypes.bool.isRequired,
  nutritionalCriteria: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.integer,
      name: PropTypes.string,
    }),
  ),
  searchNutritionalCriteria: PropTypes.string.isRequired,
  selectedNutritionalCriteria: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.number,
    }),
  ).isRequired,
  removeSelectedNutritionalCriteria: PropTypes.func.isRequired,
  updateSelectedNutritionalCriteriaValue: PropTypes.func.isRequired,
  updateNutritionalCriteriaSearchTerm: PropTypes.func.isRequired,
  selectNutritionalCriteria: PropTypes.func.isRequired,
  retryNutritionalCriteriaFetch: PropTypes.func.isRequired,
};

export default PickNutritionalCriteria;
