import React, { useReducer, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import OrganizationListItem from './OrganizationListItem';
import OrganizationSubmit from './OrganizationSubmit';
import {
  fetchAllOrganizations,
  deleteOrganization,
  patchOrganization,
  createOrganization,
} from '../../../services/organizationsService';
import { setNotification } from '../../../actions/notificationActions';

const organizationsReducer = (state, action) => {
  switch (action.type) {
    case 'init':
      return action.payload;
    case 'delete':
      return state.filter(org => org.id !== action.payload.id);
    case 'edit':
      return state.map(org => {
        return org.id === action.payload.id
          ? { ...org, ...action.payload }
          : org;
      });
    case 'new':
      return [...state, { id: action.payload.id, name: action.payload.name }];
    default:
      throw new Error();
  }
};

const OrganizationList = () => {
  const dispatch = useDispatch();
  /** Reducer to manage the list of organizations */
  const [organizations, organizationsDispatcher] = useReducer(
    organizationsReducer,
    [],
  );
  /**
   * State hook to manage if the dialog should be open or not.
   * If it should be open, then the user triggered a delete action for a given
   * organization.
   * Therefore, additional info like the ID and name MUST be present if 'open'
   * is true.
   */
  const [dialog, setDialog] = useState({
    open: false,
    organizationId: null,
    organizationName: null,
  });
  /**
   * A run-once hook to fetch all organizations from backend
   */
  useEffect(() => {
    fetchAllOrganizations()
      .then(({ data }) => {
        organizationsDispatcher({ type: 'init', payload: data });
      })
      .catch(() =>
        dispatch(
          setNotification({
            message: 'Não foi possível carregar as suas organizações',
            type: 'error',
          }),
        ),
      );
  }, [dispatch]);
  /**
   * A function to be called when the user attempts to delete an organization.
   * It will show a dialog to confirm that action and if the user proceeds to
   * delete, an API call is made and state is updated.
   * @see onDeleteAcceptClick
   * @see organizations
   */
  const onDeleteClick = (organizationId, organizationName) => {
    setDialog({ open: true, organizationId, organizationName });
  };
  /**
   * Function to be called when the user accepts to delete an organization.
   * The dialog will close, the API call is made and if sucessful updates
   * the internal state.
   */
  const onDeleteAcceptClick = () => {
    setDialog({ open: false });
    deleteOrganization(dialog.organizationId)
      .then(() => {
        organizationsDispatcher({
          type: 'delete',
          payload: { id: dialog.organizationId },
        });
      })
      .catch(() =>
        dispatch(
          setNotification({
            message: 'Não foi possível apagar a organização',
            type: 'error',
          }),
        ),
      );
  };
  /**
   * Function to be called when the user is editing an organization and
   * saves the changes. It makes the API call and updates internal state
   * @param {numer} organizationId - The organization's ID being changed
   * @param {string} organizationNewName - The new organizaion name
   */
  const onEditClick = (organizationId, organizationNewName) => {
    patchOrganization(organizationId, organizationNewName)
      .then(() => {
        organizationsDispatcher({
          type: 'edit',
          payload: { id: organizationId, name: organizationNewName },
        });
      })
      .catch(() =>
        dispatch(
          setNotification({
            message: 'Não foi possível editar a organização',
            type: 'error',
          }),
        ),
      );
  };
  /**
   * Function to be called when the user submits a new organization.
   * It makes an API call and if successful it updates internal state
   * @param {string} organizationName - The organization name
   */
  const onSubmitClick = organizationName => {
    createOrganization(organizationName)
      .then(({ data }) =>
        organizationsDispatcher({
          type: 'new',
          payload: { id: data.id, name: data.name },
        }),
      )
      .catch(() =>
        dispatch(
          setNotification({
            message: 'Não foi possível criar a nova organização',
            type: 'error',
          }),
        ),
      );
  };

  return (
    <>
      <List>
        {organizations.length > 0 ? (
          organizations.map(({ id, name }) => (
            <OrganizationListItem
              key={id}
              organizationName={name}
              organizationId={id}
              onDeleteClick={onDeleteClick}
              onEditClick={onEditClick}
            />
          ))
        ) : (
          <Typography align="center">Ainda não tem organizações!</Typography>
        )}
      </List>
      <OrganizationSubmit onSubmitClick={onSubmitClick} />
      <Dialog
        open={dialog.open}
        onClose={() => setDialog({ open: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Apagar organização?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem a certeza que pretende apagar a organização &apos;
            {dialog.organizationName}&apos; e todas as ementas e relatórios
            associados?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setDialog({ open: false })}
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onDeleteAcceptClick()}
            autoFocus
          >
            Apagar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrganizationList;
