import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import './Calendar.module.css';

const Calendar = ({ date, setDate }) => (
  <DatePicker
    selected={date}
    onChange={newDate => setDate(moment(newDate))}
    inline
    locale="pt"
  />
);

Calendar.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  setDate: PropTypes.func.isRequired,
};

export default Calendar;
