import { createMuiTheme, makeStyles } from '@material-ui/core/styles';

export const technicalSheetTheme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(183,106,95)',
    },
    secondary: {
      main: '#FFFFFF',
    },
  },
});

export const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    '& > *.Mui-disabled': {
      color: theme.palette.primary.light,
    },
  },
  iconContainer: {
    color: theme.palette.secondary.main,
    '& > *:hover': {
      color: theme.palette.primary.dark,
    },
  },
  iconActive: {
    color: theme.palette.secondary.main,
  },
  input: {
    color: theme.palette.secondary.main,
  },
  inputIcon: {
    position: 'absolute',
    right: 0,
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    justifyContent: 'space-between',
    display: 'flex',
  },
  headerTitle: {
    fontWeight: 'bolder',
  },
  footer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    justifyContent: 'flex-end',
    display: 'flex',
    '& > *': {
      marginLeft: '2em',
    },
  },
  footerSelect: {
    color: theme.palette.secondary.main,
    '& > svg': {
      color: theme.palette.secondary.main,
    },
  },
  caption: {
    color: theme.palette.secondary.main,
  },
  linkRoot: {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
  linkContainer: {
    width: '100%',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'inherit',
      color: theme.palette.primary.light,
    },
    '&:hover > * > *:not(first-child)': {
      opacity: 0.6,
    },
    '& > *': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& > * > *:first-child': {
      marginRight: '1em',
    },
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));
