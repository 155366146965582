import React from 'react';
import './ChooseModuleStyle.css';
import { Link } from 'react-router-dom';

const ChooseModuleSelection = () => (
  <div>
    <h1 className="title">o que precisas?</h1>
    <div className="modules">
      <Link to="/consultar">
        <img
          className="activeButton"
          src={`${process.env.PUBLIC_URL}/consultar.png`}
          alt="Consultar"
        />
      </Link>
      <Link to="/planear">
        <img
          className="activeButton"
          src={`${process.env.PUBLIC_URL}/planear.png`}
          alt="Planear"
        />
      </Link>
      <Link to="/avaliar">
        <img
          className="activeButton"
          src={`${process.env.PUBLIC_URL}/avaliar.png`}
          alt="Avaliar"
        />
      </Link>
    </div>
  </div>
);

export default ChooseModuleSelection;
