import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';

import { Button as LoadingButton } from '../common';
import {
  fetchAllTechnicalSheets,
  deleteTechnicalSheet,
} from '../../services/technicalSheetsService';
import fetchComponentTypes from '../../services/componentTypesService';

import useDebouncedTechSheets from '../../utils/useDebouncedTechSheets';
import { setNotification } from '../../actions/notificationActions';

import List from './List';

const getAllTechnicalSheets = async ({
  page,
  search,
  pageSize,
  filterList,
}) => {
  const { data } = await fetchAllTechnicalSheets(
    page + 1,
    search,
    pageSize,
    true,
    filterList,
  );

  const normalizedData = data.data.map(
    ({ component_type: componentType, name, id }) => ({
      componentType,
      name,
      id,
    }),
  );

  const { total } = data.meta;

  return { data: normalizedData, total };
};

const useSearchTechnicalSheets = () =>
  useDebouncedTechSheets(({ page, search, pageSize, filterList }) =>
    getAllTechnicalSheets({ page, search, pageSize, filterList }),
  );

export default function TechnicalSheetsList() {
  const dispatch = useDispatch();

  const [componentTypes, setComponentTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteTechSheet, setDeleteTechSheet] = useState(undefined); // { id, name }
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const {
    search,
    page,
    pageSize,
    filterList,
    setSearch,
    setPage,
    setPageSize,
    setFilterList,
    searchResults,
    deleteTS,
    setDeleteTS,
  } = useSearchTechnicalSheets();

  useEffect(() => {
    const { status, result } = searchResults;

    switch (status) {
      case 'loading':
        setIsLoading(true);
        break;

      case 'success':
        // eslint-disable-next-line no-case-declarations
        const { total, data: resultData } = result;
        setCount(total);
        setData(resultData);
        setIsLoading(false);
        if (isFirstLoad) setIsFirstLoad(false);
        break;

      default:
        break;
    }
  }, [searchResults, isFirstLoad]);

  useEffect(() => {
    fetchComponentTypes()
      .then(({ data: types }) => {
        setComponentTypes(types);
      })
      .catch(error => {
        dispatch(setNotification(error));
      });
  }, [dispatch]);

  const handleTableChange = (action, tableState) => {
    switch (action) {
      case 'changePage':
        setPage(tableState.page);
        break;
      case 'changeRowsPerPage':
        setPage(0);
        setPageSize(tableState.rowsPerPage);
        break;
      case 'filterChange':
        setFilterList(tableState.filterList);
        break;
      case 'resetFilters':
        setFilterList([]);
        break;
      case 'search':
        setSearch(tableState.searchText);
        break;
      case 'delete':
        setDeleteTechSheet(tableState);
        setOpenDialog(true);
        break;

      default:
        break;
    }
  };

  const handleDeleteTechnicalSheet = async confirmation => {
    if (confirmation) {
      try {
        setIsDeleteLoading(true);
        await deleteTechnicalSheet(deleteTechSheet.id);
        setOpenDialog(false);
        setDeleteTS(!deleteTS);
        setIsDeleteLoading(false);
      } catch (e) {
        // Display Snackbar
        setIsDeleteLoading(false);
      }
    } else {
      setOpenDialog(false);
    }
  };

  const tableState = {
    rowsPerPage: pageSize,
    page,
    total: count,
    availableFilters: componentTypes,
    search,
    filterList,
    data,
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => handleDeleteTechnicalSheet(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Apagar ficha técnica?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {deleteTechSheet
              ? `Tem a certeza que pretende apagar: "${deleteTechSheet.name}"`
              : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDeleteTechnicalSheet(true)}
            color="default"
          >
            Cancelar
          </Button>
          <LoadingButton
            onClick={() => handleDeleteTechnicalSheet(true)}
            color="primary"
            isLoading={isDeleteLoading}
            text="Apagar"
          />
        </DialogActions>
      </Dialog>
      {isFirstLoad && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      )}
      {!isFirstLoad && (
        <List
          loading={isLoading}
          tableState={tableState}
          handleTableChange={handleTableChange}
          emptyMessage="Ainda não existe nenhuma ficha técnica."
        />
      )}
    </>
  );
}
