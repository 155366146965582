import React from 'react';
import { Autocomplete, Alert } from '@material-ui/lab';
import {
  FormGroup,
  FormControlLabel,
  TextField,
  MenuItem,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
  Switch,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Title from './Title';
import { Icon } from '../../common';

import styles from './TechnicalSheetForm.module.css';

export const useStyles = makeStyles(() => ({
  list: {
    marginLeft: '16px',
    marginRight: '16px',
  },
  listItem: {
    paddingBottom: '16px',
    '&:not(:first-child)': {
      paddingTop: '16px',
      paddingBottom: '16px',
      borderTop: '1px dotted #949494',
    },
  },
  listItemText: {
    display: 'flex',
    alignItems: 'center',
  },
  mobileListItemContent: {
    '& > div:nth-child(2) > div:nth-child(2)':{
      width: '11em',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  listItemContent: {
    display: 'flex',
    width: '100%',
  },
  secondaryAction: {
    minWidth: '17em',
    marginLeft: '1em',
    display: 'grid',
    '& > :nth-child(1), & > :nth-child(2), & > :nth-child(3), & > :nth-child(4),' : {
      gridRowStart: '1',
    },
    '& > :nth-child(5), & > :nth-child(6), & > :nth-child(7),': {
      gridRowStart: '2',
    },
    '& > *:not(:last-child)': {
      marginRight: '1em',
      width: '6em',
    },
    '& > button, & > label:nth-child(6)' : {
      marginLeft: '2em'
    }
  },
}));

const units = ['mg', 'g', 'kg', 'ml', 'un.'];

const Ingredients = ({
  selectedIngredients,
  ingredients,
  ingredientError,
  removeSelectedIngredient,
  updateSelectedIngredientQty,
  updateSelectedIngredientUnit,
  updateSelectedIngredientProvider,
  updateSelectedIngredientOrigin,
  updateSelectedIngredientBiologic,
  updateSelectedIngredientTransgenic,
  selectIngredient,
  retryIngredientFetch,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  function addIngredient(_, value) {
    selectIngredient(value);
  }

  const sortedIngredients = ingredients
    ?.sort(({ type: t1 }, { type: t2 }) => {
      if (t1 > t2) {
        return 1;
      }
      if (t1 < t2) {
        return -1;
      }
      return 0;
    })
    .filter(
      ({ id }) => !Object.keys(selectedIngredients).includes(`ingredient${id}`),
    );

  return (
    <FormGroup>
      <Title text="Ingredientes" />
      {// eslint-disable-next-line
        ingredients ? (
          <>
            {Object.keys(selectedIngredients).length > 0 ? (
              <List classes={{ root: classes.list }}>
                { Object.keys(selectedIngredients)
                  .filter(
                    unfiltered => selectedIngredients[unfiltered] !== undefined,
                  )
                  .map(selected => {
                    const {
                      componentType,
                      name,
                      amount,
                      unit,
                      provider,
                      origin,
                      biological,
                      transgenic,
                      id,
                    } = selectedIngredients[selected];
                    
                    return (
                      <ListItem
                        key={`selected_ingredient${selected}`}
                        classes={{ root: classes.listItem }}
                      >
                        <ListItemAvatar>
                          <Icon name={componentType} />
                        </ListItemAvatar>
                        <div
                          className={
                            isMobile
                              ? classes.mobileListItemContent
                              : classes.listItemContent
                          }
                        >
                          <ListItemText className={classes.listItemText}>
                            {name}
                          </ListItemText>
                          <div
                            className={
                              isMobile
                                ? classes.mobileSecondaryAction
                                : classes.secondaryAction
                            }
                          >
                            <TextField
                              label={isMobile ? 'Quant.' : 'Quantidade'}
                              id={`quantity_${selected}`}
                              type="number"
                              name={selected}
                              inputProps={{ step: '1', min: '0' }}
                              value={amount}
                              color="secondary"
                              onChange={updateSelectedIngredientQty}
                            />
                            <TextField
                              label="Unidade"
                              select
                              id={`unit_${selected}`}
                              value={unit ?? 'un.'}
                              name={selected}
                              color="secondary"
                              onChange={updateSelectedIngredientUnit}
                            >
                              {units.map(un => (
                                <MenuItem key={un} value={un}>
                                  {un}
                                </MenuItem>
                              ))}
                            </TextField>

                            <TextField
                              label="Fornecedor"
                              onChange={updateSelectedIngredientProvider}
                              color="secondary"
                              name={selected}
                              value={provider || ""}
                            >
                            </TextField>

                            <TextField
                              label="Origem"
                              onChange={updateSelectedIngredientOrigin}
                              color="secondary"
                              name={selected}
                              value={origin || ""}
                            >
                            </TextField>

                            <FormControlLabel
                              control={<Switch name={selected} onChange={updateSelectedIngredientBiologic} value={biological} checked={biological}/>}
                              label="Biológico"
                            />

                            <FormControlLabel
                              control={<Switch name={selected} onChange={updateSelectedIngredientTransgenic} value={transgenic} checked={transgenic}/>}
                              label="Transgénico"
                            />

                            <IconButton
                              onClick={() => removeSelectedIngredient(id)}
                            >
                              <Close />
                            </IconButton>
                          </div>
                        </div>
                      </ListItem>
                    );
                  })}
              </List>
            ) : (
              <Typography color="textSecondary">
                Selecione um ingrediente para determinar a sua quantidade
              </Typography>
            )}
            <Autocomplete
              autoComplete
              className={styles.formSearch}
              label="Pesquisa de Ingredientes"
              options={sortedIngredients}
              groupBy={({ type }) => type}
              getOptionLabel={({ name }) => name}
              key={Object.keys(selectedIngredients).length}
              onChange={addIngredient}
              renderInput={params => (
                <TextField
                  {...params}
                  id="ingredientSearchInput"
                  label="Pesquisa de Ingredientes"
                  color="secondary"
                />
              )}
            />
          </>
        ) : !ingredientError ? (
          <Alert>A obter os ingredientes...</Alert>
        ) : (
          <Alert
            className={styles.retryAlert}
            color="warning"
            onClick={retryIngredientFetch}
          >
            Ocorreu um erro a tentar obter os ingredientes, carregue aqui para
            tentar novamente
          </Alert>
        )}
    </FormGroup>
  );
};

Ingredients.defaultProps = {
  ingredients: null,
};

Ingredients.propTypes = {
  selectedIngredients: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any))
    .isRequired,
  ingredients: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  ingredientError: PropTypes.bool.isRequired,
  removeSelectedIngredient: PropTypes.func.isRequired,
  updateSelectedIngredientQty: PropTypes.func.isRequired,
  updateSelectedIngredientUnit: PropTypes.func.isRequired,
  updateSelectedIngredientProvider: PropTypes.func.isRequired,
  updateSelectedIngredientOrigin: PropTypes.func.isRequired,
  updateSelectedIngredientBiologic: PropTypes.func.isRequired,
  updateSelectedIngredientTransgenic: PropTypes.func.isRequired,
  selectIngredient: PropTypes.func.isRequired,
  retryIngredientFetch: PropTypes.func.isRequired,
};

export { Ingredients, units };
