import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import MuiPopover from '@material-ui/core/Popover';

export default function Popover({
  refExit,
  trigger,
  content,
  ...providedProps
}) {
  const [open, setOpen] = useState(false);
  const popoverActions = useRef(null);
  const anchorEl = useRef(null);

  if (open === true) {
    if (popoverActions.current) popoverActions.current.updatePosition();
  }

  const handleClick = () => {
    setOpen(true);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const handleOnExit = () => {
    if (refExit) {
      refExit();
    }
  };

  const triggerEl = React.cloneElement(<span>{trigger}</span>, {
    key: 'content',
    ref: anchorEl,
    onClick: () => {
      if (trigger.props.onClick) trigger.props.onClick();
      handleClick();
    },
  });

  return (
    <>
      <MuiPopover
        action={popoverActions}
        elevation={2}
        open={open}
        onClose={handleRequestClose}
        onExited={handleOnExit}
        anchorEl={anchorEl.current}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...providedProps}
      >
        {content}
      </MuiPopover>
      {triggerEl}
    </>
  );
}

Popover.propTypes = {
  refExit: PropTypes.func.isRequired,
  trigger: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
};
