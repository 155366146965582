const districts = [
  'Aveiro',
  'Açores',
  'Beja',
  'Braga',
  'Bragança',
  'Castelo Branco',
  'Coimbra',
  'Évora',
  'Faro',
  'Guarda',
  'Leiria',
  'Lisboa',
  'Madeira',
  'Portalegre',
  'Porto',
  'Santarém',
  'Setúbal',
  'Viana do Castelo',
  'Vila Real',
  'Viseu',
];

const occupations = ['Nutricionista', 'Estudante', 'Outra'];

const institutionTypes = [
  'IPSS',
  'Centro de saúde',
  'Colégio Privado',
  'Escola Pública',
  'Lar',
  'Unidade de cuidados continuados',
  'Hospital',
  'Faculdade',
  'Centro de dia',
  'Outro',
];

export { districts, occupations, institutionTypes };
