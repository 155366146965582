import { useState } from 'react';

import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant';
import { useAsync } from 'react-async-hook';

// Generic reusable hook
const useDebouncedTechSheets = func => {
  // Handle the input text state
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [filterList, setFilterList] = useState([]);
  const [deleteTS, setDeleteTS] = useState(false);

  // Debounce the original search async function
  const debouncedFunc = useConstant(() => AwesomeDebouncePromise(func, 300));

  // The async callback is run each time the text changes,
  // but as the search function is debounced, it does not
  // fire a new request on each keystroke
  const searchResults = useAsync(async () => {
    return debouncedFunc({
      search,
      page,
      pageSize,
      filterList,
    });
  }, [debouncedFunc, page, search, pageSize, filterList, deleteTS]);

  // Return everything needed for the hook consumer
  return {
    search,
    page,
    pageSize,
    filterList,
    setSearch,
    setPage,
    setPageSize,
    setFilterList,
    searchResults,
    deleteTS,
    setDeleteTS,
  };
};

export default useDebouncedTechSheets;
