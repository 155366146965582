import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Container,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Button,
  Row,
  CardHeader,
} from 'reactstrap';

import { connect } from 'react-redux';

import LoadingAnimation from './LoadingAnimation';
import { login as doLogin } from '../../actions/authActions';
import { submitLogin } from '../../services/authService';

const SignInForm = ({ login }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const history = useHistory();
  const location = useLocation();

  const submitForm = e => {
    e.preventDefault();
    setSubmitting(true);
    setErrorMsg('');

    submitLogin({ email, password })
      .then(({ data }) => {
        const { token, user_type: userType } = data;
        login({
          token,
          userType,
        });

        if (location.state && location.state.from)
          history.push(location.state.from);
        else if (userType === 'admin') history.push('/admin');
        else history.push('/');
      })
      .catch(error => {
        if (error.response.status === 404)
          setErrorMsg('Não existe uma conta com esse endereço de email.');
        else {
          setErrorMsg('Credenciais erradas.');
          setPassword('');
        }
        setSubmitting(false);
      });
  };

  return (
    <Container>
      <Row className="mt-3 mt-lg-4 pt-xl-5">
        <Col xs={{ size: 12 }} lg={{ size: 9 }} xl={{ size: 6, offset: 3 }}>
          <Card>
            <CardHeader>
              <h2 className="my-1">Iniciar Sessão</h2>
            </CardHeader>
            <div className="position-relative">
              {isSubmitting && <LoadingAnimation />}
              <CardBody>
                <Form className="form" onSubmit={submitForm}>
                  <Col>
                    {!!errorMsg && (
                      <Alert color="danger" fade={false}>
                        {errorMsg}
                      </Alert>
                    )}
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="email">Endereço de E-mail</Label>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder=""
                        required
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="password">Palavra-chave</Label>
                      <Input
                        type="password"
                        name="password"
                        id="password"
                        placeholder=""
                        required
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <div className="small text-right">
                      Ainda não tem conta?{' '}
                      <Link to="/registar">Registe-se</Link>
                    </div>
                    <div className="small text-right mt-1">
                      Esqueceu-se da sua palavra-chave?{' '}
                      <Link to="/recuperar-conta">Recupere-a</Link>
                    </div>
                    <Button color="success" className="px-4">
                      Enviar
                    </Button>
                  </Col>
                </Form>
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

SignInForm.propTypes = {
  login: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  login: doLogin,
};

export default connect(null, mapDispatchToProps)(SignInForm);
