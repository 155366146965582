import axios from 'axios';

export const getProfile = () => {
  return axios({
    method: 'get',
    url: '/api/profile',
  });
};

export const changePassword = ({ password, passwordConfirmation }) => {
  return axios({
    method: 'post',
    url: '/api/profile/password',
    data: {
      password,
      password_confirmation: passwordConfirmation,
    },
  });
};

export const changeProfile = ({ name, email, occupation, district }) => {
  return axios({
    method: 'post',
    url: '/api/profile/edit',
    data: {
      name,
      email,
      occupation,
      district,
    },
  });
};

export const changeInstitutions = ({ institutions }) => {
  return axios({
    method: 'post',
    url: '/api/profile/institutions',
    data: {
      institutions,
    },
  });
};
