/* eslint-disable no-nested-ternary */

import React from 'react';
import { ListGroup, ListGroupItem, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

import styles from './CommonList.module.css';

import Pagination from './Pagination';

const CommonList = ({
  data,
  displayItem,
  pagination,
  lastPage,
  updatePage,
  searchTerm,
  isLoading,
}) => {
  return (
    <>
      <>
        <ListGroup flush className={styles.listWrapper}>
          {isLoading ? (
            <div className={styles.loadingOverlay}>
              <Spinner color="success" size="md" />
            </div>
          ) : data.length > 0 ? (
            data.map(item => (
              <ListGroupItem key={`item${item.id}`}>
                {displayItem(item)}
              </ListGroupItem>
            ))
          ) : (
            <p className={styles.notFound}>Nenhum item encontrado!</p>
          )}
        </ListGroup>
      </>
      {pagination && data.length > 0 && (
        <Pagination
          lastPage={lastPage}
          updatePage={updatePage}
          searchTerm={searchTerm}
        />
      )}
    </>
  );
};

CommonList.defaultProps = {
  data: [],
  pagination: false,
  lastPage: 1,
  updatePage: () => {},
  searchTerm: '',
  isLoading: false,
};

CommonList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  displayItem: PropTypes.func.isRequired,
  pagination: PropTypes.bool,
  lastPage: PropTypes.number,
  updatePage: PropTypes.func,
  searchTerm: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default CommonList;
