import axios from 'axios';

export default () => {
  const csrf = document.querySelector('meta[name=csrf-token]')
    ? document.querySelector('meta[name=csrf-token]').content
    : '';

  const token = localStorage.getItem('spare_token');

  axios.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
    'X-CSRF-TOKEN': csrf,
  };
};
