import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const AgeRangePicker = ({ ageRange, setAgeRange }) => {
  const ageRangeOptions = [
    '1-3',
    '3-6',
    '6-10',
    '10-15',
    '15-18',
    '18-65',
    '65+',
  ];
  useEffect(() => {
    const age = ageRange ?? ageRangeOptions[0];
    setAgeRange(age);
  }, [ageRange, setAgeRange, ageRangeOptions]);
  return (
    <Autocomplete
      id="select-age-range"
      autoComplete
      value={ageRange}
      options={ageRangeOptions}
      getOptionLabel={ar => `${ar} anos`}
      onChange={(event, value) => setAgeRange(value)}
      renderInput={params => <TextField {...params} label="Faixa etária" />}
    />
  );
};

AgeRangePicker.propTypes = {
  ageRange: PropTypes.string,
  setAgeRange: PropTypes.func.isRequired,
};

AgeRangePicker.defaultProps = {
  ageRange: null,
};

export const MealPicker = ({ mealType, setMealType }) => {
  const mealTypes = [
    'Pequeno-almoço',
    'Merenda da manhã',
    'Almoço',
    'Merenda da tarde',
    'Jantar',
    'Ceia',
  ];
  useEffect(() => {
    const meal = mealType ?? mealTypes[0];
    setMealType(meal);
  }, [mealType, setMealType, mealTypes]);
  return (
    <Autocomplete
      id="select-meal-type"
      autoComplete
      value={mealType}
      options={mealTypes}
      onChange={(event, value) => setMealType(value)}
      renderInput={params => <TextField {...params} label="Tipo de refeição" />}
    />
  );
};

MealPicker.propTypes = {
  mealType: PropTypes.string,
  setMealType: PropTypes.func.isRequired,
};

MealPicker.defaultProps = {
  mealType: null,
};
