import React from 'react';
import styles from './PoliticaAlimentarStyle.module.css';
import polList from './PoliticaAlimentar.json';
import TableListItemWithImg from './TableListItemWithImg';

const PoliticaAlimentarTable = () => {
  return (
    <div>
      <table className={styles.tableList}>
        <TableListItemWithImg list={polList} />
      </table>
    </div>
  );
};

export default PoliticaAlimentarTable;
