import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';

import AppNavbar from '../../components_lgp/Navbar';
import Footer from '../../components_lgp/Footer';
import SideBar from './Sidebar';

import styles from './Layout.module.css';

const Layout = ({ children, sidebarCollapsed, isLoggedIn }) => {
  return (
    <div className={styles.root}>
      <AppNavbar />
      <main
        className={classNames(
          styles.main,
          isLoggedIn && sidebarCollapsed
            ? styles.sidebarCollapsed
            : isLoggedIn && styles.sidebarExpanded,
        )}
      >
        {isLoggedIn && <SideBar />}
        <Container fluid style={{ padding: 0, flexGrow: 1 }}>
          {children}
        </Container>
        <Footer />
      </main>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  sidebarCollapsed: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ sidebar, auth }) => ({
  sidebarCollapsed: sidebar,
  isLoggedIn: auth.loggedIn,
});

export default connect(mapStateToProps, {})(Layout);
