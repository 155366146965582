import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import RouteLink from '../RouteLink/RouteLink';

const useStyles = makeStyles({
  separator: {
    marginLeft: 0,
    marginRight: 0,
  },
  link: {
    color: '#B76A5F',
    fontSize: '1.3rem',
    fontWeight: 'bold',
    '&:hover': {
      opacity: '0.75',
      /* the following lines are needed to override Bootstrap hover effect
        on <a> elements */
      textDecoration: 'none',
      color: '#B76A5F',
    },
  },
  lastLink: {
    extend: 'link',
    color: '#6B342D',
    'pointer-events': 'none',
  },
});

/**
 *
 * @param {Object} props
 * @param {?Object} props.classes An object of style rules to be applied to
 * <Breadcrumbs> from MUI. @link[See the API]{https://material-ui.com/api/breadcrumbs/}
 * @param {Object[]} props.links
 * @param {String} props.links.name
 * @param {String} props.links.link
 */
const Breadcrumb = ({ classes, links }) => {
  const styles = useStyles();

  return (
    <Breadcrumbs
      classes={{
        separator: styles.separator,
        ...classes,
      }}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {links.map((link, index) => {
        const thisKey = `${link.name}__${link.url}`;
        return index !== links.length - 1 ? (
          <RouteLink
            key={thisKey}
            classes={{ root: styles.link }}
            to={link.url}
            variant="body1"
          >
            {link.name}
          </RouteLink>
        ) : (
          <Typography
            key={thisKey}
            classes={{ root: styles.lastLink }}
            variant="body1"
          >
            {link.name}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};

Breadcrumb.propTypes = {
  classes: PropTypes.object,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ).isRequired,
};

Breadcrumb.defaultProps = {
  classes: {},
};

export default Breadcrumb;
