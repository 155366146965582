// mostly code from reactjs.org/docs/error-boundaries.html
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, status: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, status: error.status };
  }

  componentDidCatch(error, info) {
    console.error(
      'There was an error while rendering this listing!',
      error,
      info,
    );
  }

  render() {
    const { hasError, status } = this.state;
    const { children } = this.props;
    if (hasError) {
      switch (status) {
        case 404:
          return (
            <h1>
              Ocorreu um erro durante a obtenção do elemento requisitado ao
              servidor!
            </h1>
          );
        default:
          return <h1>Ocorreu um erro no servidor!</h1>;
      }
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default ErrorBoundary;
