import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Row, Col, Alert } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';

import styles from '../../styles/view.module.css';

import Layout from '../../components/layout/Layout';
import OptionList from '../../components/common/OptionList';
import Overview from '../../components/Meals/ViewMeal/Overview';
import ErrorBoundary from '../../components/common/ErrorBoundary';
import { setNotification } from '../../actions/notificationActions';
import { fetchMeal, deleteMeal } from '../../services/mealsService';
import Page from '../../components/common/Page';
import DeleteModal from '../../components/common/DeleteModal';
import List from '../../components/common/List/List';
import TechnicalSheetListItemCollapse from '../../components/Meals/ViewMeal/TechnicalSheetListItemCollapse';
import NutritionalChart from '../../components/TechnicalSheets/NutritionalChart';

const ViewMeal = () => {
  const { id } = useParams();
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [meal, setMeal] = useState(null);
  const [hasError, setError] = useState(null);
  const history = useHistory();

  const buttons = [
    {
      name: 'Apagar',
      onClick: () => setIsDeleteModalOpen(!isDeleteModalOpen),
    },
  ];

  const links = [];

  /* eslint no-use-before-define: ["error", { "variables": false }] */

  const doDelete = async () => {
    try {
      setDeleteLoading(true);
      await deleteMeal(id);
      setNotification({
        message: `Ementa apagada com sucesso!`,
        type: 'success',
      });
      history.push('/ementas');
    } catch (e) {
      setNotification({
        message: 'Ocorreu um erro ao tentar apagar esta ementa.',
        type: 'error',
      });
    }
    setDeleteLoading(false);
  };

  useEffect(() => {
    if (hasError) throw hasError;
  }, [hasError]);

  useEffect(() => {
    const doFetch = async () => {
      try {
        const { data } = await fetchMeal(id);
        setMeal(data);
      } catch (error) {
        setError(error.response);
      }
    };
    doFetch();
  }, [id]);

  return (
    <div className={classnames(styles.main, 'meal')}>
      {meal === null ? (
        <Alert color="success">A obter ementa...</Alert>
      ) : (
        <Page title={`${meal.name} - Ementa`}>
          <div>
            <Container fluid className={styles.titleBg}>
              <Row>
                <Col xs="12" md="8">
                  <h1 className={styles.title}>
                    {meal.type} {moment(meal.date).format('DD/MM/YYYY')}
                  </h1>
                </Col>
                <Col xs="12" md="4" style={{ margin: 'auto 0' }}>
                  <OptionList buttons={buttons} links={links} />
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className={styles.row}>
                <Col className={styles.col} xs="12">
                  <h1 className={styles.name}>{meal.name}</h1>
                </Col>
                <Col className={styles.col} xs="12">
                  <List
                    title="Fichas Técnicas"
                    items={meal.technicalSheets}
                    component={TechnicalSheetListItemCollapse}
                    printItemTitle={item => item.name}
                  />
                </Col>
                <Col className={styles.col} xs="12">
                  <NutritionalChart
                    nutritionalInfo={meal.total_nutritional_values}
                  />
                </Col>
                <Col className={styles.col} sm="12">
                  <Overview
                    type={meal.type}
                    createdAt={meal.created_at}
                    lastUpdated={meal.updated_at}
                  />
                </Col>
              </Row>
              <DeleteModal
                toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                isOpen={isDeleteModalOpen}
                name={meal.name}
                onDelete={doDelete}
                isLoading={isDeleteLoading}
                type="esta ementa"
                deleteType="Ementa"
              />
            </Container>
          </div>
        </Page>
      )}
    </div>
  );
};

const ErrorBoundaryWrapper = () => (
  <Layout>
    <ErrorBoundary>
      <ViewMeal />
    </ErrorBoundary>
  </Layout>
);

export default ErrorBoundaryWrapper;
