import React from 'react';
import PropTypes from 'prop-types';
import { FaTrashAlt } from 'react-icons/fa';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';

import modalStyles from './modal.module.css';

const DeleteModal = ({
  toggle,
  isOpen,
  name,
  onDelete,
  isLoading,
  type,
  header,
}) => (
  <Modal isOpen={isOpen} toggle={toggle}>
    <ModalHeader toggle={toggle}>Apagar {header}</ModalHeader>
    <ModalBody>
      Tem a certeza que pretende apagar <strong>{name}</strong>? Esta ação é
      irreversível e não poderá voltar a usar {type} novamente.
    </ModalBody>
    <ModalFooter>
      <Button
        color="danger"
        onClick={async () => {
          await onDelete();
          toggle();
        }}
        className={modalStyles.button}
      >
        {isLoading ? (
          <>
            <Spinner className="mr-1" size="sm" color="light" />
            <span>A processar </span>
          </>
        ) : (
          <>
            <FaTrashAlt className="mr-1" />
            <span>Apagar</span>
          </>
        )}
      </Button>
      <Button color="secondary" onClick={toggle}>
        Cancelar
      </Button>
    </ModalFooter>
  </Modal>
);

DeleteModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
};

export default DeleteModal;
