import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import Week from './Week';

const moment = extendMoment(Moment);

const getWeeks = (year, month) => {
  const weeks = [];
  let firstDayOfWeek = moment([year, month])
    .startOf('month')
    .startOf('week');
  const lastDayOfMonth = moment([year, month]).endOf('month');

  while (!firstDayOfWeek.isAfter(lastDayOfMonth)) {
    const temp = moment(firstDayOfWeek).add(6, 'days');
    const lastDayOfWeek = temp;

    weeks.push(moment.range(firstDayOfWeek, lastDayOfWeek));
    firstDayOfWeek = temp.add(1, 'days');
  }

  return weeks;
};

const WeeksInMonth = ({ year, month, date, setDate }) => {
  const weeks = getWeeks(year, month - 1);

  return (
    <div style={{ width: '100%' }}>
      {weeks.map(week => (
        <Week
          key={week.start.format('YYYY-MM-DD')}
          range={week}
          date={date}
          setDate={setDate}
        />
      ))}
    </div>
  );
};

WeeksInMonth.propTypes = {
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  setDate: PropTypes.func.isRequired,
  date: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default WeeksInMonth;
