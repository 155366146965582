import React from 'react';

import Layout from '../../components_lgp/layout/Layout';
import Page from '../../components/common/Page';
import IngredientForm from '../../components_lgp/Ingredients/IngredientForm';

const EditIngredient = () => {
  return (
    <Page title="Criar Ingrediente">
      <Layout
        breadcrumb_links={[
          {
            name: 'Administração',
            url: '/admin',
          },
          {
            name: 'Gerir Ingredientes',
            url: '/admin/ingredientes',
          },
          {
            name: 'Criar Ingrediente',
            url: `'/admin/ingredientes/criar`,
          },
        ]}
      >
        <IngredientForm />
      </Layout>
    </Page>
  );
};

export default EditIngredient;
