import React from 'react';

import Layout from '../components_lgp/layout/Layout';
import Page from '../components/common/Page';
import EditUserProfile from '../components_lgp/Profile/EditUserProfile';

const breadcrumbLinks = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'Perfil',
    url: '/perfil',
  },
  {
    name: 'Editar',
    url: '/perfil/editar',
  },
];

const EditProfile = () => {
  return (
    <Page title="Editar Perfil">
      <Layout breadcrumb_links={breadcrumbLinks}>
        <EditUserProfile />
      </Layout>
    </Page>
  );
};

export default EditProfile;
