import React from 'react';
import { Redirect } from 'react-router';
import { useDispatch } from 'react-redux';

import { logout } from '../actions/authActions';

export default function() {
  const dispatch = useDispatch();
  dispatch(logout());

  return <Redirect to="/" />;
}
