import React, { useContext } from 'react';
import propTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from '../styles';
import PlanningDayRow from './PlanningDayRow';
import FeedbackRow from './FeedbackRow';
import { MealsContext } from '../MealsContextProvider';

const moment = require('moment');

const WeekPlanningSection = props => {
  const classes = useStyles();

  const {
    /**
     * The existing meal plan for the selected organization, date and meal type
     */
    organizationMeals,
    mealComponents,
  } = useContext(MealsContext);

  let { weekStartDate, weekEndDate } = props;

  // create moment objects for dates
  weekStartDate = moment(weekStartDate, moment.ISO_8601);
  weekEndDate = moment(weekEndDate, moment.ISO_8601);
  if (!weekStartDate.isValid() || !weekEndDate.isValid())
    throw new Error('Unexpected date format');

  // build array of WeekDayPlanRow components for this week section
  const [state, setState] = React.useState(() => {
    const dates = [];
    const techSheets = {};
    for (
      let currentDate = weekStartDate.clone();
      currentDate <= weekEndDate;
      currentDate.add(1, 'day')
    ) {
      dates.push(currentDate.clone());

      const dateKey = currentDate.format('YYYY-MM-DD');
      const dateMeals = organizationMeals[dateKey];
      if (dateMeals) {
        const categories = dateMeals.technical_sheets;
        Object.keys(categories).forEach(category => {
          const sheet = categories[category];
          const weekSheets = techSheets[category];
          if (sheet) {
            sheet.date = dateKey;
            techSheets[category] = weekSheets
              ? [...weekSheets, sheet]
              : [sheet];
          }
        });
      }
    }
    if (Object.keys(techSheets).length === 0) {
      mealComponents.forEach(component => {
        techSheets[component.name] = [];
      });
    }
    return { dates, techSheets };
  });

  const addWeekTechSheet = (category, sheet) => {
    const { techSheets } = state;
    const currentSheets = techSheets[category];
    const newTechSheets = {
      ...techSheets,
      [category]: currentSheets ? [...currentSheets, sheet] : [sheet],
    };
    setState({ ...state, techSheets: newTechSheets });
  };

  const updateWeekTechSheet = (category, prevSheet, sheet) => {
    const { techSheets } = state;
    const currentSheets = techSheets[category];
    const index = currentSheets.findIndex(ts => ts.id === prevSheet.id);
    currentSheets.splice(index, 1, sheet);
    const newTechSheets = {
      ...techSheets,
      [category]: currentSheets,
    };
    setState({ ...state, techSheets: newTechSheets });
  };

  const deleteWeekTechSheet = (category, prevSheet) => {
    const { techSheets } = state;
    const currentSheets = techSheets[category];
    const index = currentSheets.findIndex(ts => ts.id === prevSheet.id);
    currentSheets.splice(index, 1);
    const newTechSheets = {
      ...techSheets,
      [category]: currentSheets,
    };
    setState({ ...state, techSheets: newTechSheets });
  };

  const getNumPlannedDays = () => {
    const { techSheets } = state;
    const allDays = Object.values(techSheets).flatMap(sheets =>
      sheets.map(ts => ts.date),
    );
    return new Set(allDays).size;
  };

  return (
    <Grid container item className={classes.planningWeek}>
      <Grid item className={classes.header}>
        <Typography variant="h6">
          {`Semana ${weekStartDate.format('DD MMM')} - ${weekEndDate.format(
            'DD MMM',
          )}`}
        </Typography>
      </Grid>
      {state.dates.map(date => (
        <PlanningDayRow
          key={`weekDayRow_${date.format('DD-MM-YYYY')}`}
          date={date.toISOString()}
          addWeekTechSheet={addWeekTechSheet}
          updateWeekTechSheet={updateWeekTechSheet}
          deleteWeekTechSheet={deleteWeekTechSheet}
        />
      ))}
      {state.techSheets && (
        <Grid container item>
        <div>Média da Semana</div>
        <FeedbackRow
          numDays={getNumPlannedDays()}
          weekTechnicalSheets={state.techSheets}
        />
        </Grid>
      )}
    </Grid>
  );
};

WeekPlanningSection.propTypes = {
  weekStartDate: propTypes.string.isRequired,
  weekEndDate: propTypes.string.isRequired,
};

export default WeekPlanningSection;
