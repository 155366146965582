import React from 'react';
import PropTypes from 'prop-types';

import styles from './DiversityTableElements.module.css';

import Icon from '../common/Icon';

export const Table = ({
  weeks,
  ingridients,
  begin,
  type,
  isMobile,
  isSmallScreen,
}) => {
  let tableClass = styles.diverTable;

  if (isSmallScreen) {
    tableClass = styles.diverTableSmall;
  }
  if (isMobile) {
    tableClass = styles.diverTableMobile;
  }

  const tableIsEmpty = ingridients.length === 0;
  return (
    <div className={styles.tableWrapper}>
      <table className={tableClass}>
        <tbody>
          <tr className={styles.tableHeader}>
            <td rowSpan="2">Preparação culinária</td>
            <td rowSpan="2" className={styles.legendas}></td>
            {weeks.map(week => {
              return (
                <>
                  <WhiteSpace />
                  <td colSpan="7">{week}</td>
                </>
              );
            })}
            <td />
          </tr>
          <tr className={styles.days}>
            {weeks.map(() => {
              return (
                <>
                  <WhiteSpace />
                  <DaysOfTheWeekGroup />
                </>
              );
            })}
            <td>Total</td>
          </tr>
          <EmptyRow weeks={weeks} />
          {tableIsEmpty && <NoComponentsRow weeks={weeks} />}
          {!tableIsEmpty &&
            ingridients.map(ingridient => {
              return (
                <>
                  <ComponentRow
                    weeks={weeks}
                    ingridient={ingridient}
                    start={begin}
                    type={type}
                  />
                  <EmptyRow weeks={weeks} />
                </>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  weeks: PropTypes.arrayOf(PropTypes.string).isRequired,
  ingridients: PropTypes.object.isRequired,
  begin: PropTypes.instanceOf(Date).isRequired,
  type: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
};

const DaysOfTheWeekGroup = () => {
  const daysOfTheWeek = ['D', '2.ª', '3.ª', '4.ª', '5.ª', '6.ª', 'S'];
  return daysOfTheWeek.map(day => <DayOfTheWeek dayOfTheWeek={day} />);
};
const DayOfTheWeek = ({ dayOfTheWeek }) => {
  return <td>{dayOfTheWeek}</td>;
};

DayOfTheWeek.propTypes = {
  dayOfTheWeek: PropTypes.string.isRequired,
};

const EmptyRow = ({ weeks }) => {
  return (
    <tr className={styles.emptyrow}>
      <td colSpan={weeks.length * 8 + 2} />
    </tr>
  );
};

EmptyRow.propTypes = {
  weeks: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const WhiteSpace = () => {
  return <td className={styles.whiteSpace} />;
};

const mapTimesOfDay = meal => {
  switch (meal) {
    case 'Pequeno-almoço':
      return 0;
    case 'Merenda da manhã':
      return 1;
    case 'Almoço':
      return 2;
    case 'Merenda da tarde':
      return 3;
    case 'jantar':
      return 4;
    default:
      return 5;
  }
};

const getOcorrenceArray = partsOfTheDay => {
  const timesOfTheDayMap = [false, false, false, false, false, false];

  for (let i = 0; i < partsOfTheDay.length; i += 1) {
    timesOfTheDayMap[mapTimesOfDay(partsOfTheDay[i])] = true;
  }

  return timesOfTheDayMap;
};

const Meal = ({ isOcorrence, icon }) => {
  if (isOcorrence) {
    return <Icon name={icon} height="15px" />;
  }

  return <span>-</span>;
};

Meal.propTypes = {
  isOcorrence: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
};

const Column = ({ partsOfTheDay, type }) => {
  return (
    <div className={styles.dayWrapper}>
      <div className={styles.day}>
        {partsOfTheDay.map(isMeal => {
          return <Meal isOcorrence={isMeal} icon={type} />;
        })}
      </div>
    </div>
  );
};

Column.propTypes = {
  partsOfTheDay: PropTypes.arrayOf(PropTypes.bool).isRequired,
  type: PropTypes.string.isRequired,
};

const Grid = ({ ingridientOcorrences, startDate, type }) => {
  const finalDate = new Date(startDate);
  finalDate.setDate(finalDate.getDate() + 7);
  const columns = [];

  do {
    let index = 0;
    let occorrence = false;

    for (; index < ingridientOcorrences.length; index += 1) {
      const auxDate = new Date(ingridientOcorrences[index].ocorrenceDate);
      if (auxDate.getTime() === startDate.getTime()) {
        occorrence = true;
        break;
      }
    }
    if (occorrence) {
      columns.push(
        <Column
          partsOfTheDay={getOcorrenceArray(
            ingridientOcorrences[index].partsOfTheDay,
          )}
          type={type}
        />,
      );
    } else {
      columns.push(
        <Column
          partsOfTheDay={[false, false, false, false, false, false]}
          type={type}
        />,
      );
    }

    startDate.setDate(startDate.getDate() + 1);
  } while (startDate.getTime() !== finalDate.getTime());

  return <div className={styles.grids}>{columns}</div>;
};

Grid.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  ingridientOcorrences: PropTypes.exact({
    ocorrenceDate: PropTypes.string,
    name: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  type: PropTypes.string.isRequired,
};

const generateStartOfTheWeekDates = (start, numWeeks) => {
  const aux = new Date(start);
  const dates = [];

  for (let i = 0; i < numWeeks; i += 1) {
    dates.push(new Date(aux));
    aux.setDate(aux.getDate() + 7);
  }

  return dates;
};

const getTotalOcorrences = occorences => {
  let total = 0;

  occorences.forEach(occorrence => {
    total += occorrence.partsOfTheDay.length;
  });

  return total;
};

const ComponentRow = ({ weeks, ingridient, start, type }) => {
  const { name } = ingridient;
  const occorrences = ingridient.ocorrences;
  const dates = generateStartOfTheWeekDates(start, weeks.length);

  return (
    <tr className={styles.component}>
      
      <td className={styles.componentName}>{name}</td>
      <td className={styles.legendas}>PA <br></br> MM <br></br> AL <br></br> MT <br></br> J <br></br> C</td>
      {dates.map(date => {
        return (
          <>
            <WhiteSpace />
            <ComponentCell>
              <Grid
                key={date}
                ingridientOcorrences={ingridient.ocorrences}
                startDate={date}
                type={type}
              />
            </ComponentCell>
          </>
        );
      })}
      <ComponentTotal total={getTotalOcorrences(occorrences)} />
    </tr>
  );
};

ComponentRow.propTypes = {
  weeks: PropTypes.arrayOf(PropTypes.string).isRequired,
  ingridient: PropTypes.object.isRequired,
  start: PropTypes.instanceOf(Date).isRequired,
  type: PropTypes.string.isRequired,
};

const ComponentCell = ({ children }) => {
  return (
    <td colSpan="7" className={styles.componentCell}>
      {children}
    </td>
  );
};

ComponentCell.propTypes = {
  children: PropTypes.node.isRequired,
};

const ComponentTotal = ({ total }) => {
  return <td className={styles.total}>{total}</td>;
};

ComponentTotal.propTypes = {
  total: PropTypes.number.isRequired,
};

const NoComponentsRow = ({ weeks }) => {
  return (
    <tr className={styles.noValues}>
      <td colSpan={weeks.length * 8 + 2} className={styles.emptyRowValue}>
        Não há analises para este tipo de alimento no mês e ano selecionado
      </td>
    </tr>
  );
};

NoComponentsRow.propTypes = {
  weeks: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Table;
