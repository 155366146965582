import React, { useEffect, useState, useCallback } from 'react';
import { withRouter, useHistory } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  Select,
  Typography,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  MenuItem,
  CircularProgress,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// import FileSaver from 'file-saver';
// import moment from 'moment';

import { setNotification as setNotificationAction } from '../../actions/notificationActions';

import {
  setReportName as setReportNameAction,
  setReportDate as setReportDateAction,
  setSections as setSectionsAction,
} from '../../actions/reportActions';

import ErrorBoundary from '../common/ErrorBoundary';

import {
  fetchAllReports,
  fetchAllTypes,
  createReport,
  exportManualReport,
  exportReport,
} from '../../services/reportsService';
import { fetchAllOrganizations } from '../../services/organizationsService';
import CommonList from '../common/CommonList';
import ReportListItem from './ReportListItem';

import Layout from '../../components_lgp/layout/Layout';

const useStyles = makeStyles(() => ({
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    marginBottom: '3em',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'baseline',
    '& > p': {
      marginRight: '0.5em',
    },
  },
  buttons: {
    display: 'flex',
    width: '15em',
    justifyContent: 'space-evenly',
  },
  buttonLabel: {
    fontWeight: 700,
  },
  spinner: {
    marginLeft: '0.5em',
  },
  label: {
    marginRight: '0.5em',
  },
}));

// eslint-disable-next-line no-shadow
const ListReport = ({
  type,
  setReportName,
  setReportDate,
  setSections,
  setNotification,
}) => {
  const [reports, setReports] = useState([]);
  const [newReport, setNewReport] = useState();
  const history = useHistory();
  const [reportTypes, setReportTypes] = useState([]); // [{id, name}]
  const [hasError, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isExportLoading, setExportLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [openDialog, setOpenDialog] = useState('');
  const [organization, setOrganization] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [isLoadingOrganizations, setIsLoadingOrganizations] = useState(true);

  const classes = useStyles();

  let nameTitle = '';
  if (type === '') {
    nameTitle = 'Resultados - Todos os relatórios';
  } else {
    nameTitle = `Resultados - ${type}`;
  }

  const links = [
    {
      name: 'Avaliar',
      url: '/avaliar',
    },
    {
      name: nameTitle,
      url: '',
    },
  ];

  const printReport = async (manual, answerReport) => {
    if (!isExportLoading) {
      try {
        setExportLoading(true);
        const { data } = manual
          ? await exportManualReport(newReport)
          : await exportReport(answerReport.id);

        const file = new Blob([data], {
          type: 'application/pdf',
        });
        // const reportName = reportTypes.find(({ id }) => id === newReport).name;

        // Save file
        // FileSaver.saveAs(
        //   file,
        //   manual
        //     ? `${reportName}.pdf`
        //     : `${reportName} ${moment(answerReport.lastUpdate).format(
        //         'DD_MM_YYYY__H_m_s',
        //       )}.pdf`,
        // );

        // Print File
        const fileURL = URL.createObjectURL(file);
        const iframe = document.createElement('iframe'); //load content in an iframe to print later
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.src = fileURL;
        iframe.onload = function () {
          setTimeout(function () {
            iframe.focus();
            iframe.contentWindow.print();
          }, 1);
        };

      } catch (error) {
        setNotification({
          message: 'Não foi possível exportar este relatório.',
          type: 'error',
        });
      }
      setExportLoading(false);
    }
  };

  useEffect(() => {
    const doFetch = async () => {
      try {
        setIsLoading(true);
        const { data } = await fetchAllReports(page, type, organization);
        setReports(data.data);
        if (data.meta.last_page !== lastPage) {
          setLastPage(data.meta.last_page);
        }
      } catch (error) {
        setError(error.response);
      }
      setIsLoading(false);
    };
    if (organization) doFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastPage, page, organization]);

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const { data } = await fetchAllTypes(type);
        setReportTypes(data);
        setNewReport(data[0].id);
      } catch (error) {
        setError(error.response);
      }
    };

    const fetchOrganizations = async () => {
      try {
        setIsLoadingOrganizations(true);
        const { data } = await fetchAllOrganizations();
        setOrganization(data[0].id);
        setOrganizations(data);
        setIsLoadingOrganizations(false);
      } catch (error) {
        setError(error.response);
      }
    };

    fetchTypes();
    fetchOrganizations();
  }, [type]);

  useEffect(() => {
    if (hasError) throw hasError;
  }, [hasError]);

  const createNewReport = useCallback(() => {
    const doCreate = async () => {
      try {
        // TODO: Add picker to create a new report to an organization
        const { data } = await createReport(newReport, organization);
        const { id, name, date, sections } = data;

        setReportName(name);
        setReportDate(date);
        setSections(sections);

        history.push(`/avaliar/${id}/1`);
      } catch (error) {
        setError(error.response);
      }
    };

    doCreate();
  }, [
    newReport,
    organization,
    setReportName,
    setReportDate,
    setSections,
    history,
  ]);

  const triggerCreateNewReport = (method = openDialog) => {
    switch (method) {
      case 'online':
        createNewReport();
        break;
      case 'print':
        printReport(true);
        break;

      default:
        break;
    }
  };

  const handleNewReport = method => {
    if (reportTypes.length === 1) {
      triggerCreateNewReport(method);
    } else {
      setOpenDialog(method);
    }
  };

  return (
    <Layout breadcrumb_links={links}>
      <Dialog
        open={openDialog !== ''}
        onClose={() => setOpenDialog('')}
        aria-labelledby="dialog-title"
      >
        <DialogTitle id="dialog-title">
          {openDialog === 'online'
            ? 'Preencher nova lista de verificação'
            : 'Imprimir nova lista de verificação'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Selecione a lista de verificação</DialogContentText>
          <Select
            value={newReport}
            onChange={e => setNewReport(e.target.value)}
            fullWidth
          >
            {reportTypes.map(reportType => (
              <MenuItem key={reportType.id} value={reportType.id}>
                {reportType.name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog('')}
            variant="outlined"
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => triggerCreateNewReport()}
            variant="contained"
            color="primary"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        {!isLoadingOrganizations && (
          <div>
            {type === 'Qualidade de Ementas - saudáveis e sustentáveis' && (
              <div>
                <p>A escola pode e deve contribuir para o cumprimento dos pressupostos de uma alimentação saudável e sustentável, com a adoção de comportamentos e/ou desenvolvendo iniciativas que promovam a consciencialização da comunidade, minimizando, simultaneamente, a ação no meio ambiente.</p>
                <p>Considerando o paradigma atual no que respeita à utilização de recursos naturais, muitos são os estudos com evidência científica que apelam a que preocupações com a sustentabilidade integrem os hábitos alimentares, não só do consumidor como também dos ambientes alimentares onde se inserem. A existência de um ambiente facilitador da prática de uma alimentação saudável e sustentável, conduzirá a benefícios quer para a saúde e a qualidade de vida dos consumidores, quer para os custos relacionados com a saúde.</p>
                <p>Nesta nova versão procedemos à adição de critérios que permitam a elaboração e avaliação qualitativa de ementas tendo por base não só aspetos relacionados com a saúde, mas também com a sustentabilidade, assim com atualizar e complementar com critérios expressos na Circular n.º 3097/DGE/2018. Resumidamente, adicionou-se à anterior ferramenta de avaliação qualitativa de ementas, novos critérios, a fim de serem tidos em consideração aquando da elaboração de uma ementa escolar.</p>
                <p>A sustentabilidade alimentar inclui uma abordagem ampla, na medida em que conjuga as premissas de uma alimentação saudável com fatores ambientais (respeito e preservação dos recursos naturais), socioculturais (os fatores culturais e as oportunidades de decisão inerentes a cada sociedade/cultura) e económicos (custo-benefício do acesso aos alimentos).</p>
              </div>
            )}
            <div className={classes.topBar}>
              <div>
                <FormControlLabel
                  label={
                    <Typography variant="body2" classes={{ root: classes.label }}>
                      Organização
                    </Typography>
                  }
                  labelPlacement="start"
                  control={
                    <Select
                      value={organization}
                      onChange={e => setOrganization(e.target.value)}
                    >
                      {organizations.map(({ id, name }) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                />
              </div>
              <div className={classes.buttonsContainer}>
                <Typography classes={{ root: classes.buttonLabel }}>
                  Nova Lista de Verificação
                </Typography>
                <div className={classes.buttons}>
                  <Button
                    onClick={() => handleNewReport('online')}
                    variant="outlined"
                    color="primary"
                  >
                    Online
                  </Button>
                  <Button
                    onClick={() => handleNewReport('print')}
                    variant="outlined"
                    color="primary"
                  >
                    Impressão
                    {isExportLoading && (
                      <CircularProgress
                        classes={{ root: classes.spinner }}
                        size="1em"
                      />
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <div>
          <CommonList
            data={reports}
            displayItem={item => (
              <ReportListItem
                id={item.id}
                name={item.name}
                lastUpdate={item.lastUpdate}
                printReport={printReport}
              />
            )}
            pagination
            updatePage={newPage => setPage(newPage)}
            lastPage={lastPage}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Layout>
  );
};

ListReport.propTypes = {
  type: PropTypes.string.isRequired,
  setReportName: PropTypes.func.isRequired,
  setReportDate: PropTypes.func.isRequired,
  setSections: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

const ErrorBoundaryWrapper = props => (
  <ErrorBoundary>
    <ListReport
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  </ErrorBoundary>
);

export default compose(
  withRouter,
  connect(null, {
    setReportName: setReportNameAction,
    setReportDate: setReportDateAction,
    setSections: setSectionsAction,
    setNotification: setNotificationAction,
  }),
)(ErrorBoundaryWrapper);
