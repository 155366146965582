import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { createMuiTheme } from '@material-ui/core/styles';
import { NavLink as RRNavLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import styles from './BottomBar.module.css';
import { Login } from '../common';

const inputTheme = createMuiTheme({
  palette: {
    secondary: {
      main: '#4C6D4F',
    },
  },
});

function BottomBar({ isMobile, isLoggedIn }) {
  const renderMobileBar = () => {
    return (
      <>
        {// USER IS NOT LOGGED IN
        !isLoggedIn && (
          <>
            <NavItem>
              <NavLink
                className={styles.navbarLink}
                activeClassName={styles.navbarLinkActive}
                tag={RRNavLink}
                exact
                to="/login"
              >
                Iniciar Sessão
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={styles.navbarLink}
                activeClassName={styles.navbarLinkActive}
                tag={RRNavLink}
                exact
                to="/registar"
              >
                Registar
              </NavLink>
            </NavItem>
          </>
        )}
      </>
    );
  };

  const renderWebBar = () => {
    return (
      <>
        {// USER IS NOT LOGGED IN
        !isLoggedIn && (
          <span className={styles.barContent}>
            <div className={classNames(styles.content, styles.login)}>
              <Login inputTheme={inputTheme} />
            </div>
            <div className={classNames(styles.content, styles.register)}>
              <span className={styles.registerText}>Ainda não tem conta?</span>
              <Link
                to="/registar"
                className={classNames('mr-auto', styles.registerLink)}
              >
                Registe-se
              </Link>
            </div>
          </span>
        )}
      </>
    );
  };

  return (
    <Nav
      className={classNames(
        'ml-auto',
        isLoggedIn ? styles.bottomBarLoggedIn : styles.bottomBar,
      )}
      navbar
    >
      {isMobile && renderMobileBar()}
      {!isMobile && renderWebBar()}
      {// USER IS LOGGED IN
      isLoggedIn && (
        <NavItem
          className={classNames(
            isMobile ? '' : 'd-flex ml-auto',
            styles.content,
          )}
        >
        </NavItem>
      )}
    </Nav>
  );
}

BottomBar.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default BottomBar;
