import React, { useEffect, useContext } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { fetchAllOrganizations } from '../../../services/organizationsService';
import { MealsContext } from '../MealsContextProvider';

const OrganizationPicker = () => {
  // hooks for selected organization
  const {
    userInput: { setSelectedOrganization, selectedOrganization },
  } = useContext(MealsContext);

  // hook to store the user's organizations
  const [organizationOptions, setOrganizationOptions] = React.useState([]);

  // get organizations defined by the user only once!
  useEffect(() => {
    fetchAllOrganizations().then(({ data }) => {
      setOrganizationOptions(data);
      if (data.length > 0) setSelectedOrganization(data[0]);
    });
  }, [setSelectedOrganization]);

  return (
    <Autocomplete
      id="select-organization"
      disableClearable
      options={organizationOptions}
      value={selectedOrganization}
      getOptionLabel={option => option.name}
      onChange={(event, value) => setSelectedOrganization(value)}
      renderInput={params => <TextField {...params} label="Instituição" />}
    />
  );
};

export default OrganizationPicker;
