import React from 'react';
import PropTypes from 'prop-types';

import { ThemeProvider } from '@material-ui/core/styles';

import ListHeader from './ListHeader';
import ListBody from './ListBody';
import ListFooter from './ListFooter';
import tableStatePropTypes from './constants';
import { technicalSheetTheme } from '../styles';

export default function MyList({
  tableState,
  handleTableChange,
  emptyMessage,
  loading,
}) {
  const { data } = tableState;
  return (
    <>
      <ThemeProvider theme={technicalSheetTheme}>
        <ListHeader tableState={tableState} onTableChange={handleTableChange} />
      </ThemeProvider>
      <ListBody
        emptyMessage={emptyMessage}
        data={data}
        loading={loading}
        onTableChange={handleTableChange}
      />
      <ThemeProvider theme={technicalSheetTheme}>
        <ListFooter tableState={tableState} onTableChange={handleTableChange} />
      </ThemeProvider>
    </>
  );
}

MyList.propTypes = {
  tableState: tableStatePropTypes.isRequired,
  handleTableChange: PropTypes.func.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};
