import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import {
  Container,
  Col,
  Form,
  FormGroup,
  Label,
  Alert,
  Button,
} from 'reactstrap';

import { connect } from 'react-redux';
import Popover from './Popover';
import FormControlLabel from './FormControlLabel';
import styles from './SignUpStyle.module.css';

import LoadingAnimation from './LoadingAnimation';
import { login as doLogin } from '../../actions/authActions';
import { submitRegister } from '../../services/authService';

const SignUpForm = ({ login }) => {
  const [name, setName] = useState('');
  const [occupation, setOccupation] = useState('');
  const [district, setDistrict] = useState('');
  const [type, setType] = useState([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState([]);
  const history = useHistory();

  const districts = [
    {
      value: 'Aveiro',
      label: 'Aveiro',
    },
    {
      value: 'Açores',
      label: 'Açores',
    },
    {
      value: 'Beja',
      label: 'Beja',
    },
    {
      value: 'Braga',
      label: 'Braga',
    },
    {
      value: 'Bragança',
      label: 'Bragança',
    },
    {
      value: 'Castelo Branco',
      label: 'Castelo Branco',
    },
    {
      value: 'Coimbra',
      label: 'Coimbra',
    },
    {
      value: 'Évora',
      label: 'Évora',
    },
    {
      value: 'Faro',
      label: 'Faro',
    },
    {
      value: 'Guarda',
      label: 'Guarda',
    },
    {
      value: 'Leiria',
      label: 'Leiria',
    },
    {
      value: 'Lisboa',
      label: 'Lisboa',
    },
    {
      value: 'Madeira',
      label: 'Madeira',
    },
    {
      value: 'Portalegre',
      label: 'Portalegre',
    },
    {
      value: 'Porto',
      label: 'Porto',
    },
    {
      value: 'Santarém',
      label: 'Santarém',
    },
    {
      value: 'Setúbal',
      label: 'Setúbal',
    },
    {
      value: 'Viana do Castelo',
      label: 'Viana do Castelo',
    },
    {
      value: 'Vila Real',
      label: 'Vila Real',
    },
    {
      value: 'Viseu',
      label: 'Viseu',
    },
  ];

  const occupations = [
    {
      value: 'Nutricionista',
      label: 'Nutricionista',
    },
    {
      value: 'Estudante',
      label: 'Estudante',
    },
    {
      value: 'Outra',
      label: 'Outra',
    },
  ];

  const types = [
    {
      value: 'IPSS',
      label: 'IPSS',
    },
    {
      value: 'Centro de saúde',
      label: 'Centro de saúde',
    },
    {
      value: 'Colégio Privado',
      label: 'Colégio Privado',
    },
    {
      value: 'Escola Pública',
      label: 'Escola Pública',
    },
    {
      value: 'Lar',
      label: 'Lar',
    },
    {
      value: 'Unidade de cuidados continuados',
      label: 'Unidade de cuidados continuados',
    },
    {
      value: 'Hospital',
      label: 'Hospital',
    },
    {
      value: 'Faculdade',
      label: 'Faculdade',
    },
    {
      value: 'Centro de dia',
      label: 'Centro de dia',
    },
    {
      value: 'Outro',
      label: 'Outro',
    },
  ];

  const submitForm = e => {
    e.preventDefault();
    setSubmitting(true);
    setErrorMsgs([]);

    submitRegister({
      name,
      occupation,
      district,
      type,
      email,
      password,
      passwordConfirmation,
    })
      .then(body => {
        login({
          token: body.data.token,
          userType: body.data.user_type,
        });
        history.push('/');
      })
      .catch(error => {
        setSubmitting(false);
        if (error.response.status === 400)
          setErrorMsgs(error.response.data.errors);
        else setErrorMsgs(['Ocorreu um erro ao efetuar o registo.']);
      });
  };

  return (
    <Container className={styles.mainContainer}>
      <h2 className={styles.registo}> Registo</h2>
      <div className="position-relative">
        {isSubmitting && <LoadingAnimation />}
        <Form className="form" onSubmit={submitForm}>
          <Col>
            {errorMsgs.length > 0 && (
              <Alert color="danger" fade={false}>
                {errorMsgs.map(msg => {
                  return (
                    <p className="my-1" key={msg}>
                      {msg}
                    </p>
                  );
                })}
              </Alert>
            )}
          </Col>
          <Col>
            <FormGroup className={styles.name}>
              <Label for="name">Nome </Label> <br />
              <TextField
                type="text"
                name="name"
                id="name"
                required
                fullWidth
                onChange={e => setName(e.target.value)}
                value={name}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="district">Local da instituição onde trabalha</Label>{' '}
              <br />
              <TextField
                select
                name="district"
                id="district"
                required
                fullWidth
                onChange={e => setDistrict(e.target.value)}
                value={district}
              >
                {districts.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="type">Tipo de instituição</Label> <br />
              <TextField
                select
                name="type"
                id="type"
                required
                fullWidth
                onChange={e => setType(e.target.value)}
                value={type}
              >
                {types.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="occupation">Profissão</Label>
              <br />
              <TextField
                select
                name="occupation"
                id="occupation"
                required
                fullWidth
                onChange={e => setOccupation(e.target.value)}
                value={occupation}
              >
                {occupations.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="email">Endereço de E-mail</Label>
              <br />
              <TextField
                type="email"
                name="email"
                id="email"
                required
                fullWidth
                onChange={e => setEmail(e.target.value)}
                value={email}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="password">Palavra-chave</Label>
              <br />
              <TextField
                type="password"
                name="password"
                id="password"
                minLength="6"
                maxLength="30"
                required
                fullWidth
                onChange={e => setPassword(e.target.value)}
                value={password}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="password_confirmation">Confirmar Palavra-chave</Label>
              <br />
              <TextField
                type="password"
                name="password_confirmation"
                id="password_confirmation"
                minLength="6"
                maxLength="30"
                required
                fullWidth
                onChange={e => setPasswordConfirmation(e.target.value)}
                value={passwordConfirmation}
              />
            </FormGroup>
          </Col>
          <br />
          <Col className={styles.teste}>
            <FormGroup>
              <FormControlLabel />
            </FormGroup>
            <div className={styles.termos}>
              <Popover />
            </div>
          </Col>

          <Col className={styles.login}>
            <Button className={styles.button}>
              Concluir
              <img
                className={styles.image}
                src={`${process.env.PUBLIC_URL}/activeButton7.png`}
                alt=""
              />
            </Button>
            <div className={styles.link}>
              Já tem conta?{' '}
              <Link className={styles.blueLink} to="/login">
                Iniciar sessão
              </Link>
            </div>
          </Col>
        </Form>
      </div>
    </Container>
  );
};

SignUpForm.propTypes = {
  login: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  login: doLogin,
};

export default connect(null, mapDispatchToProps)(SignUpForm);
