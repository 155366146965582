import React from 'react';
import styles from './ConsHigSani.module.css';

const ConsHigSani = () => (
  <div>
    <div className={styles.title}>
      Higiene / salubridade / ambiente / sustentabilidade
    </div>
    <div className={styles.box}>
      <div className={styles.text}>
        Assegurar o respeito pelos direitos e pelo bem-estar da criança é
        fundamental para garantir o desenvolvimento sustentável de um país. Cabe
        aos profissionais da área da educação, trabalhar para que as crianças e
        jovens tenham acesso a escolas de boa qualidade, não só do ponto de
        vista pedagógico, mas também no que diz respeito à alimentação, de forma
        a minimizar os riscos de saúde.
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.text}>
        Tendo em conta o universo das refeições servidas nestes locais,
        revela-se de extrema importância o conhecimento das condições de higiene
        e salubridade, quer dos locais onde são produzidas e ou distribuídas,
        bem como das próprias refeições e ainda o conhecimento da frequência de
        monitorização.
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.text}>
        Deve-se, então, avaliar de uma forma objetiva e quantificada as
        condições estruturais e funcionais dos locais onde são
        preparadas/regeneradas e servidas as refeições dos estabelecimentos de
        ensino, tendo em vista a evidência de informação autêntica, em prol do
        fornecimento de refeições seguras, servindo, ainda, de referência para
        aplicação das boas práticas de manipulação e de alicerce para o
        progresso no sentido da melhoria contínua.
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.text}>
        Neste contexto, foram elaboradas duas grelhas de avaliação
        higiossanitária para Refeitórios Escolares, que facilitassem a obtenção
        de dados necessários ao diagnóstico e caracterização das condições
        higiossanitárias e técnico – funcionais de refeitórios escolares,
        quantificadas e passíveis de serem utilizadas como instrumentos de
        monitorização:
      </div>
    </div>
    <div className={styles.box}>
      <div className="textmargin">
        Grelha de Avaliação das Condições higiossanitárias de Refeitórios com
        Confeção de Refeições;
        <br /> Grelha de Avaliação das Condições higiossanitárias de Refeitórios
        com Receção de Refeições já confecionadas.
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.text}>
        Obtém-se informações sobre infra-estruturas e instalações/equipamentos,
        procedimentos humanos e técnicos, autocontrolo, tendo em conta a
        sustentabilidade ambiental e social. O conjunto destas características e
        o fácil tratamento e análise, permitem caracterizar quantitativa e
        qualitativamente os refeitórios escolares.
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.text}>
        Obtém-se informações sobre infra-estruturas e instalações/equipamentos,
        procedimentos humanos e técnicos, autocontrolo, tendo em conta a
        sustentabilidade ambiental e social. O conjunto destas características e
        o fácil tratamento e análise, permitem caracterizar quantitativa e
        qualitativamente os refeitórios escolares.
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.text}>
        As grelhas permitem ainda obter informação relativamente à sala de
        refeição, quanto à adequação das infra-estruturas, materiais e
        equipamentos, condições ambientais e higiossanitárias, que no seu
        conjunto contextualizam e potenciam uma refeição escolar favorável,
        quando em conformidade.
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.text}>
        As grelhas resultaram de uma adaptação e modificação de ferramentas de
        trabalho pré-existentes. A sua estrutura e apresentação, os domínios e
        respetivo conteúdo e a ponderação atribuída a cada domínio resultaram de
        um compêndio de concepções de diferentes autores.
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.text}>
        A Grelha de Avaliação das Condições higiossanitárias de Refeitórios com
        Confeção de Refeições encontra-se organizada em 12 domínios de
        avaliação, cada um dos quais composto por vários parâmetros, num total
        de 145 (tabela 1).
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.text}>
        <strong>Tabela 1: </strong>Designação dos 12 domínios considerados na
        Grelha de Avaliação das Condições higiossanitárias de Refeitórios com
        Confeção de Refeições, e respetiva valoração
      </div>
    </div>
    <table className={styles.table1}>
      <thead>
      <tr>
        <th>Dominio</th>
        <th>Valoração %</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          1. Condições Técnico-Funcionais de Vestiários e Sanitários do Pessoal
          e Sanitários dos Utentes
        </td>
        <td style={{textAlign: 'center'}}>6</td>
      </tr>
      <tr>
        <td>
          2. Condições Técnico-Funcionais da Zona de Receção e Condições das
          Matérias-Primas
        </td>
        <td style={{textAlign: 'center'}}>7,5</td>
      </tr>
      <tr>
        <td>3. Condições Técnico-Funcionais do Economato</td>
        <td style={{textAlign: 'center'}}>7,5</td>
      </tr>
      <tr>
        <td>4. Condições Técnico-Funcionais da Rede de Frio</td>
        <td style={{textAlign: 'center'}}>10</td>
      </tr>
      <tr>
        <td>
          5. Condições Técnico-Funcionais da Cozinha (Zonas de preparação e
          confeção, copas limpa e suja)
        </td>
        <td style={{textAlign: 'center'}}>12</td>
      </tr>
      <tr>
        <td>6. Condições de Manipulação</td>
        <td style={{textAlign: 'center'}}>12</td>
      </tr>
      <tr>
        <td>7. Condições dos Produtos Confecionados</td>
        <td style={{textAlign: 'center'}}>7,5</td>
      </tr>
      <tr>
        <td>8. Condições Gerais de Higienização e Conservação</td>
        <td style={{textAlign: 'center'}}>10</td>
      </tr>
      <tr>
        <td>9. Condições do Pessoal Manipulador de Alimentos</td>
        <td style={{textAlign: 'center'}}>10</td>
      </tr>
      <tr>
        <td>10. Controlo de Resíduos</td>
        <td style={{textAlign: 'center'}}>7,5</td>
      </tr>
      <tr>
        <td>11. Controlo de Higienização e de Pragas</td>
        <td style={{textAlign: 'center'}}>5</td>
      </tr>
      <tr>
        <td>12. Autocontrolo</td>
        <td style={{textAlign: 'center'}}>5</td>
      </tr>
      </tbody>
    </table>
    <div className={styles.box}>
      <div className={styles.text}>
        A Grelha de Avaliação das Condições higiossanitárias de Refeitórios com
        Receção de Refeições possui 9 domínios de avaliação, igualmente composto
        por vários parâmetros, num total de 92 (tabela 2).
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.text}>
        O somatório da valoração atribuída a cada domínio na tabela 2 perfaz um
        total de 75 pontos, sendo este valor transposto para 100% no final da
        avaliação, de modo a que o tratamento de dados seja possível.
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.text}>
        <strong>Tabela 2: </strong>Designação dos 9 domínios considerados na
        Grelha de Avaliação das Condições Higiossanitárias de Refeitórios com
        Receção de Refeições, e respetiva valoração.
      </div>
    </div>
    <table className={styles.table2}>
      <thead>
      <tr>
        <th>Dominio</th>
        <th>Valoração %</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          1. Condições Técnico-Funcionais de Vestiários e Sanitários do Pessoal
          e Sanitários dos Utentes
        </td>
        <td style={{textAlign: 'center'}}>6</td>
      </tr>
      <tr>
        <td>
          2. Condições Técnico-Funcionais da Copa Limpa/Zona de Empratamento
        </td>
        <td style={{textAlign: 'center'}}>12</td>
      </tr>
      <tr>
        <td>3. Condições de Manipulação</td>
        <td style={{textAlign: 'center'}}>12</td>
      </tr>
      <tr>
        <td>4. Condições dos Produtos Confecionados</td>
        <td style={{textAlign: 'center'}}>7,5</td>
      </tr>
      <tr>
        <td>5. Condições Gerais de Higienização e Conservação</td>
        <td style={{textAlign: 'center'}}>10</td>
      </tr>
      <tr>
        <td>6. Condições do Pessoal Manipulador de Alimentos</td>
        <td style={{textAlign: 'center'}}>10</td>
      </tr>
      <tr>
        <td>7. Controlo de Resíduos</td>
        <td style={{textAlign: 'center'}}>7,5</td>
      </tr>
      <tr>
        <td>8. Controlo de Higienização e de Pragas</td>
        <td style={{textAlign: 'center'}}>5</td>
      </tr>
      <tr>
        <td>9. Autocontrolo</td>
        <td style={{textAlign: 'center'}}>5</td>
      </tr>
      </tbody>
    </table>
    <div className={styles.box}>
      <div className="subtitle">
        <strong>Classificação quantitativa e qualitativa</strong>
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.text}>
        Os parâmetros foram quantificados de acordo com a sua importância
        relativa, assim como, a cada domínio foi atribuído um peso específico,
        em percentagem, tendo em conta o seu contributo para o comprometimento
        da Segurança Alimentar permitindo, assim, que os resultados pudessem ser
        convertidos para a forma qualitativa, mediante a seguinte grelha de
        classificação (tabela 3) também utilizada no Centro Regional de Saúde
        Pública do Norte e ainda por outros autores.
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.text}>
        <strong>Tabela 3: </strong>Classificação qualitativa dos resultados.
      </div>
    </div>
    <table className={styles.table3}>
      <thead>
      <tr>
        <th>Classificação</th>
        <th>Valor Percentual % </th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Muito Bom</td>
        <td style={{textAlign: 'center'}}>&gt; 90 e ≤ 100</td>
      </tr>
      <tr>
        <td>Bom</td>
        <td style={{textAlign: 'center'}}>&gt; 75 e ≤ 90</td>
      </tr>
      <tr>
        <td>Aceitável</td>
        <td style={{textAlign: 'center'}}>&gt; 50 e ≤ 75</td>
      </tr>
      <tr>
        <td>Não Aceitável</td>
        <td style={{textAlign: 'center'}}>≤ 50</td>
      </tr>
      </tbody>
    </table>
  </div>
);

export default ConsHigSani;
