import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';
import FileSaver from 'file-saver';
import moment from 'moment';
import {
  exportReport,
  duplicateReport,
  fetchReport,
} from '../../services/reportsService';

import PureView from '../../components_lgp/Reports/pure/PureView';

import styles from './ViewReport.module.css';

import {
  setReportName,
  setReportDate,
  setSections,
} from '../../actions/reportActions';
import { setNotification } from '../../actions/notificationActions';

import ErrorBoundary from '../common/ErrorBoundary';

const ViewReport = ({
  // eslint-disable-next-line no-shadow
  setReportName,
  // eslint-disable-next-line no-shadow
  setReportDate,
  // eslint-disable-next-line no-shadow
  setSections,
  // eslint-disable-next-line no-shadow
  setNotification,
}) => {
  const { id } = useParams();
  const [hasError, setError] = useState(null);
  const [report, setReport] = useState({
    answers: [],
    name: 'Relatório',
    lastUpdate: null,
  });
  const [newReport, setNewReport] = useState(null);
  const [isExportLoading, setExportLoading] = useState(false);

  const printReport = async reportId => {
    if (!isExportLoading) {
      try {
        setExportLoading(true);
        const { data } = await exportReport(reportId);
        const file = new Blob([data], {
          type: 'application/pdf',
        });
        FileSaver.saveAs(
          file,
          `${report.name}_${moment(report.lastUpdate).format(
            'DD_MM_YYYY__H_m_s',
          )}.pdf`,
        );
      } catch (error) {
        setNotification({
          message: 'Não foi possível exportar este relatório.',
          type: 'error',
        });
      }
      setExportLoading(false);
    }
  };

  const copyReport = useCallback(() => {
    const doCreate = async () => {
      try {
        const { data } = await duplicateReport(id);
        const { id: newID, name, date, sections } = data;

        setNewReport(newID);
        setReportName(name);
        setReportDate(date);
        setSections(sections);
        setNotification({
          message: `Relatório duplicado com sucesso!`,
          type: 'success',
        });
      } catch (error) {
        setError(error.response);
      }
    };

    doCreate();
  }, [id, setReportName, setReportDate, setSections, setNotification]);

  useEffect(() => {
    const doFetch = async () => {
      try {
        const { data } = await fetchReport(id);
        const { name, lastUpdate, answers } = data;

        setReport(data);
        setReportName(name);
        setReportDate(lastUpdate);
        setSections(answers.length);
      } catch (error) {
        setError(error.response);
      }
    };

    doFetch();
  }, [id, setReportName, setReportDate, setSections]);

  useEffect(() => {
    if (hasError) throw hasError;
  }, [hasError]);

  const { answers, name, lastUpdate } = report;

  return (
    <div className={styles.container}>
      {lastUpdate === null ? (
        <Alert color="success">A obter relatório...</Alert>
      ) : (
        <>
          <PureView
            name={name}
            lastUpdate={lastUpdate}
            answers={answers}
            id={id}
            newReport={newReport}
            duplicateReport={copyReport}
            printReport={printReport}
          />
        </>
      )}
    </div>
  );
};

const ErrorBoundaryWrapper = props => (
  <ErrorBoundary>
    <ViewReport
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  </ErrorBoundary>
);

ViewReport.propTypes = {
  setReportName: PropTypes.func.isRequired,
  setReportDate: PropTypes.func.isRequired,
  setSections: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

ErrorBoundaryWrapper.propTypes = {
  setReportName: PropTypes.func.isRequired,
  setReportDate: PropTypes.func.isRequired,
  setSections: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  connect(null, {
    setReportName,
    setReportDate,
    setSections,
    setNotification,
  }),
)(ErrorBoundaryWrapper);
