import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import Moment from 'moment';

import Calendar from './Calendar';
import DailyMealsTable from './DailyMealsTable';
import IntervalSelect from './IntervalSelect';

const DailyView = ({
  date,
  setDate,
  meals,
  interval,
  setInterval,
  options,
}) => (
  <>
    <Row className="mt-2">
      <Col
        lg="4"
        xs="12"
        className="d-flex justify-content-center align-items-center flex-column"
      >
        <IntervalSelect
          interval={interval}
          setInterval={setInterval}
          options={options}
        />
        <Calendar date={date} setDate={setDate} />
      </Col>
      <Col lg="8" xs="12">
        <DailyMealsTable
          meals={meals.filter(
            meal => meal.date === Moment(date).format('YYYY-MM-DD'),
          )}
          date={date}
        />
      </Col>
    </Row>
  </>
);

DailyView.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  setDate: PropTypes.func.isRequired,
  meals: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  interval: PropTypes.string.isRequired,
  setInterval: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
};

export default DailyView;
