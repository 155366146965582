import React from 'react';

import Layout from '../components_lgp/layout/Layout';
import Admin from '../components/Administration/Admin';
import Page from '../components/common/Page';

const AdminDashboard = () => (
  <Page title="Utilizadores">
    <Layout>
      <Admin />
    </Layout>
  </Page>
);

export default AdminDashboard;
