import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import AddIcon from '@material-ui/icons/Add';
import Layout from '../components_lgp/layout/Layout';
import Page from '../components/common/Page';

import Search from '../components/common/Search';
import CommonList from '../components/common/CommonList';
import IngredientListItem from '../components/Ingredients/List/IngredientListItem';
import { setNotification } from '../actions/notificationActions';

import styles from './IngredientsList.module.css';
import { fetchAllIngredients } from '../services/ingredientsService';

const breadCrumbLinks = [
  {
    name: 'Administração',
    url: '/admin',
  },
  {
    name: 'Gerir Ingredientes',
    url: '/admin/ingredientes',
  },
];

// eslint-disable-next-line
const IngredientsList = ({ userType, setNotification }) => {
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [listItems, setListItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const isSmallScreen = useMediaQuery({ maxWidth: '1070px' });

  const updateData = async () => {
    try {
      setIsLoading(true);
      const { data } = await fetchAllIngredients(
        currentPage,
        currentSearchTerm,
      );
      setIsLoading(false);
      setListItems(data.data);
      if (data.meta.last_page !== lastPage) {
        setLastPage(data.meta.last_page);
      }
    } catch (e) {
      setIsLoading(false);
      setNotification({
        message: `Não foi possível obter os ingredientes. Por favor, tente novamente.`,
        type: 'error',
      });
    }
  };

  const updatePage = page => {
    setCurrentPage(page);
  };

  const onSearch = query => {
    setCurrentSearchTerm(query);
  };

  useEffect(() => {
    if (currentPage === 1) {
      updateData();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearchTerm]);

  useEffect(() => {
    updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <Page title="Administração | Ingredientes">
      <Layout breadcrumb_links={breadCrumbLinks}>
        <Container fluid>
          <Row className={styles.contentWrapper}>
            <Col xs="10">
              <Search onSearch={onSearch} searchTarget="um ingrediente" />
            </Col>
            <Col xs="2" className={isSmallScreen ? styles.col : ''}>
              <Link to="/admin/ingredientes/criar" className={styles.add}>
                <div className={styles.addLink}>
                  <AddIcon />
                  <span
                    className={isSmallScreen ? styles.textSmall : styles.text}
                  >
                    Adicionar
                  </span>
                </div>
              </Link>
            </Col>
            <Col xs="12">
              <CommonList
                data={listItems}
                displayItem={item => (
                  <IngredientListItem id={item.id} name={item.name} />
                )}
                searchTerm={currentSearchTerm}
                pagination
                updatePage={updatePage}
                lastPage={lastPage}
                isLoading={isLoading}
              />
            </Col>
          </Row>
        </Container>
      </Layout>
    </Page>
  );
};

IngredientsList.propTypes = {
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  userType: auth.userType,
});

export default connect(mapStateToProps, { setNotification })(IngredientsList);
