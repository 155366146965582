import React from 'react';
import PropTypes from 'prop-types';

import { TablePagination } from '@material-ui/core';

import tableStatePropTypes from './constants';
import { useStyles } from '../styles';

export default function ListFooter({ tableState, onTableChange }) {
  const classes = useStyles();

  const { page, rowsPerPage, total } = tableState;

  return (
    <div className={classes.footer}>
      <TablePagination
        component="div"
        className={classes.root}
        classes={{
          caption: classes.caption,
          selectRoot: classes.footerSelect,
          actions: classes.iconContainer,
        }}
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage="Linhas por página: "
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count}`
        }
        backIconButtonProps={{
          'aria-label': 'página anterior',
        }}
        nextIconButtonProps={{
          'aria-label': 'página seguinte',
        }}
        rowsPerPageOptions={[10, 20, 100]}
        onChangePage={(_, newPage) =>
          onTableChange('changePage', { ...tableState, page: newPage })
        }
        onChangeRowsPerPage={event =>
          onTableChange('changeRowsPerPage', {
            ...tableState,
            rowsPerPage: event.target.value,
          })
        }
      />
    </div>
  );
}

ListFooter.propTypes = {
  tableState: tableStatePropTypes.isRequired,
  onTableChange: PropTypes.func.isRequired,
};
