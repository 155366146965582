import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import styles from './AppNavbar.module.css';

function TopBar({ isMobile, isLoggedIn, isUserAdmin }) {
  // TODO: Update para 3 Módulos ou Submódulos respectivos
  const mobileItems = [
    {
      name: 'Consultar',
      to: '/consultar',
    },
    {
      name: 'Planear',
      to: '/planear',
    },
    {
      name: 'Avaliar',
      to: '/avaliar',
    },
  ];

  return (
    <Nav className={classNames('ml-auto', styles.topBar)} navbar>
      <span className={isMobile ? styles.barContentMobile : styles.barContent}>
        {isMobile && (
          <>
            {mobileItems.map(({ name, to }) => (
              <NavItem key={name}>
                <NavLink
                  className={styles.navBarLink}
                  activeClassName={styles.navBarLinkActive}
                  tag={RRNavLink}
                  exact
                  to={to}
                >
                  {name}
                </NavLink>
              </NavItem>
            ))}
            {// USER IS ADMIN
            isLoggedIn && isUserAdmin && (
              <NavItem>
                <NavLink
                  className={styles.navBarLink}
                  activeClassName={styles.navBarLinkActive}
                  tag={RRNavLink}
                  exact
                  to="/admin"
                >
                  Administração
                </NavLink>
              </NavItem>
            )}
            <div className={styles.divider} />
          </>
        )}
        <NavItem>
          <NavLink
            className={styles.navBarLink}
            activeClassName={styles.navBarLinkActive}
            tag={RRNavLink}
            exact
            to="/sobre"
          >
            SOBRE NÓS
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={styles.navBarLink}
            activeClassName={styles.navBarLinkActive}
            tag={RRNavLink}
            exact
            to="/faq"
          >
            PERGUNTAS FREQUENTES
          </NavLink>
        </NavItem>
        {// USER IS ADMIN
        isLoggedIn && isUserAdmin && (
          <NavItem>
            <NavLink
              className={styles.navBarLink}
              activeClassName={styles.navBarLinkActive}
              tag={RRNavLink}
              exact
              to="/admin"
            >
              Administração
            </NavLink>
          </NavItem>
        )}
        {// USER IS LOGGED IN
        isLoggedIn && (
          <>
            <NavItem>
              <NavLink
                className={styles.navBarLink}
                activeClassName={styles.navBarLinkActive}
                tag={RRNavLink}
                exact
                to="/perfil"
              >
                PERFIL
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={styles.navBarLink}
                activeClassName={styles.navBarLinkActive}
                tag={RRNavLink}
                exact
                to="/terminar-sessao"
              >
                SAIR
              </NavLink>
            </NavItem>
          </>
        )}
      </span>
    </Nav>
  );
}

TopBar.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isUserAdmin: PropTypes.bool.isRequired,
};

export default TopBar;
