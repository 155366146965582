import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import listMealsStyles from './listMeals.module.css';

const CurrentCalendarRange = ({ date, interval }) => {
  let range;
  let start;
  let end;

  switch (interval) {
    case 'dia':
      range = date.format('D [de] MMMM');
      break;
    case 'mes':
      range = date.format('MMMM');
      break;
    case 'semana':
      start = moment(date).startOf('week');
      end = moment(date).endOf('week');
      if (start.month() === end.month()) {
        range = `${start.date()} - ${end.format('D [de] MMMM')}`;
      } else
        range = `${start.format('D [de] MMMM')} - ${end.format('D [de] MMMM')}`;
      break;
    default:
      break;
  }

  return (
    <div className={listMealsStyles.currentRange}>Ementas para {range}</div>
  );
};

CurrentCalendarRange.propTypes = {
  date: PropTypes.instanceOf(moment).isRequired,
  interval: PropTypes.string.isRequired,
};

export default CurrentCalendarRange;
