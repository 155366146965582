import React, { useState, useEffect } from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  fetchAllTechnicalSheetsSimplified,
  createTechnicalSheet,
  fetchTechnicalSheet,
  updateTechnicalSheet,
} from '../../../services/technicalSheetsService';
import { fetchAllIngredientsSimplified } from '../../../services/ingredientsService';
import getComponentTypes from '../../../services/componentTypesService';

import { setNotification } from '../../../actions/notificationActions';
import Overview from './Overview';
import { Ingredients } from './Ingredients';
import TechnicalSheets from './TechnicalSheets';
import Preparation from './Preparation';

import styles from './TechnicalSheetForm.module.css';
import Title from './Title';
import Allergens from './Allergens';
import FullPage from '../../MealPlanning/pageLayout';

// eslint-disable-next-line
const TechnicalSheetForm = ({ isEditing, setTechSheetName, id }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [componentTypes, setComponentTypes] = useState([]);
  const [componentType, setComponentType] = useState();
  const [preparation, setPreparation] = useState({});
  const [numPeopleServed, setNumPeopleServed] = useState(1);
  const [technicalSheets, setTechnicalSheets] = useState(null);
  const [ingredients, setIngredients] = useState(null);
  const [allergens, setAllergens] = useState([]);
  const [ownAllergens, setOwnAllergens] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState({});
  const [selectedTechnicalSheets, setSelectedTechnicalSheets] = useState({});
  const [ingredientError, setIngredientError] = useState(false);
  const [technicalSheetError, setTechnicalSheetError] = useState(false);
  const [oldTechnicalSheetError, setOldTechnicalSheetError] = useState(false);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [isWaitingForTechSheetFetch, setIsWaitingForTechSheetFetch] = useState(
    false,
  );
  const [
    isWaitingForComponentTypesFetch,
    setIsWaitingForComponentTypesFetch,
  ] = useState(false);
  const [isWaitingForCreateResponse, setIsWaitingForCreateResponse] = useState(
    false,
  );
  const [isWaitingForUpdateResponse, setIsWaitingForUpdateResponse] = useState(
    false,
  );

  const setOldIngredients = technicalSheet => {
    const newSelectedIngredients = { ...selectedIngredients };
    technicalSheet.ingredients.forEach(ingredient => {
      newSelectedIngredients[`ingredient${ingredient.id}`] = {
        componentType: ingredient.type,
        id: ingredient.id,
        name: ingredient.name,
        amount: ingredient.pivot.amount,
        unit: ingredient.pivot.unit,
        provider: ingredient.pivot.supplier_name,
        origin: ingredient.pivot.supplier_origin,
        biological: ingredient.pivot.supplier_bio,
        transgenic: ingredient.pivot.supplier_trans
      };
    });
    setSelectedIngredients(newSelectedIngredients);
  };

  const setOldComponents = technicalSheet => {
    const newSelectedTechnicalSheets = { ...selectedTechnicalSheets };
    technicalSheet.components.forEach(component => {
      newSelectedTechnicalSheets[`technicalSheet${component.id}`] = {
        componentType: component.component_type,
        id: component.id,
        name: component.name,
        amount: component.pivot.amount,
        unit: component.pivot.unit,
      };
    });
    setSelectedTechnicalSheets(newSelectedTechnicalSheets);
  };

  const fetchIngredients = async () => {
    if (!isWaitingForResponse) {
      try {
        setIsWaitingForResponse(true);
        const { data } = await fetchAllIngredientsSimplified();
        setIsWaitingForResponse(false);
        setIngredients(data);
      } catch (error) {
        setIsWaitingForResponse(false);
        setIngredientError(true); // TODO
      }
    }
  };

  const fetchTechnicalSheets = async () => {
    if (!isWaitingForResponse) {
      try {
        setIsWaitingForResponse(true);
        const { data } = await fetchAllTechnicalSheetsSimplified();
        setIsWaitingForResponse(false);
        setTechnicalSheets(data);
      } catch (e) {
        setIsWaitingForResponse(false);
        setTechnicalSheetError(true); // TODO
      }
    }
  };

  const fetchOldTechnicalSheet = async () => {
    if (!isWaitingForResponse) {
      try {
        setIsWaitingForTechSheetFetch(true);
        const { data } = await fetchTechnicalSheet(id);
        setIsWaitingForTechSheetFetch(false);
        setOldIngredients(data);
        setOldComponents(data);
        setName(data.name);
        setTechSheetName(data.name);
        setComponentType(data.component_type);
        setNumPeopleServed(data.num_people_served);
        setAllergens(data.allergens);
        setOwnAllergens(data.ownAllergens);
        setPreparation({
          instructions: data.preparation.instructions,
          duration: data.preparation.duration,
          notes: data.preparation.notes,
          utensils: data.preparation.utensils,
        });
      } catch (e) {
        setIsWaitingForResponse(false);
        setOldTechnicalSheetError(true); // TODO
      }
    }
  };

  const retryIngredientFetch = async () => {
    setIngredientError(false);
    fetchIngredients();
  };

  const retryTechnicalSheetFetch = async () => {
    setTechnicalSheetError(false);
    fetchTechnicalSheets();
  };

  const retryOldTechnicalSheetFetch = async () => {
    setOldTechnicalSheetError(false);
    fetchOldTechnicalSheet();
  };

  const selectIngredient = value => {
    if (selectedIngredients[`ingredient${value.id}`] === undefined) {
      const newSelectedIngredients = { ...selectedIngredients };

      newSelectedIngredients[`ingredient${value.id}`] = {
        componentType: value.type,
        id: value.id,
        name: value.name,
        amount: '',
        unit: '',
        provider: '',
        origin: '',
        biological: false,
        transgenic: false,
      };
      setSelectedIngredients(newSelectedIngredients);
    } else {
      const newSelectedIngredients = { ...selectedIngredients };
      delete newSelectedIngredients[`ingredient${value.id}`];
      setSelectedIngredients(newSelectedIngredients);
    }
  };

  const removeSelectedIngredient = valueId => {
    const newSelectedIngredients = { ...selectedIngredients };
    delete newSelectedIngredients[`ingredient${valueId}`];
    setSelectedIngredients(newSelectedIngredients);
  };

  const removeSelectedTechnicalSheet = valueId => {
    const newSelectedTechnicalSheets = { ...selectedTechnicalSheets };
    delete newSelectedTechnicalSheets[`technicalSheet${valueId}`];
    setSelectedTechnicalSheets(newSelectedTechnicalSheets);
  };

  const selectTechnicalSheet = value => {
    if (selectedTechnicalSheets[`technicalSheet${value.id}`] === undefined) {
      const newSelectedTechnicalSheets = { ...selectedTechnicalSheets };
      newSelectedTechnicalSheets[`technicalSheet${value.id}`] = {
        componentType: value.component_type,
        id: value.id,
        name: value.name,
        amount: '',
        unit: '',
      };
      setSelectedTechnicalSheets(newSelectedTechnicalSheets);
    } else {
      const newSelectedTechnicalSheets = { ...selectedTechnicalSheets };
      delete newSelectedTechnicalSheets[`technicalSheet${value.id}`];
      setSelectedTechnicalSheets(newSelectedTechnicalSheets);
    }
  };

  const updateName = event => {
    setName(event.target.value);
  };

  const updateComponentType = (_, value) => {
    setComponentType(value);
  };

  const updateNumPeopleServed = event => {
    setNumPeopleServed(Number(event.target.value));
  };

  const updatePreparationField = event => {
    const newPreparation = { ...preparation };
    newPreparation[event.target.name] = event.target.value;
    setPreparation(newPreparation);
  };

  const updateSelectedTechnicalSheetQty = event => {
    const newSelection = { ...selectedTechnicalSheets };

    newSelection[event.target.name] = {
      ...selectedTechnicalSheets[event.target.name],
      amount: event.target.value,
    };

    setSelectedTechnicalSheets(newSelection);
  };

  const updateSelectedTechnicalSheetUnit = event => {
    const newSelection = { ...selectedTechnicalSheets };

    newSelection[event.target.name] = {
      ...newSelection[event.target.name],
      unit: event.target.value,
    };

    setSelectedTechnicalSheets(newSelection);
  };

  const updateSelectedIngredientQty = event => {
    const newSelection = { ...selectedIngredients };

    newSelection[event.target.name] = {
      ...newSelection[event.target.name],
      amount: event.target.value,
    };

    setSelectedIngredients(newSelection);
  };

  const updateSelectedIngredientUnit = event => {
    const newSelection = { ...selectedIngredients };

    newSelection[event.target.name] = {
      ...newSelection[event.target.name],
      unit: event.target.value,
    };

    setSelectedIngredients(newSelection);
  };

  const updateSelectedIngredientProvider = event => {
    const newSelection = { ...selectedIngredients };

    newSelection[event.target.name] = {
      ...newSelection[event.target.name],
      provider: event.target.value,
    };

    setSelectedIngredients(newSelection);
  };

  const updateSelectedIngredientOrigin = event => {
    const newSelection = { ...selectedIngredients };

    newSelection[event.target.name] = {
      ...newSelection[event.target.name],
      origin: event.target.value,
    };

    setSelectedIngredients(newSelection);
  };

  const updateSelectedIngredientBiologic = event => {
    const newSelection = { ...selectedIngredients };

    newSelection[event.target.name] = {
      ...newSelection[event.target.name],
      biological: event.target.checked,
    };

    setSelectedIngredients(newSelection);
  };

  const updateSelectedIngredientTransgenic = event => {
    const newSelection = { ...selectedIngredients };

    newSelection[event.target.name] = {
      ...newSelection[event.target.name],
      transgenic: event.target.checked,
    };

    setSelectedIngredients(newSelection);
  };

  const updateSelectedAllergens = (newOwn, newTotal) => {
    setOwnAllergens(newOwn);
    setAllergens(newTotal);
  };

  const sendRequest = async event => {
    event.preventDefault();
    if (isEditing) {
      if (!isWaitingForUpdateResponse) {
        try {
          setIsWaitingForUpdateResponse(true);
          const { data } = await updateTechnicalSheet(
            {
              name,
              component_type: componentType,
              num_people_served: numPeopleServed,
              ingredients: Object.keys(selectedIngredients).map(selected => ({
                ...selectedIngredients[selected],
              })),
              components: Object.keys(selectedTechnicalSheets).map(
                selected => ({
                  ...selectedTechnicalSheets[selected],
                }),
              ),
              preparation,
              ownAllergens,
            },
            id,
          );
          setIsWaitingForUpdateResponse(false);
          dispatch(
            setNotification({
              message: `Ficha técnica "${data.name}" atualizada com sucesso!`,
              type: 'success',
            }),
          );
          history.push(`/planear/fichastecnicas/${data.id}`);
        } catch (e) {
          setIsWaitingForUpdateResponse(false);
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
          dispatch(
            setNotification({
              message:
                'Ocorreu um erro ao tentar atualizar esta ficha técnica. Tente novamente.',
              type: 'error',
            }),
          );
        }
      }
    } else if (!isWaitingForCreateResponse) {
      try {
        setIsWaitingForCreateResponse(true);
        const { data } = await createTechnicalSheet({
          name,
          component_type: componentType,
          num_people_served: numPeopleServed,
          ingredients: Object.keys(selectedIngredients).map(selected => ({
            ...selectedIngredients[selected],
          })),
          components: Object.keys(selectedTechnicalSheets).map(selected => ({
            ...selectedTechnicalSheets[selected],
          })),
          preparation,
          ownAllergens,
        });
        setIsWaitingForCreateResponse(false);
        dispatch(
          setNotification({
            message: `Ficha técnica "${data.name}" criada com sucesso!`,
            type: 'success',
          }),
        );
        history.push(`/planear/fichastecnicas/${data.id}`);
      } catch (e) {
        setIsWaitingForCreateResponse(false);
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        dispatch(
          setNotification({
            message:
              'Ocorreu um erro ao tentar criar esta ficha técnica. Tente novamente.',
            type: 'error',
          }),
        );
      }
    }
  };

  const fetchComponentTypes = async () => {
    setIsWaitingForComponentTypesFetch(true);
    getComponentTypes().then(({ data: types }) => {
      setComponentTypes(types);
      setIsWaitingForComponentTypesFetch(false);
    });
  };

  useEffect(() => {
    if (isEditing) {
      fetchOldTechnicalSheet();
    } else {
      setAllergens([]);
    }

    fetchIngredients();
    fetchTechnicalSheets();
    fetchComponentTypes();
    // eslint-disable-next-line
  }, []);

  const renderForm = () => {
    return (
      <>
        <Title
          className={styles.title}
          text={isEditing ? 'Editar Ficha Técnica' : 'Crir Ficha Técnica'}
        />
        {oldTechnicalSheetError ? (
          <Col className={styles.alertCol} xs="12">
            <Alert
              className={styles.retryAlert}
              color="warning"
              onClick={retryOldTechnicalSheetFetch}
            >
              Ocorreu um erro a tentar obter a ficha técnica a atualizar,
              carregue aqui para tentar novamente
            </Alert>
          </Col>
        ) : (
          <Col className={styles.formCol} xs="12">
            <Overview
              updateName={updateName}
              updateComponentType={updateComponentType}
              name={name}
              componentType={componentType}
              componentTypes={componentTypes}
              numPeopleServed={numPeopleServed}
              updateNumPeopleServed={updateNumPeopleServed}
            />
            <Ingredients
              selectedIngredients={selectedIngredients}
              ingredients={ingredients}
              ingredientError={ingredientError}
              removeSelectedIngredient={removeSelectedIngredient}
              updateSelectedIngredientQty={updateSelectedIngredientQty}
              updateSelectedIngredientUnit={updateSelectedIngredientUnit}
              updateSelectedIngredientProvider={updateSelectedIngredientProvider}
              updateSelectedIngredientOrigin={updateSelectedIngredientOrigin}
              updateSelectedIngredientBiologic={updateSelectedIngredientBiologic}
              updateSelectedIngredientTransgenic={updateSelectedIngredientTransgenic}
              selectIngredient={selectIngredient}
              retryIngredientFetch={retryIngredientFetch}
            />
            <TechnicalSheets
              selectedTechnicalSheets={selectedTechnicalSheets}
              technicalSheetId={id}
              technicalSheets={technicalSheets}
              technicalSheetError={technicalSheetError}
              removeSelectedTechnicalSheet={removeSelectedTechnicalSheet}
              updateSelectedTechnicalSheetQty={updateSelectedTechnicalSheetQty}
              updateSelectedTechnicalSheetUnit={
                updateSelectedTechnicalSheetUnit
              }
              selectTechnicalSheet={selectTechnicalSheet}
              retryTechnicalSheetFetch={retryTechnicalSheetFetch}
            />
            <Preparation
              updatePreparationField={updatePreparationField}
              preparation={preparation}
            />
            <Allergens
              updateSelectedAllergens={updateSelectedAllergens}
              allergens={allergens}
              ownAllergens={ownAllergens}
              selectedTechnicalSheets={selectedTechnicalSheets}
            />
          </Col>
        )}
      </>
    );
  };

  return (
    <>
      {isWaitingForCreateResponse ||
      isWaitingForUpdateResponse ||
      isWaitingForComponentTypesFetch ||
      isWaitingForTechSheetFetch ? (
        <div className="mt-5 d-flex justify-content-center">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <FullPage
          main={renderForm()}
          links={[
            {
              label: 'Submeter',
              onClick: sendRequest,
            },
          ]}
        />
      )}
    </>
  );
};

TechnicalSheetForm.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  id: PropTypes.string,
  setTechSheetName: PropTypes.func,
};

TechnicalSheetForm.defaultProps = {
  setTechSheetName: () => {},
  id: '0',
};

export default TechnicalSheetForm;
