import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'reactstrap';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import listMealsStyles from './listMeals.module.css';

const icons = {
  left: <FaChevronLeft className={listMealsStyles.icon} />,
  right: <FaChevronRight className={listMealsStyles.icon} />,
};

const CalendarMover = ({ moveCalendar, interval, moveTo }) => {
  let intervalToPrint = 'Dia';

  if (interval === 'mes') intervalToPrint = 'Mês';
  else if (interval === 'semana') intervalToPrint = 'Semana';

  return (
    <Button
      className={classnames(
        listMealsStyles.mover,
        'justify-content-center align-items-center',
      )}
      outline
      color="light"
      type="button"
      onClick={moveCalendar}
      data-toggle="tooltip"
      data-placement={moveTo}
      title={`${intervalToPrint} ${
        moveTo === 'left' ? 'anterior' : 'seguinte'
      }`}
    >
      {icons[moveTo]}
    </Button>
  );
};

CalendarMover.propTypes = {
  moveCalendar: PropTypes.func.isRequired,
  interval: PropTypes.string.isRequired,
  moveTo: PropTypes.string.isRequired,
};

export default CalendarMover;
