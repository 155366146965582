import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import styles from './RecomAlimentarStyle.module.css';

const useStyles = makeStyles(() => ({
  tabStyle: {
    '& .MuiTab-wrapper': {
      display: 'inline',
      '& img': {
        marginRight: '0.5em',
      },
    },
  },
}));

function TabPanel1(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel1-${index}`}
      aria-labelledby={`simple-tab1-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel1.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TabPanel2(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel2-${index}`}
      aria-labelledby={`simple-tab2-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel2.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps1(index) {
  return {
    id: `simple-tab1-${index}`,
    'aria-controls': `simple-tabpanel1-${index}`,
  };
}
function a11yProps2(index) {
  return {
    id: `simple-tab2-${index}`,
    'aria-controls': `simple-tabpanel2-${index}`,
  };
}

const RecomAlimentarTable = () => {
  const classes = useStyles();
  const [value1, setValue1] = React.useState(0);
  const [value2, setValue2] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
  };

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  return (
    <div>
      <table className={styles.tableList}>
        <tr>
          <td className={styles.container}>
            <div className={styles.title}>A Roda dos Alimentos</div>
            <div className={styles.box}>
              <div className={styles.text}>
                <p>
                  Com o propósito de ensinar a população Portuguesa a fazer as
                  suas escolhas alimentares de forma saudável, surge em 2003 a
                  reformulação do guia alimentar português, a Roda dos
                  Alimentos.
                </p>
                <p>
                  A escolha e combinação dos alimentos deve obedecer aos
                  princípios orientadores transmitidos pelo nosso guia da
                  alimentação saudável.
                </p>
                <p>
                  Para conhecer os guias alimentares para a população Portuguesa, consulte &quot;A Nova Roda dos Alimentos&quot; (
                  <a
                    href={`${process.env.PUBLIC_URL}/documents/RodaDosAlimentos.pdf`}
                  >
                    PDF
                  </a>
                  ) e a Roda da Alimentação Mediterrânica (<a
                  href={`${process.env.PUBLIC_URL}/documents/RodaDaAlimentacaoMediterranea.pdf`}
                  >PDF</a>).
                </p>
              </div>
              <div className={styles.imgBox}>
                <img
                  alt="Roda dos Alimentos"
                  src={`${process.env.PUBLIC_URL}/roda-alimentos.png`}
                />
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td className={styles.container}>
            <div className={styles.title}>Porções Alimentares</div>

            <p>
              O número de porções de alimentos a ingerir diariamente, depende
              das necessidades energéticas e nutricionais de cada indivíduo,
              variando com múltiplos fatores como o sexo, a idade, a atividade
              física, entre outros.
            </p>
            <p>
              O quadro seguinte especifica a quantidade a que corresponde cada
              porção e seus equivalentes bem como o intervalo do número de
              porções a ingerir diariamente consoante as necessidades
              nutricionais de cada um.
            </p>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={styles.headred} />
                    <TableCell className={styles.headred} align="center">
                      N.º de porções/dia**
                    </TableCell>
                    <TableCell className={styles.headred} align="left">
                      1 porção equivale a escolher 1 entre as seguintes opções
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key="">
                    <TableCell component="th" scope="row">
                      Cereais e derivados, tubérculos
                    </TableCell>
                    <TableCell align="center">4 a 11</TableCell>
                    <TableCell align="left">
                      <ul>
                        <li>1 pão (50g)</li>
                        <li>1 fatia fina de broa (70g)</li>
                        <li>1 e 1/2 batata - tamanho médio (125g)</li>
                        <li>
                          5 colheres de sopa de cereais de pequeno-almoço (35g)
                        </li>
                        <li>6 bolachas - tipo maria / água e sal (35g)</li>
                        <li>2 colheres de sopa de arroz / massa crus (35g)</li>
                        <li>
                          4 colheres de sopa de arroz / massa cozinhados (110g)
                        </li>
                      </ul>
                    </TableCell>
                  </TableRow>

                  <TableRow key="">
                    <TableCell component="th" scope="row">
                      Hortícolas
                    </TableCell>
                    <TableCell align="center">3 a 5</TableCell>
                    <TableCell align="left">
                      <ul>
                        <li>
                          2 chávenas almoçadeiras de hortí­colas crus (180g)
                        </li>
                        <li>
                          1 chávena almoçadeira de hortícolas cozinhados (140g)
                        </li>
                      </ul>
                    </TableCell>
                  </TableRow>

                  <TableRow key="">
                    <TableCell component="th" scope="row">
                      Fruta
                    </TableCell>
                    <TableCell align="center">3 a 5</TableCell>
                    <TableCell align="left">
                      <ul>
                        <li>1 peça de fruta - tamanho médio (160g)</li>
                      </ul>
                    </TableCell>
                  </TableRow>

                  <TableRow key="">
                    <TableCell component="th" scope="row">
                      Laticínios***
                    </TableCell>
                    <TableCell align="center">2 a 3</TableCell>
                    <TableCell align="left">
                      <ul>
                        <li>1 chávena almoçadeira de leite (250ml)</li>
                        <li>
                          1 iogurte líquido ou 1 e 1/2 iogurte sólido (200g)
                        </li>
                        <li>2 fatias finas de queijo (40g)</li>
                        <li>1/4 de queijo fresco - tamanho médio (50g)</li>
                        <li>1/2 requeijão - tamanho médio (100g)</li>
                      </ul>
                    </TableCell>
                  </TableRow>

                  <TableRow key="">
                    <TableCell component="th" scope="row">
                      Carne, pescado e ovos
                    </TableCell>
                    <TableCell align="center">1,5 a 4,5</TableCell>
                    <TableCell align="left">
                      <ul>
                        <li>Carnes / pescado crus (30g)</li>
                        <li>Carnes / pescado cozinhados (25g)</li>
                        <li>1 ovo - tamanho médio (55g)</li>
                      </ul>
                    </TableCell>
                  </TableRow>

                  <TableRow key="">
                    <TableCell component="th" scope="row">
                      Leguminosas
                    </TableCell>
                    <TableCell align="center">1 a 2</TableCell>
                    <TableCell align="left">
                      <ul>
                        <li>
                          1 colher de sopa de leguminosas secas cruas (ex.: grão
                          de bico, feijão, lentilhas) (25g)
                        </li>
                        <li>
                          3 colheres de sopa de leguminosas frescas cruas (ex.:
                          ervilhas, favas) (80g)
                        </li>
                        <li>
                          3 colheres de sopa de leguminosas secas /frescas
                          cozinhadas (80g)
                        </li>
                      </ul>
                    </TableCell>
                  </TableRow>

                  <TableRow key="">
                    <TableCell component="th" scope="row">
                      Gorduras e óleos
                    </TableCell>
                    <TableCell align="center">1 a 3</TableCell>
                    <TableCell align="left">
                      <ul>
                        <li>1 colher de sopa de azeite / óleo (10g)</li>
                        <li>1 colher de chá de banha (10g)</li>
                        <li>4 colheres de sopa de nata (30ml)</li>
                        <li>
                          1 colher de sobremesa de manteiga / margarina (15g)
                        </li>
                      </ul>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <p style={{fontSize: 'small'}}>
              * As equivalências alimentares apresentadas tiveram por base
              valores estabelecidos de nutrientes, glícidos/hidratos de carbono
              - nos grupos dos cereais e derivados, tubérculos (28g), hortícolas
              (6g) e fruta (14g); proteínas - no caso de laticínios (8g) (onde
              também se teve em linha de conta o valor de cálcio - 300 mg),
              carnes, pescado e ovo (6g); e lípidos para o grupo de gorduras e
              óleos (10g); ** Os valores limite (mínimo e máximo) das porções
              aqui recomendadas foram calculados para os valores energéticos de
              1300 kcal e 3000 kcal, sendo a quantidade intermédia
              correspondente a um plano alimentar de 2200 kcal; *** A
              generalidade da população deve consumir 2 porções, com exceção de
              crianças e adolescentes, que necessitam de 3 porções.
            </p>
            <p>
              As porções a ingerir por crianças de 1 - 3 anos correspondem ao
              limite inferior do intervalo de porções estabelecido para cada
              grupo de alimentos;
            </p>

            <ul>
              <li>
                o limite superior diz respeito a homens fisicamente ativos e
                rapazes adolescentes;
              </li>
              <li>
                a restante população deve orientar-se pelos valores intermédios.
              </li>
            </ul>

            <p>
              Por exemplo, um adulto necessita em média de 2200 kcal/dia, o que
              corresponde, em porções de alimentos aos valores intermédios do
              intervalo estabelecido para cada grupo, como pode verificar na
              tabela que se segue.
            </p>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={styles.headred} align="left">
                      Grupo de alimentos
                    </TableCell>
                    <TableCell className={styles.headred} align="center">
                      N.º de porções para um adulto
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Cereais e derivados, tubérculos</TableCell>
                    <TableCell align="center">7</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Hortícolas</TableCell>
                    <TableCell align="center">4</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Fruta</TableCell>
                    <TableCell align="center">4</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Laticínios</TableCell>
                    <TableCell align="center">2,5</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Carnes, pescado e ovos</TableCell>
                    <TableCell align="center">3</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Leguminosas</TableCell>
                    <TableCell align="center">1,5</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Gorduras e óleos</TableCell>
                    <TableCell align="center">2</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </td>
        </tr>

        <tr>
          <td className={styles.container}>
            <div className={styles.title}>Exemplo de um dia alimentar</div>
            <p>
              Apresenta-se, no quadro abaixo, um exemplo de um dia alimentar
              saudável considerando as porções alimentares anteriormente
              apresentadas.
            </p>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={styles.headred} align="left">
                      Pequeno almoço
                    </TableCell>
                    <TableCell className={styles.headred} align="left">
                      Merenda da manhã
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <ul className={styles.cleanList}>
                        <li>1 chávena almoçadeira de leite (250ml)</li>
                        <li>
                          1 pão (50g) + 0,5 colher de sobremesa de manteiga
                          (7,5g)
                        </li>
                        <li>1 peça de fruta de tamanho médio (160g)</li>
                      </ul>
                    </TableCell>
                    <TableCell align="left">
                      <ul className={styles.cleanList}>
                        <li>1 peça de fruta de tamanho médio (160g)</li>
                        <li>3 bolachas tipo água e sal</li>
                      </ul>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={styles.headred} align="left">
                      Almoço
                    </TableCell>
                    <TableCell className={styles.headred} align="left">
                      Merenda da tarde
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <ul className={styles.cleanList}>
                        <li>
                          sopa (2 chávenas almoçadeiras de hortícolas cozinhados
                          + 1,5 de batata + 80g de grão de bico + 5g de azeite)*
                        </li>
                        <li>60g de carne limpa de gorduras e peles</li>
                        <li>8 colheres de sopa de massa cozida</li>
                        <li>
                          1 chávena almoçadeira de hortícolas cozinhados (140g)
                        </li>
                        <li>0,5 colher de sopa de azeite (5g)</li>
                        <li>1 peça de fruta de tamanho médio (160g)</li>
                      </ul>
                    </TableCell>
                    <TableCell align="left">
                      <ul className={styles.cleanList}>
                        <li>1 iogurte líquido</li>
                        <li>1 pão (50g)</li>
                        <li>1 fatia fina de queijo (20g)</li>
                      </ul>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell className={styles.headred} align="left">
                      Jantar
                    </TableCell>
                    <TableCell className={styles.headred} align="leftr">
                      Ceia
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <ul className={styles.cleanList}>
                        <li>sopa*</li>
                        <li>1 ovo</li>
                        <li>1 pão (50g)</li>
                        <li>0,5 colher de sopa de azeite (5g)</li>
                        <li>
                          2 chávenas almoçadeiras de hortícolas crus (180g)
                        </li>
                        <li>1 peça de fruta de tamanho médio (160g)</li>
                      </ul>
                    </TableCell>
                    <TableCell align="left">
                      <ul className={styles.cleanList}>
                        <li>1 chávena de infusão de ervas</li>
                        <li>3 bolachas tipo maria</li>
                      </ul>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <div className={styles.subtitle}>Opção Vegetariana</div>
            <p>
              Com a entrada em vigor da Lei n.º 11/2017 de 17 de abril, que
              estabelece a obrigatoriedade de existência de opção vegetariana
              nas ementas das cantinas e refeitórios públicos, para o
              planeamento e elaboração de ementas poderão consultar as
              orientações emanadas pela Direção-Geral da Saúde: Programa
              Nacional da Promoção Saudável. 
            </p>
            <ul>
              <li><a href={`${process.env.PUBLIC_URL}/documents/DGS_AlimentaçãoVegetarianaemIdadeEscolar.pdf`}>Alimentação Vegetariana em Idade Escolar</a></li>
              <li><a href={`${process.env.PUBLIC_URL}/documents/DGS_LinhasdeOrientaçãoparaumaAlimentaçãoVegetarianaSaudável.pdf`}>Linhas de Orientação para uma Alimentação Vegetariana Saudável</a></li>
              <li><a href={`${process.env.PUBLIC_URL}/documents/DGS_Planeamentoderefeiçõesvegetarianasparacriançasemrestauraçãocoletiva_principiosbase.pdf`}>Planeamento de Refeições Vegetarianas para crianças em restauração coletiva: Princípios Base</a></li>
            </ul>
          </td>
        </tr>

        <tr>
          <td className={styles.container}>
            <div className={styles.title}>
              Pequeno-almoço{' '}
              <img
                alt="Pequeno almoço"
                src={`${process.env.PUBLIC_URL}/icons/peq-almoco.png`}
              />
            </div>
            <p>
              O pequeno-almoço deverá idealmente incluir alimentos dos três
              seguintes grupos:
            </p>
            <ul>
              <li>
                <span>Laticínios</span>{' '}
                <img
                  alt="Laticínios"
                  src={`${process.env.PUBLIC_URL}/icons/bebida.png`}
                />
              </li>
              <li>
                <span>Cereais e derivados</span>{' '}
                <img
                  alt="Cereais e derivados"
                  src={`${process.env.PUBLIC_URL}/icons/cereais.png`}
                />
              </li>
              <li>
                <span>Fruta</span>{' '}
                <img
                  alt="Fruta"
                  src={`${process.env.PUBLIC_URL}/icons/fruto-similares.png`}
                />
              </li>
            </ul>
            <p>
              O pequeno-almoço é a refeição ideal para fornecer energia e desta
              forma começar o dia em pleno. Esta refeição também irá contribuir
              para:
            </p>
            <ul>
              <li>a ingestão diária de vários nutrientes;</li>
              <li>a atenção, concentração, motivação e memória;</li>
              <li>a velocidade e exatidão de resposta;</li>
              <li>a estimulação do humor e da boa disposição;</li>
              <li>a manutenção do peso adequado.</li>
            </ul>
            <p>Composição alimentar:</p>
            <ul>
              <li> Grupos: Laticínios + Cereais + Fruta;</li>
              <li> Diversificar componentes ao longo da semana;</li>
              <li> Variar apresentações e texturas.</li>
            </ul>
            <p>Composição nutricional:</p>
            <ul>
              <li>
                Deverá fornecer cerca de 20% das necessidades diárias de
                energia;
              </li>
              <li> Rico em hidratos de carbono complexos (amido);</li>
              <li> Rico em vitaminas e minerais;</li>
              <li> Rico em fibras alimentares;</li>
              <li> Moderado em proteínas e lípidos.</li>
            </ul>
            <p>
              Para uma seleção adequada, consultar a informação nutricional que
              consta no rótulo dos produtos alimentares.
            </p>
            <p>Envolvência:</p>
            <ul>
              <li> Entre 15 - 20 minutos;</li>
              <li> Em família – os pais devem ser o exemplo (papel modelo);</li>
              <li> Mastigar e ensalivar corretamente;</li>
              <li>
                Apresentação atrativa e em ambiente agradável, preferencialmente
                sentados.
              </li>
            </ul>

            <AppBar position="static">
              <Tabs
                className={classes.tabStyle}
                value={value1}
                onChange={handleChange1}
              >
                <Tab
                  label="Laticínios"
                  icon={
                    <img
                      alt="Laticínios"
                      src={`${process.env.PUBLIC_URL}/icons/bebida.png`}
                    />
                  }
                  {...a11yProps1(0)}
                />
                <Tab
                  label="Cereais e Derivados"
                  icon={
                    <img
                      alt="Cereais"
                      src={`${process.env.PUBLIC_URL}/icons/cereais.png`}
                    />
                  }
                  {...a11yProps1(1)}
                />
                <Tab
                  label="Outros Componentes"
                  icon={
                    <img
                      alt="Outros componentes"
                      src={`${process.env.PUBLIC_URL}/icons/outros.png`}
                    />
                  }
                  {...a11yProps1(2)}
                />
                <Tab
                  label="Fruta"
                  icon={
                    <img
                      alt="Fruta"
                      src={`${process.env.PUBLIC_URL}/icons/fruto-similares.png`}
                    />
                  }
                  {...a11yProps1(3)}
                />
              </Tabs>
            </AppBar>
            <TabPanel1
              component={Paper}
              className={styles.tabpanel}
              value={value1}
              index={0}
            >
              <ul>
                <li>
                  Leite, iogurte e outros leites fermentados, queijo,
                  requeijão...;
                </li>
                <li>
                  Preferencialmente o teor em gordura (lípidos) deverá ser meio
                  gordo;
                </li>
                <li>
                  Aconselha-se o consumo de leite simples, mas poderá ser
                  aromatizado com cacau, canela, cevada, entre outros
                  ingredientes;
                </li>
                <li>
                  No caso de indivíduos intolerantes à lactose ou com alergia às
                  proteínas lácteas, os laticínios devem ser substituídos por
                  produtos especiais (exemplo, bebidas sem lactose). Alguns
                  iogurtes e queijos de consumo corrente poderão fazer parte da
                  alimentação, no entanto, deve atender-se à tolerância
                  individual já que estes possuem teores variáveis de lactose
                  ainda que em menor quantidade.
                </li>
              </ul>
              <p>
                Estes alimentos são uma boa fonte de minerais (cálcio e
                fósforo), proteínas de alto valor biológico (alta qualidade) e
                vitaminas, especialmente retinol/vitamina A e vitamina D.
              </p>
            </TabPanel1>
            <TabPanel1 className={styles.tabpanel} value={value1} index={1}>
              <ul>
                <li>
                  Pão, tostas, cereais de pequeno-almoço pouco açucarados ou
                  bolachas/biscoitos com baixo teor de açúcares;
                </li>
                <li>
                  Dentro do grupo, deve dar-se prioridade ao pão devendo este
                  ser elaborado com farinhas menos refinadas e/ou mistura de
                  cereais, privilegiando também a variedade ao longo da semana;
                </li>
                <li>
                  As tostas também poderão ser incluídas no pequeno-almoço,
                  optando pelas que possuem menor teor em gordura (lípidos);
                </li>
                <li>
                  De entre os cereais de pequeno-almoço deve optar-se pelos que
                  contêm menor teor em açúcares e sal. Ter em atenção que alguns
                  cereais de pequeno-almoço contêm produtos potencialmente
                  alergénicos, tais como amendoim e frutos de casca rija,
                  conhecidos por “frutos secos” e gordos;
                </li>
                <li>
                  No caso do consumo de bolachas/ biscoitos, estes deverão ser
                  preferencialmente tipo bolacha Maria, Torrada ou Tostada,
                  atendendo também ao seu baixo teor em açúcares (hidratos de
                  carbono simples) e gorduras (lípidos, estes com menor teor de
                  ácidos gordos saturados e menos hidrogenados);
                </li>
              </ul>
              <p>
                Estes alimentos são fonte de hidratos de carbono complexos
                (amido), teores variáveis de fibra, vitaminas do complexo B e
                minerais (magnésio, zinco, …) e possuem um baixo conteúdo em
                gorduras (lípidos).
              </p>
            </TabPanel1>
            <TabPanel1 className={styles.tabpanel} value={value1} index={2}>
              <p>
                Outros alimentos poderão acompanhar o pequeno-almoço e merendas,
                nomeadamente os cremes vegetais para barrar, a manteiga, a
                marmelada, as compotas, o fiambre de aves preferencialmente…;
                poderão ainda ser adicionados cacau/ chocolate em pó, mel e
                açúcar (bebidas).
              </p>
              <p>Todos estes deverão ser consumidos com moderação:</p>
              <ul>
                <li>
                  Cremes vegetais para barrar, manteiga: são ricos em gorduras
                  (lípidos), vitaminas A e D, com elevado valor energético;
                </li>
                <li>
                  Fiambre: é rico em proteínas de elevada qualidade, mas
                  dependendo da sua origem poderá ter valores elevados em ácidos
                  gordos saturados (lípidos) e sal. O fiambre de aves (frango ou
                  peru) é aquele que possui melhor perfil lipídico;
                </li>
                <li>
                  Açúcar e mel: são ricos em açúcares (hidratos de carbono
                  simples), chamados fornecedores de &quot;calorias vazias&quot;
                  (sem relevância nutricional);
                </li>
                <li>
                  Cacau: é rico em gorduras e o chocolate em gorduras (lípidos)
                  e açúcares; ambos têm um elevado valor energético.
                </li>
              </ul>
            </TabPanel1>
            <TabPanel1 className={styles.tabpanel} value={value1} index={3}>
              <ul>
                <li>Fruta fresca ou sumos naturais;</li>
                <li>Dar preferência ao consumo de fruta em natureza;</li>
                <li>
                  Ocasionalmente, quando se optar por sumos, estes deverão ser
                  preferencialmente naturais. Os sumos de fruta deverão ser
                  limitados para crianças de 1 a 6 anos, nas quantidades diárias
                  de 120 a 180 ml, e para crianças/adolescentes dos 7 aos 18
                  anos, de 240 a 360 ml. Estes constituem uma alternativa ao
                  consumo de fruta em natureza, no entanto, apresentam um menor
                  teor de fibra, vitaminas e minerais.
                </li>
              </ul>
              <p>
                Os alimentos deste grupo são especialmente ricos em água,
                vitaminas hidrossolúveis (principalmente a vitamina C), minerais
                (magnésio e potássio), fibra e outras substâncias
                (antioxidantes).
              </p>
            </TabPanel1>
          </td>
        </tr>

        <tr>
          <td className={styles.container}>
            <div className={styles.title}>
              Almoço{' '}
              <img
                alt="Almoço"
                src={`${process.env.PUBLIC_URL}/icons/almoco.png`}
              />
            </div>
            <p>
              As refeições deverão ser confecionadas com alimentos em bom estado
              sanitário, de boa qualidade, com técnicas de confeção adequadas,
              devendo ser observadas as capitações de acordo com a faixa etária.
            </p>
            <p>
              Privilegiar a utilização de alimentos de produção
              local/regional/nacional respeitando a sua sazonalidade.
            </p>
            <ol>
              <li>
                A refeição completa deverá ser composta por:
                <ol>
                  <li>
                    sopa de produtos hortícolas tendo por base batata e/ou
                    leguminosas;
                  </li>
                  <li>
                    &quot;prato principal&quot; constituído por uma fonte
                    proteica de origem animal ou vegetal (carne, pescado, ovo,
                    leguminosas…) com os acompanhamentos fornecedores de
                    hidratos de carbono (arroz, massa, batata, cuscuz, mandioca,
                    leguminosas…) e acompanhamento obrigatório de produtos
                    hortícolas crus ou cozinhados adequados à ementa; Existindo
                    a opção dieta ou outras estas devem contemplar componente
                    proteica diferente;
                  </li>
                  <li>pão;</li>
                  <li>
                    sobremesa constituída por fruta da época variada e
                    pontualmente por uma sobremesa doce;
                  </li>
                  <li>água.</li>
                </ol>
              </li>
              <li>
                As ementas para o mês, deverão enumerar a lista de
                constituintes, nomeadamente, sopa, &quot;prato principal&quot;
                ou &quot;prato de dieta&quot; (explicitando o tipo de carne ou
                pescado, ou outros), e a sobremesa e conter informação relativa
                à composição nutricional (macronutrientes e valor energético)
                das refeições.
              </li>
              <li>
                A elaboração das ementas, deverá ser acompanhada
                obrigatoriamente pelas fichas técnicas dos constituintes
                indicando a composição da refeição, a matéria-prima utilizada
                (referenciar a categoria de carne utilizada, bem como o calibre
                do pescado e da fruta), respetiva capitação e composição
                nutricional, bem como a descrição do(s) método(s) de confeção.
              </li>
              <li>
                Na composição da refeição, deverão ser observadas as regras de
                uma alimentação saudável, ou seja, equilibrada, completa e
                variada. A composição dos constituintes da refeição deverá ser a
                seguinte:
              </li>
            </ol>

            <AppBar position="static">
              <Tabs
                className={classes.tabStyle}
                value={value2}
                onChange={handleChange2}
              >
                <Tab
                  label="Sopa"
                  {...a11yProps2(0)}
                  icon={
                    <img
                      alt="Sopa"
                      style={{ marginLeft: '-0.5em' }}
                      src={`${process.env.PUBLIC_URL}/icons/sopa.png`}
                    />
                  }
                />
                <Tab
                  label="Prato"
                  {...a11yProps2(1)}
                  icon={
                    <img
                      alt="Prato"
                      src={`${process.env.PUBLIC_URL}/icons/almoco.png`}
                    />
                  }
                />
                <Tab
                  label="Pão"
                  {...a11yProps2(2)}
                  icon={
                    <img
                      alt="Pão"
                      src={`${process.env.PUBLIC_URL}/icons/cereais.png`}
                    />
                  }
                />
                <Tab
                  label="Sobremesa"
                  {...a11yProps2(3)}
                  icon={
                    <img
                      alt="Sobremesa"
                      style={{ marginLeft: '-0.5em' }}
                      src={`${process.env.PUBLIC_URL}/icons/sobremesa.png`}
                    />
                  }
                />
                <Tab
                  label="Água"
                  {...a11yProps2(4)}
                  icon={
                    <img
                      alt="Água"
                      src={`${process.env.PUBLIC_URL}/icons/bebida.png`}
                    />
                  }
                />
              </Tabs>
            </AppBar>
            <TabPanel2
              component={Paper}
              className={styles.tabpanel}
              value={value2}
              index={0}
            >
              <ul>
                <li>
                  Ser confeccionada preferencialmente com batata em natureza. 
                  Conter no mínimo três variedades de produtos hortícolas, sem preponderância;
                </li>
                <li>
                  Privilegiar a utilização de hortaliças (couve galega,
                  espinafres, agrião, nabiças…) aos pedaços;
                </li>
                <li>
                  A sopa deve conter, entre 2 - 3 vezes por semana, leguminosas
                  secas;
                </li>
                <li>Ser temperada com azeite;</li>
                <li>Ser servida por utente uma quantidade mínima de 200 ml.</li>
              </ul>
            </TabPanel2>
            <TabPanel2 className={styles.tabpanel} value={value2} index={1}>
              <ul>
                <li>
                  Deverá ser privilegiado, o consumo de carne de aves (frango,
                  peru, pato, avestruz…) e coelho em detrimento de carnes de
                  outras espécies;
                </li>
                <li>
                  Ter o cuidado de rejeitar sempre as gorduras e peles visíveis;
                </li>
                <li>
                  Recomenda-se o consumo de peixe gordo, fornecedor de ácidos
                  gordos da série ómega-3 (salmão, sardinha, atum, cavala…), 1
                  vez por semana;
                </li>
                <li>
                  As refeições à base de ovos deverão utilizar preferencialmente
                  ovoprodutos; deverão surgir na ementa com uma periodicidade
                  semanal mínima de 1 vez;
                </li>
                <li>
                  Recomenda-se a utilização variada dos acompanhamentos
                  (hortícolas e fornecedores de hidratos de carbono);
                </li>
                <li>
                  Deverão estar presentes produtos hortícolas confecionados e/ou
                  crus, alternando as diversas variedades em cada refeição;
                </li>
                <li>
                  Todos os hortícolas consumidos crus deverão ser previamente
                  desinfetados;
                </li>
                <li>
                  As ementas deverão ser variadas, procurando diversificar o
                  mais possível os métodos de confeção, tais como cozidos,
                  grelhados, assados, guisados, caldeiradas,...;
                </li>
                <li>
                  Estes métodos deverão respeitar as regras da culinária
                  saudável, que pressupõe:
                </li>
                <ul className={styles.cleanList}>
                  <li>
                    - uma adição privilegiada de gordura monoinsaturada (azeite)
                    na confeção dos alimentos;
                  </li>
                  <li>
                    - evitar o sobreaquecimento de gordura polinsaturada,
                    presente nos óleos vegetais. Estes só poderão ser utilizados
                    para tempero em cru;
                  </li>
                  <li>
                    - tornar esporádico o recurso a procedimentos culinários
                    (fritura, refogados intensos…), geradores de gorduras trans,
                    bem como alimentos seus fornecedores (produtos de pastelaria
                    doce e salgada, folhados, rissóis, croquetes, empadas,
                    chocolate, molhos, maionese…);
                  </li>
                  <li>
                    - que em preparados culinários onde se incluem laticínios
                    (molho bechamel, lasanha, ...) optar sempre pelos que tem
                    teores reduzidos em gordura;
                  </li>
                  <li>
                    - confecionar os alimentos com pouco sal, realçando o
                    paladar dos mesmos com ervas aromáticas usualmente
                    utilizadas localmente, podendo também recorrer a marinadas
                    com sumo de limão, alho, cebola, ervas aromáticas e
                    especiarias a gosto. Não utilizar caldos concentrados
                    pré-preparados ou industrializados;
                  </li>
                  <li>
                    - com o propósito de maximizar sabores, preservando a
                    qualidade nutricional dos alimentos, restringindo gordura e
                    sal, o uso de panelas de pressão, recipientes para cozinhar
                    a vapor e/ou antiaderentes podem ser importantes.
                  </li>
                </ul>
                <li>
                  Os fritos poderão estar presentes num máximo 1 vez por mês, num dos alimentos presentes no prato.
                </li>
                <li>
                  Recurso a produtos de salsicharia no máximo 1 vez/mês.
                </li>
              </ul>
            </TabPanel2>
            <TabPanel2 className={styles.tabpanel} value={value2} index={2}>
              <ul>
                <li>
                  O pão servido deverá ser idealmente de mistura de vários
                  cereais (trigo, centeio, milho…) pouco refinados e variado ao
                  longo da semana.
                </li>
              </ul>
            </TabPanel2>
            <TabPanel2 className={styles.tabpanel} value={value2} index={3}>
              <ul>
                <li>
                  Deverá ser constituída por fruta em natureza, respeitando uma
                  distribuição equitativa semanal;
                </li>
                <li>
                  A fruta fornecida deverá respeitar a disponibilidade sazonal;
                </li>
                <li>
                  Na confeção da salada de frutas não deverá ser adicionado
                  açúcar;
                </li>
                <li>
                  A fruta adicionada deverá ser variada, sem privilegiar nenhuma
                  espécie, devendo considerar-se sempre a inclusão de pelo menos
                  1 fruta rica em vitamina C;
                </li>
                <li>A fruta em calda deverá ser considerada sobremesa doce;</li>
                <li>
                  As sobremesas com açúcar adicionado, deverão ser
                  disponibilizadas com a periodicidade mensal máxima de 2,
                  dando preferência àqueles cujos ingredientes utilizados são à
                  base de produtos lácteos e/ou fruta ou hortícolas e
                  eventualmente com adição de frutos desidratados e gordos
                  (exemplo: leite creme, aletria, arroz doce, tarte de maçã,
                  bolo de cenoura, bolo de noz);
                </li>
                <li>
                  Deverão ser utilizadas e/ou adaptadas receitas com baixo teor
                  em açúcar e lípidos;
                </li>
                <li>Recomenda-se que a gelatina seja de origem vegetal.</li>
              </ul>
            </TabPanel2>
            <TabPanel2 className={styles.tabpanel} value={value2} index={4}>
              <ul>
                <li>
                  Como bebida, incluída na refeição, deverá ser privilegiada a
                  água de abastecimento da rede pública e na sua
                  impossibilidade, água engarrafada.
                </li>
              </ul>
            </TabPanel2>
          </td>
        </tr>

        <tr>
          <td className={styles.container}>
            <div className={styles.title}>
              Jantar{' '}
              <img
                alt="Jantar"
                src={`${process.env.PUBLIC_URL}/icons/jantar.png`}
              />
            </div>
            <p>
              O planeamento do jantar deverá ter em linha de conta os princípios
              gerais descritos para o almoço. Para além destes, deverá ser
              também considerada a diversidade em relação aos componentes
              oferecidos no almoço.
            </p>
            <p>
              Em casa, para um correto planeamento do jantar dever-se-á então
              ter em conta o oferecido na escola, de modo a estar em perfeita
              articulação e com os pressupostos de um dia alimentar saudável.
              Concretamente, e a título de exemplo, se o almoço na escola foi
              frango estufado com esparguete e salada de alface e tomate, o
              jantar deverá ser pescado ou ovo, variando também a fonte de
              hidratos de carbono (exemplo arroz de cenoura) e de hortícolas
              (brócolos cozidos).
            </p>
            <p>
              Do mesmo modo dever-se-á variar os ingredientes a incluir na sopa,
              o tipo de fruta e o tipo pão. Também a alternância do modo de
              confeção deve ser um fator a ter em conta (ex. frango estufado ao
              almoço; carapau grelhado ao jantar). Como bebida a incluir
              dever-se-á manter a água.
            </p>
          </td>
        </tr>
        <tr>
          <td className={styles.container}>
            <div className={styles.title}>Merendas e ceia </div>
            <p className={styles.subtitle}>
              Merendas{' '}
              <img
                alt="Merenda Manhã"
                src={`${process.env.PUBLIC_URL}/icons/merenda-manha.png`}
              />{' '}
              <img
                alt="Merenda Tarde"
                src={`${process.env.PUBLIC_URL}/icons/merenda-tarde.png`}
              />
            </p>
            <p>Ao longo do dia é importante realizar pequenas merendas.</p>
            <p>
              A ingestão de alimentos nos intervalos das refeições principais
              contribui para um correto funcionamento do nosso organismo. Além
              do mais ajudam a um melhor controlo do apetite evitando comer em
              demasia ao almoço e ao jantar.
            </p>
            <p>
              Uma peça de fruta, um iogurte, um pão, ou combinações são
              alternativas que podem fazer parte destas refeições.
            </p>
            <p className={styles.subtitle}>
              Ceia{' '}
              <img
                alt="Ceia"
                src={`${process.env.PUBLIC_URL}/icons/ceia.png`}
              />
            </p>
            <p>
              Este refeição deve ser considerada, quando o intervalo entre o
              jantar e a hora de deitar ultrapassa as 3 horas.
            </p>
            <p>
              Deverá ser uma refeição simples, tal como indicado para as
              merendas.
            </p>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default RecomAlimentarTable;
