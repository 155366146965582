import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  FormGroup,
  TextField,
  Box,
  Chip,
  FormControlLabel,
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import Title from './Title';
import { useStyles } from './Overview';

import { fetchAllAllergens } from '../../../services/ingredientsService';
import { fetchTechnicalSheet } from '../../../services/technicalSheetsService';

function Allergens({
  ownAllergens,
  updateSelectedAllergens,
  selectedTechnicalSheets,
}) {
  const classes = useStyles();
  const [allAllergens, setAllAllergens] = useState();
  const [inheritedAllergens, setInheritedAllergens] = useState([]);

  useEffect(() => {
    async function loadAllergens(source) {
      try {
        const { data } = await fetchAllAllergens(source.token);
        setAllAllergens(data);
      } catch (e) {
        // console.error(e);
      }
    }

    const source = axios.CancelToken.source();
    loadAllergens(source);

    return () => {
      if (source) source.cancel();
    };
  }, []);

  useEffect(() => {
    async function updateLiveAllergens(source) {
      try {
        const promises = Object.keys(selectedTechnicalSheets).map(key => {
          const { id } = selectedTechnicalSheets[key];
          return fetchTechnicalSheet(id, source.token);
        });

        const results = await Promise.all(promises);
        const newAllergens = [];
        results.forEach(({ data }) => {
          const { allergens: thisAllergens } = data;
          newAllergens.push(...thisAllergens);
        });

        const uniqueNewAllergens = [];
        const auxUnique = [];

        newAllergens.forEach(value => {
          const { id } = value;
          if (!auxUnique[id]) {
            uniqueNewAllergens.push(value);
            auxUnique[id] = 1;
          }
        });

        setInheritedAllergens(uniqueNewAllergens);
      } catch (e) {
        // console.error(e);
      }
    }

    const source = axios.CancelToken.source();
    updateLiveAllergens(source);

    return () => {
      if (source) source.cancel();
    };
  }, [selectedTechnicalSheets]);

  const possibleNewAllergens = useMemo(
    () =>
      allAllergens?.filter(
        ({ id }) => !inheritedAllergens?.find(({ id: oID }) => id === oID),
      ),
    [allAllergens, inheritedAllergens],
  );

  return (
    <>
      {allAllergens ? (
        <FormGroup classes={{ root: classes.form }}>
          <Title text="Alergénios" />
          {inheritedAllergens.length !== 0 && (
            <Box className={classes.chips}>
              {inheritedAllergens.map(({ name, id }) => (
                <Chip label={name} key={id} />
              ))}
            </Box>
          )}
          <FormControlLabel
            control={
              <Autocomplete
                classes={{ root: classes.input }}
                style={{ marginLeft: '24px' }}
                multiple
                value={ownAllergens}
                options={possibleNewAllergens}
                onChange={(_, value) =>
                  updateSelectedAllergens(value, [
                    ...inheritedAllergens,
                    ...value,
                  ])
                }
                getOptionLabel={({ name }) => name}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    id="ingredientSearchInput"
                    color="secondary"
                    fullWidth
                  />
                )}
              />
            }
          />
        </FormGroup>
      ) : (
        <Alert>A obter os alergénios...</Alert>
      )}
    </>
  );
}

Allergens.defaultProps = {
  selectedTechnicalSheets: [],
  ownAllergens: [],
};

Allergens.propTypes = {
  selectedTechnicalSheets: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  ),
  ownAllergens: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  ),
  updateSelectedAllergens: PropTypes.func.isRequired,
};

export default Allergens;
