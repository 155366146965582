import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Spinner, Input, ListGroup, ListGroupItem } from 'reactstrap';

import styles from './mealComponent.module.css';
import { fetchTechnicalSheet } from '../../services/technicalSheetsService';

const MealSearch = props => {
  const { selectedMeal } = props;
  const [query, setQuery] = useState('');
  const [queryOldLength, setQueryOldLength] = useState(0);
  const [items, setItems] = useState(new Array(0));
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    async function fetchTechnicalSheets(source) {
      if (query.length < 1) {
        setQueryOldLength(query.length);
        setItems(new Array(0));
        setIsLoading(false);
        return;
      }
      if (query.length === 1 && query.length > queryOldLength) {
        setQueryOldLength(query.length);
        const url = `/api/technicalSheets/query?${`query=${query}`}`;
        try {
          setIsLoading(true);
          const res = await axios.get(url, {
            cancelToken: source.token,
          });
          setItems(
            res.data.sort((a, b) => {
              if (a.name > b.name) return 1;
              if (b.name > a.name) return -1;
              return 0;
            }),
          );
          setIsLoading(false);
        } catch (error) {
          if (axios.isCancel(error)) {
            // request cancelled
          } else {
            throw error;
          }
        }
      }
      setQueryOldLength(query.length);
    }

    const source = axios.CancelToken.source();
    fetchTechnicalSheets(source);
  }, [query, queryOldLength]);

  const fetchTechSheet = async id => {
    const fetchedTechSheet = await fetchTechnicalSheet(id);
    const repComida = [...selectedMeal.technicalSheets];
    repComida.push(fetchedTechSheet.data);
    props.setSelectedMeal({ ...selectedMeal, technicalSheets: repComida });
  };

  const suggestionSelected = id => {
    props.setSelectedMeal({ ...selectedMeal, isLoadingTechSheets: true });
    setQueryOldLength(0);
    setQuery('');
    const index = items.findIndex(item => item.id === id);
    setSelected(items[index].id);
    fetchTechSheet(items[index].id);
    setItems(new Array(0));
    props.setSelectedMeal({ ...selectedMeal, isLoadingTechSheets: true });
  };

  const RenderSuggestions = () => {
    if (isLoading) {
      return (
        <ListGroup className={styles.resultados}>
          <Spinner className={styles.center} color="success" />
        </ListGroup>
      );
    }
    if (items.length === 0) {
      return <ListGroup className={styles.fichasTechRow} />;
    }
    return (
      <div className={styles.resultados}>
        Resultados
        <ListGroup>
          {items
            .filter(item => {
              const regex = new RegExp(query, 'i');
              return regex.test(item.name);
            })
            .filter(
              item =>
                selectedMeal.technicalSheets.filter(
                  technicalSheet => technicalSheet.id !== item.id,
                ).length === selectedMeal.technicalSheets.length,
            )
            .map(item => (
              <ListGroupItem
                key={item.id}
                tag="button"
                action
                onClick={() => suggestionSelected(item.id)}
                onKeyDown={() => suggestionSelected(item.id)}
              >
                {item.name}
              </ListGroupItem>
            ))}
        </ListGroup>
      </div>
    );
  };
  return (
    <>
      <input type="hidden" name="id" value={selected} />
      <Input
        className={styles.input}
        onChange={e => {
          setQuery(e.target.value);
          setSelected(0);
        }}
        type="text"
        placeholder="Pesquisar fichas técnicas"
        value={query}
        autoComplete="off"
        name="name"
      />
      {RenderSuggestions()}
    </>
  );
};
MealSearch.propTypes = {
  // eslint-disable-next-line react/require-default-props
  selectedMeal: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    date: PropTypes.string,
    technicalSheets: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        component_type: PropTypes.string,
        num_people_served: PropTypes.number,
      }),
    ),
    total_nutritional_values: PropTypes.shape({}),
    type: PropTypes.string,
    trigger: PropTypes.bool,
    isLoadingTechSheets: PropTypes.bool,
  }),
  setSelectedMeal: PropTypes.func.isRequired,
};
export default MealSearch;
