import React from 'react';
import styles from './RecNutricionais.module.css';

const RecNutricionais = () => (
  <div>
    <div className={styles.title}>
      Distribuição do Valor Energético Total e Macronutrientes por Refeições
    </div>
    <div className={styles.box}>
      <div className={styles.textmargin}>
        De acordo com o recomendado para a prática de uma alimentação saudável,
        o valor energético total (V.E.T.) deverá ser repartido ao longo do dia.
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.subtitle}>Distribuição de macronutrientes</div>
    </div>
    <div className={styles.box}>
      <div className={styles.undertitle}>Pequeno almoço</div>
    </div>
    <div className={styles.box}>
      <div className={styles.text}>
        A refeição pequeno-almoço deverá contribuir com 20% do Valor Energético
        Total diário (V.E.T.). A distribuição do valor energético diário da
        refeição pequeno-almoço por macronutrientes deve respeitar as
        recomendações preconizadas pelo Institute of Medicine/Food and Nutrition
        Board (IOM/FNB) dos Estados Unidos da América (EUA). Esta distribuição é
        variável de acordo com o grupo etário.
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.undertitle}>Merendas da manhã e da tarde</div>
    </div>
    <div className={styles.box}>
      <div className={styles.text}>
        As merendas deverão contribuir com 10% do V.E.T.. Todavia, dependendo
        dos horários das principais refeições poderá ser conveniente a
        realização de duas merendas no período da tarde;neste caso a
        contribuição para o valor energético total das duas merendas não deverá
        ultrapassar os 15%.
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.text}>
        Dado que estas refeições podem incluir apenas um ou mais géneros
        alimentícios, constituindo refeições intercalares de menor valor
        energético, a sua distribuição percentual por macronutrientes pode ser
        variável.
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.undertitle}>Almoço</div>
    </div>

    <div className={styles.box}>
      <div className={styles.text}>
        A refeição almoço deverá contribuir com 30% do V.E.T. e a sua
        distribuição por macronutrientes segue as recomendações do Institute of
        Medicine/Food and Nutrition Board (IOM/FNB) indicadas anteriormente.
        Poderá consultar na tabela abaixo esta distribuição de acordo com a
        idade.
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.undertitle}>Jantar</div>
    </div>
    <div className={styles.box}>
      <div className={styles.text}>
        A refeição jantar deverá contribuir com 20% do V.E.T. e a sua
        distribuição por macronutrientes segue as recomendações do Institute of
        Medicine/Food and Nutrition Board (IOM/FNB) indicadas anteriormente.
      </div>
    </div>
    <div className={styles.box}>
      <div className={styles.undertitle}>Ceia</div>
    </div>
    <div className={styles.box}>
      <div className={styles.text}>
        A ceia deverá contribuir com 5% do V.E.T.
      </div>
    </div>
    <img
      className={styles.tabela}
      src={`${process.env.PUBLIC_URL}/tabela.png`}
      alt=""
    />

    <img
      className={styles.tabela1}
      src={`${process.env.PUBLIC_URL}/tabela1.png`}
      alt=""
    />
  </div>
);

// Falta tabela com valores atualizados.
export default RecNutricionais;
