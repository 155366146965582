import React, { useState } from 'react';
import { Input, Form, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';

import styles from './Search.module.css';

const Search = ({ onSearch, searchTarget }) => {
  const [search, setSearch] = useState('');

  const handleChange = event => {
    setSearch(event.target.value);
  };

  const handleSearch = event => {
    event.preventDefault();
    onSearch(search);
  };

  const handleClear = () => {
    setSearch('');
    onSearch('');
  };

  return (
    <Form onSubmit={handleSearch}>
      <FormGroup>
        <div className={styles.inputWrapper}>
          <Input
            type="text"
            name="search"
            value={search}
            placeholder={`Pesquise por ${searchTarget}...`}
            onChange={handleChange}
            className={styles.searchBox}
          />
          <button
            type="button"
            className={styles.clearButton}
            onClick={handleClear}
          >
            x
          </button>
        </div>
      </FormGroup>
    </Form>
  );
};

Search.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchTarget: PropTypes.string.isRequired,
};

export default Search;
