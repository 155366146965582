import React from 'react';

import styles from './Footer.module.css';

export default function () {
  return (
    <div className={styles.footer}>
      <img
        src={`${process.env.PUBLIC_URL}/logos/fcnaup.png`}
        alt="Faculdade de Ciências da Nutrição e Alimentação da Universidade do Porto"
        height="70vh"
      />
      <img
        src={`${process.env.PUBLIC_URL}/logos/feup.png`}
        alt="Faculdade de Engenharia da Universidade do Porto"
        height="55vh"
      />
      <img
        src={`${process.env.PUBLIC_URL}/logos/barra.png`}
        alt="República Portuguesa; SNS; DGS; PNPAS"
        height="70vh"
      />
      <img
        src={`${process.env.PUBLIC_URL}/logos/dge.png`}
        alt="Direção Geral da Educação"
        height="70vh"
      />
    </div>
  );
}
