import { combineReducers } from 'redux';
import reportReducer from './reportsReducer';
import notificationReducer from './notificationReducer';
import sidebarReducer from './sidebarReducer';
import authReducer from './authReducer';

// Multiple reducers can exist for different parts of the app
export default combineReducers({
  report: reportReducer,
  notification: notificationReducer,
  sidebar: sidebarReducer,
  auth: authReducer,
});
