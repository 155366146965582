import React from 'react';
import PropTypes from 'prop-types';

import { Button, CircularProgress } from '@material-ui/core';

import classNames from 'classnames';

import styles from './Button.module.css';

function MyButton({
  text,
  isLoading,
  className,
  onClick,
  disabled,
  style,
  type,
  color,
}) {
  return (
    <Button
      className={classNames(className, styles.button)}
      style={style}
      onClick={onClick}
      disabled={disabled}
      type={type}
      variant="contained"
      color={color}
    >
      {!isLoading && <span className={styles.leftPad} />}
      <span>{text}</span>
      {isLoading && (
        <CircularProgress
          color={color}
          className={styles.spinner}
          size={18}
          disableShrink
        />
      )}
      {!isLoading && <span className={styles.rightPad} />}
    </Button>
  );
}

MyButton.propTypes = {
  text: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  type: PropTypes.string,
  color: PropTypes.string,
};

MyButton.defaultProps = {
  isLoading: false,
  disabled: false,
  className: '',
  style: {},
  onClick: () => {},
  text: '',
  type: 'button',
  color: 'secondary',
};

export default MyButton;
