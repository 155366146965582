import propTypes from 'prop-types';

export const organizationType = propTypes.shape({
  id: propTypes.number.isRequired,
  name: propTypes.string.isRequired,
});

export const technicalSheetType = propTypes.shape({
  id: propTypes.number.isRequired,
  name: propTypes.string.isRequired,
  component_type: propTypes.string.isRequired,
  total_nutritional_values: propTypes.objectOf(
    propTypes.shape({
      value: propTypes.number.isRequired,
      unit: propTypes.string.isRequired,
      is_essential: propTypes.bool.isRequired,
    }),
  ),
});

export const mealRecommendationType = propTypes.shape({
  id: propTypes.number.isRequired,
  name: propTypes.string.isRequired,
  unit: propTypes.string.isRequired,
  edible: propTypes.string.isRequired,
  is_essential: propTypes.bool.isRequired,
  minValue: propTypes.number.isRequired,
  maxValue: propTypes.number.isRequired,
});

export const mealComponentsPropCheck = propTypes.arrayOf(
  propTypes.exact({
    name: propTypes.string.isRequired,
    technicalSheets: propTypes.arrayOf(technicalSheetType).isRequired,
  }),
);

export const nutritionalValuesType = propTypes.shape({
  'Energia [kcal]': propTypes.number.isRequired,
  'Hidratos de carbono': propTypes.number.isRequired,
  Lípidos: propTypes.number.isRequired,
  Proteína: propTypes.number.isRequired,
});
