import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Spinner, Alert, Row, Col, Container } from 'reactstrap';

import CalendarMover from '../../components/Meals/ListMealPlans/CalendarMover';
import CurrentCalendarRange from '../../components/Meals/ListMealPlans/CurrentCalendarRange';
import Layout from '../../components/layout/Layout';
import MonthView from '../../components/Meals/ListMealPlans/MonthView';
import WeekView from '../../components/Meals/ListMealPlans/WeekView';
import DailyView from '../../components/Meals/ListMealPlans/DailyView';

import { fetchAllMeals } from '../../services/mealsService';
import Page from '../../components/common/Page';

const ListMeals = ({ match, history }) => {
  const { day, month, year, interval } = match.params;
  const [params, setParams] = useState({ day, month, year, interval });
  const [meals, setMeals] = useState([]);
  const [areMealsLoading, setMealsLoading] = useState(false);
  const [isError, setError] = useState(false);

  useEffect(() => {
    const fetchMeals = async () => {
      setMealsLoading(true);
      try {
        const { data } = await fetchAllMeals(params);
        setMeals(data);
      } catch (e) {
        setError(true);
      }
      setMealsLoading(false);
    };

    fetchMeals();
  }, [params]);

  const setInterval = newInterval => {
    history.push(`/ementas/${newInterval}/${day}/${month}/${year}`);
    setParams({ ...params, interval: newInterval });
  };

  const setDate = (newDate, config = true) => {
    const newDay = newDate.date();
    const newMonth = newDate.month() + 1;
    const newYear = newDate.year();

    history.push(`/ementas/${interval}/${newDay}/${newMonth}/${newYear}`);

    if (config)
      setParams({ ...params, day: newDay, month: newMonth, year: newYear });
  };

  const moveCalendarLeft = () => {
    const date = moment(
      new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10)),
    );

    switch (interval) {
      case 'mes':
        date.subtract(1, 'M');
        break;
      case 'semana':
        date.subtract(1, 'w');
        break;
      case 'dia':
        date.subtract(1, 'd');
        break;
      default:
        break;
    }

    setDate(date);
  };

  const moveCalendarRight = () => {
    const date = moment(
      new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10)),
    );

    switch (interval) {
      case 'mes':
        date.add(1, 'M');
        break;
      case 'semana':
        date.add(1, 'w');
        break;
      case 'dia':
        date.add(1, 'd');
        break;
      default:
        break;
    }

    setDate(date);
  };

  const options = [
    {
      value: 'dia',
      name: 'Dia',
    },
    {
      value: 'semana',
      name: 'Semana',
    },
    {
      value: 'mes',
      name: 'Mês',
    },
  ];

  if (isError)
    return (
      <Page title="Ementas">
        <Layout>
          <Alert color="danger">
            Ocorreu um erro ao carregar as suas ementas.{' '}
            <Link to="/ementas" className="alert-link">
              Tente novamente!
            </Link>
          </Alert>
        </Layout>
      </Page>
    );

  return (
    <Page title="Ementas">
      <Layout>
        {areMealsLoading ? (
          <div className="mt-5 d-flex justify-content-center">
            <Spinner color="success" size="md" />
          </div>
        ) : (
          <div>
            <Row
              style={{
                backgroundColor: '#4c6d4f',
                padding: '1rem 0',
                margin: 0,
              }}
            >
              <Col className="d-flex justify-content-center align-items-center">
                <CalendarMover
                  moveCalendar={moveCalendarLeft}
                  interval={interval}
                  moveTo="left"
                />
              </Col>
              <Col className="d-flex justify-content-center align-items-center">
                <CurrentCalendarRange
                  date={moment(new Date(year, month - 1, day))}
                  interval={interval}
                />
              </Col>
              <Col className="d-flex justify-content-center align-items-center">
                <CalendarMover
                  moveCalendar={moveCalendarRight}
                  interval={interval}
                  moveTo="right"
                />
              </Col>
            </Row>
            <Container style={{ paddingBottom: '15px' }}>
              {interval === 'mes' && (
                <MonthView
                  date={new Date(year, month - 1, day)}
                  setDate={setDate}
                  meals={meals}
                  options={options}
                  interval={interval}
                  setInterval={setInterval}
                />
              )}
              {interval === 'semana' && (
                <WeekView
                  date={new Date(year, month - 1, day)}
                  setDate={setDate}
                  meals={meals}
                  options={options}
                  interval={interval}
                  setInterval={setInterval}
                />
              )}
              {interval === 'dia' && (
                <DailyView
                  date={new Date(year, month - 1, day)}
                  setDate={setDate}
                  meals={meals}
                  options={options}
                  interval={interval}
                  setInterval={setInterval}
                />
              )}
            </Container>
          </div>
        )}
      </Layout>
    </Page>
  );
};

ListMeals.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ListMeals;
