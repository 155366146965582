import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

import styles from './ListItem.module.css';

const ListItem = ({
  item,
  activeItem,
  toggleItem,
  printItemTitle,
  component: Component,
}) => (
  <li className={styles.listItem}>
    <div className={styles.listCollapse}>
      <div className={styles.listItemClosed}>
        <p className={styles.itemName}>{printItemTitle(item)}</p>
        <button
          className={styles.buttonIconStyle}
          onClick={() => toggleItem(item.id)}
          type="button"
        >
          {item.id === activeItem ? <FaAngleUp /> : <FaAngleDown />}
        </button>
      </div>
      <div className={styles.listItemExpanded}>
        <Collapse isOpen={activeItem === item.id}>
          <Component item={item} />
        </Collapse>
      </div>
    </div>
  </li>
);

ListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  activeItem: PropTypes.number.isRequired,
  toggleItem: PropTypes.func.isRequired,
  component: PropTypes.func.isRequired,
  printItemTitle: PropTypes.func.isRequired,
};

export default ListItem;
