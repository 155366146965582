import { Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(
  createStyles({
    question: {
      fontWeight: 'bold',
    },
    answer: {
      '&~&': {
        // sibling 'answer' elements
        marginTop: '1em',
      },
    },
  }),
);

/**
 *
 * @param {Object} props
 * @param {Number} props.id The question/answer ID
 * @param {String} props.question The question
 * @param {String} props.answer The answer
 * @param {Function} props.expandHandler A function to be called when the panel
 *  expanded state changes
 * @param {String} props.expandedPanel A string (hook) indicating the current
 *  expanded panel
 */
const FaqItem = props => {
  // extract props
  const { id, question, answer, expandHandler, expandedPanel } = props;
  // get the styles object
  const styles = useStyles();
  return (
    <ExpansionPanel
      key={id}
      expanded={expandedPanel === `panel${id}`}
      onChange={expandHandler(`panel${id}`)}
    >
      <ExpansionPanelSummary
        aria-controls={`panel${id}bh-content`}
        id={`panel${id}bh-header`}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography
          classes={{ body1: styles.question }}
          component="h2"
          variant="body1"
        >
          {question}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div>
          {answer.split('\n').map(p => (
            <Typography classes={{ root: styles.answer }}>{p}</Typography>
          ))}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

FaqItem.propTypes = {
  id: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  expandHandler: PropTypes.func.isRequired,
  expandedPanel: PropTypes.string.isRequired,
};

export default FaqItem;
