import React from 'react';
import PropTypes from 'prop-types';
import { Toolbar, Typography, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router';
import RouteLink from '../../common/RouteLink/RouteLink';

import { useStyles } from '../styles';

function Table({ title, editable }) {
  const classes = useStyles();
  const { location } = useHistory();

  const id = location.pathname.split('/')?.slice(-1)?.[0];

  const handleEditButton = () => {};

  return (
    <Toolbar
      classes={{
        root: classes.header,
      }}
    >
      <Typography
        color="secondary"
        classes={{
          root: classes.headerTitle,
        }}
      >
        {title}
      </Typography>
      {editable && (
        <RouteLink to={`${id}/editar`}>
          <IconButton
            aria-label="edit"
            onClick={handleEditButton}
            classes={{
              root: classes.icon,
            }}
          >
            <EditIcon />
          </IconButton>
        </RouteLink>
      )}
    </Toolbar>
  );
}

Table.propTypes = {
  title: PropTypes.string.isRequired,
  editable: PropTypes.bool,
};

Table.defaultProps = {
  editable: true,
};

export default Table;
