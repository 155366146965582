import axios from 'axios';

const baseUrl = '/api/reports';

const fetchAllReports = (page, type, organizationID = '') => {
  return axios.get(`${baseUrl}/${organizationID}`, {
    params: {
      page,
      type,
    },
  });
};

const fetchReport = reportID => {
  return axios.get(`${baseUrl}/view/${reportID}`);
};

const fetchAllTypes = type => {
  return axios.get(`${baseUrl}/types`, {
    params: {
      type,
    },
  });
};

const createReport = (reportType, organizationID) => {

  return axios.post(`${baseUrl}/${organizationID}/${reportType}`);
};

const fetchSectionQuestions = (reportID, sectionID) => {
  return axios.get(`${baseUrl}/view/${reportID}/${sectionID}`);
};

const updateAnswer = (answerID, value) => {
  return axios.post(`${baseUrl}/answers/${answerID}`, {
    answer: String(value),
  });
};

const duplicateReport = reportAnswerID => {
  return axios.post(`${baseUrl}/duplicate/${reportAnswerID}`);
};

const exportReport = reportID =>
  axios.get(`${baseUrl}/download/${reportID}`, {
    responseType: 'blob',
  });

const exportManualReport = reportID =>
  axios.get(`${baseUrl}/download/manual/${reportID}`, {
    responseType: 'blob',
  });

export {
  fetchAllReports,
  fetchReport,
  fetchAllTypes,
  createReport,
  fetchSectionQuestions,
  updateAnswer,
  duplicateReport,
  exportManualReport,
  exportReport,
};
