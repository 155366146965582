import React from 'react';
import { FormGroup, TextField, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import Title from './Title';
import { useStyles } from './Overview';

import labels from './preparation.json';

const Preparation = ({ updatePreparationField, preparation }) => {
  const classes = useStyles();

  return (
    <FormGroup>
      <Title text="Preparação" />
      <FormGroup>
        {labels.map(({ label, multiline, key }) => (
          <FormControlLabel
            classes={{ label: classes.label }}
            key={key}
            label={label}
            labelPlacement="top"
            control={
              <TextField
                multiline={multiline}
                fullWidth
                color="secondary"
                name={key}
                onChange={updatePreparationField}
                value={preparation[key] ?? ''}
              />
            }
          />
        ))}
      </FormGroup>
    </FormGroup>
  );
};

Preparation.propTypes = {
  updatePreparationField: PropTypes.func.isRequired,
  preparation: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Preparation;
