import React from 'react';
import PropTypes from 'prop-types';

import { FaSearch } from 'react-icons/fa';
import { Input } from 'reactstrap';
import classNames from 'classnames';

import styles from './SearchBar.module.css';

function SearchBar({ className, placeholder, onSubmit }) {
  return (
    <div className={classNames(className, styles.formGroup)}>
      <FaSearch className={styles.icon} />
      <Input
        type="text"
        className={styles.input}
        placeholder={placeholder}
        onSubmit={onSubmit}
      />
    </div>
  );
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
};

SearchBar.defaultProps = {
  placeholder: 'Pesquisar',
  className: '',
  onSubmit: () => {},
};

export default SearchBar;
