import PropTypes from 'prop-types';

export const dataType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    componentType: PropTypes.string.isRequired,
  }),
);

export default PropTypes.shape({
  page: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  search: PropTypes.string.isRequired,
  data: dataType.isRequired,
  availableFilters: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  filterList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
});
