import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import Moment from 'moment';

import Calendar from './Calendar';
import DailyMealsTable from './DailyMealsTable';
import WeeksInMonth from './WeeksInMonth';
import IntervalSelect from './IntervalSelect';

const MonthView = ({
  date,
  setDate,
  meals,
  interval,
  setInterval,
  options,
}) => (
  <>
    <Row className="mt-3">
      <Col lg="4" className="d-flex align-items-center flex-column">
        <IntervalSelect
          interval={interval}
          setInterval={setInterval}
          options={options}
        />
        <Calendar date={date} setDate={setDate} />
      </Col>
      <Col lg="8" className="d-flex justify-content-center align-items-center">
        <WeeksInMonth
          year={date.getFullYear()}
          month={date.getMonth() + 1}
          date={date}
          setDate={setDate}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <DailyMealsTable
          meals={meals.filter(
            meal => meal.date === Moment(date).format('YYYY-MM-DD'),
          )}
          date={date}
        />
      </Col>
    </Row>
  </>
);

MonthView.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  setDate: PropTypes.func.isRequired,
  meals: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  interval: PropTypes.string.isRequired,
  setInterval: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
};

export default MonthView;
