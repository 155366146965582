import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './styles';
import AgeRangePicker from './Pickers/AgeRangePicker';
import DatePicker from './Pickers/DatePicker';
import OrganizationPicker from './Pickers/OrganizationPicker';
import MealTypePicker from './Pickers/MealTypePicker';

const MealPlanPicker = () => {
  const styles = useStyles();
  return (
    <>
      <Grid container className={styles.pickers} spacing={1}>
        <Grid item xs={6}>
          <OrganizationPicker />
        </Grid>
        <Grid item xs={3}>
          <DatePicker />
        </Grid>
        <Grid item xs={3}>
          <AgeRangePicker />
        </Grid>
      </Grid>
      <MealTypePicker />
    </>
  );
};

export default MealPlanPicker;
