import React, { useState } from 'react';
import propTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

const OrganizationListItem = props => {
  const {
    onEditClick,
    onDeleteClick,
    organizationId,
    organizationName,
  } = props;

  const [isEditMode, setEditMode] = useState(false);
  const [myOrganizationName, setMyOrganizationName] = useState(
    organizationName,
  );

  const editClick = () => {
    setEditMode(false);
    onEditClick(organizationId, myOrganizationName);
  };

  return (
    <ListItem dense divider>
      <ListItemText primary={isEditMode ? undefined : myOrganizationName}>
        {isEditMode && (
          <TextField
            id="text_field_org_name"
            fullWidth
            placeholder="Nome da organização"
            value={myOrganizationName}
            onChange={event => setMyOrganizationName(event.target.value)}
            onKeyPress={event => event.key === 'Enter' && editClick()}
          />
        )}
      </ListItemText>
      <ListItemSecondaryAction>
        {!isEditMode && (
          <>
            <IconButton
              edge="end"
              aria-label="comments"
              size="small"
              onClick={() => setEditMode(true)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="comments"
              size="small"
              onClick={() => onDeleteClick(organizationId, myOrganizationName)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}
        {isEditMode && (
          <IconButton
            edge="end"
            aria-label="comments"
            size="small"
            onClick={() => editClick()}
          >
            <SaveIcon />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

OrganizationListItem.propTypes = {
  organizationId: propTypes.number.isRequired,
  organizationName: propTypes.string.isRequired,
  onEditClick: propTypes.func.isRequired,
  onDeleteClick: propTypes.func.isRequired,
};

export default OrganizationListItem;
