/* eslint-disable no-shadow */

import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';

import PureSection from './pure/PureSection';
import ErrorBoundary from '../common/ErrorBoundary';
import {
  fetchSectionQuestions,
  fetchReport,
  updateAnswer,
} from '../../services/reportsService';
import {
  setReportName,
  setReportDate,
  setSections,
} from '../../actions/reportActions';

const ReportSection = ({
  reportName,
  reportDate,
  sections,
  setReportName,
  setReportDate,
  setSections,
}) => {
  const { id, sectionID } = useParams();
  const [questions, setQuestions] = useState([]);
  const [sectionName, setSectionName] = useState('');
  const [hasError, setError] = useState(null);
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [isSectionLoading, setIsSectionLoading] = useState(false);

  useEffect(() => {
    const doFetch = async () => {
      try {
        setIsSectionLoading(true);
        const { data } = await fetchSectionQuestions(id, sectionID);
        
        const { questions: sectionQuestions, name } = data;
        setQuestions(sectionQuestions);
        setSectionName(name);
      } catch (error) {
        setError(error.response);
      }
      setIsSectionLoading(false);
    };

    doFetch();
  }, [id, sectionID]);

  useEffect(() => {
    const doFetch = async () => {
      try {
        setIsReportLoading(true);
        const { data } = await fetchReport(id);
        const { name, lastUpdate, answers } = data;
        
        setReportName(name);
        setReportDate(lastUpdate);
        setSections(answers.length);
      } catch (error) {
        setError(error.response);
      }
      setIsReportLoading(false);
    };

    if (!reportName) doFetch();
  }, [id, reportName, setReportName, setReportDate, setSections]);

  const toggleAnswer = useCallback((answerID, value) => {
    const changeAnswer = async () => {
      try {
        await updateAnswer(answerID, value);
      } catch (error) {
        setError(error.response);
      }
    };

    changeAnswer();
  }, []);

  useEffect(() => {
    if (hasError) throw hasError;
  }, [hasError]);
  
  return (
    <>
      {!reportName || isReportLoading ? (
        <Alert color="success">A obter secção da lista de verificação...</Alert>
      ) : (
        <PureSection
          sectionID={sectionID}
          id={id}
          toggleAnswer={toggleAnswer}
          reportDate={reportDate}
          questions={questions}
          sections={sections}
          section={sectionName}
          reportName={reportName}
          isSectionLoading={isSectionLoading}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    sections: state.report.sections,
    reportName: state.report.reportName,
    reportDate: state.report.reportDate,
  };
};

const ErrorBoundaryWrapper = props => (
  <ErrorBoundary>
    <ReportSection
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  </ErrorBoundary>
);

ReportSection.propTypes = {
  reportName: PropTypes.string.isRequired,
  reportDate: PropTypes.string.isRequired,
  sections: PropTypes.number.isRequired,
  setReportName: PropTypes.func.isRequired,
  setReportDate: PropTypes.func.isRequired,
  setSections: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  setReportName,
  setReportDate,
  setSections,
})(ErrorBoundaryWrapper);
