import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import { Row, Col, Container, Alert } from 'reactstrap';

import NutritionalChart from '../TechnicalSheets/NutritionalChart';
import Overview from '../TechnicalSheets/Overview';

import { setNotification as setNotificationAction } from '../../actions/notificationActions';

import ErrorBoundary from '../common/ErrorBoundary';
import OptionList from '../common/OptionList';

import styles from '../TechnicalSheets/TechnicalSheet.module.css';

import {
  fetchIngredient,
  deleteIngredient,
} from '../../services/ingredientsService';

import DeleteModal from '../common/DeleteModal';

import Page from '../common/Page';

const IngredientsView = ({ id, history, setNotification, isAdmin }) => {
  const [ingredient, setIngredient] = useState(null);
  const [hasError, setError] = useState(null);
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const links = [
    {
      name: 'Editar',
      to: `/admin/ingredientes/${id}/editar`,
    },
  ];

  const buttons = [
    {
      name: 'Apagar',
      onClick: () => setIsDeleteModalOpen(!isDeleteModalOpen),
    },
  ];

  const doDelete = async () => {
    try {
      setDeleteLoading(true);
      const { data } = await deleteIngredient(id);
      setNotification({
        message: `Ingrediente "${data.name}" apagado com sucesso!`,
        type: 'success',
      });
      history.push('/admin/ingredientes');
    } catch (e) {
      setNotification({
        message: 'Ocorreu um erro ao tentar apagar este ingrediente.',
        type: 'error',
      });
    }
    setDeleteLoading(false);
  };

  useEffect(() => {
    if (hasError) throw hasError;
  }, [hasError]);

  useEffect(() => {
    const doFetch = async () => {
      try {
        const { data } = await fetchIngredient(id);
        setIngredient(data);
      } catch (error) {
        setError(error.response);
      }
    };
    doFetch();
  }, [id]);

  return (
    <div className={classNames(styles.main, 'technicalSheet')}>
      <Container fluid className={styles.titleBg}>
        <Row>
          <Col xs="12" md="6">
            <h1 className={styles.title}>Ingrediente</h1>
          </Col>
          <Col xs="12" md="6" style={{ margin: 'auto 0' }}>
            {isAdmin && <OptionList buttons={buttons} links={links} />}
          </Col>
        </Row>
      </Container>
      {ingredient === null ? (
        <Alert color="success">A obter ingrediente...</Alert>
      ) : (
        <Page title={`${ingredient.name} - Ingrediente`}>
          <div>
            <Container>
              <Row className={styles.row}>
                <Col className={styles.col} style={{ padding: '0' }} xs="12">
                  <h1 className={styles.name}>{ingredient.name}</h1>
                </Col>
              </Row>
              <Row>
                <Col className={styles.col} sm="12">
                  <Overview
                    allergens={ingredient.allergens}
                    type={ingredient.type}
                    createdAt={ingredient.created_at}
                    lastUpdated={ingredient.updated_at}
                    ediblePart={ingredient.ediblePart}
                    nutritionalInfo={ingredient.total_nutritional_values}
                    nutritionalCriteria={ingredient.nutritionalCriteria}
                  />
                </Col>
                <Col className={styles.col} sm="12">
                  <NutritionalChart
                    nutritionalInfo={ingredient.total_nutritional_values}
                  />
                </Col>
              </Row>
              <div>
                <DeleteModal
                  toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                  isOpen={isDeleteModalOpen}
                  name={ingredient.name}
                  onDelete={doDelete}
                  isLoading={isDeleteLoading}
                  type="este ingrediente"
                  header="Ingrediente"
                />
              </div>
            </Container>
          </div>
        </Page>
      )}
    </div>
  );
};

const ErrorBoundaryWrapper = props => (
  <ErrorBoundary>
    <IngredientsView
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  </ErrorBoundary>
);

IngredientsView.propTypes = {
  id: PropTypes.string.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  setNotification: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  isAdmin: auth.userType === 'admin' && auth.loggedIn,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { setNotification: setNotificationAction }),
)(ErrorBoundaryWrapper);
