import React from 'react';
import PropTypes from 'prop-types';

const ConditionalWrap = ({ condition, wrap, children }) =>
  condition ? wrap(children) : <>{children}</>;

ConditionalWrap.propTypes = {
  condition: PropTypes.bool.isRequired,
  wrap: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ConditionalWrap;
