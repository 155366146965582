import React from 'react';
import ListReport from '../components/Reports/ListReport';
import Page from '../components/common/Page';

const ListReportsHigioSanidadePage = () => (
  <Page title="Relatórios">
    <ListReport type="Avaliação higiossanitária" />
  </Page>
);

export default ListReportsHigioSanidadePage;
