import React from 'react';
import PropTypes from 'prop-types';
import Form from './Form/TechnicalSheetForm';

function EditTechnicalSheets({ id, setTechSheetName }) {
  return <Form isEditing id={id} setTechSheetName={setTechSheetName} />;
}

EditTechnicalSheets.propTypes = {
  id: PropTypes.string.isRequired,
  setTechSheetName: PropTypes.func.isRequired,
};

export default EditTechnicalSheets;
