const sumNutritionalValues = weekTechnicalSheets => {
  const weekNutritionalValues = [];
  Object.keys(weekTechnicalSheets).forEach(category => {
    const values = weekTechnicalSheets[category]
      .filter(ts => ts != null)
      .map(ts => ts.total_nutritional_values);
    weekNutritionalValues.push(...values);
  });
  const totalNutritionalValues = {
    'Energia [kcal]': 0,
    'Hidratos de carbono': 0,
    Lípidos: 0,
    Proteína: 0,
  };
  Object.keys(totalNutritionalValues).forEach(param => {
    weekNutritionalValues.forEach(nutritionalValues => {
      const paramInfo = nutritionalValues[param];
      if (paramInfo) totalNutritionalValues[param] += paramInfo.value;
    });
    totalNutritionalValues[param] = +totalNutritionalValues[param].toFixed(1);
  });
  return totalNutritionalValues;
};

export default sumNutritionalValues;
