import React, { useState } from 'react';
import Layout from '../components_lgp/layout/Layout';
import Page from '../components/common/Page';
import FullPage from '../components_lgp/MealPlanning/pageLayout/FullPage';
import MealPlanning from '../components_lgp/MealPlanning/MealPlanning';
import { links, info } from '../components_lgp/MealPlanning/auxBar.json';

const MealPlanningPage = () => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const toggleModal = () => setModalVisibility(!isModalVisible);
  const newLinks = links;
  newLinks[1].onClick = toggleModal;

  return (
    <Page title="Planeamento de ementas">
      <Layout
        breadcrumb_links={[
          {
            name: 'Planear',
            url: '/planear',
          },
          {
            name: 'Gerir ementas',
            url: '/ementas/criar',
          },
        ]}
      >
        <FullPage
          main={
            <MealPlanning
              isModalVisible={isModalVisible}
              toggleModal={toggleModal}
            />
          }
          links={newLinks}
          info={info}
        />
      </Layout>
    </Page>
  );
};

export default MealPlanningPage;
