import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { FaFileDownload } from 'react-icons/fa';
import Moment from 'moment';
import FileSaver from 'file-saver';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { fetchPDFMealPlan, fetchPDFMealPlanMonth } from '../../services/mealsService';

const ExportPlanButton = ({
  date,
  className,
  organizationId,
  isModalVisible,
  toggleModal,
}) => {
  const mealsNames = [
    'Pequeno-almoço',
    'Merenda da manhã',
    'Almoço',
    'Merenda da tarde',
    'Jantar',
    'Ceia',
  ];
  const [isLoadingW, setIsLoadingW] = useState(false);
  const [isLoadingM, setIsLoadingM] = useState(false);
  const [mealsToExport, setMealsToExport] = useState(mealsNames);

  const [selectedDate, setSelectedDate] = useState(Moment(date));

  const dateFormatted = `${Moment(selectedDate)
    .startOf('isoWeek')
    .format('DD/MM/YYYY')}`;

  const monthFormatted = `${Moment(selectedDate)
    .startOf('isoWeek')
    .format('MM')}`;

  const toggleMeal = event => {
    const mealName = event.target.name;
    if (mealsToExport.includes(mealName))
      setMealsToExport(mealsToExport.filter(item => item !== mealName));
    else setMealsToExport([...mealsToExport, mealName]);
  };

  const exportPDFmonth = async () => {
    if (isLoadingM) return;
    setIsLoadingM(true);
    
    const { data } = await fetchPDFMealPlanMonth({
      date: dateFormatted,
      meals: mealsToExport.toString(),
      organizationId,
    }).finally(() => {
      setIsLoadingM(false);
      toggleModal();
    });

    const blob = new Blob([data], {
      type: 'application/pdf',
    });
    FileSaver.saveAs(blob, `Ementa mês ${monthFormatted}.pdf`);
  };

  const exportPDF = async () => {
    if (isLoadingW) return;
    setIsLoadingW(true);
    const { data } = await fetchPDFMealPlan({
      date: dateFormatted,
      meals: mealsToExport.toString(),
      organizationId,
    }).finally(() => {
      setIsLoadingW(false);
      toggleModal();
    });
    const blob = new Blob([data], {
      type: 'application/pdf',
    });
    FileSaver.saveAs(blob, `Ementa semana ${dateFormatted}.pdf`);
  };

  return (
    <>
      <Modal
        isOpen={isModalVisible}
        toggle={toggleModal}
        className={className}
        centered
      >
        <ModalHeader toggle={toggleModal}>
          Exportar ementa da semana
        </ModalHeader>
        <ModalBody className="pb-1">
          <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                value={selectedDate}
                onChange={newDate => setSelectedDate(newDate)}
                views={['year', 'month', 'date']}
                label="Semana da ementa"
                format="D MMM, YYYY"
              />
            </MuiPickersUtilsProvider>
          </div>{' '}
          Selecione as refeições que pretende exportar:
          <Form className="pl-4 py-3">
            {mealsNames.map(meal => (
              <FormGroup className="mb-1" key={meal}>
                <Label check>
                  <Input
                    type="checkbox"
                    name={meal}
                    checked={mealsToExport.includes(meal)}
                    onChange={toggleMeal}
                  />
                  {meal}
                </Label>
              </FormGroup>
            ))}
          </Form>
        </ModalBody>
        <ModalFooter>
        <Button color="primary" onClick={exportPDFmonth}>
            <FaFileDownload className="mt-n1" />
            &nbsp;
            {!isLoadingM && <>Exportar mês inteiro</>}
            {isLoadingM && <>Exportando...</>}
          </Button>
          <Button color="primary" onClick={exportPDF}>
            <FaFileDownload className="mt-n1" />
            &nbsp;
            {!isLoadingW && <>Exportar</>}
            {isLoadingW && <>Exportando...</>}
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

ExportPlanButton.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  className: PropTypes.string,
  isModalVisible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  organizationId: PropTypes.object.isRequired,
};

ExportPlanButton.defaultProps = {
  className: '',
};

export default ExportPlanButton;
