import React from 'react';
import ChooseModuleSelection from '../components_lgp/ChooseModule/ChooseModuleSelection';
import Layout from '../components_lgp/layout/Layout';
import Page from '../components/common/Page';

const ChooseModule = () => (
  <Page title="Módulos">
    <Layout>
      <ChooseModuleSelection />
    </Layout>
  </Page>
);

export default ChooseModule;
