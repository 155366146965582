import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

import { getProfile } from '../../services/profileService';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    border: 'none',
  },
  pos: {
    marginBottom: 12,
  },
  spinner: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  large: {
    width: theme.spacing(18),
    height: theme.spacing(18),
    fontSize: '4em',
    margin: 'auto',
  },
  info: {
    backgroundColor: '#ececec',
  },
  textMargin: {
    marginBottom: '1em',
  },
  name: {
    fontWeight: 'bolder',
    fontSize: '3em',
  },
  inst: {
    fontWeight: 'bold',
    fontSize: '1.2em',
  },
  actions: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}));

const UserProfile = () => {
  const styles = useStyles();

  const [profile, setProfile] = useState('');

  if (!profile) {
    getProfile().then(({ data }) => {
      setProfile(data);
    });
  }

  return (
    <>
      <Grid container spacing={3}>
        {!profile && (
          <div className={styles.spinner}>
            <CircularProgress />
          </div>
        )}
        {profile && (
          <>
            <Grid item xs={12} md={3} lg={2}>
              <Avatar
                alt={profile.name}
                src="/profile-image.jpg"
                className={styles.large}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <Card className={styles.root} variant="outlined">
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} className={styles.textMargin}>
                      <Typography
                        variant="h2"
                        component="h2"
                        className={styles.name}
                      >
                        {profile.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={styles.textMargin}>
                      <Typography component="span">
                        <Grid container>
                          <Grid item xs={6} className={styles.inst}>
                            Instituições:
                          </Grid>
                          <Grid item xs={6}>
                            {profile.institutions}
                          </Grid>
                        </Grid>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={styles.info}>
                      <Typography component="span">
                        <Grid container>
                          <Grid item xs={6}>
                            Contacto:
                          </Grid>
                          <Grid item xs={6}>
                            {profile.email}
                          </Grid>
                        </Grid>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={styles.info}>
                      <Typography component="span">
                        <Grid container>
                          <Grid item xs={6}>
                            Ocupação:
                          </Grid>
                          <Grid item xs={6}>
                            {profile.occupation}
                          </Grid>
                        </Grid>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={styles.info}>
                      <Typography component="span">
                        <Grid container>
                          <Grid item xs={6}>
                            Distrito:
                          </Grid>
                          <Grid item xs={6}>
                            {profile.district}
                          </Grid>
                        </Grid>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={styles.info}>
                      <Typography component="span">
                        <Grid container>
                          <Grid item xs={6}>
                            Data de Registo:
                          </Grid>
                          <Grid item xs={6}>
                            {profile.register_date}
                          </Grid>
                        </Grid>
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions className={styles.actions}>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    href="/perfil/editar"
                  >
                    Editar Perfil
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default UserProfile;
