import React from 'react';
import PropTypes from 'prop-types';
import styles from './PoliticaAlimentarStyle.module.css';

const TableListItemWithImg = props => {
  const { list } = props;

  return (
    <>
      {list.map(({ title, description }) => (
        <tr>
          <td className={styles.container}>
            <div>{title}</div>
            <div className={styles.box}>
              <div className={styles.text}>
                {description.split('\n').map(item => {
                  if (item.charAt(0) === '\r') {
                    return <li>{item}</li>;
                  }
                  return <p>{item}</p>;
                })}
              </div>
            </div>
          </td>
        </tr>
      ))}
    </>
  );
};

TableListItemWithImg.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default TableListItemWithImg;
