import React from 'react';
import Layout from '../components_lgp/layout/Layout';
import RecomAlimentarTable from '../components_lgp/RecomAlimentar/RecomAlimentarTable';
import Page from '../components/common/Page';

const links = [
  {
    name: 'Consultar',
    url: '/Consultar',
  },
  {
    name: 'Recomendações Alimentares',
  },
];

const RecomAlimentar = () => (
  <Page title="Recomendações Alimentares">
    <Layout breadcrumb_links={links}>
      <RecomAlimentarTable />
    </Layout>
  </Page>
);

export default RecomAlimentar;
