import React, { useState, useEffect, useContext } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import NavigationBefore from '@material-ui/icons/NavigateBefore';
import NavigationNext from '@material-ui/icons/NavigateNext';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles } from '../styles';
import { MealsContext } from '../MealsContextProvider';

const MealTypePicker = () => {
  const classes = useStyles();
  // TODO: perhaps this should be retrieved from backend?
  const mealTypes = [
    { name: 'Pequeno-almoço', code: 'pequeno_almoco' },
    { name: 'Merenda da manhã', code: 'merenda_manha' },
    { name: 'Almoço', code: 'almoco' },
    { name: 'Merenda da tarde', code: 'merenda_tarde' },
    { name: 'Jantar', code: 'jantar' },
    { name: 'Ceia', code: 'ceia' },
  ];
  const {
    // function to update the selected meal type in parent component's state
    userInput: { setSelectedMealType },
    // number of pending requests for saving meals
    pendingRequests,
  } = useContext(MealsContext);
  // internal state for current index
  const [index, setIndex] = useState(0);
  // auxiliar func to increment index
  const indexIncrement = () => {
    setIndex(prevIndex => (prevIndex + 1) % mealTypes.length);
  };
  // auxiliar func to decrement index
  const indexDecrement = () => {
    // module % operation in javascript can result in negative values
    // an additional check is needed. if the value goes below 0,
    // then set then count from last
    setIndex(prevIndex =>
      prevIndex - 1 < 0 ? mealTypes.length - 1 : prevIndex - 1,
    );
  };
  // when the index changes, invoke hook
  useEffect(() => {
    setSelectedMealType(mealTypes[index]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  // TODO: flag icons onClick handlers
  return (
    <div className={classes.toolbar}>
      <div id="navigation" className={classes.navigation}>
        <IconButton size="small" onClick={indexDecrement}>
          <NavigationBefore />
        </IconButton>
        <Typography component="span">{mealTypes[index].name}</Typography>
        <IconButton size="small" onClick={indexIncrement}>
          <NavigationNext />
        </IconButton>
      </div>
      <Tooltip
        title={
          pendingRequests
            ? 'A guardar as alterações...'
            : 'Alterações guardadas com sucesso'
        }
      >
        {pendingRequests ? (
          <CircularProgress
            disableShrink
            className={classes.savingStatus}
            size="1.5rem"
          />
        ) : (
          <CloudDoneIcon className={classes.savedStatus} />
        )}
      </Tooltip>
    </div>
  );
};

export default MealTypePicker;
