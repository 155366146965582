import axios from 'axios';

const baseUrl = '/api/organizations';

/**
 * Fetch all organizations owned by the authenticated user
 * The response is an array of objects.
 * E.g. [{ name: 'Some name', id: 1 }, { name: 'Another name', id: 2 }]
 */
export const fetchAllOrganizations = async () => {
  return axios.get(baseUrl);
};

/**
 * Updates an organization information. At moment, the only attribute for an
 * organization is its name
 * @param {number} id - The organization's id to be updated
 * @param {string} name - The new organization name
 */
export const patchOrganization = async (id, name) => {
  return axios.put(`${baseUrl}/${id}`, {
    name,
  });
};

/**
 * Deletes an organization. On the backend, this also deletes all meals and
 * reports related to this organization
 * @param {number} id - The organization's id to be removed
 */
export const deleteOrganization = async id => {
  return axios.delete(`${baseUrl}/${id}`);
};

/**
 * Creates a new organization
 * @param {string} name The organization name
 */
export const createOrganization = async name => {
  return axios.post(baseUrl, {
    name,
  });
};

export const fetchOrganizationDailyMeals = async (
  cancelToken = axios.CancelToken.source().token,
  id,
  year,
  month,
  type,
) =>
  axios.get(`${baseUrl}/${id}/meals/${year}/${month}/${type}`, {
    cancelToken,
  });

/**
 * Create/replace a single daily meal plan. Each meal has a date, a type,
 * belongs to an organization and has associated a zero or more technical sheets
 *
 * @param {number[]} technicalSheetIds - Technical sheet IDs to be assigned to
 * the meal plan
 * @param {number} organizationId - Organization ID
 * @param {string} mealDate - Meal date in YYYY-MM-DD format (e.g. 2020-5-14)
 * @param {string} mealType - Meal type (e.g. 'pequeno_almoco', 'almoco', etc.)
 */
export const submitOrganizationDailyMeal = async (
  technicalSheetIds,
  organizationId,
  mealDate,
  mealType,
) => {
  // request body
  const body = {
    technicalSheetIds,
  };
  return axios.put(
    `${baseUrl}/${organizationId}/meals/${mealDate}/${mealType}`,
    body,
  );
};
