import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

const RouteLink = ({ children, to, ...other }) => {
  return (
    <Link to={to} component={RouterLink} {...other}>
      {children}
    </Link>
  );
};

RouteLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
  ]).isRequired,
};

export default RouteLink;
