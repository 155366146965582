import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import Notification from '../../../components/common/Notification';

import styles from '../ViewReport.module.css';
import FullPage from '../pageLayout/FullPage';

const colorValue = value => {
  if (value < 0.5) {
    return '#e53e58';
  }
  if (value < 0.75) {
    return '#fa7a2b';
  }
  if (value < 0.9) {
    return '#fdc03f';
  }
  return '#76c14f';
};

const barLength = value => {
  if (value === 0) {
    return 0.1;
  }
  return value;
};

const PureView = ({
  name,
  lastUpdate,
  answers,
  id,
  newReport,
  printReport,
}) => {
  const handleExport = async () => {
    await printReport(id);
  };
  let total = 0;
  if(name === 'Avaliação Higiossanitária - Confeção de refeições' || name === 'Avaliação Higiossanitária - Receção de refeições'){
    for (let i = 0; i < answers.length; i++) {
      
      switch(answers[i]['name']){
        case 'Controlo de Higienização e de Pragas':
        case 'Autocontrolo':
          total += 0.05 * (answers[i]['weightedTrue']/answers[i]['weightedTotal']);
          break;
        case 'Condições Técnico-Funcionais de Vestiários e Sanitários do Pessoal e Sanitários dos Utentes':
          total += 0.06 * (answers[i]['weightedTrue']/answers[i]['weightedTotal']);
          break;
        case 'Condições Técnico-Funcionais da Zona de Receção e Condições das Matérias-Primas':
        case 'Condições Técnico-Funcionais do Economato':
        case 'Condições dos Produtos Confecionados':
        case 'Controlo de Resíduos':
          total += 0.075 * (answers[i]['weightedTrue']/answers[i]['weightedTotal']);
          break;
        case 'Condições Técnico-Funcionais da Rede de Frio':
        case 'Condições Gerais de Higienização e Conservação':
        case 'Condições do Pessoal Manipulador de Alimentos':
          total += 0.1 * (answers[i]['weightedTrue']/answers[i]['weightedTotal']);
          break;
        case 'Condições Técnico-Funcionais da Cozinha (Zona de Preparação/Confeção/Copa Suja/Copa limpa)':
        case 'Condições de Manipulação':
        case 'Condições Técnico-Funcionais da Copa Limpa/Zona de Empratamento':
          total += 0.12 * (answers[i]['weightedTrue']/answers[i]['weightedTotal']);
          break;
        default:
          break;
      }
    }
    if(name === 'Avaliação Higiossanitária - Receção de refeições'){
      total = (100/75) * total;
    }
    total = (total * 100).toFixed(1);
  }
  else {
    const totalTrueAnswers = answers.reduce(
      (acc, { weightedTrue: trueN }) => acc + trueN,
      0,
    );
    const totalAnswers = answers.reduce((acc, { weightedTotal: totalN }) => acc + totalN, 0);
    total = ((totalTrueAnswers / totalAnswers) * 100).toFixed(1);
  }
  
  

  const renderResults = () => {
    return (
      <>
        {newReport && <Redirect to={`/avaliar/${newReport}`} />}
        <div className={styles.alertNotification}>
          <Notification />
        </div>
        <h4>{name}</h4>
        <p>{moment(lastUpdate).format('DD-MM-YYYY HH:mm:ss')}</p>
        <div className={styles.sectionList}>
          <ul>
            {answers &&
              answers.map(({weightedTotal: weightedTotalN, weightedTrue: weightedTrueN, true: trueN, total: totalN, name: sectionName }, idx) => (
                <li key={`${sectionName}${trueN}`} className={styles.section}>
                  <span className={styles.result}>
                    {trueN}/{totalN}
                  </span>
                  <span className={styles.description}>
                    <Link
                      to={`/avaliar/${id}/${idx + 1}`}
                      className={styles.link}
                    >
                      {sectionName}
                    </Link>
                    <div className={styles.progressBarParent}>
                      <div
                        style={{
                          width: `${barLength(weightedTrueN / weightedTotalN) * 100}%`,
                          marginRight: `${100 -
                            barLength(weightedTrueN / weightedTotalN) * 100}%`,
                          background: `${colorValue(weightedTrueN / weightedTotalN)}`,
                        }}
                        className={styles.progressBar}
                      />
                    </div>
                  </span>
                </li>
              ))}
            {answers.length > 0 && (
              <li className={styles.section}>
                <span>
                  <span className={styles.result}>
                    {total}%
                  </span>
                  <span className={styles.description}>Total</span>
                </span>
              </li>
            )}
            <li>
              <ul className={styles.colorCodes}>
                <li>
                  <div className={styles.greenball} />
                  muito bom
                </li>
                <li>
                  <div className={styles.yellowball} />
                  bom
                </li>
                <li>
                  <div className={styles.orangeball} />
                  aceitável
                </li>
                <li>
                  <div className={styles.redball} />
                  não aceitável
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </>
    );
  };

  const getLabel = namePage => {
    if (namePage === 'Avaliação Higiossanitária - Confeção de refeições') {
      return (
        <div>
          <p>
            A Grelha de Avaliação das Condições Higiossanitárias de Refeitórios
            com Confeção de Refeições encontra-se organizada em 12 domínios de
            avaliação, com a respetiva valoração, composto por vários
            parâmetros, num total de 145:
          </p>
          <ul>
            <li>
              Condições Técnico-Funcionais de Vestiários e Sanitários do Pessoal
              e Sanitários dos Utentes: 6%
            </li>
            <li>
              Condições Técnico-Funcionais da Zona de Receção e Condições das
              Matérias-Primas: 7,5%
            </li>
            <li>Condições Técnico-Funcionais do Economato: 7,5%</li>
            <li>Condições Técnico-Funcionais da Rede de Frio: 10%</li>
            <li>
              Condições Técnico-Funcionais da Cozinha (Zonas de preparação e
              confeção, copas limpa e suja): 12%
            </li>
            <li>Condições de Manipulação: 12%</li>
            <li>Condições dos Produtos confecionados: 7,5%</li>
            <li>Condições Gerais de Higienização e Conservação: 10%</li>
            <li>Condições do Pessoal Manipulador de Alimentos: 10%</li>
            <li>Controlo de Resíduos: 7,5%</li>
            <li>Controlo de Higienização e de Pragas: 5%</li>
            <li>Autocontrolo: 5%</li>
          </ul>

          <p>
            Esta avaliação permite a obtenção de dados necessários ao
            diagnóstico e caracterização das condições higiossanitárias e
            técnico – funcionais de refeitórios escolares, quantificadas e
            passíveis de serem utilizadas como instrumentos de monitorização.
            Obtém-se informações sobre infraestruturas e
            instalações/equipamentos, procedimentos humanos e técnicos,
            autocontrolo, tendo em conta a sustentabilidade ambiental e social.
            As grelhas permitem ainda obter informação relativamente à sala de
            refeição, quanto à adequação das infraestruturas, materiais e
            equipamentos, condições ambientais e higiossanitárias, que no seu
            conjunto contextualizam e potenciam uma refeição escolar favorável,
            quando em conformidade.
          </p>
        </div>
      );
    }
    if (namePage === 'Qualidade de ementas - saudáveis e sustentáveis') {
      return (
        <div>
          <p>
            A avaliação qualitativa é feita através do preenchimento da lista de
            verificação correspondente, dividida em 2 passos.{' '}
          </p>
          <p>
            Esta grelha encontra-se organizada em 7 domínios de avaliação, com a 
            respetiva valoração, composto por vários parâmetros, num total de 56:
          </p>
          <ul>
            <li>1. Itens gerais: 50%</li>
            <li>2. Sopa: 8,3%</li>
            <li>3. Conduto (Carne, pescado, ovo): 8,3%</li>
            <li>4. Opção Vegetariana: 8,3%</li>
            <li>5. Acompanhamento (cereais e derivados, tubérculos e leguminosas): 8,3%</li>
            <li>6. Hortícolas (crus e cozinhados): 8,3%</li>
            <li>7. Sobremesa: 8,3%</li>
          </ul>
        </div>
      );
    }

    return <p>Resultado pronto a imprimir</p>;
  };

  return (
    <FullPage
      main={renderResults()}
      links={[
        {
          label: 'Imprimir',
          onClick: handleExport,
        },
      ]}
      info={getLabel(name)}
    />
  );
};

PureView.propTypes = {
  name: PropTypes.string.isRequired,
  lastUpdate: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      true: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  id: PropTypes.string.isRequired,
  newReport: PropTypes.number,
  printReport: PropTypes.func.isRequired,
};

PureView.defaultProps = {
  newReport: null,
};

export default PureView;
