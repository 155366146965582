import React from 'react';
import GeographicStatsMap from './GeographicStatsMap';
import styles from './AdminStyle.module.css';
import Statistics from '../Admin/Statistics';

const Admin = () => (
  <div className={styles.cont}>
    <h1 className={styles.titulo}>Utilizadores</h1>
    <Statistics />
    <div className={styles.main}>
      <GeographicStatsMap />
    </div>
  </div>
);

export default Admin;
