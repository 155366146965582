import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';

import { getProfile, changeInstitutions } from '../../services/profileService';

import { institutionTypes } from './Resources';

import {
  clearNotification,
  setNotification,
} from '../../actions/notificationActions';

const useStyles = makeStyles(theme => ({
  form: {
    padding: '1.5em',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
  },
  formControl: {
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    overflow: 'hidden',
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  field: {
    marginTop: theme.spacing(2),
    width: 'fit-content',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(institution, institutionName, theme) {
  return {
    fontWeight:
      institutionName.indexOf(institution) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

// const updateInstitutions = (initialInstitutions, setInstitution) => {
//   setInstitution(...initialInstitutions);
// }

const ChangeInstitutionForm = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const theme = useTheme();

  const [profile, setProfile] = useState('');
  const [institution, setInstitution] = useState([]);

  const handleChange = event => {
    setInstitution(event.target.value);
  };

  const submitFormInstitutions = e => {
    e.preventDefault();
    dispatch(clearNotification());

    const institutions = institution.join(', ');
    changeInstitutions({
      institutions,
    })
      .then(() => {
        dispatch(
          setNotification({
            message: 'As suas instituições foram actualizadas.',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        if (error.response.status !== 200) {
          dispatch(
            setNotification({
              message: 'Ocorreu um erro ao alterar as suas instituições.',
              type: 'error',
            }),
          );
        }
      });
  };

  if (!profile) {
    getProfile().then(({ data }) => {
      setProfile(data);
      if (data.institutions !== null && data.institutions !== undefined) {
        let parse = data.institutions;
        parse = parse.split(', ');
        setInstitution(parse);
      }
    });
  }

  return (
    <>
      <form onSubmit={submitFormInstitutions} className={styles.form}>
        <FormControl className={styles.formControl}>
          <InputLabel id="institution-chip-label">Instituição</InputLabel>
          <Select
            labelId="institution-chip-label"
            id="institution-chip"
            multiple
            value={institution}
            onChange={handleChange}
            input={<Input id="select-institution-chip" />}
            renderValue={selected => (
              <div className={styles.chips}>
                {selected.map(value => (
                  <Chip key={value} label={value} className={styles.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {institutionTypes.map(institutionName => (
              <MenuItem
                key={institutionName}
                value={institutionName}
                style={getStyles(institution, institutionName, theme)}
              >
                {institutionName}
              </MenuItem>
            ))}
          </Select>

          <Button
            variant="outlined"
            size="small"
            color="primary"
            type="submit"
            label="submit"
            className={styles.field}
          >
            Alterar
          </Button>
        </FormControl>
      </form>
    </>
  );
};

export default ChangeInstitutionForm;
