import React from 'react';
import PropTypes from 'prop-types';

import classes from './AuxBox.module.css';

function AuxBox({ children, style }) {
  return (
    <div className={classes.box} style={style}>
      {children}
    </div>
  );
}

AuxBox.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

AuxBox.defaultProps = {
  style: {},
};

export default AuxBox;
