import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { FaFileDownload } from 'react-icons/fa';
import Moment from 'moment';
import FileSaver from 'file-saver';

import { fetchPDFMealPlan } from '../../../services/mealsService';

const ExportPlanButton = ({ date, className }) => {
  const mealsNames = [
    'Pequeno-almoço',
    'Merenda da manhã',
    'Almoço',
    'Merenda da tarde',
    'Jantar',
    'Ceia',
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [mealsToExport, setMealsToExport] = useState(mealsNames);
  const [isModalVisible, setModalVisibility] = useState(false);

  const toggleModal = () => setModalVisibility(!isModalVisible);

  const dateFormatted = `${Moment(date)
    .startOf('isoWeek')
    .format('DD/MM/YYYY')}`;

  const toggleMeal = event => {
    const mealName = event.target.name;
    if (mealsToExport.includes(mealName))
      setMealsToExport(mealsToExport.filter(item => item !== mealName));
    else setMealsToExport([...mealsToExport, mealName]);
  };

  const exportPDF = async () => {
    if (isLoading) return;
    setIsLoading(true);
    const { data } = await fetchPDFMealPlan({
      date: dateFormatted,
      meals: mealsToExport,
    }).finally(() => {
      setIsLoading(false);
      toggleModal();
    });
    const blob = new Blob([data], {
      type: 'application/pdf',
    });
    FileSaver.saveAs(blob, `Ementa semana ${dateFormatted}.pdf`);
  };

  return (
    <>
      <Button color="success" className={className} onClick={toggleModal}>
        <FaFileDownload className="mt-n1" />
        &nbsp; Exportar Ementa
      </Button>
      <Modal
        isOpen={isModalVisible}
        toggle={toggleModal}
        className={className}
        centered
      >
        <ModalHeader toggle={toggleModal}>
          Exportar ementa da semana {dateFormatted}
        </ModalHeader>
        <ModalBody className="pb-1">
          Selecione as refeiçõe que pretende exportar:
          <Form className="pl-4 py-3">
            {mealsNames.map(meal => (
              <FormGroup className="mb-1" key={meal}>
                <Label check>
                  <Input
                    type="checkbox"
                    name={meal}
                    checked={mealsToExport.includes(meal)}
                    onChange={toggleMeal}
                  />
                  {meal}
                </Label>
              </FormGroup>
            ))}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={exportPDF}>
            <FaFileDownload className="mt-n1" />
            &nbsp;
            {!isLoading && <>Exportar</>}
            {isLoading && <>Exportando...</>}
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

ExportPlanButton.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  className: PropTypes.string,
};

ExportPlanButton.defaultProps = {
  className: '',
};

export default ExportPlanButton;
