import { reportTypes } from '../actions/types';

const initialState = { reportName: '', reportDate: '', sections: 0 };

const reportsReducer = (state, action) => {
  if (state === undefined) return initialState;

  switch (action.type) {
    case reportTypes.SET_REPORT_NAME:
      return { ...state, reportName: action.payload };
    case reportTypes.SET_REPORT_DATE:
      return { ...state, reportDate: action.payload };
    case reportTypes.SET_SECTIONS:
      return { ...state, sections: action.payload };
    default:
      return state;
  }
};

export default reportsReducer;
