import { authTypes } from './types';
import initAuth from '../initAuth';

export const login = ({ token, userType }) => async dispatch => {
  localStorage.setItem('spare_token', token);
  localStorage.setItem('spare_user_type', userType);
  initAuth();
  dispatch({
    type: authTypes.LOGIN,
    payload: { token, userType },
  });
};

export const logout = () => async dispatch => {
  localStorage.setItem('spare_token', '');
  localStorage.setItem('spare_user_type', '');
  initAuth();
  dispatch({
    type: authTypes.LOGOUT,
  });
};
