import React from 'react';

const panelWelcome = (
  <>
  <p>A ferramenta inform&aacute;tica SPARE&reg;, &eacute; um instrumento concebido, planeado e desenvolvido pela Faculdade de Ci&ecirc;ncias 
    da Nutri&ccedil;&atilde;o e Alimenta&ccedil;&atilde;o da Universidade do Porto (FCNAUP) por solicita&ccedil;&atilde;o da Dire&ccedil;&atilde;o Geral da Sa&uacute;de - Programa 
    Nacional para a Promo&ccedil;&atilde;o da Alimenta&ccedil;&atilde;o Saud&aacute;vel, que apresenta agora uma nova edi&ccedil;&atilde;o.
  </p>
  <p>
    Com cerca de 10 anos de exist&ecirc;ncia, esta ferramenta de acesso livre tem auxiliado a promover ambientes alimentares
     saud&aacute;veis e seguros. &Eacute; um servi&ccedil;o p&uacute;blico e uma parceria entre uma universidade p&uacute;blica e a autoridade de sa&uacute;de do 
     governo portugu&ecirc;s. 
  </p>
  <p>
    Para o desenvolvimento desta nova edi&ccedil;&atilde;o, tivemos como parceiro a Faculdade de Engenharia da Universidade do Porto 
    (FEUP) a quem agradecemos o apoio e enorme qualidade. Esta atualiza&ccedil;&atilde;o permitiu a melhoria de diversas funcionalidades
     e outras foram profundamente alteradas tendo como objetivo a melhoria da seguran&ccedil;a do utilizador e a prote&ccedil;&atilde;o dos dados. 
     Questões fundamentais nos dias que correm e que nos obrigaram a uma demorada paragem e a trabalho intenso. Pedimos desculpas
      pelos inconvenientes que possamos ter causado.
  </p>
  <p>
    Nesta edi&ccedil;&atilde;o do SPARE, incorporamos a vers&atilde;o atualizada da tabela de composi&ccedil;&atilde;o de alimentos do Instituto Nacional de Sa&uacute;de
     Ricardo Jorge e adicionamos o planeamento do jantar e da ceia, &eacute; tamb&eacute;m possível verificar a adequa&ccedil;&atilde;o nutricional das ementas
      planeadas para todas as faixas et&aacute;rias. Foram tamb&eacute;m adicionados novos itens, à lista de verifica&ccedil;&atilde;o para avalia&ccedil;&atilde;o qualitativa
       de ementas, de forma a atualizar esta funcionalidade baseada em crit&eacute;rios de sa&uacute;de e tamb&eacute;m de sustentabilidade.
  </p>
  <p>
    Tendo consci&ecirc;ncia do vasto n&uacute;mero de utilizadores desta ferramenta, procuramos mais uma vez incluir as sugestões que nos foram 
    transmitindo, na certeza que melhor faremos com o contributo de todos em edi&ccedil;ões futuras. Este &eacute; um instrumento em atualiza&ccedil;&atilde;o permanente.
  </p>
  <p>
  Porto, dezembro de 2022
  </p>
  <p>
  Pedro Gra&ccedil;a
  </p>
  <p>
  Diretor da FCNAUP
  </p>
    
  </>
);

export default panelWelcome;
