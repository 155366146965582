import { notificationTypes } from './types';

let prevTimeout;

export const clearNotification = () => {
  if (prevTimeout) clearTimeout(prevTimeout);

  return {
    type: notificationTypes.CLEAR_NOTIFICATION,
  };
};

export const setNotification = (
  notification,
  duration = 10,
) => async dispatch => {
  dispatch({
    type: notificationTypes.SET_NOTIFICATION,
    data: notification,
  });

  clearTimeout(prevTimeout);
  prevTimeout = setTimeout(() => {
    dispatch(clearNotification());
  }, duration * 1000);
};
