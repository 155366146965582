import axios from 'axios';

const baseUrl = '/api/technicalSheets';

const fetchTechnicalSheet = (
  id,
  cancelToken = axios.CancelToken.source().token,
) => axios.get(`${baseUrl}/${id}`, { cancelToken });

const fetchAllTechnicalSheets = async (
  page,
  query,
  pageSize,
  simplified = true,
  filterList,
) => {
  return axios.get(baseUrl, {
    params: {
      page,
      query,
      pageSize,
      simplified,
      filterList,
    },
  });
};

const fetchAllTechnicalSheetsSimplified = async () => {
  return axios.get('/api/technicalSheetsSimplified?simplified=true');
};

const deleteTechnicalSheet = async id => {
  return axios.delete(`${baseUrl}/${id}`);
};

const createTechnicalSheet = async technicalSheet => {
  return axios.post(baseUrl, technicalSheet);
};

const updateTechnicalSheet = async (technicalSheet, id) => {
  return axios.put(`${baseUrl}/${id}`, technicalSheet);
};
const exportTechnicalSheet = async id =>
  axios.get(`${baseUrl}/${id}/download`, {
    responseType: 'blob',
  });

export {
  fetchTechnicalSheet,
  fetchAllTechnicalSheets,
  deleteTechnicalSheet,
  fetchAllTechnicalSheetsSimplified,
  createTechnicalSheet,
  updateTechnicalSheet,
  exportTechnicalSheet,
};
