import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaAngleDoubleLeft, FaAngleLeft } from 'react-icons/fa';

import styles from './CommonList.module.css';

const buttonActions = {
  INC: 'inc',
  DEC: 'dec',
  FIRST: 'first',
  LAST: 'last',
};

const Pagination = ({ updatePage, lastPage, searchTerm }) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  const handlePageChange = event => {
    switch (event.target.value) {
      case buttonActions.FIRST:
        setPage(1);
        break;
      case buttonActions.DEC:
        setPage(page > 1 ? page - 1 : 1);
        break;
      case buttonActions.INC:
        setPage(page < lastPage ? page + 1 : page);
        break;
      case buttonActions.LAST:
        setPage(lastPage);
        break;
      default:
    }
  };

  useEffect(() => {
    updatePage(page);
  }, [page, updatePage]);

  return (
    <div className={styles.paginationWrapper}>
      <div className={styles.toggleWrapper}>
        <button
          type="button"
          value={buttonActions.FIRST}
          onClick={handlePageChange}
          className={styles.paginationButton}
        >
          <FaAngleDoubleLeft />
        </button>
        <button
          type="button"
          value={buttonActions.DEC}
          onClick={handlePageChange}
          className={styles.paginationButton}
        >
          <FaAngleLeft />
        </button>
        <p className={styles.currentPage}>
          {page} de {lastPage}
        </p>
        <button
          type="button"
          value={buttonActions.INC}
          onClick={handlePageChange}
          className={styles.paginationButton}
        >
          <FaAngleLeft className={styles.right} />
        </button>
        <button
          type="button"
          value={buttonActions.LAST}
          onClick={handlePageChange}
          className={styles.paginationButton}
        >
          <FaAngleDoubleLeft className={styles.right} />
        </button>
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  updatePage: () => {},
  searchTerm: '',
};

Pagination.propTypes = {
  updatePage: PropTypes.func,
  lastPage: PropTypes.number.isRequired,
  searchTerm: PropTypes.string,
};

export default Pagination;
