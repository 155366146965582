import React from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { NavLink } from 'reactstrap';

import styles from './Info.module.css';

const getDescriptionClass = (isMobile, isSmallScreen) => {
  if (isMobile) {
    return styles.descriptionMobile;
  }
  if (isSmallScreen) {
    return styles.descriptionSmall;
  }
  return styles.description;
};

const Info = ({ isMobile, isSmallScreen }) => {
  const descriptionClass = getDescriptionClass(isMobile, isSmallScreen);

  const knowMoreClass = isMobile ? styles.knowMoreMobile : styles.knowMore;

  return (
    <div className={styles.info}>
      <div className={descriptionClass}>
        <h2 className={styles.title}>Spare</h2>
        <p className={styles.spareDescription}>
          O SPARE Sistema de Planeamento e Avaliação de Refeições Escolares é
          uma ferramenta informática que permite planear refeições de forma
          efetiva e organizada, de acordo com as principais recomendações
          alimentares e nutricionais nacionais e internacionais vigentes. Esta
          ferramenta permite o planeamento, avaliação, monitorização e
          verificação no sentido da melhoria continua da qualidade das refeições
          escolares.
        </p>
        <div className={knowMoreClass}>
          <NavLink tag={RRNavLink} exact to="/sobre">
            Conheça-nos mais
            <img
              src={`${process.env.PUBLIC_URL}/activeButton7.png`}
              alt="Seguir para sobre Nós"
            />
          </NavLink>
        </div>
      </div>
    </div>
  );
};

Info.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
};

export default Info;
