import React from 'react';
import { Link } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';
import styles from './ConsultarOptions.module.css';

const Admin = () => (
  <div>
    <div className={styles.titulo}>
      <div
        style={{
          color: '#FFFFFF',
          backgroundColor: '#729EA1',
          padding: '0.1em',
          borderRadius: '50%',
          fontSize: '4em',
          width: '83px',
          height: '83px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <SettingsIcon color="inherit" fontSize="inherit" />
      </div>
      <h1 className={styles.titleAdmin}>Administração</h1>
    </div>
    <div>
      <div className={styles.line}>
        <Link to="/admin/utilizadores" className={styles.link}>
          Utilizadores
          <img
            className={styles.iconeAtivo}
            src={`${process.env.PUBLIC_URL}/botaoAtivo7.png`}
            alt=""
          />
        </Link>
      </div>

      <div className={styles.line}>
        <Link to="/admin/ingredientes" className={styles.link}>
          Ingredientes
          <img
            className={styles.iconeAtivo}
            src={`${process.env.PUBLIC_URL}/botaoAtivo7.png`}
            alt=""
          />
        </Link>
      </div>

      <div className={styles.line}>
        <Link to="/admin/recomendacoes-nutricionais" className={styles.link}>
          Recomendações Nutricionais
          <img
            className={styles.iconeAtivo}
            src={`${process.env.PUBLIC_URL}/botaoAtivo7.png`}
            alt=""
          />
        </Link>
      </div>
      <Link to="/">
        <h5 className={styles.back}>&lt; voltar</h5>
      </Link>
    </div>
  </div>
);

export default Admin;
