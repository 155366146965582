import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { Grid, Typography, Fab, Dialog, Button } from '@material-ui/core';
import { Info } from '@material-ui/icons';

import styles from './FullPage.module.css';
import RouteLink from '../../common/RouteLink/RouteLink';
import { AuxBox, ConditionalWrap } from '../../common';
import { useStyles } from '../styles';

function FullPage({ main, links, info }) {
  const classes = useStyles();
  const isMobile = useMediaQuery({ maxWidth: '960px' });

  const [isFabOpen, setIsFabOpen] = useState(false);

  const desktopPage = (
    <div className={styles.root}>
      <Grid container spacing={4}>
        <Grid item sm={8}>
          {main}
        </Grid>

        <Grid item sm={4}>
          <>
            {info && <AuxBox>{info}</AuxBox>}
            {links && (
              <AuxBox style={{ marginBottom: '2em', backgroundColor: 'white' }}>
                {links.map(({ url, label, onClick }) => (
                  <ConditionalWrap
                    key={label}
                    condition={!!url}
                    wrap={children => (
                      <RouteLink
                        to={url}
                        classes={{
                          root: classes.linkRoot,
                        }}
                      >
                        {children}
                      </RouteLink>
                    )}
                  >
                    <Button
                      onClick={onClick}
                      classes={{ root: classes.linkContainer }}
                    >
                      <Typography>{label}</Typography>
                      <img
                        src={`${process.env.PUBLIC_URL}/icons/triangle.png`}
                        alt="arrow pointing forward"
                      />
                    </Button>
                  </ConditionalWrap>
                ))}
              </AuxBox>
            )}
          </>
        </Grid>
      </Grid>
    </div>
  );

  const mobilePage = (
    <div className={styles.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {main}
        </Grid>
      </Grid>
      {links && (
        <AuxBox style={{ marginTop: '2em', marginBottom: '2em' }}>
          {links.map(({ url, label }) => (
            <ConditionalWrap
              key={label}
              condition={!!url}
              wrap={children => (
                <RouteLink
                  to={url}
                  classes={{
                    root: classes.linkRoot,
                  }}
                >
                  {children}
                </RouteLink>
              )}
            >
              <div className={styles.link}>
                <Typography>{label}</Typography>
                <img
                  width={24}
                  src={`${process.env.PUBLIC_URL}/icons/triangle.png`}
                  alt="arrow pointing forward"
                />
              </div>
            </ConditionalWrap>
          ))}
        </AuxBox>
      )}
      {info && (
        <>
          <Fab
            color="primary"
            aria-label="extra information"
            className={classes.fab}
            onClick={() => setIsFabOpen(!isFabOpen)}
          >
            <Info />
          </Fab>
          <Dialog
            open={isFabOpen}
            onClose={() => setIsFabOpen(false)}
            aria-labelledby="extra information dialog"
          >
            <AuxBox>{info}</AuxBox>
          </Dialog>
        </>
      )}
    </div>
  );

  return isMobile ? mobilePage : desktopPage;
}

FullPage.defaultProps = {
  info: null,
};

FullPage.propTypes = {
  main: PropTypes.node.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      onClick: PropTypes.func,
      label: PropTypes.string,
    }),
  ).isRequired,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default FullPage;
