import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from 'reactstrap';
import { HorizontalBar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import axios from 'axios';

import styles from '../TechnicalSheets/NutritionalChart.module.css';

const datasetDefault = {
  label: 'Contribuição (%)',
  backgroundColor: 'rgb(72,161,99)',
  borderColor: 'rgb(76,109,79)',
  borderWidth: 1,
  hoverBackgroundColor: 'rgba(72,161,99,0.6)',
  hoverBorderColor: 'rgba(76,109,79, 0.6)',
  data: [100, 100, 100, 100, 100],
};

const MealChart = ({ nutritionalInfo }) => {
  const [ranges, setRanges] = useState({});
  const [activeAgeRange, setActiveAgeRange] = useState(null);
  const [recommendations, setRecommendations] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const formatFetchedData = fetchedData => {
    const formatted = {};
    fetchedData.forEach(item => {
      const formattedIntakeValues = [];
      item.recommended_intake_values.forEach(intake => {
        formattedIntakeValues[intake.name] = { ...intake };
      });
      formatted[item.name] = {
        ...item,
        recommended_intake_values: formattedIntakeValues,
      };
    });
    return formatted;
  };

  const buildDataset = () => {
    const formattedData = formatFetchedData(recommendations);
    const newRanges = { ...ranges };
    // loop for every age range
    Object.keys(formattedData).map(index => {
      const newLabels = [];
      // loop for every nutrient in specific age range
      const dataset = Object.keys(nutritionalInfo)
        .filter(
          item =>
            formattedData[index].recommended_intake_values[item] !== undefined,
        )
        .map(filteredNutrient => {
          newLabels.push(filteredNutrient);
          return (
            (nutritionalInfo[filteredNutrient].value /
              formattedData[index].recommended_intake_values[filteredNutrient]
                .pivot.value) *
            100 // returns a percentage
          );
        });
      newRanges[index] = {
        labels: [...newLabels],
        datasets: [{ ...datasetDefault, data: [...dataset] }],
      };
      return newRanges;
    });
    setRanges(newRanges);
    setActiveAgeRange(Object.keys(newRanges)[0]);
    setLoading(false);
  };

  const changeActiveAgeRange = selectedAgeRange => {
    setActiveAgeRange(selectedAgeRange);
  };

  useEffect(() => {
    async function fetchRecommendations(source) {
      const baseUrl = `/api/nutritionalRecommendations`;
      try {
        const res = await axios.get(baseUrl, {
          cancelToken: source.token,
        });
        setRecommendations(res.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          // request cancelled
        } else {
          throw error;
        }
      }
    }
    const source = axios.CancelToken.source();
    fetchRecommendations(source);
    // cleanup function
    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    if (recommendations) buildDataset();
    // eslint-disable-next-line
  }, [recommendations,nutritionalInfo]);

  return (
    <Card>
      <CardHeader className={styles.cardHeader}>
        <h2>Contribuição nutricional total</h2>
      </CardHeader>
      <CardBody>
        {!isLoading && activeAgeRange ? (
          <>
            <HorizontalBar data={ranges[activeAgeRange]} />
            <Form>
              <FormGroup>
                <Label for="ageRangeSelect">Faixa etária</Label>
                <Input
                  onChange={event => changeActiveAgeRange(event.target.value)}
                  type="select"
                  name="select"
                  id="ageRangeSelect"
                >
                  {Object.keys(ranges).map(index => (
                    <option key={index} value={index}>
                      {index}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Form>
          </>
        ) : (
          <Alert>A carregar informação...</Alert>
        )}
      </CardBody>
    </Card>
  );
};

MealChart.propTypes = {
  nutritionalInfo: PropTypes.objectOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      unit: PropTypes.string.isRequired,
      is_essential: PropTypes.bool,
    }),
  ).isRequired,
};

export default MealChart;
