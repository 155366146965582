import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Table, Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';

import styles from './Overview.module.css';

const Overview = ({
  allergens,
  numPeopleServed,
  type,
  createdAt,
  lastUpdated,
  nutritionalInfo,
  ediblePart,
  nutritionalCriteria,
}) => {
  const [expandedExtraNutrients, setExpandedExtraNutrients] = useState(false);
  const [formattedAllergens, setFormattedAllergens] = useState('');

  const toggleExtraNutrients = () => {
    setExpandedExtraNutrients(!expandedExtraNutrients);
  };

  useEffect(() => {
    let allergensFormatted = '';

    allergens.forEach(({ name }) => {
      allergensFormatted = allergensFormatted.concat(`${name}, `);
    });
    setFormattedAllergens(
      allergensFormatted.substring(0, allergensFormatted.length - 2),
    );
  }, [allergens]);

  const uniqueReferencePoints = [
    ...new Set(nutritionalCriteria.map(({ edible }) => edible)),
  ];

  const refPoint =
    uniqueReferencePoints.length === 1 ? uniqueReferencePoints[0] : undefined;
  const refPoints = [];

  if (refPoint === undefined) {
    nutritionalCriteria.forEach(({ name, edible }) => {
      refPoints[name] = edible;
    });
  }

  return (
    <Card>
      <CardHeader className={styles.cardHeader}>
        <h2>Informação geral</h2>
      </CardHeader>
      <CardBody>
        <div className={styles.overviewDetails}>
          <Alert color="warning">
            <h3>Possíveis alergénios</h3>
            <hr />
            {formattedAllergens !== '' ? (
              <p>{formattedAllergens}</p>
            ) : (
              <p>Nenhum</p>
            )}
          </Alert>
          <h3>Tipo de componente</h3>
          <hr />
          <p>{type}</p>
          {numPeopleServed && (
            <>
              <h3>Porção (nº de pessoas)</h3>
              <hr />
              <p>{numPeopleServed}</p>{' '}
            </>
          )}
          {ediblePart && (
            <>
              <h3>Parte Edível</h3>
              <hr />
              <p>{ediblePart * 100}%</p>{' '}
            </>
          )}
          <h3>Data de criação</h3>
          <hr />
          <p>{moment(createdAt).format('LLLL')}</p>
          <h3> Última modificação</h3>
          <hr />
          <p>{moment(lastUpdated).format('LLLL')}</p>
          <h3>Valores nutricionais totais</h3>
          <hr />
          <Table responsive>
            <thead>
              <tr>
                <th>Elemento</th>
                <th>Valor {refPoint && `(por 100${refPoint})`}</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(nutritionalInfo).map(item => (
                <tr
                  key={item}
                  className={
                    !nutritionalInfo[item].is_essential &&
                    !expandedExtraNutrients
                      ? styles.notExpanded
                      : ''
                  }
                >
                  <td>
                    <div>
                      <strong>{item}</strong>
                    </div>
                  </td>
                  <td>
                    <div>
                      {nutritionalInfo[item].value} {nutritionalInfo[item].unit}{' '}
                      {!refPoint && `(por 100 ${refPoints[item]})`}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <button
            className={styles.buttonStyle}
            type="button"
            onClick={toggleExtraNutrients}
          >
            {expandedExtraNutrients ? 'Colapsar' : 'Expandir'}
          </button>
        </div>
      </CardBody>
    </Card>
  );
};

Overview.propTypes = {
  allergens: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
  numPeopleServed: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  ediblePart: PropTypes.string,
  lastUpdated: PropTypes.string.isRequired,
  nutritionalInfo: PropTypes.objectOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      unit: PropTypes.string.isRequired,
      is_essential: PropTypes.bool,
    }),
  ).isRequired,
  nutritionalCriteria: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      edible: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

Overview.defaultProps = {
  numPeopleServed: undefined,
  ediblePart: undefined,
};

export default Overview;
