import React from 'react';
import Layout from '../components/layout/Layout';

import CreateMeal from '../components/CreateMealPlan/CreateMealPlan';

const CreateMealPlan = match => {
  return (
    <Layout>
      <CreateMeal params={match.match.params} />
    </Layout>
  );
};
export default CreateMealPlan;
