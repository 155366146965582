import React from 'react';
import PropTypes from 'prop-types';
import styles from './Icon.module.css';

function Icon({ name, height }) {
  const n = !name ? 'outros' : name;
  let iconName = '';
  switch (n.toLowerCase()) {
    case 'bebidas':
    case 'bebidas alcoólicas':
    case 'água e bebidas à base de água':
    case 'sumos e néctares de frutos e produtos hortícolas (incluindo concentrados)':
    case 'café, cacau, chá e tisanas':
      iconName = 'bebida.png';
      break;
    case 'leites e produtos lácteos':
    case 'leite e produtos lácteos':
      iconName = 'lacticinios.png';
      break;
    case 'frutos e produtos derivados de frutos':
    case 'frutos ou similares':
      iconName = 'fruto-similares.png';
      break;
    case 'sobremesa':
    case 'sobremesas':
    case 'açúcar e similares, confeitaria e sobremesas doces à base de água':
    case 'outras sobremesas de colher':
      iconName = 'sobremesa.png';
      break;
    case 'sopas, molhos e diversos':
    case 'sopas (prontas a comer)':
    case 'sopa':
      iconName = 'sopa.png';
      break;
    case 'produtos hortícolas e derivados':
    case 'batatas, produtos hortícolas e derivados excepto leguminosas':
    case 'hortícolas':
      iconName = 'horticolas.png';
      break;
    case 'cereais e produtos à base de cereais':
    case 'cereais e derivados':
      iconName = 'cereais.png';
      break;
    case 'raízes amiláceas ou tubérculos e seus produtos, plantas sacarinas (batatas, farinha de pau, etc.)':
      iconName = 'complementos.png';
      break;
    case 'peixes, mariscos, anfíbios, répteis e invertebrados':
    case 'pescado (peixe, moluscos e crustáceos) e derivados':
      iconName = 'peixe.png';
      break;
    case 'carne e produtos cárneos':
    case 'carne e derivados, criação e caça':
      iconName = 'carne.png';
      break;
    case 'ovos':
    case 'ovos e ovoprodutos':
      iconName = 'ovos.png';
      break;
    case 'almoco':
      iconName = 'almoco.png';
      break;
    case 'jantar':
      iconName = 'jantar.png';
      break;
    case 'cereais e derivados, leguminosas e tubérculos':
      iconName = 'compl-cereais-derivados.png';
      break;
    default:
      iconName = 'outros.png';
      break;
  }

  return (
    <img
      src={`${process.env.PUBLIC_URL}/icons/${iconName}`}
      alt="spare logo"
      height={height}
      className={styles.iconStyle}
    />
  );
}

Icon.propTypes = {
  name: PropTypes.string,
  height: PropTypes.string,
};

Icon.defaultProps = {
  height: '32px',
};

export default Icon;

/** Icons Missing
 * 'Açúcar, Produtos Açucarados e Mel'
 * 'Azeite, Óleos e Gorduras'
 * 'Leguminosas Frescas e Secas e Derivados'
 * 'Cacau e Derivados'
 * 'Ovos'
 */
