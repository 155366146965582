import axios from 'axios';

const baseUrl = '/api/admin/getMeals';

const fetchAllLastMeals = async () => {
  return axios.get(baseUrl);
};

// eslint-disable-next-line import/prefer-default-export
export { fetchAllLastMeals };
