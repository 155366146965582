import { makeStyles, createMuiTheme, rgbToHex } from '@material-ui/core/styles';

export const mealPlanningTheme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(183,106,95)',
    },
    secondary: {
      main: '#FFFFFF',
    },
    text: {
      secondary: 'rgb(73,119,100)',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

export const useButtonStyles = makeStyles(theme => ({
  button: {
    color: '#FFFFFF',
  },
  green: {
    extend: 'button',
    background: theme.palette.text.secondary,
  },
  red: {
    extend: 'button',
    background: theme.palette.primary.main,
  },
  yellow: {
    extend: 'button',
    background: 'rgb(255,193,7)',
  },
}));

export const useStyles = makeStyles(theme => ({
  pickers: {
    display: 'flex',
    flexWrap: 'noWrap',
    marginBottom: '1em',
    '& > div': {
      width: '100%',
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  navigation: {
    '& button': {
      border: '1px solid lightgrey',
    },
    '& button:first-child': {
      marginRight: '0.5em',
    },
    '& button:last-child': {
      marginLeft: '0.5em',
    },
    flexGrow: '1',
  },
  savedStatus: {
    margin: '12px',
    color: rgbToHex('rgb(140,195,172)'),
  },
  savingStatus: {
    color: rgbToHex('rgb(140,195,172)'),
    margin: '12px',
  },
  mealComponents: {
    marginTop: '0.5em',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.grey[100],
    '& > div': {
      display: 'inline-flex',
          alignItems: 'center',
    },
  },
  component: {
    display: 'inline-flex',
    margin: 'auto 0',
    padding: '0.5em',
    '& div': {
      margin: 'auto',
    },
    '& div:last-child': {
      fontSize: 'small',
      fontWeight: 'bold',
    },
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
  header: {
    width: '100%',
    display: 'flex',
    padding: '2px 0 2px 1em',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  weekDay: {
    margin: 'auto',
    textAlign: 'center',
    paddingRight: '1em',
  },
  planningWeek: {
    marginTop: '0.5em',
  },
  autoComplete: {
    backgroundColor: theme.palette.grey[100],
    padding: '0.2rem 0.5rem',
  },
  tableRow: {
    marginTop: '0.5em',
    display: 'flex',
    flexWrap: 'nowrap'
  },
  dayRow: {
    paddingBottom: '1em',
  },
  feedbackRow: {
    width: '100%',
    justifyContent: 'space-evenly',
    marginTop: '0.5em',
    '& div': {
      border: '1px solid black',
    },
  },
  '@media (max-width: 960px)': {
    weekDay: {
      margin: '1em 0 0 0',
    },
    tableRow: {
      flexDirection: 'column',
    },
    feedbackRow: {
      flexDirection: 'column',
      '& button': {
        marginTop: '0.5em',
      },
    },
  },
  linkContainer: {
    width: '100%',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'inherit',
      color: theme.palette.primary.light,
      textDecoration: 'underline',
    },
    '& > *': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& > * > *:first-child': {
      marginRight: '1em',
    },
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default useStyles;
