import React, { useState, useEffect } from 'react';
import { Card, CardTitle, CardBody, CardText, Spinner } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import styles from './Statistics.module.css';
import { fetchAllLastUsers } from '../../services/lastUsersService';
import { fetchAllLastMeals } from '../../services/lastMealsService';
import { setNotification } from '../../actions/notificationActions';

const Statistics = () => {
  const [lastUsers, setLastUsers] = useState(null);
  const [lastMeals, setLastMeals] = useState(null);
  const [isFirstLoad, setFirstLoad] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await fetchAllLastUsers();
        setLastUsers(data);
        setFirstLoad(false);
      } catch (e) {
        dispatch(
          setNotification({
            message: `Ocorreu um erro. Tente Novamente`,
            type: 'error',
          }),
        );
      }
    };
    fetchUsers();

    const fetchMeals = async () => {
      try {
        const { data } = await fetchAllLastMeals();
        setLastMeals(data);
        setFirstLoad(false);
      } catch (e) {
        dispatch(
          setNotification({
            message: `Ocorreu um erro. Tente Novamente`,
            type: 'error',
          }),
        );
      }
    };

    fetchMeals();
  }, [dispatch, setFirstLoad]);

  return (
    <>
      {isFirstLoad ? (
        <div className="mt-5 d-flex justify-content-center">
          <Spinner color="success" size="md" />
        </div>
      ) : (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item sm={12} md={6} direction="row">
            <div className={styles.row}>
              <Card className={styles.card}>
                <CardBody>
                  <CardTitle className={styles.CardTitle}>
                    {lastUsers && lastUsers.last30Days}
                  </CardTitle>
                  <CardText className={styles.text}>
                    Utilizadores registados no último mês
                  </CardText>
                </CardBody>
              </Card>
              <Card className={styles.card}>
                <CardBody>
                  <CardTitle className={styles.CardTitle}>
                    {lastUsers && lastUsers.lastYear}
                  </CardTitle>
                  <CardText className={styles.text}>
                    Utilizadores registados no último ano
                  </CardText>
                </CardBody>
              </Card>
            </div>
          </Grid>
          <Grid item sm={12} md={6} direction="row">
            <div className={styles.row}>
              <Card className={styles.card}>
                <CardBody>
                  <CardTitle className={styles.CardTitle}>
                    {lastMeals && lastMeals.last30Days}
                  </CardTitle>
                  <CardText className={styles.text}>
                    Ementas criadas no último mês
                  </CardText>
                </CardBody>
              </Card>
              <Card className={styles.card}>
                <CardBody>
                  <CardTitle className={styles.CardTitle}>
                    {lastMeals && lastMeals.lastYear}
                  </CardTitle>
                  <CardText className={styles.text}>
                    Ementas criadas nos último ano
                  </CardText>
                </CardBody>
              </Card>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Statistics;
