import * as Sentry from '@sentry/browser';

export default () => {
  // Init sentry on production and staging servers.
  if (
    process.env.production === 'production' &&
    window.location.href.includes('spare')
  ) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_API_URL,
    });
  }
};
