import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FaTimesCircle, FaRegEdit } from 'react-icons/fa';
import classnames from 'classnames';
import listMealsStyles from './listMeals.module.css';

const DailyMealsTable = ({ meals, date }) => {
  const types = [
    'Pequeno-almoço',
    'Merenda da manhã',
    'Almoço',
    'Merenda da tarde',
    'Jantar',
    'Ceia',
  ];
  const typesSmall = [
    'pequeno_almoco',
    'merenda_manha',
    'almoco',
    'merenda_tarde',
    'jantar',
    'ceia',
  ];

  return (
    <>
      <Row className="text-center mt-4 mb-4">
        <Col>
          <h4 className={listMealsStyles.mealDayTitle}>
            Ementas para o dia {moment(date).format('DD/MM/YYYY')}
          </h4>
        </Col>
      </Row>
      {types.map((type, index) => (
        <Row key={type} style={{ margin: '0 15px' }}>
          <Col
            className={classnames(
              listMealsStyles.mealType,
              'd-flex justify-content-center align-items-center',
            )}
            lg="3"
          >
            <span>{type}</span>
            <Link
              className={listMealsStyles.iconEdit}
              to={`/criar-ementa/${moment(date).format('DD')}/${moment(
                date,
              ).format('MM')}/${moment(date).format('YYYY')}/${
                typesSmall[index]
              }`}
            >
              <FaRegEdit />
            </Link>
          </Col>
          <Col
            lg="9"
            className={classnames(
              'd-flex justify-content-center align-items-center',
              listMealsStyles.meals,
            )}
            style={{ padding: '1rem 0' }}
          >
            {meals.filter(meal => meal.type === type).length === 0 ? (
              <div>
                <FaTimesCircle /> Não há ementas para esta refeição.
              </div>
            ) : (
              meals
                .filter(meal => meal.type === type)
                .map(meal => (
                  <Button
                    tag={Link}
                    color="success"
                    key={meal.id}
                    to={`/ementa/${meal.id}`}
                    className={listMealsStyles.link}
                  >
                    {meal.name}
                  </Button>
                ))
            )}
          </Col>
        </Row>
      ))}
    </>
  );
};

DailyMealsTable.propTypes = {
  meals: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  date: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DailyMealsTable;
