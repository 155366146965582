import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from 'react-simple-maps';
import { Card, CardBody, Spinner } from 'reactstrap';
import { scaleLinear } from 'd3-scale';
import tooltip from 'wsdm-tooltip';

import { getUsersByDistrict } from '../../services/adminService';
import styles from './GeographicStatsMap.module.css';

const GeographicStatsMap = () => {
  const [mapData, setMapData] = useState();
  const [loadingMap, SetLoadingMap] = useState(true);
  const [mapJson, SetMapJson] = useState();
  const tip = tooltip();
  tip.create({
    styles: {
      padding: 0,
    },
  });

  if (!mapData) {
    getUsersByDistrict().then(body => {
      const { data } = body;
      const parsedData = {};
      data.forEach(item => {
        parsedData[item.district] = item.users_number;
      });

      const maxUsersPerDistrict = data.reduce((max, item) =>
        max > item.users_number ? max : item.users_number,
      );
      setMapData({
        districts: parsedData,
        colorScale: scaleLinear()
          .domain([0, maxUsersPerDistrict])
          .range(['#ffedea', '#ff5233']),
        maxUsersPerDistrict,
      });
    });
  }

  const handleMouseMove = ({ districtName, usersNumber, evt }) => {
    tip.show(`
      <div class="tooltip-inner">
        ${districtName}: ${usersNumber}
      </div>
    `);
    tip.position({ pageX: evt.pageX, pageY: evt.pageY });
  };

  const handleMouseLeave = () => {
    tip.hide();
  };

  useEffect(() => {
    SetLoadingMap(true);
    const fetchMap = async name => {
      const map = await axios({ method: 'get', url: `/api/district/${name}` });
      SetMapJson(JSON.parse(map.data));
      SetLoadingMap(false);
    };
    fetchMap('portugal');
  }, []);

  return (
    <Card>
      <CardBody className="py-2">
        {mapData && (
          <>
            <ComposableMap>
              <ZoomableGroup disablePanning zoom={35} center={[-7.9, 39.5]}>
                {loadingMap ? (
                  <Spinner />
                ) : (
                  <Geographies geography={mapJson}>
                    {({ geographies }) =>
                      geographies.map(geo => {
                        const districtName = geo.properties.NAME_1;
                        const usersNumber =
                          mapData.districts[districtName] || 0;
                        return (
                          <Geography
                            key={geo.rsmKey}
                            geography={geo}
                            fill={mapData.colorScale(usersNumber)}
                            onMouseMove={evt =>
                              handleMouseMove({
                                districtName,
                                usersNumber,
                                evt,
                              })
                            }
                            onMouseLeave={handleMouseLeave}
                          />
                        );
                      })
                    }
                  </Geographies>
                )}
              </ZoomableGroup>
            </ComposableMap>
            <div className="ml-1">
              <h5>Número de utilizadores</h5>
              <ul className={styles['legend-labels']}>
                <li>
                  <span style={{ background: mapData.colorScale(0) }} />0
                </li>
                <li>
                  <span
                    style={{
                      background: mapData.colorScale(
                        mapData.maxUsersPerDistrict * 0.25,
                      ),
                    }}
                  />
                  {Math.floor(mapData.maxUsersPerDistrict * 0.25)}
                </li>
                <li>
                  <span
                    style={{
                      background: mapData.colorScale(
                        mapData.maxUsersPerDistrict * 0.5,
                      ),
                    }}
                  />
                  {Math.floor(mapData.maxUsersPerDistrict * 0.5)}
                </li>
                <li>
                  <span
                    style={{
                      background: mapData.colorScale(
                        mapData.maxUsersPerDistrict * 0.75,
                      ),
                    }}
                  />
                  {Math.floor(mapData.maxUsersPerDistrict * 0.75)}
                </li>
                <li>
                  <span
                    style={{
                      background: mapData.colorScale(
                        mapData.maxUsersPerDistrict,
                      ),
                    }}
                  />
                  {mapData.maxUsersPerDistrict}
                </li>
              </ul>
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default GeographicStatsMap;
