import React from 'react';
import Layout from '../components_lgp/layout/Layout';
import PoliticaAlimentarTable from '../components_lgp/PoliticaAlimentar/PoliticaAlimentarTable';
import Page from '../components/common/Page';

const links = [
  {
    name: 'Consultar',
    url: '/Consultar',
  },
  {
    name: 'Política Alimentar',
  },
];

const PoliticaAlimentar = () => (
  <Page title="Política Alimentar">
    <Layout breadcrumb_links={links}>
      <PoliticaAlimentarTable />
    </Layout>
  </Page>
);

export default PoliticaAlimentar;
