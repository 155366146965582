import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import listMealsStyles from './listMeals.module.css';
import WeekToggler from './WeekToggler';
import DaysInWeek from './DaysInWeek';
import ExportPlanButton from './ExportPlanButton';

const Week = ({ range, date, setDate }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div style={{ borderBottom: '2px solid #4c6d4f', width: '100%' }}>
      <div className={listMealsStyles.week}>
        <span>
          {range.start.format('DD/MM')} - {range.end.format('DD/MM')}
        </span>
        <WeekToggler
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          id={range.start.format('DD/MM')}
        />
      </div>
      <Collapse isOpen={isOpen}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0.5rem',
          }}
        >
          <DaysInWeek week={range} setDate={setDate} date={date} />
          <ExportPlanButton date={range.start.toDate()} className="mt-1" />
        </div>
      </Collapse>
    </div>
  );
};

Week.propTypes = {
  range: PropTypes.objectOf(PropTypes.any).isRequired,
  setDate: PropTypes.func.isRequired,
  date: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Week;
