import store from './store';
import { checkJWT } from './services/authService';
import { authTypes } from './actions/types';

export default () => {
  const token = localStorage.getItem('spare_token');
  if (token) {
    checkJWT(token).catch(e => {
      if (e.response.status === 403) {
        localStorage.setItem('spare_token', '');
        store.dispatch({ type: authTypes.LOGOUT });
      }
    });
  }
};
