import React, { useEffect, useReducer } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import {
  fetchMealComponents,
  fetchMealRecommendations,
} from '../../services/mealsService';
import { fetchMonthView } from '../../services/utilsService';
import { fetchOrganizationDailyMeals } from '../../services/organizationsService';

const moment = require('moment');

export const MealsContext = React.createContext({});
export const MealsContextProvider = ({ children }) => {
  /** Keep track of the number of pending requests (sort of semaphore/mutex).
   * This is used to report to the user that all changes were saved
   */
  const [pendingRequests, pendingRequestsDispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'INC':
          return state + 1;
        case 'DEC':
          return state - 1;
        default:
          throw new Error();
      }
    },
    0,
  );
  /** hook for the selected organization by the user */
  const [selectedOrganization, setSelectedOrganization] = React.useState(null);
  /** hook for the selected date by the user */
  const [selectedDate, setSelectedDate] = React.useState(null);
  /** hook for the selected age range by the user */
  // eslint-disable-next-line no-unused-vars
  const [selectedAgeRange, setSelectedAgeRange] = React.useState(null);
  /** hook for the selected meal type */
  const [selectedMealType, setSelectedMealType] = React.useState(null);
  /** hook for the meal recommendations */
  const [mealRecommendations, setMealRecommendations] = React.useState(null);
  /**
   * Hook for meals components.
   * For example, a breakfast is composed of a drink, cereals, etc.
   * Each of these components will have a list of candidate technical sheets
   * owned by the authenticated user
   */
  const [mealComponents, setMealComponents] = React.useState([]);
  /** hook for month calendar information */
  const [monthCalendarInfo, setMonthCalendarInfo] = React.useState([]);
  /** hook for organization meals in a given pair <month,year> */
  const [organizationDailyMeals, setOrganizationDailyMeals] = React.useState(
    {},
  );
  /** Hook for API calls loading state */
  const [isLoading, setIsLoading] = React.useState({
    mealComponents: true,
    monthCalendar: true,
    organizationDailyMeals: true,
    mealRecommendations: true,
  });
  /**
   * Update one the loading state of 'setIsLoading' hook
   * @param {String} propName - The property name in {@link isLoading}
   * @param {Boolean} newState - True if the it's loading, false otherwise
   */
  const changeLoadingState = (propName, newState) => {
    setIsLoading(prevState => {
      return {
        ...prevState,
        [propName]: newState,
      };
    });
  };
  /**
   * Auxiliar function that tells if all requests are completed or not
   * @see {@link isLoading}
   */
  const isLoaded = () => {
    return Object.values(isLoading).reduce((acc, cur) => acc && !cur, true);
  };
  /**
   * The following useEffect executes once, after the component is mounted and
   * it's used to perform API calls that are independent of user input
   */
  useEffect(() => {
    if (selectedMealType == null) return () => {};
    changeLoadingState('mealComponents', true);
    const source = axios.CancelToken.source();
    fetchMealComponents(source.token, selectedMealType.code).then(
      ({ data }) => {
        setMealComponents(data);
        changeLoadingState('mealComponents', false);
      },
    );
    return () => {
      if (source) source.cancel();
    };
  }, [selectedMealType]);
  /**
   * Fetch the selected calendar information for the selected month/year
   */
  useEffect(() => {
    if (selectedDate == null) return;
    changeLoadingState('monthCalendar', true);
    const date = moment(selectedDate);
    fetchMonthView(date.year(), date.month() + 1).then(({ data }) => {
      setMonthCalendarInfo(data);
      changeLoadingState('monthCalendar', false);
    });
  }, [selectedDate]);
  /**
   * Fetch all meals for the selected organization and month/year
   */
  useEffect(() => {
    if (
      selectedDate == null ||
      selectedOrganization == null ||
      selectedMealType == null
    )
      return () => {};
    changeLoadingState('organizationDailyMeals', true);
    const date = moment(selectedDate);
    const source = axios.CancelToken.source();
    fetchOrganizationDailyMeals(
      source.token,
      selectedOrganization.id,
      date.year(),
      date.month() + 1,
      selectedMealType.code,
    ).then(({ data }) => {
      setOrganizationDailyMeals(data);
      changeLoadingState('organizationDailyMeals', false);
    });
    return () => {
      if (source) source.cancel();
    };
  }, [selectedDate, selectedOrganization, selectedMealType]);

  useEffect(() => {
    if (selectedMealType == null || selectedAgeRange == null) return () => {};
    const separatorIndex =
    selectedAgeRange.indexOf('-') !== -1 ? selectedAgeRange.indexOf('-') : selectedAgeRange.indexOf('+');
    const age = separatorIndex === selectedAgeRange.length - 1 ? parseInt(selectedAgeRange) : selectedAgeRange.substr(0, separatorIndex);
    changeLoadingState('mealRecommendations', true);
    const source = axios.CancelToken.source();
    fetchMealRecommendations(source.token, selectedMealType.name, age).then(
      ({ data }) => {
        if (data.length === 0) return;
        const { recommendedValues } = data[0];
        setMealRecommendations(recommendedValues);
        changeLoadingState('mealRecommendations', false);
      },
    );
    return () => {
      if (source) source.cancel();
    };
  }, [selectedMealType, selectedAgeRange]);

  return (
    <MealsContext.Provider
      value={{
        userInput: {
          setSelectedOrganization,
          selectedOrganization,
          setSelectedDate,
          selectedDate,
          setSelectedAgeRange,
          selectedAgeRange,
          setSelectedMealType,
          selectedMealType,
        },
        organizationMeals: organizationDailyMeals,
        mealRecommendations,
        mealComponents,
        monthCalendarInfo,
        isLoaded,
        pendingRequests,
        pendingRequestsDispatch,
      }}
    >
      
      {children}
    </MealsContext.Provider>
  );
};

MealsContextProvider.propTypes = {
  children: propTypes.element.isRequired,
};

export default MealsContextProvider;
