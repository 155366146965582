import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Layout from '../components/layout/Layout';
import Page from '../components/common/Page';

const NotFound = ({ history }) => (
  <Page title="Não Encontrado">
    <Layout>
      <p>404 Not Found</p>
      <Button onClick={() => history.goBack()}>Voltar</Button>
    </Layout>
  </Page>
);

NotFound.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
};

export default NotFound;
