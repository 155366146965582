import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Button,
  Row,
  CardHeader,
} from 'reactstrap';

import LoadingAnimation from './LoadingAnimation';
import { submitForgotPassword } from '../../services/authService';

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitMsg, setSubmitMsg] = useState(<span />);

  const submitForm = e => {
    e.preventDefault();
    setSubmitting(true);
    setSubmitMsg(<span />);

    submitForgotPassword({ email })
      .then(body => {
        setSubmitMsg(
          <Alert color="success" fade={false}>
            {body.data.msg}
          </Alert>,
        );
        setSubmitting(false);
        setEmail('');
      })
      .catch(error => {
        if (error.response.status === 404)
          setSubmitMsg(
            <Alert color="danger" fade={false}>
              Não existe uma conta com esse endereço de email.
            </Alert>,
          );
        else if (error.response.status === 429)
          setSubmitMsg(
            <Alert color="warning" fade={false}>
              Por favor aguarde um pouco antes de fazer outro pedido.
            </Alert>,
          );
        else
          setSubmitMsg(
            <Alert color="danger" fade={false}>
              Ocorreu algum erro.
            </Alert>,
          );
        setSubmitting(false);
      });
  };

  return (
    <Container>
      <Row className="mt-3 mt-lg-4 pt-xl-5">
        <Col xs={{ size: 12 }} lg={{ size: 9 }} xl={{ size: 6, offset: 3 }}>
          <Card>
            <CardHeader>
              <h2 className="my-1">Recuperar Conta</h2>
            </CardHeader>
            <div className="position-relative">
              {isSubmitting && <LoadingAnimation />}
              <CardBody>
                <Form className="form" onSubmit={submitForm}>
                  <Col>{submitMsg}</Col>
                  <Col>
                    <FormGroup>
                      <Label for="email">Endereço de E-mail</Label>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder=""
                        required
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <div className="small text-right">
                      Ainda não tem conta?{' '}
                      <Link to="/registar">Registe-se</Link>
                    </div>
                    <Button color="success" className="px-4">
                      Enviar
                    </Button>
                  </Col>
                </Form>
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPasswordForm;
