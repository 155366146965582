import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useStyles } from '../styles';
import { Icon } from '../../common';
import { MealsContext } from '../MealsContextProvider';
import PlanningWeekSection from './PlanningWeekSection';

const PlanningSection = () => {
  const classes = useStyles();
  const { mealComponents, monthCalendarInfo, userInput } = useContext(
    MealsContext,
  );
  const mealType = userInput.selectedMealType.code;

  return (
    <Grid container>
      <Grid container item>
        <Typography variant="h6" className={classes.title}>
          Elaboração de ementa
        </Typography>
        
      </Grid>
      {monthCalendarInfo.map(({ weekStartDate, weekEndDate }) => (
        <PlanningWeekSection
          key={`weekSection_${weekStartDate}-${weekEndDate}`}
          className={classes.planningTable}
          weekStartDate={weekStartDate}
          weekEndDate={weekEndDate}
        />
      ))}
    </Grid>
  );
};

export default PlanningSection;
