import React from 'react';
import Layout from '../components_lgp/layout/Layout';
import Page from '../components/common/Page';
import PlanearOptions from '../components_lgp/ChooseModule/PlanearOptions';

const Planear = () => (
  <Page title="Planear">
    <Layout>
      <PlanearOptions />
    </Layout>
  </Page>
);

export default Planear;
