import React from 'react';
import ListReport from '../components/Reports/ListReport';
import Page from '../components/common/Page';

const ListReportsQualidadeEmentasPage = () => (
  <Page title="Relatórios">
    <ListReport type="Qualidade de Ementas - saudáveis e sustentáveis" />
  </Page>
);

export default ListReportsQualidadeEmentasPage;
