import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import styles from './OptionList.module.css';

const OptionList = ({ buttons, links }) => (
  <div className="optionList">
    <ul className={styles.list}>
      {links.map(({ to, name }) => (
        <li key={name}>
          <NavLink className={styles.buttonStyle} to={to}>
            {name}
          </NavLink>
        </li>
      ))}
      {buttons.map(({ name, onClick, isLoading }) => (
        <li key={name}>
          <button
            className={styles.buttonStyle}
            type="button"
            onClick={onClick}
          >
            {isLoading ? (
              <>
                <Spinner className="mr-1" size="sm" color="light" />
                <span>A processar</span>
              </>
            ) : (
              <span>{name}</span>
            )}
          </button>
        </li>
      ))}
    </ul>
  </div>
);

OptionList.defaultProps = {
  links: null,
};

OptionList.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ).isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

export default OptionList;
