import React from 'react';
import { FaCog } from 'react-icons/fa';
import styles from './LoadingAnimation.module.css';

const LoadingAnimation = () => {
  return (
    <div className={styles.loadingAnimation}>
      <FaCog size={35} className={styles.iconSpin} />
    </div>
  );
};

export default LoadingAnimation;
