import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/* eslint-disable */
const GuestRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !isLoggedIn ? <Component {...props} /> : <Redirect to="/" />
    }
  />
);

GuestRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  component: PropTypes.elementType.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  isLoggedIn: auth.loggedIn,
});

export default connect(mapStateToProps)(GuestRoute);
