import React, { useState } from 'react';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles({
  submitSection: {
    display: 'flex',
    marginTop: '1.5rem',
  },
  textField: {
    flexGrow: 1,
  },
});

const OrganizationSubmit = props => {
  const classes = useStyles();
  const { onSubmitClick } = props;
  const [name, setName] = useState('');
  const submitClick = () => {
    onSubmitClick(name);
    setName('');
  };
  return (
    <section className={classes.submitSection}>
      <TextField
        placeholder="Nome da nova organização"
        onChange={event => setName(event.target.value)}
        className={classes.textField}
        value={name}
        onKeyPress={event => event.key === 'Enter' && submitClick()}
      />
      <IconButton onClick={() => submitClick()}>
        <SendIcon size="small" edge="end" />
      </IconButton>
    </section>
  );
};

OrganizationSubmit.propTypes = {
  onSubmitClick: propTypes.func.isRequired,
};

export default OrganizationSubmit;
