import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ConsultarOptions.module.css';

const ConsultarOptions = () => (
  <div>
    <div className={styles.titulo}>
      <img
        className={styles.individualModule1}
        src={`${process.env.PUBLIC_URL}/activeConsult4.png`}
        alt=""
      />
      <h1 className={styles.titleConsultar}>Consultar</h1>
    </div>

    <div className={styles.line}>
      <Link to="/consultar/politica-alimentar" className={styles.link}>
        {' '}
        Política Alimentar
        <img
          className={styles.iconeAtivo}
          src={`${process.env.PUBLIC_URL}/botaoAtivo7.png`}
          alt=""
        />
      </Link>
    </div>

    <div className={styles.line}>
      <Link to="/consultar/recomendacoes-alimentares" className={styles.link}>
        {' '}
        Recomendações Alimentares
        <img
          className={styles.iconeAtivo}
          src={`${process.env.PUBLIC_URL}/botaoAtivo7.png`}
          alt=""
        />
      </Link>
    </div>

    <div className={styles.line}>
      <Link to="/consultar/recomendacoes-nutricionais" className={styles.link}>
        {' '}
        Recomendações Nutricionais
        <img
          className={styles.iconeAtivo}
          src={`${process.env.PUBLIC_URL}/botaoAtivo7.png`}
          alt=""
        />
      </Link>
    </div>

    <div className={styles.line}>
      <Link
        to="/consultar/consideracoes-higiossanitarias"
        className={styles.link}
      >
        {' '}
        Considerações higiossanitárias
        <img
          className={styles.iconeAtivo}
          src={`${process.env.PUBLIC_URL}/botaoAtivo7.png`}
          alt=""
        />
      </Link>
    </div>

    <Link to="/">
      <h5 className={styles.back}>&lt; voltar</h5>
    </Link>
  </div>
);

export default ConsultarOptions;
