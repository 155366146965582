import axios from 'axios';

export const submitLogin = ({ email, password }) => {
  return axios({
    method: 'post',
    url: '/api/login',
    data: { email, password },
  });
};

export const submitRegister = ({
  name,
  occupation,
  district,
  type,
  email,
  password,
  passwordConfirmation,
}) => {
  return axios({
    method: 'post',
    url: '/api/signup',
    data: {
      name,
      occupation,
      district,
      type,
      email,
      password,
      password_confirmation: passwordConfirmation,
    },
  });
};

export const submitForgotPassword = ({ email }) => {
  return axios({
    method: 'post',
    url: '/api/forgot_password',
    data: { email },
  });
};

export const submitResetPassword = ({
  token,
  password,
  passwordConfirmation,
}) => {
  return axios({
    method: 'post',
    url: `/api/reset_password/${token}`,
    data: { password, password_confirmation: passwordConfirmation },
  });
};

export const checkJWT = ({ token }) => {
  return axios(
    {
      method: 'get',
      url: `/api/jwt/validate`,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};
