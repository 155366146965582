import React, { useEffect, useContext } from 'react';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MealsContext } from '../MealsContextProvider';

const DatePicker = () => {
  // hooks for selected organization
  const {
    userInput: { setSelectedDate, selectedDate },
  } = useContext(MealsContext);
  // run once to set initial value
  useEffect(() => {
    const date = selectedDate ?? new Date();
    setSelectedDate(date);
  }, [setSelectedDate, selectedDate]);
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        value={selectedDate}
        onChange={date => setSelectedDate(date)}
        variant="inline"
        openTo="year"
        views={['year', 'month']}
        label="Mês da ementa"
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
