import React from 'react';
import Layout from '../components_lgp/layout/Layout';
import RecNutricionais from '../components_lgp/RecNutricionais/RecNutricionais';
import Page from '../components/common/Page';

const links = [
  {
    name: 'Consultar',
    url: '/Consultar',
  },
  {
    name: 'Recomendações Nutricionais',
  },
];

const RecomendacoesNutricionais = () => (
  <Page title="Recomendações Nutricionais">
    <Layout breadcrumb_links={links}>
      <RecNutricionais />
    </Layout>
  </Page>
);

export default RecomendacoesNutricionais;
