import React, { useState } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

import ListItem from './ListItem';
import styles from './List.module.css';

const List = ({ title, items, component, printItemTitle }) => {
  const [activeItem, setActiveItem] = useState(-1);

  const toggleItem = id =>
    id === activeItem ? setActiveItem(-1) : setActiveItem(id);

  return (
    <Card>
      <CardHeader className={styles.cardHeader}>
        <h2>{title}</h2>
      </CardHeader>
      <CardBody>
        <ul className={styles.list}>
          {items.map(item => (
            <ListItem
              key={item.id}
              item={item}
              activeItem={activeItem}
              toggleItem={toggleItem}
              component={component}
              printItemTitle={printItemTitle}
            />
          ))}
        </ul>
      </CardBody>
    </Card>
  );
};

List.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
  ).isRequired,
  component: PropTypes.func.isRequired,
  printItemTitle: PropTypes.func.isRequired,
};

export default List;
