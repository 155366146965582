import React from 'react';
import LayoutWithImg from '../components_lgp/layout/LayoutWithImg';
import FaqSection from '../components_lgp/Faq/FaqSection';
import Page from '../components/common/Page';

const breadcrumb = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'Perguntas Frequentes',
    url: '',
  },
];

const Faq = () => (
  <Page title="Perguntas Frequentes">
    <LayoutWithImg
      breadcrumb_links={breadcrumb}
      imgSrc="faq-banner.jpg"
      imgAlt="Frequently Asked Questions"
    >
      <FaqSection />
    </LayoutWithImg>
  </Page>
);

export default Faq;
