import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const IntervalSelect = ({ interval, setInterval, options }) => (
  <div className="mb-2">
    {options.map(({ value, name }, i) => (
      <Button
        color={interval === value ? 'success' : 'secondary'}
        type="button"
        onClick={() => setInterval(value)}
        key={value}
        className={i < options.length - 1 ? 'mr-2' : ''}
      >
        {name}
      </Button>
    ))}
  </div>
);

IntervalSelect.propTypes = {
  interval: PropTypes.string.isRequired,
  setInterval: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
};

export default IntervalSelect;
