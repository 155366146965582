import React from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './TechnicalSheetListItemCollapse.module.css';

const TechnicalSheetListItemCollapse = ({ item: technicalSheet }) => {
  const printRow = component => (
    <tr key={component.id}>
      <td>
        <strong>{component.name}</strong>
      </td>
      <td>{`${component.pivot.amount} ${
        component.pivot.unit ? component.pivot.unit : ''
      }`}</td>
    </tr>
  );

  return (
    <>
      <Table className={styles.table}>
        <tbody>
          {technicalSheet.ingredients.map(printRow)}
          {technicalSheet.components.map(printRow)}
        </tbody>
      </Table>
      {!technicalSheet.deleted_at && (
        <button className={styles.buttonStyle} type="button">
          <Link
            className={styles.buttonLinkStyle}
            to={`/planear/fichastecnicas/${technicalSheet.id}`}
          >
            Consultar
          </Link>
        </button>
      )}
    </>
  );
};

TechnicalSheetListItemCollapse.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    ingredients: PropTypes.arrayOf(
      PropTypes.shape({
        created_at: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        pivot: PropTypes.shape({
          ingredient_id: PropTypes.number.isRequired,
          technical_sheet_id: PropTypes.number.isRequired,
        }).isRequired,
        updated_at: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    components: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        pivot: PropTypes.shape({
          amount: PropTypes.string.isRequired,
          quantity: PropTypes.string,
        }).isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

export default TechnicalSheetListItemCollapse;
