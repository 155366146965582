import PropTypes from 'prop-types';
import React, { useState } from 'react';
import FaqItem from './FaqItem';

/**
 *
 * @param {Object} props
 * @param {Object[]} props.list
 * @param {Number} props.list.id
 * @param {String} props.list.question
 * @param {String} props.list.answer
 */
const FaqListItem = props => {
  /* Deconstruct props */
  const { list } = props;

  /* Create hook to manage which FaqItem is expanded */
  const [expanded, setExpanded] = useState('');

  /* Handler to be called by FaqItem whenever their expand state changes
   * If a FaqItem expands, the hook 'expanded' is updated with the FaqItem id
   * The other FaqItem's as a result will collapse automatically
   */
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {list.map(({ id, question, answer }) => (
        <FaqItem
          key={id}
          id={id}
          question={question}
          answer={answer}
          expandedPanel={expanded}
          expandHandler={handleChange}
        />
      ))}
    </>
  );
};

FaqListItem.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default FaqListItem;
