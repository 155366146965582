import React, { useEffect, useCallback } from 'react';
import {
  CircularProgress,
  Grid,
  FormGroup,
  FormControlLabel,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { MealPicker, AgeRangePicker } from './RecomPickers';
import FullPage from '../MealPlanning/pageLayout';
import { setNotification } from '../../actions/notificationActions';
import { updateRecommendation } from '../../services/adminService';
import { fetchAllMealRecommendations } from '../../services/mealsService';

const useStyles = makeStyles(() => ({
  form: {
    flexDirection: 'row',
    paddingRight: '1em',
    margin: '0.5em',
    borderBottom: '1px solid black',
    '& span': {
      margin: 'auto 0',
    },
  },
  pickers: {
    marginBottom: '2em',
    '& > div': {
      marginTop: '0.5em',
    },
  },
  nutritionalValues: {
    fontWeight: 'bold',
  },
  inputs: {
    marginLeft: 'auto',
    '& span': {
      marginRight: '1em',
      marginBottom: 0,
    },
    '& label:first-child': {
      marginRight: '0.5em',
    },
  },
}));

const NutritionalRecomForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loadingRecom, setLoadingRecom] = React.useState(true);
  const [ageRange, setAgeRange] = React.useState(null);
  const [mealType, setMealType] = React.useState(null);
  const [recommendations, setRecommendations] = React.useState(null);
  const [selectedRecom, setSelectedRecom] = React.useState(null);

  const getSelectedAge = useCallback(() => {
    const separatorIndex = ageRange.indexOf('-') !== -1 ? ageRange.indexOf('-') : ageRange.indexOf('+');
    if (separatorIndex === ageRange.length - 1) {

        return parseInt(ageRange);
    } else {
        return +ageRange.substr(0, separatorIndex);
    }
}, [ageRange]);


  const onSubmit = async event => {
    event.preventDefault();
    try {
      await updateRecommendation(getSelectedAge(), mealType, selectedRecom);
      dispatch(
        setNotification({
          message: 'Recomendação nutricional editada com sucesso!',
          type: 'success',
        }),
      );
    } catch (e) {
      dispatch(
        setNotification({
          message:
            'Ocorreu um erro ao tentar editar a recomendação nutricional!',
          type: 'error',
        }),
      );
    }
  };

  useEffect(() => {
    const fetchRecom = async () => {
      const { data } = await fetchAllMealRecommendations();
      setRecommendations(data);
    };
    fetchRecom();
  }, [setLoadingRecom, setRecommendations]);

  useEffect(() => {
    if (!recommendations || !ageRange || !mealType) return;
    const age = getSelectedAge();
    const recom = recommendations.filter(
      r => r.meal === mealType && r.lower_age === age,
    );
    if (recom.length > 0) setSelectedRecom(recom[0].recommendedValues);
    setLoadingRecom(false);
  }, [recommendations, ageRange, mealType, setSelectedRecom, getSelectedAge]);

  const getForm = () => {
    const inputTypes = { Mínimo: 'minValue', Máximo: 'maxValue' };
    const orderedRecom = selectedRecom.sort((r1, r2) =>
      r1.name.localeCompare(r2.name),
    );
    return orderedRecom.map(rv => (
      <FormGroup classes={{ root: classes.form }} key={`form-${rv.name}`}>
        <span>{rv.name !== 'Energia [kcal]' ? `${rv.name} [g]` : rv.name}</span>
        <div className={classes.inputs}>
          {Object.entries(inputTypes).map(([type, code]) => (
            <FormControlLabel
              key={`${rv.name}-${code}`}
              label={type}
              labelPlacement="start"
              control={
                <TextField
                  error={rv[code] !== null && rv[code] <= 0}
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  value={rv[code]}
                  color="secondary"
                  onChange={e => {
                    setSelectedRecom(prevState => {
                      const entryIndex = prevState.findIndex(
                        entry => entry.name === rv.name,
                      );
                      const newState = [...prevState];
                      newState[entryIndex][code] = e.target.value;
                      return newState;
                    });
                  }}
                  required
                />
              }
            />
          ))}
        </div>
      </FormGroup>
    ));
  };

  const render = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid item className={classes.pickers}>
            <MealPicker mealType={mealType} setMealType={setMealType} />
            <AgeRangePicker ageRange={ageRange} setAgeRange={setAgeRange} />
          </Grid>
          {loadingRecom ? (
            <div
              style={{
                display: 'flex',
                marginTop: '2em',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Grid item>
              <Typography variant="h6" className={classes.nutritionalValues}>
                Valores nutricionais
              </Typography>
              {getForm()}
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <FullPage
      main={render()}
      links={[
        {
          label: 'Submeter',
          onClick: onSubmit,
        },
      ]}
    />
  );
};

export default NutritionalRecomForm;
