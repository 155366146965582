import React from 'react';

import Page from '../components/common/Page';

import Layout from '../components/layout/Layout';
import SignInForm from '../components/Authentication/SignInForm';

const SignIn = () => {
  return (
    <Page title="Iniciar Sessão">
      <Layout>
        <SignInForm />
      </Layout>
    </Page>
  );
};

export default SignIn;
