import React from 'react';
import PropTypes from 'prop-types';
import { FaAngleUp } from 'react-icons/fa';

import listMealsStyles from './listMeals.module.css';

const WeekToggler = ({ id, isOpen, setIsOpen }) => (
  <div className={listMealsStyles.toggler}>
    <label htmlFor={id}>
      <FaAngleUp size="1.5rem" />
      <input
        type="checkbox"
        checked={isOpen}
        onChange={e => setIsOpen(e.target.checked)}
        id={id}
      />
    </label>
  </div>
);

WeekToggler.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default WeekToggler;
