import React from 'react';
import Layout from '../components/layout/Layout';
import ForgotPasswordForm from '../components/Authentication/ForgotPasswordForm';
import Page from '../components/common/Page';

const ForgotPassword = () => {
  return (
    <Page title="Recuperar Conta">
      <Layout>
        <ForgotPasswordForm />
      </Layout>
    </Page>
  );
};

export default ForgotPassword;
