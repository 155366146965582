import React from 'react';
import propTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';
import { useStyles } from '../styles';
import { ConditionalWrap } from '../../common';

const moment = require('moment');

/**
 * Represents a cell in the meal planning grid. It has an input selector
 * for the user select a technical sheet for a meal component
 * (e.g. a breakfast has drinks, cereals, ...) in a given date
 */
const PlanningDayMealComponent = props => {
  // Classes for styling
  const classes = useStyles();
  // Props
  const {
    date,
    mealComponentName,
    technicalSheetsOptions,
    selectedTechnicalSheet,
    setSelectedTechnicalSheet,
  } = props;

  return (
    <Grid item xs>
      <Autocomplete
        id={`ficha_tecnica_${date}_${mealComponentName}`}
        className={classes.autoComplete}
        options={technicalSheetsOptions}
        value={selectedTechnicalSheet}
        limitTags={1}
        size="small"
        getOptionLabel={option => (option ? option.name : '')}
        getOptionSelected={(option1, option2) => option1.id === option2.id}
        onChange={(event, newValue) => {
          setSelectedTechnicalSheet(mealComponentName, newValue);
        }}
        renderInput={params => (
          <ConditionalWrap
            condition={
              (selectedTechnicalSheet && selectedTechnicalSheet.name) != null
            }
            wrap={children => (
              <Tooltip title={selectedTechnicalSheet.name}>
                <div>{children}</div>
              </Tooltip>
            )}
          >
            <TextField {...params} variant="standard" />
          </ConditionalWrap>
        )}
      />
    </Grid>
  );
};

PlanningDayMealComponent.propTypes = {
  /** The date for this meal plan date */
  date: propTypes.oneOfType([propTypes.string, propTypes.instanceOf(moment)])
    .isRequired,
  /**
   * The meal components name (e.g. for breakfast planning you have drinks,
   * cereals, ...)
   */
  mealComponentName: propTypes.string.isRequired,
  /**
   * List of possible technical sheets owned by the user for this meal
   * component
   * Can be an empty array if the user doesn't own technical sheets
   * for this meal component
   */
  technicalSheetsOptions: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number.isRequired,
      name: propTypes.string.isRequired,
    }),
  ).isRequired,
  /**
   * A technical sheet assigned for this meal component
   * Can be null if no technical sheets are assigned
   */
  selectedTechnicalSheet: propTypes.shape({
    id: propTypes.number.isRequired,
    name: propTypes.string.isRequired,
  }),
  /**
   * A hook setter to update assigned technical sheets on user input change
   * The function expects two arguments:
   * -> The meal component name
   * -> A technical sheet object selected by the user or null of the selection
   * is empty. It's an element of {@link technicalSheetsOptions}
   */
  setSelectedTechnicalSheet: propTypes.func.isRequired,
};

PlanningDayMealComponent.defaultProps = {
  selectedTechnicalSheet: null,
};

export default PlanningDayMealComponent;
