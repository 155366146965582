import React from 'react';
import Layout from '../components_lgp/layout/Layout';
import Page from '../components/common/Page';
import AvaliarOptions from '../components_lgp/ChooseModule/AvaliarOptions';

const Avaliar = () => (
  <Page title="Avaliar">
    <Layout>
      <AvaliarOptions />
    </Layout>
  </Page>
);

export default Avaliar;
