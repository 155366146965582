import React from 'react';
import Layout from '../components_lgp/layout/Layout';
import List from '../components_lgp/TechnicalSheets/ListTechnicalSheets';
import Page from '../components/common/Page';
import FullPage from '../components_lgp/MealPlanning/pageLayout';
import { links, info } from '../components_lgp/TechnicalSheets/auxBar.json';

const ListTechnicalSheets = () => (
  <Page title="Fichas Técnicas">
    <Layout
      breadcrumb_links={[
        {
          name: 'Planear',
          url: '/planear',
        },
        {
          name: 'Gerir Fichas Técnicas',
          url: '/planear/fichastecnicas',
        },
      ]}
    >
      <FullPage main={<List />} links={links} info={info} />
    </Layout>
  </Page>
);

export default ListTechnicalSheets;
