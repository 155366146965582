import { sidebarTypes } from '../actions/types';

const sidebarReducer = (
  state = window.matchMedia('(max-width: 768px)').matches,
  action,
) => {
  switch (action.type) {
    case sidebarTypes.OPEN_SIDEBAR:
      return false;
    case sidebarTypes.COLLAPSE_SIDEBAR:
      return true;
    default:
      return state;
  }
};

export default sidebarReducer;
