import React from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './IngredientListItem.module.css';

const IngredientListItem = ({ id, name }) => {
  return (
    <div className={styles.ingredientListItemWrapper}>
      <Link
        to={`/admin/ingredientes/${id}/editar`}
        className={classnames(styles.linkWrapper, styles.title)}
      >
        {name}
      </Link>
      <Link
        to={`/admin/ingredientes/${id}/editar`}
        className={styles.linkWrapper}
      >
        <div className={styles.linkContentWrapper}>
          <EditIcon />
          <p>Editar</p>
        </div>
      </Link>
    </div>
  );
};

IngredientListItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default IngredientListItem;
