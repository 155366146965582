import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';

import listMealsStyles from './listMeals.module.css';

const DaysInWeek = ({ week, date, setDate }) => (
  <div className={listMealsStyles.daysInWeek}>
    {Array.from(week.by('days')).map(day => (
      <Button
        key={day.format('YYYY-MM-DD')}
        type="button"
        color={
          moment(date).format('YYYY-MM-DD') === day.format('YYYY-MM-DD')
            ? 'success'
            : 'dark'
        }
        onClick={() => setDate(day, false)}
        className="mr-1 mb-1"
      >
        <div>{day.format('dddd')}</div> <div>{day.format('D')}</div>
      </Button>
    ))}
  </div>
);

DaysInWeek.propTypes = {
  week: PropTypes.objectOf(PropTypes.any).isRequired,
  setDate: PropTypes.func.isRequired,
  date: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DaysInWeek;
