import React, { useState } from 'react';

import { useLocation, useParams } from 'react-router';
import Layout from '../../components_lgp/layout/Layout';
import Page from '../../components/common/Page';
import IngredientForm from '../../components_lgp/Ingredients/IngredientForm';

const EditIngredient = () => {
  const { id } = useParams();
  const { state: locationState } = useLocation();
  const name = locationState?.name;

  const [ingredientName, setIngredientName] = useState(name ?? 'Ingrediente');

  return (
    <Page title={`Editar ${ingredientName}`}>
      <Layout
        breadcrumb_links={[
          {
            name: 'Administração',
            url: '/admin',
          },
          {
            name: 'Gerir Ingredientes',
            url: '/admin/ingredientes',
          },
          {
            name: 'Editar Ingrediente',
            url: `'/admin/ingredientes/${id}/editar`,
          },
        ]}
      >
        <IngredientForm
          id={id}
          setIngredientName={setIngredientName}
          isEditing
        />
      </Layout>
    </Page>
  );
};

export default EditIngredient;
