import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';

import { Table, TextBody, NutritionalBody } from './View';
import { fetchTechnicalSheet } from '../../services/technicalSheetsService';
import labels from './labels.json';

import { technicalSheetTheme } from './styles';
import classes from './styles.module.css';
import { Icon } from '../common';

function ViewTechnicalSheets({ id, setTechSheetName }) {
  const [techSheet, setTechSheet] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    async function getTechnicalSheet() {
      const data = await fetchTechnicalSheet(id);
      const {
        name,
        component_type: componentType,
        ingredients,
        components,
        allergens,
        total_nutritional_values: totalNutritionalValues,
        preparation,
        total_quantity: totalWeight,
        num_people_served: portions,
      } = data.data;

      const formattedComponents = components.map(component => {
        const { component_type: cType } = component;

        return {
          ...component,
          componentType: cType,
        };
      });
      const formattedIngredients = ingredients.map(ingredient => {
        const { type, nutritional_criteria: nutritionalCriteria } = ingredient;

        const ret = {
          ...ingredient,
          componentType: type,
        };

        ret.nutritional_criteria = {};

        nutritionalCriteria.forEach(({ name: nCName, ...rest }) => {
          ret.nutritional_criteria[nCName] = rest;
        });

        return ret;
      });
      const { notes, ...prep } = preparation;

      const formattedPreparation = Object.keys(prep).map(key => ({
        label: labels[key],
        content: prep[key],
      }));

      const extraInformation = [
        {
          label: labels.notes,
          content: notes,
        },
        {
          label: labels.allergens,
          content:
            allergens.length > 0
              ? allergens.map(({ name: alName }) => alName).join(', ')
              : 'Não há registo de alergénios.',
        },
      ];

      setTechSheetName(name);
      setTechSheet({
        name,
        componentType,
        ingredients: [...formattedComponents, ...formattedIngredients],
        allergens,
        totalNutritionalValues,
        preparation: formattedPreparation,
        totalWeight,
        portions,
        extraInformation,
      });

      setIsLoading(false);
    }
    const source = axios.CancelToken.source();
    getTechnicalSheet();
    // cleanup function
    return () => {
      source.cancel();
    };
  }, [id, setTechSheetName]);

  useEffect(() => {
    if (techSheet) {
      const {
        ingredients,
        totalNutritionalValues: totalNutValues,
        totalWeight,
        portions,
      } = techSheet;
      const data = ingredients.map(
        ({
          name,
          id: ingId,
          nutritional_criteria: nutritionalCriteria,
          total_nutritional_values: totalNutritionalValues,
          component_type: componentType,
          type,
          pivot: { amount, unit, supplier_name, supplier_origin, supplier_bio, supplier_trans },
        }) => {
          let unitMultiplier = 1;
          if (unit === 'kg') unitMultiplier = 1000;
          if (unit === 'mg') unitMultiplier = 0.001;

          const ret = {
            name,
            id: ingId,
            type: type || componentType,
            amount: amount * unitMultiplier,
            supplier_name,
            supplier_origin,
            supplier_bio,
            supplier_trans
          };

          // Component is an ingredient
          if (nutritionalCriteria)
            Object.keys(nutritionalCriteria).forEach(key => {
              ret[key] = nutritionalCriteria[key].pivot.value ?? 0;
            });

          // Component is a technical sheet
          if (totalNutritionalValues)
            Object.keys(totalNutritionalValues).forEach(key => {
              ret[key] = totalNutritionalValues[key].value ?? 0;
            });

          return ret;
        },
      );

      const total = {
        name: 'Total',
        amount: '',
        id: -1,
      };

      Object.keys(totalNutValues).forEach(key => {
        total[key] = (totalNutValues[key].value * totalWeight) / 100 ?? 0;
      });

      data.push(total);

      const per100g = {
        name: 'Total (100g)',
        amount: '',
        id: -2,
      };

      Object.keys(totalNutValues).forEach(key => {
        per100g[key] = totalNutValues[key].value ?? 0;
      });

      data.push(per100g);

      const perPortion = {
        name: 'Total (por porção)',
        amount: '',
        id: -3,
      };

      Object.keys(totalNutValues).forEach(key => {
        perPortion[key] =
          (totalNutValues[key].value * totalWeight) / 100 / portions ?? 0;
      });

      data.push(perPortion);

      setTableData(data);
    }
  }, [techSheet]);

  return (
    <ThemeProvider theme={technicalSheetTheme}>
      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      )}
      {!isLoading && (
        <div className={classes.container}>
          <div className={classes.titleContainer}>
            <Icon name={techSheet.componentType} />
            <Typography variant="h5">{techSheet.name}</Typography>
          </div>
          <Table
            title="Ingredientes/Ficha Técnica"
            body={<NutritionalBody data={tableData} />}
          />
          <Table
            title="Preparação e confeção"
            body={<TextBody data={techSheet.preparation} />}
          />
          <Table
            title="Observações"
            body={<TextBody data={techSheet.extraInformation} />}
          />
          <Table
            title="Tipo de Componente"
            body={
              <TextBody
                data={[{ content: techSheet.componentType, label: '' }]}
              />
            }
          />
        </div>
      )}
    </ThemeProvider>
  );
}

ViewTechnicalSheets.propTypes = {
  id: PropTypes.string.isRequired,
  setTechSheetName: PropTypes.func.isRequired,
};

export default ViewTechnicalSheets;
