import React from 'react';
import moment from 'moment';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import Faq from './pages/Faq';
import ListTechnicalSheets from './pages/TechnicalSheetsList';
import TechnicalSheetsView from './pages/TechnicalSheetsView';
import TechnicalSheetsEdit from './pages/TechnicalSheetsEdit';
import TechnicalSheetsCreate from './pages/TechnicalSheetsCreate';
import ListReport from './pages/ListReports';
import ListReportsAlimentacaoEscolar from './pages/ListReportsAlimentacaoEscolar';
import ListReportsHigioSanidade from './pages/ListReportsHigioSanidade';
import ListReportsQualidadeEmentas from './pages/ListReportsQualidadeEmentas';
import ReportView from './pages/ReportView';
import ReportSection from './pages/ReportSection';
import NotFound from './pages/NotFound';
import MealPlanningPage from './pages/MealPlanningPage';
import ListMeals from './pages/Meals/ListMeals';
import ViewMeal from './pages/Meals/ViewMeal';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Profile from './pages/Profile';
import ProfileEdit from './pages/ProfileEdit';
import Logout from './pages/Logout';
import ConsHigioSanitarias from './pages/ConsHigioSanitarias';
import CreateIngredient from './pages/admin/CreateIngredient';
import EditIngredient from './pages/admin/EditIngredient';
import IngredientsList from './pages/IngredientsList';
import IngredientsView from './pages/IngredientsView';
import AdminDashboard from './pages/AdminDashboard';
import ChooseModule from './pages/ChooseModule';
import Consultar from './pages/Consultar';
import Admin from './pages/Admin';
import Planear from './pages/Planear';
import Avaliar from './pages/Avaliar';

import PoliticaAlimentar from './pages/PoliticaAlimentar';
import RecomAlimentar from './pages/RecomAlimentar';
import DiversityPage from './pages/DiversityPage';
import RecomendacoesNutricionais from './pages/RecomendacoesNutricionais';

import ProtectedRoute from './RouteTypes/ProtectedRoute';
import GuestRoute from './RouteTypes/GuestRoute';
import CreateMealPlan from './pages/CreateMealPlan';
import AdminRoute from './RouteTypes/AdminRoute';
import EditNutritionalRecom from './pages/admin/EditNutritionalRecom';

const AppRouter = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={HomePage} />
      <ProtectedRoute exact path="/modulos" component={ChooseModule} />
      <Route exact path="/sobre" component={AboutPage} />
      <Route path="/faq" component={Faq} />
      <Route
        path="/consultar/politica-alimentar"
        component={PoliticaAlimentar}
      />
      <Route
        path="/consultar/consideracoes-higiossanitarias"
        component={ConsHigioSanitarias}
      />
      <Route
        path="/consultar/recomendacoes-alimentares"
        component={RecomAlimentar}
      />
      <GuestRoute exact path="/login" component={SignIn} />
      <GuestRoute exact path="/registar" component={SignUp} />
      <GuestRoute exact path="/recuperar-conta" component={ForgotPassword} />
      <GuestRoute
        exact
        path="/redefinir-palavra-chave/:token"
        component={ResetPassword}
      />

      <Route
        exact
        path="/consultar/recomendacoes-nutricionais"
        component={RecomendacoesNutricionais}
      />
      <ProtectedRoute exact path="/perfil" component={Profile} />
      <ProtectedRoute exact path="/perfil/editar" component={ProfileEdit} />
      <ProtectedRoute exact path="/terminar-sessao" component={Logout} />
      <ProtectedRoute
        exact
        path="/admin/ingredientes"
        component={IngredientsList}
      />
      <AdminRoute
        path="/admin/ingredientes/:id/editar"
        component={EditIngredient}
      />
      <AdminRoute
        path="/admin/ingredientes/criar"
        component={CreateIngredient}
      />
      <AdminRoute
        path="/admin/recomendacoes-nutricionais"
        component={EditNutritionalRecom}
      />
      <AdminRoute
        exact
        path="/admin/ingredientes/:id"
        component={IngredientsView}
      />
      <ProtectedRoute
        exact
        path="/planear/fichastecnicas"
        component={ListTechnicalSheets}
      />
      <ProtectedRoute
        path="/planear/fichastecnicas/:id/editar"
        component={TechnicalSheetsEdit}
      />
      <ProtectedRoute
        path="/planear/fichastecnicas/criar"
        component={TechnicalSheetsCreate}
      />
      <ProtectedRoute
        path="/planear/fichastecnicas/:id"
        component={TechnicalSheetsView}
      />
      <ProtectedRoute path="/planear/ementas" component={MealPlanningPage} />
      <ProtectedRoute
        exact
        path="/ementas"
        component={() => (
          <Redirect
            to={`/ementas/mes/${moment().date()}/${moment().month() +
              1}/${moment().year()}`}
          />
        )}
      />
      <ProtectedRoute path="/ementa/:id" component={ViewMeal} />
      <ProtectedRoute
        path="/ementas/:interval/:day/:month/:year"
        component={ListMeals}
      />
      <ProtectedRoute path="/avaliar-diversidade" component={DiversityPage} />
      <ProtectedRoute
        path="/criar-ementa/:day/:month/:year/:type"
        component={CreateMealPlan}
      />
      <ProtectedRoute exact path="/avaliar/:id" component={ReportView} />
      <ProtectedRoute
        exact
        path="/avaliar/:id/:sectionID"
        component={ReportSection}
      />
      <ProtectedRoute exact path="/avaliar-resultados" component={ListReport} />
      <ProtectedRoute
        exact
        path="/avaliar-politica-alimentacao-escolar"
        component={ListReportsAlimentacaoEscolar}
      />
      <ProtectedRoute
        exact
        path="/avaliar-higiossanidade"
        component={ListReportsHigioSanidade}
      />
      <ProtectedRoute
        exact
        path="/avaliar-qualidade-ementas"
        component={ListReportsQualidadeEmentas}
      />

      <Route exact path="/consultar" component={Consultar} />
      <ProtectedRoute exact path="/planear" component={Planear} />
      <ProtectedRoute exact path="/avaliar" component={Avaliar} />
      <AdminRoute exact path="/admin" component={Admin} />
      <AdminRoute exact path="/admin/utilizadores" component={AdminDashboard} />
      <Route component={NotFound} />
    </Switch>
  </Router>
);

export default AppRouter;
