import React from 'react';
import ListReport from '../components/Reports/ListReport';
import Page from '../components/common/Page';

const ListReportsPage = () => (
  <Page title="Relatórios">
    <ListReport type="" />
  </Page>
);

export default ListReportsPage;
