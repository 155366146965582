import React from 'react';
import PropTypes from 'prop-types';
import {
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Table,
  Typography,
  Tooltip,
  makeStyles,
} from '@material-ui/core';

import { Icon, ConditionalWrap } from '../../common';
import columnNames from './columns.json';

const useStyles = makeStyles(() => ({
  nameCell: {
    paddingRight: '0',
    paddingLeft: '0',
    '& > *': {
      display: 'flex',
      alignItems: 'center',
      width: '14em',
    },
  },
  totalCell: {
    marginLeft: 'auto',
    fontWeight: 'bold',
  },
  headRow: {
    '& > *': {
      textAlign: 'center',
    },
  },
  row: {
    '& > *': {
      textAlign: 'center',
    },
    '&:last-child > *': {
      borderBottom: 'none',
    },
  },
}));

const wordMapping = {
  true: 'Sim',
  false: 'Não'
}

export default function NutritionalBody({ data }) {
  const styles = useStyles();

  return (
    <>
      <TableContainer>
        <Table aria-label="ingredient list">
          <TableHead>
            <TableRow classes={{ root: styles.headRow }}>
              {columnNames.map(({ name, label }) => (
                <TableCell size="small" key={name}>
                  {label ?? name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(({ id, amount, ...row }) => (
              <TableRow
                key={`${id}_${row.type}`}
                classes={{ root: styles.row }}
              >
                {columnNames.map(({ name }) => (
                  <TableCell
                    classes={name === 'name' ? { root: styles.nameCell } : {}}
                    size="small"
                    key={name}
                  >
                    <ConditionalWrap
                      condition={name === 'name'}
                      wrap={children => (
                        <Tooltip title={row[name]}>
                          <div>
                            {row.type && <Icon name={row.type} />}
                            {children}
                          </div>
                        </Tooltip>
                      )}
                    >
                      <Typography
                        classes={
                          name === 'name' && row.name.includes('Total')
                            ? { root: styles.totalCell }
                            : {}
                        }
                        noWrap
                      >
                        {/* Continuar aqui, dar display dos valores de supplier e tirar os 0's em Totais */}
                        {
                          (row[name] === undefined || row[name] === null)
                            ? '-'
                            : Number.isNaN(parseFloat(row[name]))
                              ? (row[name] ?? 0).toString().replace(/\b(?:true|false)\b/gi, matched => wordMapping[matched])
                              : parseFloat(
                                row.name.includes('Total')
                                  ? row[name]
                                  : (row[name] * amount) / 100,
                              ).toFixed(name === 'Sal' ? 1 : 0).replace('.', ',')
                        }
                      </Typography>
                    </ConditionalWrap>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="subtitle2" align="right" color="textSecondary">
        Valores em gramas, exceto quando especificado.
      </Typography>
    </>
  );
}

NutritionalBody.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string,
    }).isRequired,
  ).isRequired,
};
