import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from '@material-ui/core';
import { useStyles, useButtonStyles } from '../styles';
import { mealRecommendationType, nutritionalValuesType } from '../types';
import { MealsContext } from '../MealsContextProvider';
import sumNutritionalValues from '../utils';

const FeedbackRow = ({ numDays, weekTechnicalSheets }) => {
  const classes = useStyles();
  const { mealRecommendations } = useContext(MealsContext);
  const totalValues = sumNutritionalValues(weekTechnicalSheets);
  return (
    <Grid container item className={classes.feedbackRow}>
      {mealRecommendations
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(recom => (
          <FeedbackBox
            key={`feedback-${recom.name}`}
            numDays={numDays}
            totalValues={totalValues}
            recommendation={recom}
          />
        ))}
    </Grid>
  );
};

FeedbackRow.propTypes = {
  numDays: PropTypes.number.isRequired,
  weekTechnicalSheets: PropTypes.object.isRequired,
};

const FeedbackBox = ({ numDays, totalValues, recommendation }) => {
  const classes = useButtonStyles();



  const { name, unit, minValue, maxValue } = recommendation;

  if(numDays === 0){
    totalValues[name] = 0;
  }
  else{
    totalValues[name] = totalValues[name] / numDays;
  }
  
  const totalValue = Math.floor(totalValues[name]);

  let btnColor = 'yellow';
  if (totalValue >= minValue && totalValue <= maxValue) {
    btnColor = 'green';
  } else if (totalValue > maxValue) {
    btnColor = 'red';
  }

  const label = `Valor ${
    numDays > 0 ? 'total' : 'diário'
  } recomendado entre ${minValue} e ${maxValue} ${unit}`;

  return (
    <Tooltip title={label}>
      <Button
        id={`${name}-feedback`}
        variant="contained"
        className={classes[btnColor]}
        size="small"
      >
        {name === 'Energia [kcal]' ? 'Energia' : name}: {totalValue} {unit}
      </Button>
    </Tooltip>
  );
};

FeedbackBox.propTypes = {
  numDays: PropTypes.number.isRequired,
  totalValues: nutritionalValuesType.isRequired,
  recommendation: mealRecommendationType.isRequired,
};

export default FeedbackRow;
