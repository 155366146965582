import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout/Layout';
import Ingredient from '../components/Ingredients/IngredientsView';

const IngredientsView = ({ match }) => (
  <Layout>
    <Ingredient id={match.params.id} />
  </Layout>
);

IngredientsView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default IngredientsView;
