import React, { useState } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, NavbarBrand } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

import TopBar from './TopBar';
import BottomBar from './BottomBar';
import styles from './AppNavbar.module.css';

export default function() {
  const [isOpen, setOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: '790px' });
  const isSmallScreen = useMediaQuery({ maxWidth: '1070px' });

  const { loggedIn, userType } = useSelector(state => state.auth);
  const isUserAdmin = userType === 'admin';

  const toggle = () => {
    setOpen(!isOpen);
  };

  let navStyle;
  if (isMobile) {
    navStyle = styles.navBarMobile;
  } else if (loggedIn) {
    navStyle = styles.navBarLoggedIn;
  } else {
    navStyle = styles.navBar;
  }

  return (
    <Navbar className={navStyle} dark expand="lg" sticky="top">
      <NavbarBrand
        className={isMobile ? '' : styles.navBarBrand}
        tag={RRNavLink}
        style={{ height: '90%' }}
        to="/"
      >
        <picture style={{ maxWidth: '8em' }}>
          <source
            srcSet={`${process.env.PUBLIC_URL}/logos/spare.webp`}
            type="image/webp"
          />
          <img
            src={`${process.env.PUBLIC_URL}/logos/spare.png`}
            alt="spare logo"
            height={isMobile ? '60em' : '100%'}
          />
        </picture>
        {!isMobile && ((isSmallScreen && loggedIn) || !isSmallScreen) && (
          <p className={loggedIn ? styles.logoTextLoggedIn : styles.logoText}>
            Sistema de Planeamento e Avaliação de Refeições Escolares
          </p>
        )}
      </NavbarBrand>
      <NavbarToggler className={styles.navBarToggler} onClick={toggle} />
      <Collapse isOpen={isOpen} className={styles.collapse} navbar>
        <TopBar
          isMobile={isMobile}
          isLoggedIn={loggedIn}
          isUserAdmin={isUserAdmin}
        />
        <BottomBar
          isMobile={isMobile}
          isLoggedIn={loggedIn}
          isUserAdmin={isUserAdmin}
        />
      </Collapse>
    </Navbar>
  );
}
