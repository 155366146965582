import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  ListGroup,
  ListGroupItem,
  Col,
  Spinner,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { withRouter, useParams } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchMealsType, createMeal } from '../../services/mealsService';
import { setNotification } from '../../actions/notificationActions';
import EditMealPlan from './EditMealPlan';
import styles from './createMealPlan.module.css';

// eslint-disable-next-line
const CreateMeal = ({ setNotification }) => {
  const { day, month, year, type } = useParams();
  const typesSmall = [
    'pequeno_almoco',
    'merenda_manha',
    'almoco',
    'merenda_tarde',
    'jantar',
    'ceia',
  ];
  let name = 'Pequeno-almoço';
  const nutNames = [
    'Energia [kcal]',
    'Energia [kJ]',
    'Lípidos',
    'Ácidos gordos saturados',
    'Ácidos gordos monoinsaturados',
    'Ácidos gordos polinsaturados',
    'Ácido linoleico',
    'Ácidos gordos trans',
    'Hidratos de carbono',
    'Mono+dissacáridos (AÇÚCARES)',
    'Oligossacáridos',
    'Amido',
    'Sal',
    'Fibra alimentar',
    'Proteína',
    'Álcool',
    'Água',
    'Ácidos orgânicos',
    'Colesterol',
    'Vitamina A total (equivalentes de retinol)',
    'Caroteno',
    'Vitamina D',
    'alfa-tocoferol',
    'Tiamina',
    'Riboflavina',
    'Niacina',
    'Equivalentes de niacina',
    'Triptofano/60',
    'Vitamina B6',
    'Vitamina B12',
    'Vitamina C',
    'Folatos',
    'Cinza',
    'Sódio',
    'Potássio',
    'Cálcio',
    'Fósforo',
    'Magnésio',
    'Ferro',
    'Zinco',
  ];

  if (type === typesSmall[0]) name = 'Pequeno-almoço';
  else if (type === typesSmall[1]) name = 'Merenda da manhã';
  else if (type === typesSmall[2]) name = 'Almoço';
  else if (type === typesSmall[3]) name = 'Merenda da tarde';
  else if (type === typesSmall[4]) name = 'Jantar';
  else if (type === typesSmall[5]) name = 'Ceia';

  const [meals, setMeals] = useState([]);
  const [mealsSearch, setmealsSearch] = useState([]);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [selectedId, setSelectedId] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [negativeId, setNegativeId] = useState(-1);
  const [hasError, setError] = useState(false);

  useEffect(() => {
    setMeals(meals.concat(mealsSearch));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mealsSearch]);

  useEffect(() => {
    const fetchMeals = async () => {
      try {
        setIsLoading(true);
        const res = await fetchMealsType({ day, month, year, type: name });
        setmealsSearch(
          res.data.map(element => {
            const copy = element;
            if (Array.isArray(element.total_nutritional_values))
              copy.total_nutritional_values = {};
            return copy;
          }),
        );
        setIsLoading(false);
      } catch (error) {
        setError(true);
        setIsLoading(false);
      }
    };
    fetchMeals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (meals.length > 0 && selectedMeal != null) {
      const ar = [...meals];

      const totalNutValues = { ...selectedMeal.total_nutritional_values };
      nutNames.forEach(nutrient => {
        totalNutValues[nutrient] = {
          value: 0,
          unit: '',
          is_essential: true,
        };
      });
      if (selectedMeal.technicalSheets.length !== 0) {
        selectedMeal.technicalSheets.forEach(element => {
          Object.keys(element.total_nutritional_values).forEach(key => {
            totalNutValues[key].value +=
              element.total_nutritional_values[key].value;
            totalNutValues[key].is_essential =
              element.total_nutritional_values[key].is_essential;
            totalNutValues[key].unit =
              element.total_nutritional_values[key].unit;
          });
        });
      }
      selectedMeal.total_nutritional_values = totalNutValues;
      ar[selectedId] = selectedMeal;
      setMeals(ar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMeal]);

  const adicionarEmenta = () => {
    const novaEmenta = {
      id: negativeId,
      name: `Nova Ementa ${-1 * negativeId}`,
      technicalSheets: new Array(0),
      total_nutritional_values: {},
      type: name,
      updated_at: 'Agora',
      isLoadingTechSheets: false,
    };
    setNegativeId(negativeId - 1);
    setMeals(meals.concat(novaEmenta));
    setSelectedMeal(novaEmenta);
    setSelectedId(meals.length);
  };

  const deleteEmenta = id => {
    const ar = [...meals];
    ar.splice(id, 1);
    setMeals(ar);
    setSelectedMeal(null);
    setSelectedId(0);
  };
  const guardar = async () => {
    try {
      setNotification({
        message: `Guardando...`,
        type: 'warning',
      });
      await createMeal(meals, day, month, year, type);
      setNotification({
        message: `Alterações guardadas com sucesso!`,
        type: 'success',
      });
      setSelectedMeal(null);
    } catch (e) {
      setNotification({
        message: `Ocorreu um erro a tentar guardar as alterações!`,
        type: 'error',
      });
    }
  };

  return (
    <div>
      <Container fluid className={styles.titleBg}>
        <Row className="justify-content-md-center">
          <h1 className={styles.title}>
            Criar {name} para {day}/{month}/{year}
          </h1>
        </Row>
      </Container>
      <Container>
        <div style={{ paddingTop: '1em' }}>
          <Card>
            <CardBody>
              {(() => {
                if (isLoading) {
                  return (
                    <>
                      <h5>A procurar ementas criadas </h5>
                      <div className="mt-5 d-flex justify-content-center">
                        <Spinner color="success" size="md" />
                      </div>
                    </>
                  );
                }
                if (hasError) {
                  return <h5>Ocorreu um erro ao tentar obter a ementa</h5>;
                }
                if (meals.length > 0) {
                  return (
                    <>
                      <Card>
                        <CardBody>
                          <CardTitle>Ementas criadas:</CardTitle>
                          <ListGroup>
                            {meals.map((meal, index) => {
                              return (
                                <Row
                                  className={styles.rowEmentas}
                                  key={meal.id}
                                >
                                  <Col md="10">
                                    <ListGroupItem
                                      className={styles.linhaEmenta}
                                      tag="button"
                                      action
                                      onClick={() => {
                                        setSelectedMeal(meal);
                                        setSelectedId(index);
                                      }}
                                    >
                                      <Row>
                                        <Col md="6">Nome: {meal.name}</Col>
                                        <Col md="6">
                                          Atualizada em: {meal.updated_at}
                                        </Col>
                                      </Row>
                                    </ListGroupItem>
                                  </Col>
                                  <Col
                                    md="2"
                                    className={styles.colApagarEmentaBotao}
                                  >
                                    <Button
                                      className={styles.apagarEmentaBotao}
                                      onClick={() => deleteEmenta(index)}
                                      key={`${meal.name}delete`}
                                      outline
                                      color="danger"
                                    >
                                      Apagar
                                    </Button>
                                  </Col>
                                </Row>
                              );
                            })}
                          </ListGroup>
                          <Button
                            className={styles.adicionarEmentaBotao}
                            onClick={adicionarEmenta}
                            color="success"
                          >
                            Criar nova ementa
                          </Button>
                        </CardBody>
                      </Card>
                      <EditMealPlan
                        selectedMeal={selectedMeal}
                        setSelectedMeal={setSelectedMeal}
                      />
                    </>
                  );
                }
                return (
                  <>
                    <h4>Não existem ementas</h4>
                    <Button
                      className={styles.adicionarEmentaBotao}
                      onClick={adicionarEmenta}
                      color="success"
                    >
                      Criar nova ementa
                    </Button>
                    <EditMealPlan
                      selectedMeal={selectedMeal}
                      setSelectedMeal={setSelectedMeal}
                    />
                  </>
                );
              })()}
            </CardBody>
          </Card>
        </div>
        <Row className={styles.rowGuardarTudo}>
          <Col className={styles.grupoBaixo}>
            <ButtonGroup>
              <Button color="success" onClick={guardar}>
                Guardar todas as alterações feitas
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

CreateMeal.propTypes = {
  setNotification: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  connect(null, { setNotification }),
)(CreateMeal);
