/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import classNames from 'classnames';
import { Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';

import stylesSection from '../ReportSection.module.css';
import stylesView from '../ViewReport.module.css';

const PureSection = ({
  id,
  sectionID,
  toggleAnswer,
  reportDate,
  questions,
  sections,
  reportName,
  section,
  isSectionLoading,
}) => {
  let num = 1;
  return (
    <div>
      {isSectionLoading ? (
        <div>
          <Spinner color="success" size="md" />
        </div>
      ) : (
        <>
          {' '}
          <h3 className={stylesSection.sectionTitle}>{reportName}</h3>
          <p className={stylesSection.sectionTitle}>
            {moment(reportDate).format('DD-MM-YYYY HH:mm:ss')}
          </p>
          <h4 className={stylesSection.sectionTitle}>{section}</h4>
          <ul className={stylesView.sectionList}>
            {questions &&
              questions.map(({ content, answer, id: answerID }) => (
                <li className={stylesView.section} key={answerID}>
                  <span className={stylesView.description}>{(num++).toString() + ' - ' + content}</span>
                  <div className={stylesSection.checkboxParent}>
                    <label>
                      <input
                        type="radio"
                        id={`yes${answerID}`}
                        name={`${answerID}`}
                        onChange={() => toggleAnswer(answerID, true)}
                        className={stylesSection.checkbox}
                        defaultChecked={answer === true ? 'checked' : ''}
                      />
                      Sim
                    </label>
                    <label>
                      <input
                        type="radio"
                        id={`no${answerID}`}
                        name={`${answerID}`}
                        onChange={() => toggleAnswer(answerID, false)}
                        className={stylesSection.checkbox}
                        defaultChecked={answer === false ? 'checked' : ''}
                      />
                      Não
                    </label>
                    <label>
                      <input
                        type="radio"
                        id={`na${answerID}`}
                        name={`${answerID}`}
                        className={stylesSection.checkbox}
                        onChange={() => toggleAnswer(answerID, null)}
                        defaultChecked={answer === null ? 'checked' : ''}
                      />
                      N/A
                    </label>
                  </div>
                </li>
              ))}
          </ul>
          <div className={stylesSection.concluir}>
            {Number(sectionID) === sections && (
              <Link to={`/avaliar/${id}`}>
                Concluir
                <img
                  src={`${process.env.PUBLIC_URL}/activeButton7.png`}
                  alt="Concluir"
                />
              </Link>
            )}
          </div>
          <div className={stylesSection.pagination}>
            {[...Array(sections)].map((e, i) => (
              <div
                className={classNames(
                  sectionID === i + 1
                    ? stylesSection.enabledSection
                    : stylesSection.disabledSection,
                  stylesSection.paginationItem,
                )}
                active={sectionID === i + 1}
                // eslint-disable-next-line react/no-array-index-key
                key={i}
              >
                {Number(sectionID) !== i + 1 && (
                  <Link to={`./${i + 1}`}>{i + 1}</Link>
                )}
                {Number(sectionID) === i + 1 && <span>{i + 1}</span>}
              </div>
            ))}
          </div>
        </>
      )}
      <p style={{margin: '1em 0 0 14%'}}>N/A - Não Aplicável e/ou Não Auditável</p>
    </div>
    
  );
};

PureSection.propTypes = {
  id: PropTypes.string.isRequired,
  sectionID: PropTypes.string.isRequired,
  toggleAnswer: PropTypes.func.isRequired,
  reportDate: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      answer: PropTypes.bool,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  sections: PropTypes.number.isRequired,
  reportName: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  isSectionLoading: PropTypes.bool.isRequired,
};

export default PureSection;
