import React, { useState } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { TextField, Link, IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Button from '../Button';
import ConditionalWrap from '../ConditionalWrap';

import { submitLogin } from '../../../services/authService';
import { login } from '../../../actions/authActions';
import { clearNotification, setNotification } from '../../../actions/notificationActions';

import styles from './Login.module.css';

const Login = ({ inputTheme, buttonTheme }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const timeouts = [];

  const submitForm = e => {
    e.preventDefault();
    setSubmitting(true);
    timeouts.forEach(to => clearTimeout(to));
    dispatch(clearNotification());

    submitLogin({ email, password })
      .then(({ data }) => {
        const { token, user_type: userType } = data;
        dispatch(
          login({
            token,
            userType,
          }),
        );

        if (location.state && location.state.from)
          history.push(location.state.from);
        else if (userType === 'admin') history.push('/admin');
        else history.push('/');
      })
      .catch(error => {
        if (error.response.status === 404)
          dispatch(
            setNotification({
              message: 'Não existe uma conta com esse endereço de email.',
              type: 'error',
            }),
          );
        else {
          const { errors } = error.response.data;
          let message;
          if (errors) {
            message = errors.join('\n');
          } else {
            message = 'Credenciais erradas.';
          }
          dispatch(
            setNotification({
              message,
              type: 'error',
            }),
          );
          setPassword('');
        }
        setSubmitting(false);
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <form className={styles.form} onSubmit={submitForm}>
        <div>
          <ConditionalWrap
            condition={!!inputTheme}
            wrap={children => (
              <ThemeProvider theme={inputTheme}>{children}</ThemeProvider>
            )}
          >
            <div className={styles.formGroup}>
              <TextField
                type="email"
                name="email"
                id="email"
                placeholder="E-mail"
                label="E-mail"
                color="secondary"
                inputMode="email"
                onChange={e => setEmail(e.target.value)}
                value={email}
                fullWidth
              />
            </div>
            <div className={styles.formGroup}>
              <TextField
                type={showPassword ? 'text' : 'password'}
                name="password"
                id="password"
                placeholder="Palavra-Chave"
                label="Palavra-Chave"
                color="secondary"
                onChange={e => setPassword(e.target.value)}
                value={password}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={togglePasswordVisibility}
                        edge="end"
                        style={{ padding: 10 }} // Adjust padding to control the size of the eye button
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <Button type="submit" style={{ display: 'none' }} text="Submit" />
            <div className={styles.forgotPasswordLink}>
              <Link
                color="secondary"
                to="/recuperar-conta"
                className={styles.link}
                component={RouterLink}
              >
                Esqueceu-se da sua palavra-chave?
              </Link>
            </div>
          </ConditionalWrap>
        </div>
        <div />
      </form>
      <div className={styles.loginButtonContainer}>
        <ConditionalWrap
          condition={!!buttonTheme}
          wrap={children => (
            <ThemeProvider theme={buttonTheme}>{children}</ThemeProvider>
          )}
        >
          <Button
            className={styles.loginButton}
            text="Entrar"
            isLoading={isSubmitting}
            disabled={isSubmitting}
            onClick={submitForm}
          />
        </ConditionalWrap>
      </div>
    </>
  );
};

Login.propTypes = {
  inputTheme: PropTypes.object,
  buttonTheme: PropTypes.object,
};

Login.defaultProps = {
  inputTheme: undefined,
  buttonTheme: undefined,
};

export default Login;