import React from 'react';
import { FormGroup, TextField, FormControlLabel } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Title from './Title';
import styles from './TechnicalSheetForm.module.css';

export const useStyles = makeStyles(() => ({
  form: {
    '& > *': {
      marginBottom: '0.5em',
    },
  },
  label: {
    alignSelf: 'flex-start',
    fontSize: 'larger',
  },
  input: {
    width: '100%',
    marginLeft: '16px',
  },
  chips: {
    alignSelf: 'start',
    width: '100%',
    '& > *': {
      marginRight: '0.5em',
      marginTop: '0.3em',
    },
  },
}));

const Overview = ({
  updateName,
  updateComponentType,
  updateNumPeopleServed,
  numPeopleServed,
  name,
  componentType,
  componentTypes,
}) => {
  const classes = useStyles();

  return (
    <FormGroup classes={{ root: classes.form }}>
      <Title text="Informações Gerais" />
      <FormControlLabel
        label="Nome"
        classes={{ label: classes.label }}
        labelPlacement="top"
        control={
          <TextField
            fullWidth
            value={name !== null ? name : ''}
            color="secondary"
            onChange={updateName}
          />
        }
      />
      {componentTypes && (
        <FormControlLabel
          label="Tipo de Componente"
          classes={{ label: classes.label }}
          labelPlacement="top"
          control={
            <Autocomplete
              autoComplete
              value={componentType}
              className={styles.formSearch}
              style={{ width: '100%' }}
              label="Pesquisa de Tipo de Componente"
              options={componentTypes}
              key={Object.keys(componentTypes).length}
              onChange={updateComponentType}
              renderInput={params => (
                <TextField
                  {...params}
                  id="ingredientSearchInput"
                  label="Pesquisa de Tipo de Componente"
                  color="secondary"
                />
              )}
            />
          }
        />
      )}
      <FormControlLabel
        classes={{ label: classes.label }}
        labelPlacement="top"
        label="Porção (nº de pessoas)"
        control={
          <TextField
            type="number"
            fullWidth
            inputProps={{ step: '1', min: '1' }}
            color="secondary"
            value={numPeopleServed}
            onChange={updateNumPeopleServed}
          />
        }
      />
    </FormGroup>
  );
};

Overview.defaultProps = {
  name: null,
  componentType: null,
  numPeopleServed: null,
};

Overview.propTypes = {
  updateName: PropTypes.func.isRequired,
  updateComponentType: PropTypes.func.isRequired,
  name: PropTypes.string,
  numPeopleServed: PropTypes.number,
  componentType: PropTypes.string,
  componentTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateNumPeopleServed: PropTypes.func.isRequired,
};

export default Overview;
