export const reportTypes = {
  SET_REPORT_NAME: 'SET_REPORT_NAME',
  SET_REPORT_DATE: 'SET_REPORT_DATE',
  SET_SECTIONS: 'SET_SECTIONS',
};

export const notificationTypes = {
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  CLEAR_NOTIFICATION: 'CLEAR_NOTIFICATION',
};

export const fetchTypes = {
  FETCH_INIT: 'FETCH_INIT',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILURE: 'FETCH_FAILURE',
};

export const sidebarTypes = {
  OPEN_SIDEBAR: 'OPEN_SIDEBAR',
  COLLAPSE_SIDEBAR: 'COLLAPSE_SIDEBAR',
};

export const authTypes = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
};
