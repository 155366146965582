import React from 'react';
import Layout from '../components_lgp/layout/Layout';
import ReportView from '../components/Reports/ViewReport';

const links = [
  {
    name: 'Avaliar',
    url: '/avaliar',
  },
  {
    name: 'Relatório',
  },
];

const ReportViewPage = () => (
  <Layout breadcrumb_links={links}>
    <ReportView />
  </Layout>
);

export default ReportViewPage;
