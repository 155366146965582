import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Card, CardContent } from '@material-ui/core';

function TextBody({ data }) {
  return (
    <div>
      {data.map(({ label, content }) => (
        <div key={label}>
          <Card square>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                {label}
              </Typography>
              <Typography variant="body2" component="p">
                {content}
              </Typography>
            </CardContent>
          </Card>
        </div>
      ))}
    </div>
  );
}

TextBody.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default TextBody;
