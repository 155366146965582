import React, { useEffect, useState } from 'react';
import {
  TextField,
  CircularProgress,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import Title from '../TechnicalSheets/Form/Title';
import FullPage from '../MealPlanning/pageLayout';

import { setNotification } from '../../actions/notificationActions';

import {
  getAllNutritionalCriteria,
  createIngredient,
  fetchIngredient,
  updateIngredient,
} from '../../services/ingredientsService';

import PickNutritionalCriteria from './Form/PickNutritionalCriteria';

import { useStyles } from '../TechnicalSheets/Form/Overview';

const types = [
  'Açúcar e similares, confeitaria e sobremesas doces à base de água',
  'Água e bebidas à base de água',
  'Batatas fritas, aperitivos e similares produzidos a partir de massa',
  'Bebidas alcoólicas',
  'Café, cacau, chá e tisanas',
  'Carne e produtos cárneos',
  'Cereais e produtos à base de cereais',
  'Frutos e produtos derivados de frutos',
  'Ingredientes principais isolados, aditivos, aromas, fermentos e auxiliares tecnológicos',
  'Leguminosas, frutos de casca rija, sementes oleaginosas e especiarias',
  'Leite e produtos lácteos',
  'Óleos e gorduras de origem animal e vegetal e seus derivados',
  'Outras sobremesas de colher',
  'Ovos e ovoprodutos',
  'Peixes, mariscos, anfíbios, répteis e invertebrados',
  'Pratos compostos',
  'Produtos alimentares para a população jovem',
  'Produtos hortícolas e derivados',
  'Produtos para dietas não padronizadas, substitutos de alimentos e suplementos alimentares',
  'Raízes amiláceas ou tubérculos e seus produtos, plantas sacarinas',
  'Saladas',
  'Sanduíches, pizzas e outros produtos similares recheados',
  'Sopas (prontas a comer)',
  'Sumos e néctares de frutos e produtos hortícolas (incluindo concentrados)',
  'Temperos, molhos e condimentos',
];


const IngredientForm = ({ isEditing }) => {
  const { id } = useParams();
  const [nutrientsLoading, setNutrientsLoading] = useState(false);
  const [allNutritionalCriteria, setNutritionalCriteria] = useState(null);
  const [fetchError, setFetchError] = useState(false);
  const [
    selectedNutritionalCriteria,
    setSelectedNutritionalCriteria,
  ] = useState({});
  const [searchNutritionalCriteria, setSearchNutritionalCriteria] = useState(
    '',
  );
  const [name, setName] = useState(null);
  const [ediblePart, setEdiblePart] = useState(null);
  const [type, setType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [
    isWaitingForIngredientFetch,
    setIsWaitingForIngredientFetch,
  ] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const classes = useStyles();

  const onSubmit = async event => {
    event.preventDefault();

    if (!isLoading) {
      try {
        setIsLoading(true);
        const requestBody = {
          name,
          ediblePart,
          type,
          nutritionalCriteria: Object.keys(selectedNutritionalCriteria).map(
            selected => selectedNutritionalCriteria[selected],
          ),
        };
        const { data } = isEditing
          ? await updateIngredient(requestBody, id)
          : await createIngredient(requestBody);
        dispatch(
          setNotification({
            message: `Ingrediente "${data.name}" ${
              isEditing ? 'editado' : 'criado'
            } com sucesso!`,
            type: 'success',
          }),
        );

        history.push(`/admin/ingredientes/${data.id}`);
      } catch (ex) {
        dispatch(
          setNotification({
            message: `Ocorreu um erro ao tentar ${
              isEditing ? 'editar' : 'criar'
            } este ingrediente. Tente novamente.`,
            type: 'error',
          }),
        );
      }
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      setIsLoading(false);
    }
  };

  const fetchNutritionalCriteria = async () => {
    setFetchError(false);

    if (!nutrientsLoading) {
      try {
        setNutrientsLoading(true);

        const { data } = await getAllNutritionalCriteria();
        setNutritionalCriteria(data);
      } catch (err) {
        setFetchError(true);
      }
      setNutrientsLoading(false);
    }
  };

  const setOldNutritionalCriteria = ingredient => {
    const newNutritionalCriteria = { ...selectedNutritionalCriteria };
    ingredient.nutritionalCriteria.forEach(nutritionalCriterium => {
      newNutritionalCriteria[
        `nutritionalCriteria${nutritionalCriterium.id}`
      ] = {
        id: nutritionalCriterium.id,
        name: nutritionalCriterium.name,
        value: nutritionalCriterium.pivot.value,
      };
    });
    setSelectedNutritionalCriteria(newNutritionalCriteria);
  };

  const fetchOldIngredient = async () => {
    if (!isWaitingForIngredientFetch) {
      setIsWaitingForIngredientFetch(true);
      const { data } = await fetchIngredient(id);
      setIsWaitingForIngredientFetch(false);
      setName(data.name);
      setType(data.type);
      setEdiblePart(data.ediblePart * 100);
      setOldNutritionalCriteria(data);
    }
  };

  useEffect(() => {
    if (isEditing) {
      fetchOldIngredient();
    }

    fetchNutritionalCriteria();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectNutritionalCriteria = ({ id: ncID, name: ncName }) => {
    if (!selectedNutritionalCriteria[`nutritionalCriteria${ncID}`]) {
      const newSelectedNutritionalCriteria = { ...selectedNutritionalCriteria };
      newSelectedNutritionalCriteria[`nutritionalCriteria${ncID}`] = {
        id: ncID,
        name: ncName,
        value: '0',
      };
      setSelectedNutritionalCriteria(newSelectedNutritionalCriteria);
    } else {
      const newSelectedNutritionalCriteria = { ...selectedNutritionalCriteria };
      delete newSelectedNutritionalCriteria[`nutritionalCriteria${ncID}`];
      setSelectedNutritionalCriteria(newSelectedNutritionalCriteria);
    }
  };

  const removeSelectedNutritionalCriteria = ncID => {
    const key = `nutritionalCriteria${ncID}`;

    const newSelectedNutritionalCriteria = { ...selectedNutritionalCriteria };
    delete newSelectedNutritionalCriteria[key];
    setSelectedNutritionalCriteria(newSelectedNutritionalCriteria);
  };

  const updateSelectedNutritionalCriteriaValue = ({ id: ncID, value }) => {
    const key = `nutritionalCriteria${ncID}`;

    setSelectedNutritionalCriteria({
      ...selectedNutritionalCriteria,
      [key]: {
        ...selectedNutritionalCriteria[key],
        value,
      },
    });
  };

  const renderForm = () => (
    <>
      <FormGroup classes={{ root: classes.form }}>
        <Title text="Informação geral" />

        <FormControlLabel
          label="Nome"
          classes={{ label: classes.label }}
          labelPlacement="top"
          control={
            <TextField
              fullWidth
              value={name ?? ''}
              error={name === ''}
              color="secondary"
              onChange={e => setName(e.target.value)}
            />
          }
        />
        <FormControlLabel
          label="Parte edível (%)"
          classes={{ label: classes.label }}
          labelPlacement="top"
          control={
            <TextField
              fullWidth
              error={
                ediblePart !== null &&
                (!parseFloat(ediblePart) || ediblePart <= 0 || ediblePart > 100)
              }
              inputProps={{ max: 100, min: 0 }}
              type="number"
              value={ediblePart ?? ''}
              color="secondary"
              onChange={e => setEdiblePart(e.target.value)}
              required
            />
          }
        />
        <FormControlLabel
          label="Tipo"
          classes={{ label: classes.label }}
          labelPlacement="top"
          control={
            <Autocomplete
              autoComplete
              value={type}
              style={{ marginBottom: '0.5em', width: '100%' }}
              label="Pesquisa de Tipo de Ingrediente"
              options={types}
              onChange={(_, value) => setType(value)}
              renderInput={params => (
                <TextField
                  {...params}
                  id="ingredientSearchInput"
                  label="Pesquisa de Tipo de Ingrediente"
                  color="secondary"
                  error={type === ''}
                />
              )}
            />
          }
        />
      </FormGroup>
      <PickNutritionalCriteria
        nutritionalCriteria={allNutritionalCriteria}
        fetchError={fetchError}
        selectedNutritionalCriteria={selectedNutritionalCriteria}
        selectNutritionalCriteria={selectNutritionalCriteria}
        removeSelectedNutritionalCriteria={removeSelectedNutritionalCriteria}
        updateSelectedNutritionalCriteriaValue={
          updateSelectedNutritionalCriteriaValue
        }
        searchNutritionalCriteria={searchNutritionalCriteria}
        updateNutritionalCriteriaSearchTerm={e =>
          setSearchNutritionalCriteria(e.target.value)
        }
        retryNutritionalCriteriaFetch={fetchNutritionalCriteria}
        nutrientsLoading={nutrientsLoading}
      />
    </>
  );

  return (
    <>
      {isWaitingForIngredientFetch ? (
        <div
          style={{
            display: 'flex',
            marginTop: '2em',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <FullPage
          main={renderForm()}
          links={[
            {
              label: 'Submeter',
              onClick: onSubmit,
            },
          ]}
        />
      )}
    </>
  );
};

IngredientForm.defaultProps = {
  isEditing: false,
};

IngredientForm.propTypes = {
  isEditing: PropTypes.bool,
};

export default IngredientForm;
