import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
  Paper,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { dataType } from './constants';
import { Icon } from '../../common';
import RouteLink from '../../common/RouteLink/RouteLink';

import styles from './List.module.css';
import { useStyles } from '../styles';

function ListBody({ data, emptyMessage, loading, onTableChange }) {
  const classes = useStyles();

  return (
    <List className={styles.list}>
      {data.length === 0 && (
        <ListItem>
          <ListItemText primary={emptyMessage} />
        </ListItem>
      )}
      {loading && (
        <Paper elevation={3} className={styles.loadingPaper}>
          <CircularProgress />
          <Typography variant="caption">A carregar...</Typography>
        </Paper>
      )}
      {data.length > 0 &&
        data.map(({ componentType, name, id }, index) => {
          return (
            <ListItem
              key={id}
              className={
                index !== data.length - 1
                  ? styles.listItem
                  : styles.lastListItem
              }
            >
              <ListItemIcon className={styles.icon}>
                <Icon name={componentType} />
              </ListItemIcon>
              <RouteLink
                to={{ pathname: `fichastecnicas/${id}`, state: { name } }}
                color="textPrimary"
                classes={{
                  root: classes.linkRoot,
                }}
              >
                <ListItemText primary={name} />
              </RouteLink>
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => onTableChange('delete', { id, name })}
                >
                  <Close color="action" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
    </List>
  );
}

ListBody.propTypes = {
  data: dataType.isRequired,
  onTableChange: PropTypes.func.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ListBody;
