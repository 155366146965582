import React from 'react';
import { Snackbar } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function MySnackbar({ children, open, onClose }) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {children}
    </Snackbar>
  );
}

MySnackbar.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

MySnackbar.defaultProps = {
  onClose: () => {},
};
