import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  TextField,
  Card,
  CardContent,
  Tooltip,
  Grow,
  Button,
  Toolbar,
  IconButton,
  Typography,
} from '@material-ui/core';
import { FilterList, Search, Close } from '@material-ui/icons';

import { Select, Popover } from '../../common';
import tableStatePropTypes from './constants';
import { useStyles } from '../styles';
import styles from './List.module.css';

export default function ListHeader({ tableState, onTableChange }) {
  const classes = useStyles();

  const { availableFilters, filterList, search } = tableState;

  const [iconActive, setIconActive] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState(null);

  const hideSearch = () => {
    setIconActive(null);
    setShowSearch(false);
    setSearchText(null);
    onTableChange('search', {
      ...tableState,
      searchText: '',
    });
  };

  const setActiveIcon = iconName => {
    const isSearchShown = showSearch ? searchText : iconName === 'search';

    setIconActive(iconName);
    setShowSearch(isSearchShown);
  };

  const getActiveIcon = iconName => {
    let isActive = iconActive === iconName;
    if (iconName === 'search') {
      isActive = isActive || showSearch || searchText;
    }
    return isActive ? classes.iconActive : classes.icon;
  };

  const handleSearchIconClick = () => {
    if (showSearch && !searchText) {
      hideSearch();
    } else {
      setIconActive('search');
      setShowSearch(true);
    }
  };

  return (
    <Toolbar className={classes.header}>
      {showSearch && (
        <Grow appear in timeout={300}>
          <div className={styles.headerSearch}>
            <div style={{ padding: '0.5em' }}>
              <Search color="secondary" />
            </div>
            <TextField
              autoFocus
              color="secondary"
              inputProps={{
                className: classes.input,
              }}
              value={search}
              onChange={event =>
                onTableChange('search', {
                  ...tableState,
                  searchText: event.target.value,
                })
              }
            />
            <IconButton aria-label="close" onClick={hideSearch}>
              <Close style={{ color: '#FFF' }} />
            </IconButton>
          </div>
        </Grow>
      )}
      <div className={styles.headerButtons}>
        <IconButton
          aria-label="search"
          onClick={handleSearchIconClick}
          classes={{ root: getActiveIcon('search') }}
        >
          <Search />
        </IconButton>
        <Popover
          refExit={() => setActiveIcon(null)}
          trigger={
            <Tooltip title="Filtrar" disableFocusListener>
              <IconButton
                aria-label="Filtrar"
                classes={{ root: getActiveIcon('filter') }}
                onClick={() => setActiveIcon('filter')}
              >
                <FilterList />
              </IconButton>
            </Tooltip>
          }
          content={
            <Card bgcolor="primary.dark" className={styles.filterCard}>
              <CardContent>
                <div className={styles.popoverHeader}>
                  <Typography className={styles.popoverTitle}>
                    FILTRO
                  </Typography>
                  <Button color="primary">RESET</Button>
                </div>
                <Select
                  options={availableFilters}
                  label="Tipo"
                  value={filterList}
                  onChange={(_, filters) => {
                    onTableChange('filterChange', {
                      ...tableState,
                      filterList: filters,
                    });
                  }}
                />
              </CardContent>
            </Card>
          }
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          anchorPosition={{
            top: -50,
            left: -300,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        />
      </div>
    </Toolbar>
  );
}

ListHeader.propTypes = {
  tableState: tableStatePropTypes.isRequired,
  onTableChange: PropTypes.func.isRequired,
};
