import React from 'react';
import { Alert } from '@material-ui/lab';
import { Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { clearNotification } from '../../actions/notificationActions';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%, 0) !important',
    zIndex: 1021,
    marginTop: '5em',
  },
}));

const Notification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { message, type } = useSelector(({ notification }) => notification);

  return message ? (
    <Slide classes={{ root: classes.root }} in={!!message}>
      <Alert severity={type} onClose={() => dispatch(clearNotification())}>
        {message}
      </Alert>
    </Slide>
  ) : null;
};

export default Notification;
