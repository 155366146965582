import React from 'react';
import PropTypes from 'prop-types';

import TableHeader from './TableHeader';

export default function Table({ title, body }) {
  return (
    <div>
      <TableHeader title={title} />
      {body}
    </div>
  );
}

Table.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.element.isRequired,
};
