import axios from 'axios';

const baseUrl = '/api/ingredients';

const fetchAllIngredientsSimplified = async () => {
  return axios.get('/api/ingredientsSimplified');
};

const fetchAllIngredients = async (page, query) => {
  return axios.get(
    baseUrl,
    query !== ''
      ? {
          params: {
            page,
            query,
            simplified: true,
          },
        }
      : {
          params: {
            page,
            simplified: true,
          },
        },
  );
};

const fetchIngredient = id => {
  return axios.get(`${baseUrl}/${id}`);
};

// eslint-disable-next-line
const createIngredient = async ingredient => {
  return axios.post(baseUrl, ingredient);
};

const updateIngredient = async (ingredient, id) => {
  return axios.put(`${baseUrl}/${id}`, ingredient);
};

const deleteIngredient = id => {
  return axios.delete(`${baseUrl}/${id}`);
};

const getAllNutritionalCriteria = async () =>
  axios.get('/api/nutritionalCriteria');

const fetchAllAllergens = (cancelToken = axios.CancelToken.source().token) =>
  axios.get('/api/allergens', { cancelToken });

export {
  fetchAllIngredientsSimplified,
  fetchAllIngredients,
  fetchIngredient,
  createIngredient,
  getAllNutritionalCriteria,
  updateIngredient,
  fetchAllAllergens,
  deleteIngredient,
};
