import React from 'react';
import Layout from '../components_lgp/layout/Layout';
import AboutInfo from '../components_lgp/AboutInfo/AboutInfo';
import Page from '../components/common/Page';

const breadcrumbLinks = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'Sobre Nós',
    url: '/sobre',
  },
];

const AboutPage = () => (
  <Page title="Sobre Nós">
    <Layout breadcrumb_links={breadcrumbLinks}>
      <AboutInfo />
    </Layout>
  </Page>
);

export default AboutPage;
