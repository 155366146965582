import React from 'react';
import { Link } from 'react-router-dom';
import styles from './AvaliarOptions.module.css';

const renderOption = (title, link) => (
  <div className={styles.line}>
    <Link
      to={link}
      className={styles.link}
    >
      {' '}
      {title}
      <img
        className={styles.iconeAtivo}
        src={`${process.env.PUBLIC_URL}/botaoAtivo7.png`}
        alt=""
      />
    </Link>
  </div>
)

const options = [
  { title: 'Política de alimentação escolar', link: '/avaliar-politica-alimentacao-escolar' },
  { title: 'Diversidade', link: '/avaliar-diversidade' },
  { title: 'Qualidade das ementas - saudáveis e sustentáveis', link: '/avaliar-qualidade-ementas' },
  { title: 'Composição nutricional', link: '/planear/ementas' },
  { title: 'Higiossanidade', link: '/avaliar-higiossanidade' },
  { title: 'Resultados', link: '/avaliar-resultados' }];

const AvaliarOptions = () => (
  <div>
    <div className={styles.titulo}>
      <img
        className={styles.individualModule1}
        src={`${process.env.PUBLIC_URL}/activeEvaluate6.png`}
        alt=""
      />
      <h1 className={styles.titleAvaliar}>Avaliar</h1>
    </div>

    {options.map(option => renderOption(option.title, option.link))}

    <Link to="/modulos">
      <h5 className={styles.back}>&lt; voltar</h5>
    </Link>
  </div>
);

export default AvaliarOptions;
