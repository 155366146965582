import React from 'react';
import Layout from '../components_lgp/layout/Layout';
import Page from '../components/common/Page';
import ConsultarOptions from '../components_lgp/ChooseModule/ConsultarOptions';

const Consultar = () => (
  <Page title="Consultar">
    <Layout>
      <ConsultarOptions />
    </Layout>
  </Page>
);

export default Consultar;
