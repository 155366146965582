import React, { useEffect, useContext } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { MealsContext } from '../MealsContextProvider';

const AgeRangePicker = () => {
  // hooks for selected organization
  const {
    userInput: { setSelectedAgeRange, selectedAgeRange },
  } = useContext(MealsContext);
  // TODO: Perhaps fetch this from the backend ?
  const ageRangeOptions = [
    '1-3',
    '3-6',
    '6-10',
    '10-15',
    '15-18',
    '18-65',
    '65+',
  ];
  useEffect(() => {
    const ageRange = selectedAgeRange ?? ageRangeOptions[0];
    setSelectedAgeRange(ageRange);
  }, [setSelectedAgeRange, selectedAgeRange, ageRangeOptions]);
  return (
    <Autocomplete
      id="select-age-range"
      autoComplete
      value={selectedAgeRange}
      options={ageRangeOptions}
      getOptionLabel={ar => `${ar} anos`}
      onChange={(event, value) => setSelectedAgeRange(value)}
      renderInput={params => <TextField {...params} label="Faixa etária" />}
    />
  );
};

export default AgeRangePicker;
