import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { FaDownload, FaFileAlt } from 'react-icons/fa';
import moment from 'moment';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './ReportListItem.module.css';

const ReportListItem = ({ id, name, lastUpdate, printReport }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleExport = async () => {
    setIsLoading(true);
    await printReport(false, { id, lastUpdate });
    setIsLoading(false);
  };

  return (
    <div className={styles.listItemWrapper}>
      <Link
        to={`/avaliar/${id}`}
        className={classnames(styles.linkWrapper, styles.title)}
      >
        {moment(lastUpdate).format('DD-MM-YYYY HH:mm')} - {name}
      </Link>
      <Link to={`/avaliar/${id}`} className={styles.linkWrapper}>
        <div className={styles.linkContentWrapper}>
          <FaFileAlt />
          <p>Consultar</p>
        </div>
      </Link>
      <button type="button" className={styles.button} onClick={handleExport}>
        {isLoading ? (
          <>
            <Spinner className="mr-1" size="sm" color="dark" />
            <span>A processar</span>
          </>
        ) : (
          <>
            <FaDownload className={styles.icon} />
            <span>Exportar</span>
          </>
        )}
      </button>
    </div>
  );
};

ReportListItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  lastUpdate: PropTypes.string.isRequired,
  printReport: PropTypes.func.isRequired,
};

export default ReportListItem;
