import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { registerLocale } from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/pt'; // without this line it didn't work
import pt from 'date-fns/locale/pt';
import FontLoader from 'webfontloader';
import {
  createMuiTheme,
  ThemeProvider,
  StylesProvider,
  rgbToHex,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import jss from 'jss';
import preset from 'jss-preset-default';

import 'normalize.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import initTelemetry from './initTelemetry';

import App from './App';
import store from './store';
import initAuth from './initAuth';
import validateAuthorizationToken from './validateAuthorizationToken';

registerLocale('pt', pt);
moment.locale('pt');
initAuth();

validateAuthorizationToken();

initTelemetry();

jss.setup(preset());

FontLoader.load({
  google: {
    families: [
      'Roboto Condensed:ital,wght@1,300;1,400',
      'Raleway:400,700',
      'sans-serif',
    ],
  },
});

let theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(183, 106, 95)',
    },
    secondary: {
      main: '#8CC3AC',
    },
    primaryRed: {
      main: rgbToHex('rgb(215, 146, 136)'),
    },
    secondaryRed: {
      main: rgbToHex('rgb(183, 106, 95)'),
    },
    primaryYellow: {
      main: rgbToHex('rgb(215, 146, 136)'),
    },
  },
  typography: {
    fontFamily: '"Raleway", "Roboto", "sans-serif"',
    h1: {
      fontSize: '2.8rem',
    },
    h2: {
      fontSize: '2.2rem',
    },
    h3: {
      fontSize: '1.9rem',
    },
    h4: {
      fontSize: '1.7rem',
    },
    h5: {
      fontSize: '1.3rem',
    },
    h6: {
      fontSize: '1.2rem',
    },
  },
});

// Generate responsive typography settings based on the options above
theme = responsiveFontSizes(theme);

ReactDOM.render(
  <Provider store={store}>
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StylesProvider>
  </Provider>,
  document.getElementById('root'),
);
