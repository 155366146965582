import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import AppNavbar from '../Navbar';
import Breadcrumb from '../common/Breadcrumb/Breadcrumb';
import Footer from '../Footer';

import cssStyles from './Layout.module.css';

const useStyles = makeStyles(
  createStyles({
    container: {
      flexGrow: 1, // let the <Container> take all available space
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '3rem',
      marginBottom: '3rem',
    },
    breadcrumb: {
      marginBottom: '2rem',
    },
  }),
);

const Layout = ({ children, breadcrumb_links: links }) => {
  const styles = useStyles();
  return (
    <div className={cssStyles.root}>
      <AppNavbar />
      <main className={cssStyles.main}>
        <Container classes={{ root: styles.container }} fixed>
          {links && (
            <Breadcrumb classes={{ root: styles.breadcrumb }} links={links} />
          )}
          {children}
        </Container>
        <Footer />
      </main>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumb_links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
};

Layout.defaultProps = {
  breadcrumb_links: null,
};

export default Layout;
