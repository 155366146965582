import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Layout from './Layout';

const useStyles = makeStyles(theme =>
  createStyles({
    img: {
      position: 'sticky',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        width: '15rem',
        height: '15rem',
        top: 'calc(50vh - 15rem/2)',
        marginLeft: '2rem',
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        width: '20rem',
        height: '20rem',
        top: 'calc(50vh - 20rem/2)',
        marginLeft: '4rem',
      },
      [theme.breakpoints.up('xl')]: {
        width: '25rem',
        height: '25rem',
        top: 'calc(50vh - 25rem/2)',
        marginLeft: '5rem',
      },
    },
    gridChildren: {
      alignSelf: 'center',
    },
  }),
);

const LayoutWithImg = ({
  imgSrc,
  imgAlt,
  children,
  breadcrumb_links: links,
}) => {
  const styles = useStyles();
  return (
    <Layout breadcrumb_links={links}>
      <Grid container wrap="nowrap">
        <Grid item xs={12} classes={{ root: styles.gridChildren }}>
          {children}
        </Grid>
        <Grid item>
          <img
            className={styles.img}
            src={`${process.env.PUBLIC_URL}/${imgSrc}`}
            alt={imgAlt}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

LayoutWithImg.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  breadcrumb_links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
};

LayoutWithImg.defaultProps = {
  breadcrumb_links: null,
};

export default LayoutWithImg;
