import React from 'react';
import { Link } from 'react-router-dom';
import styles from './PlanearOptions.module.css';

const PlanearOptions = () => (
  <div>
    <div className={styles.titulo}>
      <img
        className="individualModule1"
        src={`${process.env.PUBLIC_URL}/activePlan5.png`}
        alt=""
      />
      <h1 className={styles.titlePlanear}>Planear</h1>
    </div>

    <div className={styles.line}>
      <Link to="/planear/fichastecnicas" className={styles.link}>
        Gerir fichas técnicas
        <img
          className={styles.iconeAtivo}
          src={`${process.env.PUBLIC_URL}/botaoAtivo7.png`}
          alt=""
        />
      </Link>
    </div>

    <div className={styles.line}>
      <Link to="/planear/ementas" className={styles.link}>
        Gerir ementas
        <img
          className={styles.iconeAtivo}
          src={`${process.env.PUBLIC_URL}/botaoAtivo7.png`}
          alt=""
        />
      </Link>
    </div>

    <div className={styles.line}>
      <Link to="/avaliar-diversidade" className={styles.link}>
        Diversidade
        <img
          className={styles.iconeAtivo}
          src={`${process.env.PUBLIC_URL}/botaoAtivo7.png`}
          alt=""
        />
      </Link>
    </div>

    <Link to="/modulos">
      <h5 className={styles.back}>&lt; voltar</h5>
    </Link>
  </div>
);

export default PlanearOptions;
