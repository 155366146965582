import { authTypes } from '../actions/types';

const getInitialState = () => {
  const token = localStorage.getItem('spare_token');
  const userType = localStorage.getItem('spare_user_type');
  if (token) {
    return {
      loggedIn: true,
      token,
      userType,
    };
  }
  return {
    loggedIn: false,
    token: '',
    userType: '',
  };
};

const authReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case authTypes.LOGIN:
      return {
        loggedIn: true,
        token: action.payload.token,
        userType: action.payload.userType,
      };
    case authTypes.LOGOUT:
      return {
        loggedIn: false,
        token: '',
        userType: '',
      };
    default:
      return state;
  }
};

export default authReducer;
