import React from 'react';
import { useMediaQuery } from 'react-responsive';

import Layout from '../components_lgp/layout/Layout';
import Page from '../components/common/Page';
import Info from '../components_lgp/Home/Info';
import Needs from '../components_lgp/Home/Needs';

const HomePage = () => {
  const isMobile = useMediaQuery({ maxWidth: '767px' });
  const isSmallScreen = useMediaQuery({ maxWidth: '1070px' });

  return (
    <Page title="Página Inicial">
      <Layout>
        <Info isMobile={isMobile} isSmallScreen={isSmallScreen} />
        <Needs isMobile={isMobile} isSmallScreen={isSmallScreen} />
      </Layout>
    </Page>
  );
};

export default HomePage;
