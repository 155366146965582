import { notificationTypes } from '../actions/types';

const initialState = {
  message: null,
  type: null,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationTypes.SET_NOTIFICATION:
      return action.data;
    case notificationTypes.CLEAR_NOTIFICATION:
      return initialState;
    default:
      return state;
  }
};

export default notificationReducer;
