import React from 'react';

import Layout from '../components_lgp/layout/Layout';
import Page from '../components/common/Page';
import Create from '../components_lgp/TechnicalSheets/CreateTechnicalSheets';

const TechnicalSheetsCreate = () => {
  return (
    <Page title="Criar Ficha Técnica">
      <Layout
        breadcrumb_links={[
          {
            name: 'Planear',
            url: '/planear',
          },
          {
            name: 'Gerir Fichas Técnicas',
            url: '/planear/fichastecnicas',
          },
          {
            name: 'Criar Ficha Técnica',
            url: `/planear/fichastecnicas/criar`,
          },
        ]}
      >
        <Create />
      </Layout>
    </Page>
  );
};

export default TechnicalSheetsCreate;
