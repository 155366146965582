import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Layout from '../components_lgp/layout/Layout';
import Page from '../components/common/Page';
import Edit from '../components_lgp/TechnicalSheets/EditTechnicalSheets';

const TechnicalSheetsEdit = () => {
  const { state: locationState } = useLocation();
  const { id } = useParams();
  const name = locationState?.name;

  const [techSheetName, setTechSheetName] = useState(name ?? 'Ficha Técnica');

  return (
    <Page title={`Editar ${techSheetName}`}>
      <Layout
        breadcrumb_links={[
          {
            name: 'Planear',
            url: '/planear',
          },
          {
            name: 'Gerir Fichas Técnicas',
            url: '/planear/fichastecnicas',
          },
          {
            name: 'Editar Ficha Técnica',
            url: `/planear/fichastecnicas/${id}/editar`,
          },
        ]}
      >
        <Edit id={id} setTechSheetName={setTechSheetName} />
      </Layout>
    </Page>
  );
};

export default TechnicalSheetsEdit;
