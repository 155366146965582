import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout/Layout';
import Page from '../components/common/Page';

import ResetPasswordForm from '../components/Authentication/ResetPasswordForm';

const ResetPassword = ({ match }) => {
  return (
    <Page title="Recuperar Conta">
      <Layout>
        <ResetPasswordForm token={match.params.token} />
      </Layout>
    </Page>
  );
};

ResetPassword.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default ResetPassword;
