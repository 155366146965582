import React from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { NavLink } from 'reactstrap';

import styles from './Needs.module.css';

const styleFactory = (isMobile, isSmallScreen) => {
  const stylingProps = {
    brownSpaceClass: styles.brownSpace,
    whiteSpaceClass: styles.whiteSpace,
    questionClass: styles.question,
    optionsClass: styles.options,
    needsClass: styles.needs,
    optionClass: styles.option,
  };

  if (isMobile) {
    stylingProps.whiteSpaceClass = styles.whiteSpaceMobile;
    stylingProps.questionClass = styles.questionMobile;
    stylingProps.brownSpaceClass = styles.brownSpaceMobile;
    stylingProps.optionsClass = styles.optionsMobile;
  } else if (isSmallScreen) {
    stylingProps.whiteSpaceClass = styles.whiteSpaceSmall;
    stylingProps.questionClass = styles.questionSmall;
    stylingProps.brownSpaceClass = styles.brownSpaceSmall;
    stylingProps.optionsClass = styles.optionsSmall;
  }

  return stylingProps;
};

const Needs = ({ isMobile, isSmallScreen }) => {
  const elementClasses = styleFactory(isMobile, isSmallScreen);

  return (
    <div className={elementClasses.needsClass}>
      <div className={elementClasses.whiteSpaceClass}>O que</div>
      <div className={elementClasses.brownSpaceClass}>
        <div className={elementClasses.questionClass}>Precisas?</div>
        <div className={elementClasses.optionsClass}>
          <div className={elementClasses.optionClass}>
            <NavLink tag={RRNavLink} exact to="/consultar">
              <img
                src={`${process.env.PUBLIC_URL}/activeConsult4.png`}
                alt="Seguir para consultar"
              />
              Consultar
            </NavLink>
          </div>
          <div className={elementClasses.optionClass}>
            <NavLink tag={RRNavLink} exact to="/planear">
              <img
                src={`${process.env.PUBLIC_URL}/activePlan5.png`}
                alt="Seguir para planear"
              />
              Planear
            </NavLink>
          </div>
          <div className={elementClasses.optionClass}>
            <NavLink tag={RRNavLink} exact to="/avaliar">
              <img
                src={`${process.env.PUBLIC_URL}/activeEvaluate6.png`}
                alt="Seguir para avaliar"
              />
              Avaliar
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

Needs.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
};

export default Needs;
