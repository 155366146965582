import axios from 'axios';

/* eslint-disable import/prefer-default-export */
export const getUsersByDistrict = () => {
  return axios({
    method: 'get',
    url: '/api/admin/users_by_district',
  });
};

export const updateRecommendation = async (age, meal, recommendation) => {
  const criteria = recommendation.reduce((obj, item) => {
    return {
      ...obj,
      [item.name]: {
        min_value: +item.minValue,
        max_value: +item.maxValue,
      },
    };
  }, {});
  return axios.post(`/api/nutritionalRecommendations`, {
    age,
    meal,
    criteria: JSON.stringify(criteria),
  });
};
