import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Card, CardBody, CardHeader } from 'reactstrap';
import OrganizationList from './OrganizationList/OrganizationList';
import { changeProfile, getProfile } from '../../services/profileService';
import { districts, occupations } from './Resources';
import ChangeInstitutionForm from './ChangeInstitution';
import ChangePasswordForm from './ChangePassword';

import {
  clearNotification,
  setNotification,
} from '../../actions/notificationActions';

const useStyles = makeStyles(theme => ({
  form: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    padding: '1.5em',
    width: 'inherit',
    '& > *': {
      margin: theme.spacing(1),
      width: 'inherit',
    },
  },
  field: {
    width: '100%',
  },
  pos: {
    marginBottom: 12,
  },
  spinner: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  large: {
    width: theme.spacing(18),
    height: theme.spacing(18),
    fontSize: '4em',
    margin: 'auto',
  },
  text: {
    padding: '2em',
  },
  name: {
    fontWeight: 'bolder',
    fontSize: '3em',
  },
  btn: {
    width: 'fit-content',
  },
  box: {
    padding: '2em',
  },
}));

const EditUserProfile = () => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const [profile, setProfile] = useState('');
  const [name, setProfileName] = useState('');
  const [email, setProfileEmail] = useState('');
  const [occupation, setProfileOccupation] = useState('');
  const [district, setProfileDistrict] = useState('');

  const submitFormProfile = e => {
    e.preventDefault();
    dispatch(clearNotification());

    changeProfile({
      name,
      email,
      occupation,
      district,
    })
      .then(() => {
        dispatch(
          setNotification({
            message: 'O seu perfil foi actualizado.',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        if (error.response.status !== 200) {
          dispatch(
            setNotification({
              message: 'Ocorreu um erro ao actualizar o perfil.',
              type: 'error',
            }),
          );
        }
      });
  };

  if (!profile) {
    getProfile().then(({ data }) => {
      setProfile(data);
      setProfileName(data.name);
      setProfileEmail(data.email);
      setProfileOccupation(data.occupation);
      setProfileDistrict(data.district);
    });
  }

  return (
    <>
      <Grid container>
        {!profile && (
          <div className={styles.spinner}>
            <CircularProgress />
          </div>
        )}
        {profile && (
          <>
            <Grid item xs={12} md={3} lg={2}>
              <Avatar
                alt={profile.name}
                src="/profile-image.jpg"
                className={styles.large}
              />
            </Grid>
            <Grid item xs={12} md={9} lg={8}>
              <Grid container alignItems="flex-start">
                <Grid item xs={12} className={styles.text}>
                  <Typography
                    variant="h2"
                    component="span"
                    className={styles.name}
                  >
                    {profile.name}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6} className={styles.box}>
                  <form
                    className={styles.form}
                    autoComplete="off"
                    onSubmit={submitFormProfile}
                  >
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        label="Nome"
                        required
                        placeholder={name}
                        className={styles.field}
                        onChange={e => setProfileName(e.target.value)}
                        value={name}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="email"
                        label="E-mail"
                        required
                        type="email"
                        placeholder={email}
                        onChange={e => setProfileEmail(e.target.value)}
                        className={styles.field}
                        value={email}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="occupation"
                        label="Ocupação"
                        select
                        required
                        placeholder={occupation}
                        onChange={e => setProfileOccupation(e.target.value)}
                        className={styles.field}
                        value={occupation}
                      >
                        {occupations.map(occupationName => (
                          <MenuItem value={occupationName} key={occupationName}>
                            {occupationName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="district"
                        select
                        required
                        label="Distrito"
                        placeholder={district}
                        onChange={e => setProfileDistrict(e.target.value)}
                        className={styles.field}
                        value={district}
                      >
                        {districts.map(districtName => (
                          <MenuItem value={districtName} key={districtName}>
                            {districtName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      type="submit"
                      label="submit"
                      className={styles.btn}
                    >
                      Alterar
                    </Button>
                  </form>
                </Grid>

                <Grid item xs={12} md={6} className={styles.box}>
                  <ChangeInstitutionForm />
                </Grid>

                <Grid item xs={12} md={6} className={styles.box}>
                  <ChangePasswordForm />
                </Grid>

                <Grid item xs={12} md={6} className={styles.box}>
                  <Card>
                    <CardHeader>As minhas organizações</CardHeader>
                    <CardBody>
                      <OrganizationList />
                    </CardBody>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default EditUserProfile;
