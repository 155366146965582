import axios from 'axios';

const baseUrl = '/api/meals';

export const fetchAllMeals = async ({ day, month, year, interval }) => {
  return axios.get(baseUrl, {
    params: { day, month, year, interval },
  });
};

export const fetchMealsType = async ({ day, month, year, type }) => {
  return axios.get(`${baseUrl}/day`, {
    params: { day, month, year, type },
  });
};

export const fetchMeal = async id => axios.get(`${baseUrl}/${id}`);

export const deleteMeal = async id => {
  return axios.delete(`${baseUrl}/${id}`);
};

export const createMeal = async (meals, day, month, year, type) => {
  const obj = {
    info: meals,
  };
  return axios.post(`${baseUrl}/${day}/${month}/${year}/${type}`, obj);
};

export const fetchPDFMealPlanMonth = async ({ date, meals, organizationId }) => {
  return axios.get(`${baseUrl}/downloadMonth/${date}`, {
    params: {
      meals,
      organizationId,
    },
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
    },
  });
};

export const fetchPDFMealPlan = async ({ date, meals, organizationId }) => {
  return axios.get(`${baseUrl}/download/${date}`, {
    params: {
      meals,
      organizationId,
    },
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
    },
  });
};

export const fetchMealComponents = async (
  cancelToken = axios.CancelToken.source().token,
  type,
) => {
  return axios.get(`${baseUrl}/${type}/components`, { cancelToken });
};

export const fetchAllMealRecommendations = async () =>
  axios.get(`${baseUrl}/nutritionalRecommendations`);

export const fetchMealRecommendations = async (
  cancelToken = axios.CancelToken.source().token,
  type,
  ageRange,
) => {
  return axios.get(`${baseUrl}/nutritionalRecommendations/query`, {
    cancelToken,
    params: { meal: type, age: ageRange },
  });
};
