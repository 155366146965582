import React, { useContext, useState } from 'react';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant';
import propTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useStyles } from '../styles';
import { MealsContext } from '../MealsContextProvider';
import PlanningDayMealComponent from './PlanningDayMealComponent';
import { submitOrganizationDailyMeal } from '../../../services/organizationsService';
import { Icon } from '../../common';
import FeedbackRow from './FeedbackRow';

const moment = require('moment');

/**
 * Given an daily meal object, returns an array of all technical sheet IDs used
 *
 * The daily meal object is plain with each key being a meal component name
 * which in turn maps to technical sheet object
 * @param {Object} mealPlan
 */
const getTechnicalSheetIDs = mealPlan => {
  const arr = [];
  Object.values(mealPlan).forEach(techSheet => {
    // test if the technical sheet is not null. If not, add the ID to array
    if (techSheet) arr.push(techSheet.id);
  });
  return arr;
};

/**
 * Represents a row in the meal planning grid. It aggreates all inputs needed to
 * plan the multiple components of a meal for a given date
 */
const PlanningDayRow = props => {

  

  /** Classes for styling */
  const classes = useStyles();
  /** Props */
  const {
    date,
    addWeekTechSheet,
    updateWeekTechSheet,
    deleteWeekTechSheet,
  } = props;
  /** Create a moment object for date */
  const momentDate = moment(date, moment.ISO_8601);
  const dateFormatted = momentDate.format('YYYY-MM-DD');
  /** Retrieve data from the context provider */
  const {
    /** A list of the different components that compose a meal. E.g. a
     * breakfast is made of a drink, fruit, among other elements.
     * Each meal component will have a list of technical sheets owned
     * by the user and that are suited for this meal component
     */
    mealComponents,
    /**
     * The existing meal plan for the selected organization, date and meal type
     */
    organizationMeals,
    /** The current meal type being planned */
    userInput: { selectedMealType, selectedOrganization },
    /** A hook function to report there's a new pending request */
    pendingRequestsDispatch,
    userInput,
  } = useContext(MealsContext);

  const [state, setState] = React.useState(() => {
    const dates = [dateFormatted];
    const techSheets = {};

    const dateMeals = organizationMeals[dateFormatted];
    if (dateMeals) {
      const categories = dateMeals.technical_sheets;
      Object.keys(categories).forEach(category => {
        const sheet = categories[category];
        const weekSheets = techSheets[category];
        if (sheet) {
          sheet.date = dateFormatted;
          techSheets[category] = weekSheets
            ? [...weekSheets, sheet]
            : [sheet];
        }
      });
    }
    if (Object.keys(techSheets).length === 0) {
      mealComponents.forEach(component => {
        techSheets[component.name] = [];
      });
    }
    return {dates, techSheets} ;
  });

  const addTechSheet = (category, sheet) => {
    const { techSheets } = state;
    const currentSheets = techSheets[category];
    const newTechSheets = {
      ...techSheets,
      [category]: currentSheets ? [...currentSheets, sheet] : [sheet],
    };
    setState({ ...state, techSheets: newTechSheets });
  };

  const updateTechSheet = (category, prevSheet, sheet) => {
    const { techSheets } = state;
    const currentSheets = techSheets[category];
    const index = currentSheets.findIndex(ts => ts.id === prevSheet.id);
    currentSheets.splice(index, 1, sheet);
    const newTechSheets = {
      ...techSheets,
      [category]: currentSheets,
    };
    setState({ ...state, techSheets: newTechSheets });
  };

  const deleteTechSheet = (category, prevSheet) => {
    const { techSheets } = state;
    const currentSheets = techSheets[category];
    const index = currentSheets.findIndex(ts => ts.id === prevSheet.id);
    currentSheets.splice(index, 1);
    const newTechSheets = {
      ...techSheets,
      [category]: currentSheets,
    };
    setState({ ...state, techSheets: newTechSheets });
  };

  /**
   * Debounced API call to submit new changes in technical sheets for this meal
   */
  const submitDailyMealDebounced = useConstant(() =>
    AwesomeDebouncePromise(
      (technicalSheetsIds, organizationId, mealDate, mealType) => {
        // increment number of pending requests
        pendingRequestsDispatch({ type: 'INC' });
        // return request promise
        return submitOrganizationDailyMeal(
          technicalSheetsIds,
          organizationId,
          mealDate,
          mealType,
        ).then(() =>
          // request completed. Decrease number of pending requests
          pendingRequestsDispatch({ type: 'DEC' }),
        );
      },
      500,
      { keys: [date] },
    ),
  );
  /**
   * Hook for this meal plan
   *
   * It's a plain object where each key is a meal component name
   * which maps to one technical sheet object or null, in case
   * no technical sheets were assigned.
   * The object might be empty in case no plan exists yet
   */
  const [dailyMeal, setDailyMeal] = useState(
    (organizationMeals &&
      organizationMeals[dateFormatted] &&
      organizationMeals[dateFormatted].technical_sheets) ||
      {},
  );
  /**
   * A setter to be used by child components to update the technical sheets
   * assigned to this meal.
   *
   * It updates the shared state and calls the debounced function to make the
   * request.
   *
   * @param {string} componentName - The name of meal component
   * @param {object|null} selectedTechnicalSheet - A technical sheet
   * object being assigned by the user, or null to drop any assigned
   * techical sheet
   */
  const setMeals = async (componentName, selectedTechnicalSheet) => {
    setDailyMeal(prevState => {
      // compute the new state
      const newState = {
        ...prevState,
        [componentName]: selectedTechnicalSheet,
      };
      // call async debounced function to send the request
      submitDailyMealDebounced(
        getTechnicalSheetIDs(newState),
        selectedOrganization.id,
        momentDate.format('YYYY-MM-DD'),
        selectedMealType.code,
      );
      const prevTechSheet = prevState[componentName];
      if (selectedTechnicalSheet) {
        const sheet = {
          ...selectedTechnicalSheet,
          date: dateFormatted,
        };
        if (prevTechSheet) {
          updateTechSheet(componentName, prevTechSheet, sheet);
          updateWeekTechSheet(componentName, prevTechSheet, sheet);
        } else {
          addWeekTechSheet(componentName, sheet);
          addTechSheet(componentName, sheet);
        }
      } else {
        deleteWeekTechSheet(componentName, prevTechSheet);
        deleteTechSheet(componentName, prevTechSheet);
      }
      // return the new hook state
      return newState;
    });
  };
  const mealType = userInput.selectedMealType.code;

  return (
    <Grid container item className={classes.dayRow}>
      <Grid container item className={classes.tableRow}>
        <Grid className={classes.weekDay}>
          {momentDate.format('ddd')}
        </Grid>
        <Grid container item className={classes.mealComponents}>
          {mealComponents.map(({ name, technicalSheets }) => (
            <Grid item xs>
            <Grid key={name} md item>
              <div className={classes.component}>
                <div>
                  {name ===
                  'Prato (carne, pescado, ovo ou outro fornecedor proteico)' ? (
                    <Icon name={mealType} />
                  ) : (
                    <Icon name={name} />
                  )}
                </div>
                <div>{name}</div>
              </div>
            </Grid>
            <Grid item xs>
              <PlanningDayMealComponent
            key={`weekDayCol_${name}`}
            date={momentDate}
            mealComponentName={name}
            technicalSheetsOptions={technicalSheets}
            selectedTechnicalSheet={(dailyMeal && dailyMeal[name]) || null}
            setSelectedTechnicalSheet={setMeals}
          />
            </Grid>
          </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container item spacing={1}>
        {state.techSheets && (
          <FeedbackRow
            numDays={1}
            weekTechnicalSheets={state.techSheets}
          />
        )}
      </Grid>
    </Grid>
  );
};

PlanningDayRow.propTypes = {
  date: propTypes.string.isRequired,
  addWeekTechSheet: propTypes.func.isRequired,
  updateWeekTechSheet: propTypes.func.isRequired,
  deleteWeekTechSheet: propTypes.func.isRequired,
};

export default PlanningDayRow;
