import React from 'react';
import Layout from '../components_lgp/layout/Layout';
import ReportSection from '../components/Reports/ReportSection';

const links = [
  {
    name: 'Avaliar',
    url: '/avaliar',
  },
  {
    name: 'Lista de Verificação',
  },
];

const ReportSectionPage = () => (
  <Layout breadcrumb_links={links}>
    <ReportSection />
  </Layout>
);

export default ReportSectionPage;
