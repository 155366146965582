import { reportTypes } from './types';

export const setReportName = name => async dispatch => {
  dispatch({
    type: reportTypes.SET_REPORT_NAME,
    payload: name,
  });
};

export const setReportDate = date => async dispatch => {
  dispatch({
    type: reportTypes.SET_REPORT_DATE,
    payload: date,
  });
};

export const setSections = sections => async dispatch => {
  dispatch({
    type: reportTypes.SET_SECTIONS,
    payload: sections,
  });
};
