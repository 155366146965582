import React from 'react';

import styles from './Progress.module.css';

const Progress = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.ring} />
      <p className={styles.text}>A carregar...</p>
    </div>
  );
};

export default Progress;
