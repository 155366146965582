import React from 'react';

const panelReferences = (
  <>
    <h4>Geral</h4>
    <p>
      Álvarez, J.R.M., Allué, I.P. (2008). El libro blanco de la alimentación
      escolar. Madrid: McGrawHill.
    </p>
    <p>
      Azcona, A.C., Fontanillo, J.A.P. (2006). El desayuno saludable. Madrid:
      Dirección General de Salud Pública y Alimentación. Consejería de Sanidad y
      Consumo.
    </p>
    <p>
      Bramorski, A., Vasconcelos, K., Tono, A.M., Biagi, C.H.F., Abreu,
      L.R.S.S., Hruschka, V. (2005). Avaliação das áreas físicas de creches
      domiciliares do município de Joinville, SC. Revista Higiene Alimentar
      19(132): 24-28.
    </p>
    <p>
      British Nutrition Foundation (2005). Cardiovascular Disease: diet,
      nutrition and emerging risk factors: report of a British Nutrition Task
      Force. Oxford: Blackwell Publishing.
    </p>
    <p>
      Candeias, V., Nunes, E., Morais, C., Cabral, M., Silva, P.R. (2005).
      Princípios para uma alimentação saudável. Lisboa: Direção-Geral da Saúde.
    </p>
    <p>
      Carlsson-Kanyama, A., González, A.D. (2009). Potential contributions of
      food consumption patterns to climate change. Am J Clin Nutr 89 (suppl):
      1704S–1709S.
    </p>
    <p>
      Circular n.º 11/DGIDC/2007. Recomendações para os bufetes escolares.
      Lisboa: Ministério da Educação. Direção-Geral de Inovação e do
      Desenvolvimento Curricular.
    </p>
    <span>
      Circular n.º 3097/DGE/2018: Orientações sobre ementas e refeitórios
      escolares 2018. Lisboa: Ministério da Educação e Ciência. Direção Geral da
      Educação. Disponível em{' '}
      <a href="https://www.dge.mec.pt/sites/default/files/Esaude/oere.pdf">
        <p>https://www.dge.mec.pt/sites/default/files/Esaude/oere.pdf</p>
      </a>
    </span>
    <p>
      Comission E. Farm to fork Strategy - For a fair, healthy and environmentally-friendly food system.  2020.
    </p>
    <p>
      Conselho Nacional de Alimentação e Nutrição (1999). Recomendações para a
      educação alimentar da população Portuguesa. Lisboa: CNAN.
    </p>
    <p>
      Decreto-Lei n.º 55/2009 de 2 de março, que estabelece o regime jurídico
      aplicável à atribuição e ao funcionamento dos apoios no âmbito da ação
      social escolar.
    </p>
    <p>
      Decreto-Lei n.º 26/2016, de 9 de junho, que fixa as normas de prestação de
      informação relativas aos géneros alimentícios não pré-embalados.
    </p>
    <p>
      Direção Geral de Educação. Refeitórios Escolares. Orientações sobre ementas e refeitórios escolares. 2018. Disponível em: https://www.dge.mec.pt/sites/default/files/boletim/refeitorios_escolares_-_nova_circular.pdf.
    </p>
    <p>
      EAT. Summary Report of the EAT-Lancet Commission: Healthy Diets From Sustainable Food Systems - Food Planet Health.  2019. 32. Disponível em: thelancet.com/commissions/EAT.
    </p>
    <p>
      Eshel, G., Martin, P.A. (2009). Geophysics and nutritional science: toward
      a novel, unified paradigm. Am J Clin Nutr 89 (suppl): 1710S–1716S.
    </p>
    <p>
      Faculdade de Ciências da Nutrição e Alimentação da Universidade do Porto,
      Instituto do Consumidor (2002). Guia de segurança alimentar em ambiente
      escolar. Lisboa: IC.
    </p>
    <p>
      Food and Nutrition Service (FNS), USDA (2012). Nutrition standards in the
      national school lunch and school breakfast programs. Federal Register Vol.
      77, No.17/Thursday, January 26, 2012/Rules and Regulations.
    </p>
    <span>
      Food Standards Agency (2002). Catering for health. A guide for teaching
      healthier catering practices. Food Standards Agency Scotland; Scottish
      Executive Health Department. Disponível em{' '}
      <a href="http://www.food.gov.uk/multimedia/pdfs/cateringforhealthscot.pdf">
        <p>http://www.food.gov.uk/multimedia/pdfs/cateringforhealthscot.pdf</p>
      </a>
    </span>
    <p>
      Fourth Joint Task Force of the European Society of Cardiovascular and
      other Societies on Cardiovascular Disease Prevention in Clinical Practice
      (2007). European guidelines on cardiovascular disease prevention in
      clinical practice: executive summary. Eur Heart J 28(19): 2375-2414.
    </p>
    <span>
      Gebhardt, S.E., Thomas, R.G. (2002). Nutritive value of foods. Beltsville,
      Martland: U.S. Department of Agriculture, Agriculture Research Service.
      Disponível em{' '}
      <a href="http://www.ars.usda.gov/SP2UserFiles/Place/12354500/Data/hg72/hg72_2002.pdf">
        <p>
          http://www.ars.usda.gov/SP2UserFiles/Place/12354500/Data/hg72/hg72_2002.pdf
        </p>
      </a>
    </span>
    <p>
      Giddings, S.S., Dennison, B.A., Birch, L.L., Daniels, S.R., Gilman, A.H.,
      Lichtenstein, A.H., Rattay, K.T., Steinberger, J., Stettler, N., Van Horn,
      L. (2006). AHA Dietary recommendations for children and adolescents: a
      guide for practionares. Pediatrics. 117(2): 544-559.
    </p>
    <p>
      Gulbenkian F. O Futuro da Alimentação: Ambiente, Saúde e Economia. 2013. Commission on Environment and Development: Our Common Future. Disponível em: https://sustainabledevelopment.un.org/content/documents/5987our-common-future.pdf.
    </p>
    <p>
      Holland, B., McCance R.A. and Widdowson E.M. (1991) McCance and
      Widdowson&apos;s the composition of foods, Cambridge: Royal Society of
      Chemistry.
    </p>
    <p>
      Instituto Nacional de Saúde Dr. Ricardo Jorge. Centro de Segurança
      Alimentar e Nutrição (2006). Tabela de Composição dos Alimentos. Lisboa:
      INSA.
    </p>
    <p>
      Institute of Medicine (2009). School meals: building blocks for healthy
      children. Washington: The National Academies Press.
    </p>
    <p>
      Institute of Medicine (2007). Nutrition standards for foods in schools:
      leading the way toward healthier youth. Washington: The National Academies
      Press.
    </p>
    <span>
      Institute of Medicine (2005). Dietary Reference Intake for Energy,
      Carbohydrate, Fiber, Fat, Fatty Acids, Cholesterol, Protein, and Amino
      Acids. Washington, DC: The National Academies Press. Disponível em{' '}
      <a href="https://doi.org/10.17226/10490">
        <p>https://doi.org/10.17226/10490</p>
      </a>
    </span>
    <p>
      Ladeiras, L, Lima, R.M., Lopes, A. (2012). Bufetes Escolares -
      Orientações. Lisboa: Ministério da Educação e Ciência. Direção Geral da
      Educação.
    </p>
    <p>
      Lei n.º 159/99 de 14 de setembro, que estabelece o quadro de transferência
      de competências para as autarquias locais.
    </p>
    <p>
      Lei n.º 11/2017 de 17 de abril, que estabelece a obrigatoriedade de
      existência de opção vegetariana nas ementas das cantinas e refeitórios
      públicos.
    </p>
    <p>
      Lichtenstein, A.H., Appel, L.J., Brands, M., Carnethon, M., Daniels, S.,
      Franch, H.A., Franklin, B., Kris-Etherton, P., Harris, W.S., Howard, B.,
      Karanja, N., Lefevre, M., Rudel, L., Sacks, L., Van Horn, L., Winston, M.,
      Wylie-Rosett, J. (2006). Diet and Lifestyle Recommendations Revision 2006:
      A Scientific Statement from the American Heart Association. Circulation
      114: 82-96.
    </p>
    <span>
      Lobato, L., Silva, S.G., Crâmes, M., Santos, C.T., Graça, P. (2016)
      Planeamento de refeições vegetarianas para crianças em restauração
      coletiva: Princípios Base. Lisboa: Programa Nacional para a Promoção da
      Alimentação Saudável; Direção Geral da Saúde. Disponível em{' '}
      <a href="https://nutrimento.pt/activeapp/wp-content/uploads/2016/11/Planeamento-de-refei%c3%a7%c3%b5es-vegetarianas-para-crian%c3%a7as-em-restaura%c3%a7%c3%a3o-coletiva_principios-base.pdf">
        <p>
          https://nutrimento.pt/activeapp/wp-content/uploads/2016/11/Planeamento-de-refei%c3%a7%c3%b5es-vegetarianas-para-crian%c3%a7as-em-restaura%c3%a7%c3%a3o-coletiva_principios-base.pdf
        </p>
      </a>
    </span>
    <p>
      Lynch, M., Painter, J., Woodruff, R., Braden, C. (2006). Surveillance for
      foodborne-disease outbreaks. United States 1998-2002. Centers for Disease
      Control and Prevention. Surveillance Annual Reports 10: 1-34.
    </p>
    <p>
      Marinho, A. (2009). Fiscalização municipal e análise de risco [Tese de
      Licenciatura]. Porto: Faculdade de Ciências da Nutrição e Alimentação da
      Universidade do Porto.
    </p>
    <p>
      Marlow, H.J., Hayes, W.K., Soret, S., Carter, R.L., Schwab, E.R., Sabate,
      J. (2009) Diet and the environment: does what you eat matter? Am J Clin
      Nutr 89 (suppl): 1699S–1703S.
    </p>
    <p>
      Marques, M., Franchini, B., Faria, F., de Almeida, M.D.V. (1995).
      Avaliação nutricional de idosos frequentadores de centros de dia e de
      convívio. Alimentação Humana 1(3): 18-29.
    </p>
    <p>
      Meister, K. (2002). The role of eggs in the diet. American Council on
      Science and Health.
    </p>
    <p>
      Moreira, P. (2005). Obesidade. Muito peso, várias medidas. Lisboa: Ambar.
    </p>
    <p>
      Núcleo de Estudos e Pesquisas em Alimentação da Universidade Estadual de
      Campinas. (2006). Tabela Brasileira de Composição de Alimentos. 2ª ed.
      S.Paulo: NEPA/UNICAMP.
    </p>
    <p>
      Nogueira, J.M.R. (2004). O EURO 2004: A intervenção dos serviços de saúde
      pública. Resumo de comunicação oral no Congresso Qualidade e Segurança
      Alimentar inserido na XVI Semana de Ciências da Nutrição, Porto.
    </p>
    <span>
      Pádua, I., Barros, R., Moreira, P. Moreira, A. (2016). Alergia Alimentar
      na Restauração. Lisboa: Programa Nacional para a Promoção da Alimentação
      Saudável; Direção-Geral da Saúde. Disponível em{' '}
      <a href="https://nutrimento.pt/activeapp/wp-content/uploads/2016/05/Alergia-Alimentar-na-Restaura%c3%a7%c3%a3o.pdf">
        <p>
          https://nutrimento.pt/activeapp/wp-content/uploads/2016/05/Alergia-Alimentar-na-Restaura%c3%a7%c3%a3o.pdf
        </p>
      </a>
    </span>
    <p>
      Peres, E. (1994). Saber comer para melhor viver. 3ªed. Lisboa: Editorial
      Caminho.
    </p>
    <span>
      Pinho, J.P., Silva, S.C.G., Borges, C., Santos, C.T., Santos, A., Guerra,
      A., Graça, P. (2016). Alimentação Vegetariana em Idade Escolar. Lisboa:
      Programa Nacional para a Promoção da Alimentação Saudável; Direção-Geral
      da Saúde. Disponível em{' '}
      <a href="https://nutrimento.pt/activeapp/wp-content/uploads/2016/04/Alimenta%c3%a7%c3%a3o-Vegetariana-em-Idade-Escolar-.pdf">
        <p>
          https://nutrimento.pt/activeapp/wp-content/uploads/2016/04/Alimenta%c3%a7%c3%a3o-Vegetariana-em-Idade-Escolar-.pdf
        </p>
      </a>
    </span>
    <p>
      Portaria n.º 1242/2009 de 12 de outubro, que aprova o Regulamento do
      Regime de Fruta Escolar.
    </p>
    <p>
      Ortiz, C.D. (2008). Experiência em uma creche: avaliação das condições
      higiénico-sanitárias de uma creche da região metropolitana da cidade de
      São Paulo [Curso De Pós Graduação latu sensu em Higiene e Inspeção em
      Produtos de Origem Animal]. São Paulo: Universidade de Castelo Branco.
    </p>
    <p>
      Rampersaud, G.C., Pereira, M.A., Girard, B.J., Adams, J., Metzl, J.D.
      (2005). Breakfast habits, nutritional status, body weight, and academic
      performance in children and adolescents. J Am Diet Assoc 105(5): 743-760.
    </p>
    <p>
      Regulamento (UE) n.º 1169/2011 do Parlamento Europeu e do Conselho, de 25
      de outubro, relativo à prestação de informação aos consumidores sobre
      géneros alimentícios (com retificações publicadas a 18 de novembro de
      2014).
    </p>
    <p>
      Rodrigo, C.P., Barba, L.R., Majem, L.S., Bartrina, J.A. (2004).
      Recomendaciones para un desayuno saludable. In: Majem, L.S., Bartrina,
      J.A., directores. Desayuno y equilíbrio alimentario. Estudio en enKid.
      Barcelona: Masson, p.91-97.
    </p>
    <p>
      Rodrigues, S.S.P., Franchini, B., Graça, P., de Almeida, M.D.V. (2006). A
      new food guide for the Portuguese population: development and technical
      considerations. J Nutr Educ Behav 38: 189-195.
    </p>
    <p>
      Rozane MT, Sergio S. Reconstruindo o “elo perdido”: a reconexão da produção e do consumo de alimentos através do Programa De Alimentação Escolar no município de Dois Irmãos (RS). Segurança Alimentar e Nutricional. 2010; 17(1):1-15.
    </p>
    <p>
      Sancho, T., Candeias, A., Mendes, C., Silvestre, L., Cartaxo, L., Andrade,
      S. (2008). Promoção da qualidade nutricional das refeições em
      estabelecimentos de educação do Algarve: análise comparativa 2004/2005 -
      2006/2007. Nutrícias 8: 16-19.
    </p>
    <p>
      Santos, M.J.D.O., Nogueira, J.M.R., Mayan, O. (2007). Condições
      higiossanitárias das cantinas escolares do distrito de Vila Real. Revista
      Portuguesa de Saúde Pública 25 (2): 51-58.
    </p>
    <span>
      Silva, S.C.G., Pinho, J.P., Borges, C., Santos, C.T., Santos, A., Graça,
      P. (2016). Linhas de Orientação para uma Alimentação Vegetariana Saudável.
      Lisboa: Programa Nacional para a Promoção da Alimentação Saudável;
      Direção-Geral da Saúde. Disponível em{' '}
      <a href="https://nutrimento.pt/activeapp/wp-content/uploads/2015/07/Linhas-de-Orienta%c3%a7%c3%a3o-para-uma-Alimenta%c3%a7%c3%a3o-Vegetariana-Saud%c3%a1vel.pdf">
        <p>
          https://nutrimento.pt/activeapp/wp-content/uploads/2015/07/Linhas-de-Orienta%c3%a7%c3%a3o-para-uma-Alimenta%c3%a7%c3%a3o-Vegetariana-Saud%c3%a1vel.pdf
        </p>
      </a>
    </span>
    <p>
      Sousa A. Ementas Escolares Sustentáveis: proposta de critérios para avaliação qualitativa. Dissertação de Mestrado em Alimentação Coletiva da Faculdade de Ciências da Nutrição e Alimentação da U. Porto, 2021.
    </p>
    <span>
      U.S. Department of Health and Human Services, U.S. Department of
      Agriculture (2015). Dietary Guidelines for Americans 2015–2020. 8th
      Edition. Disponível em{' '}
      <a href="http://health.gov/dietaryguidelines/2015/guidelines/">
        <p>http://health.gov/dietaryguidelines/2015/guidelines/</p>
      </a>
    </span>
    <p>
      Van Horn, L., McCoin, M., Kris-Etherton, P.M., Burke, F., Carson, J.A.S.,
      Champagne, C.M., Karmally, W., Sikand, G. (2008). The evidence for dietary
      prevention and treatment of cardiovascular disease. J Am Diet Assoc 108:
      287-331.
    </p>
    <p>
      Veiros, M., Campos, G., Ruivo, I., Proença, R., Rocha, A., Kent-Smith, L.
      (2007). Avaliação qualitativa de ementas. Método AQE. Alimentação Humana
      13(3): 62-78.
    </p>

    <h4>
      Enquadramento legal e normativo das grelhas de avaliação higiossanitária
    </h4>
    <span>
      Amorim, J. (2004). Lista de Verificação: Higiene Alimentar na Restauração
      Colectiva. Lisboa: Instituto Nacional de Saúde Dr. Ricardo Jorge.
      Disponível em:{' '}
      <a href="http://www.insa.pt/sites/INSA/Portugues/Publicacoes/Outros/Documents/AlimentacaoNutricao/ListaVerificacaoV2.pdf">
        <p>
          http://www.insa.pt/sites/INSA/Portugues/Publicacoes/Outros/Documents/AlimentacaoNutricao/Lis<br></br>taVerificacaoV2.pdf
        </p>
      </a>
    </span>
    <p>
      Autoridade de Segurança Alimentar e Económica (2006). Ficha técnica de
      fiscalização – Estabelecimentos de restauração e bebidas.
    </p>
    <p>
      CAC/RCP 1-1969. Rev.4-2003. Recommended International Code of Practice.
      General principles of food hygiene. Rome: Food and Agriculture
      Organization of the United Nations and World Health Organization.
    </p>
    <p>
      CAC/RCP 1-1969. Rev. 4-2003. Código Internacional Recomendado de Práticas.
      Princípios Gerais de Higiene dos Alimentos. Roma: Programa Conjunto de
      Normas Alimentares da FAO/OMS (versão portuguesa).
    </p>
    <p>
      Decreto-Lei n.º 243/86 de 20 de agosto, que aprova o Regulamento Geral da
      Higiene e Segurança do Trabalho nos Estabelecimentos Comerciais, de
      Escritórios e Serviços (retificado pela Declaração de 31 de outubro de
      1986).
    </p>
    <p>
      Decreto-Lei nº 113/2006, de 12 de junho, que estabelece o regime
      sancionatório aplicável às infrações às normas dos Regulamentos (CE)
      nº852/2004 e nº853/2004 (retificado pela Declaração de 11 de agosto de
      2006 e alterado pelo Decreto-Lei n.º 223/2008).
    </p>
    <p>
      Decreto-Lei n.º 178/2006, de 5 de setembro, que estabelece o regime geral
      de gestão de resíduos (versão mais recente Decreto-Lei n.º 152/2017 de
      11/12)
    </p>
    <p>
      Decreto-Lei n.º 306/2007, de 27 de agosto, que regula a qualidade da água
      destinada ao consumo humano (alterado pelo Decreto-Lei n.º 92/2010, de 26
      de julho o pelo Decreto-Lei n.º 306/2007, de 27 de agosto).
    </p>
    <p>
      Decreto-Lei n.º 48/2011, de 1 de abril, que estabelece o regime de
      instalação e modificação dos estabelecimentos de restauração ou de bebidas
      (alterado pelo Decreto-lei n.º 10/2015, de 16/01).
    </p>
    <p>
      Decreto-Lei n.º 73/2011, de 17 de junho, que estabelece o regime geral
      aplicável à prevenção, produção e gestão de resíduos.
    </p>
    <p>
      Decreto-Lei n.º 10/2015, de 16 de janeiro, que aprova o regime jurídico de
      acesso e exercício de atividades de comércio, serviços e restauração.
    </p>
    <p>
      FAO/WHO/Codex Alimentarius, Codes of Practice (CCFH); General Principles of 
      Food Hygiene CXC 1-1969, FAO/WHO/Codex, 2020
    </p>
    <p>
      Guilherme, S. (2006). Segurança alimentar em unidades de restauração
      escolares [Tese de Licenciatura]. Porto: Faculdade de Ciências da Nutrição
      e Alimentação da Universidade do Porto.
    </p>
    <p>
      Lei n.º 102/2009 de 10 de setembro relativo ao regime jurídico da promoção
      da segurança e saúde no trabalho (versão mais recente Lei n.79/2019, de
      02/09).
    </p>
    <p>
      Lobato, L.P. (2009). Análise de risco e avaliação higiossanitária de
      refeitórios escolares [Tese de Licenciatura]. Porto: Faculdade de Ciências
      da Nutrição e Alimentação da Universidade do Porto.
    </p>
    <p>
      Lopes, P. (2007). Grelha de avaliação higiossanitária de estabelecimentos
      de restauração ou de bebidas. Alimentação Humana; 13(3):79-96.
    </p>
    <p>
      Nogueira, J.M.R. (2004). O EURO 2004: A intervenção dos serviços de saúde
      pública. Resumo de comunicação oral no Congresso Qualidade e Segurança
      Alimentar inserido na XVI Semana de Ciências da Nutrição, Porto.
    </p>
    <p>
      Portaria n.º 149/1988, de 9 de março, relativa às regras de asseio e
      higiene a observar pelas pessoas que, na sua atividade profissional,
      entram em contacto com alimentos.
    </p>
    <p>
      Portaria n.º 1135/1995 de 15 de setembro, que estabelece as regras a
      observar na utilização das gorduras e óleos na preparação e fabrico de
      géneros alimentícios.
    </p>
    <p>
      Regulamento (CE) n.º 178/2002 do Parlamento Europeu e do Conselho, de 28
      de janeiro que determina os princípios e normas gerais da legislação
      alimentar, cria a Autoridade Europeia para a Segurança dos Alimentos e
      estabelece em matéria de segurança dos géneros alimentícios.
    </p>
    <p>
      Regulamento (CE) n.º 852/2004 do Parlamento Europeu e do Conselho, de 29
      de abril relativo à higiene dos géneros alimentícios.
    </p>
    <p>
      Regulamento (CE) n.º 2073/2005 da Comissão, de 15 de novembro relativo a
      critérios microbiológicos aplicáveis aos géneros alimentícios.
    </p>
    <p>
      Regulamento (CE) n.º 1441/2007 da Comissão, de 5 de dezembro relativo a
      critérios microbiológicos aplicáveis aos géneros alimentícios.
    </p>
    <p>
    REGULAMENTO (UE) 2021/382 DA COMISSÃO de 3 de março de 2021 que altera os 
    anexos do Regulamento (CE) nº 852/2004 do Parlamento Europeu e do Conselho 
    relativo à higiene dos géneros alimentícios no que se refere à gestão de 
    alergénios alimentares, à redistribuição dos alimentos e à cultura de segurança 
    dos alimentos.
    </p>
    <p>
      Santos, M.C.T.R.D.T. (2005). Segurança Alimentar/Euro 2004 [Dissertação de
      Mestrado em Saúde Pública Veterinária]. Lisboa: Faculdade de Medicina
      Veterinária da Universidade Técnica de Lisboa.
    </p>
    <p>
      Veiros, M.B., Macedo, S.M., Santos, M.C.T., Proença, R.P.C., Rocha, A.,
      Kent-Smith, L. (2007). Proposta de Check List higiossanitária para
      unidades de restauração. Alimentação Humana; 13 (3): 51 – 61.
    </p>
    <p>
      Yeep, M. (2004). Avaliação das condições higiossanitárias do sector
      alimentar em jardins-de-infância [Tese de Licenciatura]. Porto: Faculdade
      de Ciências da Nutrição e Alimentação da Universidade do Porto.
    </p>
  </>
);

export default panelReferences;
