import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Layout from '../components_lgp/layout/Layout';
import Page from '../components/common/Page';
import FullPage from '../components_lgp/MealPlanning/pageLayout';
import View from '../components_lgp/TechnicalSheets/ViewTechnicalSheets';
import { info } from '../components_lgp/TechnicalSheets/TechnicalSheetBar.json';
import { setNotification } from '../actions/notificationActions';
import FileSaver from 'file-saver';
import {
  exportTechnicalSheet as exportReport,
} from '../services/technicalSheetsService';


const TechnicalSheetsView = () => {
  const { state: locationState } = useLocation();
  const { id } = useParams();
  const [loading,setLoading] = useState(false);
  const name = locationState?.name;
  const [techSheetName, setTechSheetName] = useState(name ?? 'Ficha Técnica');

  const handleExport = async () => {
    if (!loading) {
      try {
        setLoading(true);
        const { data } = await exportReport(id);
        const file = new Blob([data], {
          type: 'application/pdf',
        });
        FileSaver.saveAs(
          file,
          `${techSheetName.replace(/\s/g, '_')}.pdf`,
        );
      } catch (error) {
        setNotification({
          message: 'Não foi possível exportar este relatório.',
          type: 'error',
        });
      }
      setLoading(false);
    }
  };




  return (
    <Page title={techSheetName}>
      <Layout
        breadcrumb_links={[
          {
            name: 'Planear',
            url: '/planear',
          },
          {
            name: 'Gerir Fichas Técnicas',
            url: '/planear/fichastecnicas',
          },
          {
            name: 'Consulta de Ficha Técnica',
            url: `/planear/fichastecnicas/${id}`,
          },
        ]}
      >
        <FullPage
          main={<View id={id} setTechSheetName={setTechSheetName} />}
          links={[
            {
              label: 'Imprimir',
              onClick: handleExport,
            },
          ]}
          info={info}
        />
      </Layout>
    </Page>
  );
};

export default TechnicalSheetsView;
