import React from 'react';

import Layout from '../../components_lgp/layout/Layout';
import Page from '../../components/common/Page';
import NutritionalRecomForm from '../../components_lgp/RecNutri/NutritionalRecomForm';

const EditNutritionalRecom = () => {
  return (
    <Page title="Gerir Recomendações Nutricionais">
      <Layout
        breadcrumb_links={[
          {
            name: 'Administração',
            url: '/admin',
          },
          {
            name: 'Gerir Recomendações Nutricionais',
            url: '/admin/recomendacoes-nutricionais',
          },
        ]}
      >
        <NutritionalRecomForm />
      </Layout>
    </Page>
  );
};

export default EditNutritionalRecom;
