import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  ListGroup,
  ListGroupItem,
  Col,
  Button,
  Alert,
  Card,
  CardBody,
  Container,
  Input,
} from 'reactstrap';
import MealChart from './MealChart';
import MealSearch from './MealSearch';
import styles from './EditMealPlan.module.css';

const EditMealPlan = props => {
  const { selectedMeal } = props;
  if (selectedMeal == null) {
    return <></>;
  }
  const apagarTechnical = index => {
    const novaMeal = selectedMeal;
    novaMeal.technicalSheets.splice(index, 1);
    props.setSelectedMeal({
      ...selectedMeal,
      technicalSheets: novaMeal.technicalSheets,
    });
  };
  return (
    <Card style={{ marginTop: '2em' }}>
      <Container>
        <CardBody>
          <Row className={styles.rowName}>
            <Col>
              <Row>Nome da ementa</Row>
              <Row>
                <Input
                  onChange={e => {
                    props.setSelectedMeal({
                      ...selectedMeal,
                      name: e.target.value,
                    });
                  }}
                  type="text"
                  placeholder="Nome da ementa"
                  value={selectedMeal.name}
                  autoComplete="off"
                />
              </Row>
            </Col>
          </Row>
          <Row className={styles.rowName}>
            Fichas técnicas
            <ListGroup className={styles.listaTechSheet}>
              {(() => {
                if (selectedMeal.technicalSheets.length > 0)
                  return selectedMeal.technicalSheets.map((element, index) => {
                    return (
                      <Row key={element.name}>
                        <Col md="10">
                          <ListGroupItem>
                            <Row>
                              <Col>Nome: {element.name}</Col>
                              <Col>Componente: {element.component_type}</Col>
                              <Col>
                                Pessoas servidas: {element.num_people_served}
                              </Col>
                            </Row>
                          </ListGroupItem>
                        </Col>
                        <Col md="2" className={styles.apagarFichasTecnicas}>
                          <Button
                            onClick={() => apagarTechnical(index)}
                            outline
                            color="danger"
                          >
                            Apagar
                          </Button>
                        </Col>
                      </Row>
                    );
                  });
                if (!selectedMeal.isLoadingTechSheets)
                  return <>Não foram adicionadas fichas técnicas</>;
                // eslint-disable-next-line
                return <></>;
              })()}
            </ListGroup>
            {selectedMeal.isLoadingTechSheets && (
              <Col style={{ paddingTop: '5px' }} xs="12">
                <Alert color="warning">Atualizando fichas técnicas...</Alert>
              </Col>
            )}
          </Row>
          <Row className={styles.rowName}>
            <MealSearch
              selectedMeal={selectedMeal}
              // eslint-disable-next-line react/destructuring-assignment
              setSelectedMeal={props.setSelectedMeal}
            />
          </Row>
          <Row>
            <Col xs="12">
              <MealChart
                nutritionalInfo={selectedMeal.total_nutritional_values}
              />
            </Col>
          </Row>
        </CardBody>
      </Container>
    </Card>
  );
};

EditMealPlan.propTypes = {
  // eslint-disable-next-line react/require-default-props
  selectedMeal: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    date: PropTypes.string,
    technicalSheets: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        component_type: PropTypes.string,
        num_people_served: PropTypes.number,
      }),
    ),
    total_nutritional_values: PropTypes.shape({}),
    type: PropTypes.string,
    trigger: PropTypes.bool,
    isLoadingTechSheets: PropTypes.bool,
  }),
  setSelectedMeal: PropTypes.func.isRequired,
};

export default EditMealPlan;
