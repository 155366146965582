import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';

import styles from './Overview.module.css';

const Overview = ({ createdAt, lastUpdated }) => (
  <Card>
    <CardHeader className={styles.cardHeader}>
      <h2>Informação geral</h2>
    </CardHeader>
    <CardBody>
      <div className={styles.overviewDetails}>
        <h3>Data de criação</h3>
        <hr />
        <p>{moment(createdAt).format('LLLL')}</p>
        <h3> Última modificação</h3>
        <hr />
        <p>{moment(lastUpdated).format('LLLL')}</p>
      </div>
    </CardBody>
  </Card>
);

Overview.propTypes = {
  createdAt: PropTypes.string.isRequired,
  lastUpdated: PropTypes.string.isRequired,
};

export default Overview;
