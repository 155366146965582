import React from 'react';
import classNames from 'classnames';
import { NavLink as RRNavLink } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import {
  FaUserTie,
  FaAppleAlt,
  FaClipboardList,
  FaCalendarAlt,
  FaAngleLeft,
  FaFile,
  FaUsers,
} from 'react-icons/fa';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { openSidebar, collapseSidebar } from '../../actions/sidebarActions';
import styles from './SideBar.module.css';

const Sidebar = ({ isCollapsed, open, collapse, isAdmin }) => {
  const toggle = () => {
    if (isCollapsed) {
      open();
    } else {
      collapse();
    }
  };

  return (
    <div
      className={classNames(styles.sidebar, {
        [styles['is-open']]: !isCollapsed,
      })}
    >
      <div className={styles['side-menu']}>
        <Nav>
          <NavItem className={styles['nav-item']}>
            <NavLink
              tag={RRNavLink}
              to="/planear/fichastecnicas"
              className={styles['nav-link']}
            >
              <FaClipboardList className={styles.icon} />
              {!isCollapsed ? 'Fichas Técnicas' : ''}
            </NavLink>
          </NavItem>
          <NavItem className={styles['nav-item']}>
            <NavLink
              tag={RRNavLink}
              to="/admin/ingredientes"
              className={styles['nav-link']}
            >
              <FaAppleAlt className={styles.icon} />
              {!isCollapsed ? 'Ingredientes' : ''}
            </NavLink>
          </NavItem>
          <NavItem className={styles['nav-item']}>
            <NavLink
              tag={RRNavLink}
              to="/ementas"
              className={styles['nav-link']}
            >
              <FaCalendarAlt className={styles.icon} />
              {!isCollapsed ? 'Ementas' : ''}
            </NavLink>
          </NavItem>
          <NavItem className={styles['nav-item']}>
            <NavLink
              tag={RRNavLink}
              to="/avaliar"
              className={styles['nav-link']}
            >
              <FaFile className={styles.icon} />
              {!isCollapsed ? 'Relatórios' : ''}
            </NavLink>
          </NavItem>
          {isAdmin && (
            <>
              <NavItem className={styles['nav-item']}>
                <NavLink
                  tag={RRNavLink}
                  to="/admin/utilizadores"
                  className={styles['nav-link']}
                >
                  <FaUsers className={styles.icon} />
                  {!isCollapsed ? 'Utilizadores' : ''}
                </NavLink>
              </NavItem>
              <NavItem className={styles['nav-item']}>
                <NavLink
                  tag={RRNavLink}
                  to="/admin"
                  className={styles['nav-link']}
                >
                  <FaUserTie className={styles.icon} />
                  {!isCollapsed ? 'Administração' : ''}
                </NavLink>
              </NavItem>
            </>
          )}
          <button className={styles.closeBtn} type="button" onClick={toggle}>
            <FaAngleLeft className={styles.icon} />
            {!isCollapsed ? 'Colapsar' : ''}
          </button>
        </Nav>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  open: PropTypes.func.isRequired,
  collapse: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ sidebar, auth }) => ({
  isCollapsed: sidebar,
  isLoggedIn: auth.loggedIn,
  isAdmin: auth.userType === 'admin',
});
const mapDispatchToProps = {
  open: openSidebar,
  collapse: collapseSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
