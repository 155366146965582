import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import React from 'react';
import FaqListItem from './FaqListItem';
import faqItems from './Faq.json';

const useStyles = makeStyles(theme =>
  createStyles({
    title: {
      color: theme.palette.primaryRed.main,
      marginBottom: '0.75em',
    },
  }),
);

const FaqSection = () => {
  const styles = useStyles();
  return (
    <>
      <Typography className={styles.title} variant="h1">
        Perguntas Frequentes
      </Typography>
      <FaqListItem list={faqItems} />
    </>
  );
};

export default FaqSection;
