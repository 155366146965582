import React, { useState } from 'react';
import {
  Container,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Button,
  Row,
  CardHeader,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import LoadingAnimation from './LoadingAnimation';
import { submitResetPassword } from '../../services/authService';
import { setNotification } from '../../actions/notificationActions';

const ResetPasswordForm = ({ token }) => {
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const history = useHistory();

  const submitForm = e => {
    e.preventDefault();
    setSubmitting(true);
    setErrorMsg('');

    submitResetPassword({
      token,
      password,
      passwordConfirmation,
    })
      .then(() => {
        dispatch(
          setNotification({
            message: 'A sua palavra-chave foi alterada com successo.',
            type: 'success',
          }),
        );
        history.push({
          pathname: '/login',
        });
      })
      .catch(error => {
        setErrorMsg(error.response.data.error || 'Ocorreu algum erro.');
        setSubmitting(false);
      });
  };

  return (
    <Container>
      <Row className="mt-3 mt-lg-4 pt-xl-5">
        <Col xs={{ size: 12 }} lg={{ size: 9 }} xl={{ size: 6, offset: 3 }}>
          <Card>
            <CardHeader>
              <h2 className="my-1">Recuperar Conta</h2>
            </CardHeader>
            <div className="position-relative">
              {isSubmitting && <LoadingAnimation />}
              <CardBody>
                <Form className="form" onSubmit={submitForm}>
                  <Col>
                    {!errorMsg && (
                      <Alert color="primary" fade={false}>
                        Indique a nova palavra-chave para a sua conta.
                      </Alert>
                    )}
                  </Col>
                  <Col>
                    {errorMsg && (
                      <Alert color="danger" fade={false}>
                        {errorMsg}
                      </Alert>
                    )}
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="password">Nova palavra-chave</Label>
                      <Input
                        type="password"
                        name="password"
                        id="password"
                        placeholder=""
                        required
                        minLength="6"
                        maxLength="30"
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="password_confirmation">
                        Confirmar nova palavra-chave
                      </Label>
                      <Input
                        type="password"
                        name="password_confirmation"
                        id="password_confirmation"
                        placeholder=""
                        required
                        minLength="6"
                        maxLength="30"
                        onChange={e => setPasswordConfirmation(e.target.value)}
                        value={passwordConfirmation}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <Button color="success" className="px-4 mt-1">
                      Enviar
                    </Button>
                  </Col>
                </Form>
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

ResetPasswordForm.propTypes = {
  token: PropTypes.string.isRequired,
};

export default ResetPasswordForm;
