import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Notification from './Notification';

const Page = ({ title, children }) => (
  <>
    <Helmet>
      <title>{title} | SPARE</title>
    </Helmet>
    <Notification />
    {children}
  </>
);

Page.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Page;
