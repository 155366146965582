import axios from 'axios';

const baseUrl = '/api/admin/getUsers';

const fetchAllLastUsers = async () => {
  return axios.get(baseUrl);
};

// eslint-disable-next-line import/prefer-default-export
export { fetchAllLastUsers };
