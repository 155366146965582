import React from 'react';
import Layout from '../components_lgp/layout/Layout';
import ConsHigSani from '../components_lgp/ConsHigSani/ConsHigSani';
import Page from '../components/common/Page';

const breadcrumb = [
  {
    name: 'Consultar',
    url: '/consultar',
  },
  {
    name: 'Considerações higiossanitárias',
    url: '',
  },
];

const ConsHigioSanitarias = () => (
  <Page title="Considerações higiossanitárias">
    <Layout breadcrumb_links={breadcrumb}>
      <ConsHigSani />
    </Layout>
  </Page>
);

export default ConsHigioSanitarias;
