import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Spinner,
  CardHeader,
} from 'reactstrap';

import { changePassword } from '../../services/profileService';

import {
  clearNotification,
  setNotification,
} from '../../actions/notificationActions';

const useStyles = makeStyles(() => ({
  btn: {
    width: 'fit-content',
  },
  box: {
    padding: '1.5em',
  },
}));

const ChangePasswordForm = () => {
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);

  const styles = useStyles();

  const submitForm = e => {
    e.preventDefault();
    dispatch(clearNotification());

    setSubmitting(true);

    changePassword({
      password,
      passwordConfirmation,
    })
      .then(() => {
        dispatch(
          setNotification({
            message: 'A sua palavra-chave foi actualizada.',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        if (error.response.status !== 200) {
          dispatch(
            setNotification({
              message: 'Ocorreu um erro ao alterar a sua palavra-chave.',
              type: 'error',
            }),
          );
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <Card>
        <CardHeader>Alterar Palavra-Chave</CardHeader>
        <CardBody className={styles.box}>
          <form onSubmit={submitForm}>
            {isSubmitting && (
              <div className="my-5 d-flex justify-content-center">
                <Spinner color="success" size="md" />
              </div>
            )}
            {!isSubmitting && (
              <>
                <FormGroup>
                  <Label for="password">Palavra-chave</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    minLength="6"
                    maxLength="30"
                    required
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password_confirmation">
                    Confirmar Palavra-chave
                  </Label>
                  <Input
                    type="password"
                    name="password_confirmation"
                    id="password_confirmation"
                    minLength="6"
                    maxLength="30"
                    required
                    onChange={e => setPasswordConfirmation(e.target.value)}
                    value={passwordConfirmation}
                  />
                </FormGroup>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  type="submit"
                  label="submit"
                  className={styles.btn}
                >
                  Alterar
                </Button>
              </>
            )}
          </form>
        </CardBody>
      </Card>
    </>
  );
};

export default ChangePasswordForm;
