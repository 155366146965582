import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import Calendar from './Calendar';
import DailyMealsTable from './DailyMealsTable';
import DaysInWeek from './DaysInWeek';
import IntervalSelect from './IntervalSelect';
import ExportPlanButton from './ExportPlanButton';

const moment = extendMoment(Moment);

const WeekView = ({ date, setDate, meals, interval, setInterval, options }) => (
  <>
    <Row className="mt-4">
      <Col
        lg="4"
        className="d-flex justify-content-center align-items-center flex-column"
      >
        <IntervalSelect
          interval={interval}
          setInterval={setInterval}
          options={options}
        />
        <Calendar date={date} setDate={setDate} />
      </Col>
      <Col
        lg="8"
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <h4>Dias da semana</h4>
        <DaysInWeek
          week={moment.range(
            moment(date).startOf('week'),
            moment(date).endOf('week'),
          )}
          date={date}
          setDate={setDate}
        />
        <ExportPlanButton date={date} className="mt-3" />
      </Col>
    </Row>
    <Row>
      <Col>
        <DailyMealsTable
          meals={meals.filter(
            meal => meal.date === moment(date).format('YYYY-MM-DD'),
          )}
          date={date}
        />
      </Col>
    </Row>
  </>
);

WeekView.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  setDate: PropTypes.func.isRequired,
  meals: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  interval: PropTypes.string.isRequired,
  setInterval: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
};

export default WeekView;
