import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import styles from './DiversityFilters.module.css';

import Icon from '../common/Icon';

const getYearsForSelector = () => {
  const years = [];
  const currentYear = new Date().getFullYear();

  for (let year = 2009; year <= currentYear; year += 1) {
    years.push(<option key={year}>{year}</option>);
  }

  return years;
};

const getMonthsForSelector = () => {
  const months = [];

  for (let month = 1; month <= 12; month += 1) {
    months.push(<option key={month}>{month}</option>);
  }

  return months;
};

const DiversityFilters = ({
  children,
  parametersUpdater,
  types,
  institutions,
  areInstitutionsLoading,
}) => {
  const [currentType, setCurrentType] = useState(0);
  const [currentInstitution, setInstitution] = useState(0);
  var date = new Date();
  const [currentMonth, setMonth] = useState(date.getMonth()+1);
  const [currentYear, setYear] = useState(date.getFullYear());

  const changeType = isFoward => {
    const newType = isFoward ? currentType + 1 : currentType - 1;
    /*
     * FOR SOME REASON
     *
     * const newType = isFoward ? ((currentType + 1) % types.length): ((currentType - 1) % types.length);
     *
     * DOESN'T WORK, SOMEONE IN CODE REVIEW WHO SEE'S THIS
     * TELL ME HOW TO MAKE IT WORK
     */
    if (newType === -1) {
      setCurrentType(types.length - 1);
    } else if (newType === types.length) {
      setCurrentType(0);
    } else {
      setCurrentType(newType);
    }
  };

  const institutionChange = event => {
    setInstitution(event.target.value);
  };

  const monthChange = event => {
    setMonth(event.target.value)
  };

  const yearChange = event => {
    setYear(event.target.value);
  };

  /* EVERY TIME SOME PARAMETER CHANGES, INFORM PARENT OF CHANGE SO NEW REQUEST IS MADE */
  useEffect(() => {
    parametersUpdater(
      currentInstitution,
      currentMonth,
      currentYear,
      currentType,
    );
  }, [
    currentType,
    currentInstitution,
    currentMonth,
    currentYear,
  ]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.filters}>
        <label htmlFor="a">
          Instituição:
          <select
            value={currentInstitution}
            onChange={e => institutionChange(e)}
            id="a"
            disabled={areInstitutionsLoading}
          >
            <option value="0">Escolha uma instituição...</option>
            {institutions.map(institution => (
              <option key={institution.id} value={institution.id}>
                {institution.name}
              </option>
            ))}
          </select>
        </label>
        <label htmlFor="b">
          Data:
          <select value={currentMonth} onChange={e => monthChange(e)} id="b">
            {getMonthsForSelector()}
          </select>
          <span>/</span>
          <select value={currentYear} onChange={e => yearChange(e)}>
            {getYearsForSelector()}
          </select>
        </label>
      </div>
      <div className={styles.typeSelector}>
        <button type="button" onClick={() => changeType(false)}>
          <ArrowBackIosIcon />
        </button>
        <button type="button" onClick={() => changeType(true)}>
          <ArrowBackIosIcon />
        </button>
        <Icon name={types[currentType]} />
        {types[currentType]}
      </div>
      <div className={styles.tableTitle}>
        <h5>Análise de Diversidade</h5>
        <p>
          | PA - Pequeno Almoço | MM - Merenda da manhã | AL - Almoço | MT -
          Merenda da tarde | J - Jantar | C - Ceia
        </p>
      </div>
      {children}
    </div>
  );
};

DiversityFilters.propTypes = {
  children: PropTypes.node.isRequired,
  parametersUpdater: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
  institutions: PropTypes.arrayOf(
    PropTypes.exact({ id: PropTypes.number, name: PropTypes.string }),
  ).isRequired,
  areInstitutionsLoading: PropTypes.bool.isRequired,
};

export default DiversityFilters;
