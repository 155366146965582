import React, { useContext } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import { MealsContextProvider, MealsContext } from './MealsContextProvider';
import MealPlanPicker from './MealPlanPicker';
import PlanningSection from './PlanningSections/PlanningSection';
import ExportPlanButton from './ExportPlanButton';
import { mealPlanningTheme } from './styles';

const MealContextConsumer = ({ isModalVisible, toggleModal }) => {
  const {
    isLoaded,
    userInput: { selectedOrganization, selectedDate },
    mealRecommendations
  } = useContext(MealsContext);
  return (
    <ThemeProvider theme={mealPlanningTheme}>
      <MealPlanPicker />
      {isLoaded() ? (
        <><ul>
          Valores diários recomendados: 
          {mealRecommendations.map(function(value) {
            return( 
              <li> 
                {value.name.toString() + ' entre ' + value.minValue.toString() + ' ' + value.unit.toString() +  ' e ' + value.maxValue.toString()+ ' ' + value.unit.toString()} 
              </li>)
            })}
          </ul>
          <PlanningSection />
          <ExportPlanButton
            organizationId={selectedOrganization.id}
            isModalVisible={isModalVisible}
            toggleModal={toggleModal}
            date={selectedDate}
          />
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            marginTop: '2em',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </ThemeProvider>
  );
};
const MealPlanning = ({ isModalVisible, toggleModal }) => {
  return (
    <MealsContextProvider>
      
      <MealContextConsumer
        isModalVisible={isModalVisible}
        toggleModal={toggleModal}
      />
    </MealsContextProvider>
  );
};

MealPlanning.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

MealContextConsumer.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default MealPlanning;
