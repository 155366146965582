import React from 'react';

import PropTypes from 'prop-types';
import { Table } from './DiversityTableElements';

const extractInfoFromIngridients = (minDay, maxDay, days) => {
  const extraction = [];

  Object.keys(days).forEach(day => {
    const date = day;

    const auxToCompare = new Date(date).getTime();

    if (auxToCompare >= minDay.getTime() && auxToCompare <= maxDay.getTime()) {
      const mealsOfTheDay = Object.keys(days[day]);

      for (let i = 0; i < mealsOfTheDay.length; i += 1) {
        const mealOfTheDay = days[day][mealsOfTheDay[i]];

        const partOfDay = mealsOfTheDay[i];
        const meals = Object.keys(mealOfTheDay.technical_sheets);

        for (let j = 0; j < meals.length; j += 1) {
          const meal = mealOfTheDay.technical_sheets[meals[j]];
          
          if (meal) {
            const { name } = meal;
            const type = meal.component_type;
            let alreadyExists = false;
            for (let n = 0; n < extraction.length; n += 1) {
              if (extraction[n].name === name && extraction[n].type === type) {
                // if the name and type are the ones we are analysing
                let ocorrenceAlreadyExists = false;
                for (let k = 0; k < extraction[n].ocorrences.length; k += 1) {
                  // go through that object ocorrences
                  if (extraction[n].ocorrences[k].ocorrenceDate === date) {
                    let partOfTheDayAlreadyExists = false;
                    for (
                      let o = 0;
                      o < extraction[n].ocorrences[k].partsOfTheDay.length;
                      o += 1
                    ) {
                      if (
                        extraction[n].ocorrences[k].partsOfTheDay[o] ===
                        partOfDay
                      ) {
                        partOfTheDayAlreadyExists = true;
                        break;
                      }
                    }

                    if (!partOfTheDayAlreadyExists) {
                      extraction[n].ocorrences[k].partsOfTheDay.push(partOfDay);
                    }
                    ocorrenceAlreadyExists = true;
                    break;
                  }
                }
                if (!ocorrenceAlreadyExists) {
                  extraction[n].ocorrences.push({
                    ocorrenceDate: date,
                    partsOfTheDay: [partOfDay],
                  });
                }
                alreadyExists = true;
                break;
              }
            }
            if (!alreadyExists) {
              const newIngredient = {
                name,
                type,
                ocorrences: [
                  { ocorrenceDate: date, partsOfTheDay: [partOfDay] },
                ],
              };
              extraction.push(newIngredient);
            }
          }
        }
      }
    }
  });
  return extraction;
};

const filterType = (componentsList, type) => {
  const final = [];

  for (let i = 0; i < componentsList.length; i += 1) {
    if (componentsList[i].type === type) {
      final.push(componentsList[i]);
    }
  }

  return final;
};

const getWeekHeaders = (firstDay, lastDay) => {
  const weekHeaders = [];
  let aux;
  const firstAux = new Date(firstDay);

  do {
    aux = new Date(firstAux); // last day of the week is monday date + 4 days

    aux.setDate(aux.getDate() + 6);

    weekHeaders.push(
      `${firstAux.getDate()}/${firstAux.getMonth() +
        1} - ${aux.getDate()}/${aux.getMonth() + 1}`,
    );

    firstAux.setDate(firstAux.getDate() + 7); // next monday is + 7 days
  } while (firstAux.getTime() < lastDay.getTime());

  return weekHeaders;
};

const DiversityTable = ({
  typeInAnalisis,
  year,
  month,
  ingridients,
  isMobile,
  isSmallScreen,
}) => {
  const firstDayOfTheWeekOfTheMonth = new Date(`${year}-${month}-01`).getDay();

  let numDaysBack = 0;
  let numDaysFoward = 0;

  numDaysBack = firstDayOfTheWeekOfTheMonth;

  const lastDayOfTheWeekOfTheMonth =
    new Date(year,month,0).getDay() === 0
      ? 6
      : new Date(year,month,0).getDay() - 1;

  numDaysFoward = 6 - lastDayOfTheWeekOfTheMonth;

  const firstDayToDisplay = new Date(
    Number(year),
    Number(month) - 1,
    1 - numDaysBack,
  );

  const lastDayToDisplay = new Date(Number(year), Number(month), numDaysFoward);

  const data = extractInfoFromIngridients(
    firstDayToDisplay,
    lastDayToDisplay,
    ingridients,
  );

  const filteredIngridients = filterType(data, typeInAnalisis);

  const headers = getWeekHeaders(firstDayToDisplay, lastDayToDisplay);

  return (
    <Table
      weeks={headers}
      ingridients={filteredIngridients}
      begin={firstDayToDisplay}
      type={typeInAnalisis}
      isMobile={isMobile}
      isSmallScreen={isSmallScreen}
    />
  );
};

DiversityTable.propTypes = {
  typeInAnalisis: PropTypes.string,
  year: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  //ingridients: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
};

export default DiversityTable;
