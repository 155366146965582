import React from 'react';
import Page from '../components/common/Page';

import Layout from '../components_lgp/layout/Layout';
import SignUpForm from '../components/Authentication/SignUpForm';

const SignUp = () => {
  return (
    <Page title="Efetuar Registo">
      <Layout
        breadcrumb_links={[
          {
            name: 'Home',
            url: '/',
          },
          {
            name: 'Registo',
            url: '/registar',
          },
        ]}
      >
        <SignUpForm />
      </Layout>
    </Page>
  );
};

export default SignUp;
