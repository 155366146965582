import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { makeStyles, createStyles } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';

import { useMediaQuery } from 'react-responsive';
import panelWelcome from './Welcome';
import panelTechnicalSheet from './TechnicalSheet';
import panelAboutSpare from './AboutSpare';
import panelReferences from './References';

const useStyles = makeStyles(theme =>
  createStyles({
    heading: {
      color: 'rgb(183,106,95)',
      fontSize: '1.5rem',
    },
    text: {
      marginTop: theme.spacing(2),
    },
    link: {
      display: 'block',
      color: theme.palette.primaryRed.main,
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.secondaryRed.main,
        textDecoration: 'none',
      },
    },
    contactLink: {
      extend: 'link',
      color: 'inherit',
    },
    textContent: {
      width: '100%',
      '& p': {
        overflow: 'auto',
      },
    },
    div: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    img: {
      height: '4em',
    },
    smallImg: {
      height: '3em',
    },
  }),
);

const panelContacts = (styles, isSmallScreen) => {
  return (
    <div>
      <a href="http://www.alimentacaosaudavel.dgs.pt/">
        <img
          src={`${process.env.PUBLIC_URL}/logos/pnpas.png`}
          alt="Programa Nacional Para a Promoção da Alimentação Saudável"
          width={isSmallScreen ? '100%' : '50%'}
        />
      </a>
      <div className={styles.text}>
        <a
          href="http://www.alimentacaosaudavel.dgs.pt/"
          className={styles.contactLink}
        >
          PNPAS
        </a>
        <a href="mailto:pnpas@dgs.min-saude.pt" className={styles.link}>
          pnpas@dgs.min-saude.pt
        </a>
      </div>
    </div>
  );
};

export default function AboutInfo() {
  const styles = useStyles();
  const isSmallScreen = useMediaQuery({ maxWidth: '1070px' });

  const [expanded, setExpanded] = React.useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  },[expanded]);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);    
  };

  return (
    <Container fluid>
      <Grid container spacing={3}>
        <Grid item zeroMinWidth sm={12} md={8}>
          <ExpansionPanel
            defaultExpanded
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={styles.heading}>Bem-vindo</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography component="span">{panelWelcome}</Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}

          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={styles.heading}>
                O que é e para que serve
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography component="span">{panelAboutSpare}</Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}

          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
              
            >
              <Typography className={styles.heading}>Ficha Técnica</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography component="span">
                {panelTechnicalSheet(styles, isSmallScreen)}
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === 'panel4'}
            onChange={handleChange('panel4')}

          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className={styles.heading}>Contactos</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {panelContacts(styles, isSmallScreen)}
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={expanded === 'panel5'}
            onChange={handleChange('panel5')}

          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
            >
              <Typography className={styles.heading}>Referências</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography component="span" className={styles.textContent}>
                {panelReferences}
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>

        <Grid item sm={12} md={4}>
          <img
            src={`${process.env.PUBLIC_URL}/logos/spare.png`}
            alt="spare logo"
            width="100%"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
