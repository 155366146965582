import React from 'react';

const panelAboutSpare = (
  <>
    <p>
      O SPARE - Sistema de Planeamento e Avalia&ccedil;&atilde;o de
      Refei&ccedil;&otilde;es Escolares - &eacute; uma ferramenta
      inform&aacute;tica que permite planear refei&ccedil;&otilde;es de forma
      efetiva e organizada, de acordo com as principais
      recomenda&ccedil;&otilde;es alimentares e nutricionais nacionais e
      internacionais vigentes. Esta ferramenta permite o planeamento,
      avalia&ccedil;&atilde;o, monitoriza&ccedil;&atilde;o e
      verifica&ccedil;&atilde;o no sentido da melhoria cont&iacute;nua da
      qualidade das refei&ccedil;&otilde;es escolares.
    </p>
    <p>
      Pretende-se envolver toda a comunidade escolar - incluindo as
      fam&iacute;lias, na promo&ccedil;&atilde;o e refor&ccedil;o de
      comportamentos alimentares saud&aacute;veis transmitindo linhas de
      orienta&ccedil;&atilde;o, coerentes e concordantes, veiculadas para as
      escolas pelas entidades com compet&ecirc;ncia nesta &aacute;rea do saber.
    </p>
    <span>
      Trata-se de uma ferramenta inform&aacute;tica cujos objetivos e
      funcionalidades, dependendo do tipo de utilizador, permitir&atilde;o:
      <ol>
        <li>
          conhecer, identificar e verificar as pol&iacute;ticas alimentares
          escolares;
        </li>
        <li>
          facultar informa&ccedil;&atilde;o necess&aacute;ria de forma a
          possibilitar, planear, estruturar e organizar o seu dia alimentar;
        </li>
        <li>
          planear ementas (pequeno almo&ccedil;o, merendas, almo&ccedil;o,
          jantar e ceia) adequadas &agrave;s idades da popula&ccedil;&atilde;o
          escolar, ainda que nesta vers&atilde;o, seja tamb&eacute;m
          poss&iacute;vel planear ementas considerando os demais grupos
          populacionais;
        </li>
        <li>
          calcular, verificar e monitorizar o valor e equil&iacute;brio
          nutricional das ementas;
        </li>
        <li>considerar a variedade semanal e mensal da oferta alimentar;</li>
        <li>avaliar e monitorizar a qualidade de ementas;</li>
        <li>
          avaliar e monitorizar as condi&ccedil;&otilde;es de higiene e
          seguran&ccedil;a alimentar.
        </li>
      </ol>
    </span>
  </>
);

export default panelAboutSpare;
