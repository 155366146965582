import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';

import Layout from '../components_lgp/layout/Layout';
import Page from '../components/common/Page';
import Progress from '../components_lgp/Diversity/Progress';
import Error from '../components_lgp/Diversity/Error';
import DiversityFilters from '../components_lgp/Diversity/DiversityFilters';
import DiversityTable from '../components_lgp/Diversity/DiversityTable';
import { fetchAllOrganizations } from '../services/organizationsService';
import getComponentTypes from '../services/componentTypesService';

const baseUrl = '/api/analysis';

const fetchAnalysis = async (institution, month, year) => {
  return axios.get(`${baseUrl}/${institution}/${year}/${month}`);
};

const breadcrumbLinks = [
  {
    name: 'Planear',
    url: '/planear',
  },
  {
    name: 'Gerir ementas',
    url: '/ementas',
  },
  {
    name: 'Diversidade',
    url: '/ementas/diversidade',
  },
];

const DiversityPage = () => {
  const isMobile = useMediaQuery({ maxWidth: '767px' });
  const isSmallScreen = useMediaQuery({ maxWidth: '1070px' });

  const [isError, setError] = useState(false);

  const [institutions, setInstitutions] = useState([]);
  const [institutionsAreLoading, setInstitutionsAreLoading] = useState(false);
  const [institutionsAreLoaded, setInstitutionsAreLoaded] = useState(false);

  const [types, setTypes] = useState([]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      setInstitutionsAreLoading(true);
      try {
        const { data } = await fetchAllOrganizations();
        getComponentTypes().then(({ data: componentTypes }) => {
          setTypes(componentTypes);
        });
        setInstitutionsAreLoaded(true);
        setInstitutions(data);
      } catch (e) {
        setError(true);
      }
      setInstitutionsAreLoading(false);
    };

    fetchOrganizations();
  }, []);

  const [currentType, setCurrentType] = useState('undefined');
  const [currentInstitution, setInstitution] = useState('undefined');
  const [currentMonth, setMonth] = useState(1);
  const [currentYear, setYear] = useState(2020);


  const [analysis, setAnalysis] = useState([]);

  const [isAnalysisLoading, setAnalysisLoading] = useState(false);

  const [isAnalysisLoaded, setLoaded] = useState(false);

  function updateParams(
    updatedInstitution,
    updatedMonth,
    updatedYear,
    updatedType,
  ) {
    setInstitution(updatedInstitution);
    setCurrentType(updatedType);
    setMonth(updatedMonth);
    setYear(updatedYear);
  }

  useEffect(() => {
    const fetchanalysisForMonth = async () => {
      setAnalysisLoading(true);
      setError(false);
      try {
        const { data } = await fetchAnalysis(
          currentInstitution,
          currentMonth,
          currentYear,
        );
        setLoaded(true);
        setAnalysis(data);
      } catch (e) {
        setError(true);
      }
      setAnalysisLoading(false);
    };
    if (institutionsAreLoaded) {
      fetchanalysisForMonth();
    }
  }, [institutionsAreLoaded, currentInstitution, currentMonth, currentYear]);

  return (
    <Page title="Página Inicial">
      <Layout breadcrumb_links={breadcrumbLinks}>
        <DiversityFilters
          parametersUpdater={updateParams}
          types={types}
          institutions={institutions}
          areInstitutionsLoading={institutionsAreLoading}
        >
          {isError && <Error />}
          {isAnalysisLoading && <Progress />}
          {isAnalysisLoaded && (
            <DiversityTable
              typeInAnalisis={types[currentType]}
              year={currentYear}
              month={currentMonth}
              ingridients={analysis}
              isMobile={isMobile}
              isSmallScreen={isSmallScreen}
            />
          )}
        </DiversityFilters>
      </Layout>
    </Page>
  );
};

export default DiversityPage;
